import { CdkDrag, DragDropModule, Point } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { LetDirective } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, tap } from 'rxjs';
import { getPercentageFromPixel, getPixelFromPercentage, safeHotspotRangePercentage, safeHotspotRangePixel } from '../../helpers/pixel-utils';

/**
 *  ====== esempio di utilizzo ===============
 *
 *  <div class="classe-container">
      <img #mapImage
      (click)="creaHotspot($event)"
      [src]="source"
    />
      <datalean-hotspot *ngFor="let hotspot of hotspots"
        classBboundaryContainer=".classe-container"      //Input fondamentale per avere il boundary (non sconfinare l'hotspot)
        [coordinate]="{x: hotspot.x, y: hotspot.y}"
        label="{{index}}"
      >
      </datalean-hotspot>
    </div>
 *    rif. https://material.angular.io/cdk/drag-drop/overview
 */

@Component({
	selector: 'datalean-hotspot',
	templateUrl: './hotspot.component.html',
	styleUrls: ['./hotspot.component.scss'],
	standalone: true,
	imports: [CommonModule, CdkDrag, MatMenuModule, TranslateModule, DragDropModule, LetDirective],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HotspotComponent implements OnChanges {
	readonly epsilon = 0.0007;
	//NB le coordinate x ed y ci si aspetta che siano da 0 a 100 (perchè intese come percentuali)
	@Input() coordinate: Point = { x: 0, y: 0 };

	coordinate$ = new BehaviorSubject<Point | undefined>(undefined);
	coordinateInPx$ = this.coordinate$.pipe(
		tap((el) => {
			this.baseCoordinate = structuredClone(el); //salvo base
			this.oldCoordinate = undefined; //reset dopo il cambio base
		})
	);

	//coordinate salvate nell'ultimo punto in cui hai draggato l'hotspot
	//serve per eseguire logiche di cambio base (o per evitare che la base cambi)
	oldCoordinate: Point | undefined;
	baseCoordinate: Point | undefined; //base (in pixel) del componente (il cdk fa operazioni a partire da questo valore)

	//serve per evitare che il drag&drop sconfini, occorre passare ".CLASSE_CSS" del contenitore per tenere "boundary"
	@Input() classBboundaryContainer: string = ''; //FONDAMENTALE per il funzionamento (vedi commento in alto per esempio)
	@Input() label: string = '';
	@Output() deleteHotspot = new EventEmitter();
	@Output() newValueEmitted = new EventEmitter<{ x: number; y: number }>();

	@ViewChild(CdkDrag) cdkDrag: CdkDrag | undefined;

	@Input() backgorundRect: {
		width: number;
		height: number;
		x?: number;
		y?: number;
	} = {
		width: 50,
		height: 50,
	};

	//variabile di controllo usata per evitare l'apertura automatica
	//del menu una volta finito un drag&drop
	protected clickable: boolean = true;

	private lastModifyByDrag = false;

	ngOnChanges(changes: SimpleChanges) {
		const coordinateChange = changes['coordinate'];
		const backgorundRect = changes['backgorundRect'];

		if (coordinateChange?.currentValue) {
			// console.log("onChange", coordinateChange);

			const currentValue = {
				x: coordinateChange.currentValue.x ?? 0, //quando crei usando il pulsante viene passato il valore "null"
				y: coordinateChange.currentValue.y ?? 0,
			};
			//al primo cambio setto la base del componente (il cdkDrag usa base + i suoi offsetinterni)
			if (coordinateChange.isFirstChange()) {
				const pixelCoord = safeHotspotRangePixel(
					{
						x: getPixelFromPercentage(currentValue.x, this.backgorundRect.width),
						y: getPixelFromPercentage(currentValue.y, this.backgorundRect.height),
					},
					this.backgorundRect.width,
					this.backgorundRect.height
				);
				this.coordinate$.next(pixelCoord);
			} else {
				const safeNewCoord = safeHotspotRangePixel(
					{
						x: getPixelFromPercentage(currentValue.x, this.backgorundRect.width),
						y: getPixelFromPercentage(currentValue.y, this.backgorundRect.height),
					},
					this.backgorundRect.width,
					this.backgorundRect.height
				);
				//se le coordinate sono diverse allora il padre ci sta dicendo una nuova base
				//in realtà accettiamo una tolleranza di diversità per problemi di calcolo numerico
				if (
					!this.oldCoordinate ||
					((safeNewCoord.x !== this.oldCoordinate?.x || safeNewCoord.y !== this.oldCoordinate?.y) &&
						(Math.abs(safeNewCoord.x - this.oldCoordinate.x) > this.epsilon || Math.abs(safeNewCoord.y - this.oldCoordinate.y) > this.epsilon))
				) {
					// console.log(
					//   "******** SONO DIVERSI",
					//   Math.abs(safeNewCoord.x - (this.oldCoordinate?.x ?? 0)),
					//   Math.abs(safeNewCoord.y - (this.oldCoordinate?.y ?? 0)),
					//   (Math.abs(safeNewCoord.x - (this.oldCoordinate?.x ?? 0)) > this.epsilon ||
					//     Math.abs(safeNewCoord.y - (this.oldCoordinate?.y ?? 0)) > this.epsilon)
					// );
					// console.log("******** ultimo click", this.oldCoordinate);
					// console.log("******** valore corrente in percentuale", coordinateChange.currentValue);
					// console.log("******** x", getPixelFromPercentage(coordinateChange.currentValue.x, this.backgorundRect.width));
					// console.log("******** y", getPixelFromPercentage(coordinateChange.currentValue.y, this.backgorundRect.height));

					//cambio la base
					this.coordinate$.next(safeNewCoord);
					this.cdkDrag?.reset();
				}
				//altrimenti non devo eseguire nessuna azione
				if (!this.lastModifyByDrag) {
					// console.log("LANCIO NEXT,", currentValue);

					this.coordinate$.next(safeNewCoord);
					this.cdkDrag?.reset();
					this.lastModifyByDrag = false;
				}
				// else console.log("LASCIO STARE", currentValue);
			}
		} else if (backgorundRect?.currentValue) {
			if (
				backgorundRect.currentValue.width !== backgorundRect.previousValue.width ||
				backgorundRect.currentValue.height !== backgorundRect.previousValue.height
			) {
				//è cambiata la dimensione dell'immagine => occorre cambiare la base
				const safeNewCoord = safeHotspotRangePixel(
					{
						x: getPixelFromPercentage(this.coordinate?.x ?? 0, backgorundRect.currentValue.width),
						y: getPixelFromPercentage(this.coordinate?.y ?? 0, backgorundRect.currentValue.height),
					},
					backgorundRect.currentValue.width,
					backgorundRect.currentValue.height
				);

				//cambio la base
				this.coordinate$.next(safeNewCoord);
				this.cdkDrag?.reset();
				this.lastModifyByDrag = false;
			}
		}
	}

	endedDrag() {
		this.clickable = false;
		this.lastModifyByDrag = true;
		const distance = this.cdkDrag?.getFreeDragPosition();

		if (distance && this.baseCoordinate) {
			const xInPixel = this.baseCoordinate.x + distance.x;
			const yInPixel = this.baseCoordinate.y + distance.y;
			this.oldCoordinate = safeHotspotRangePixel(
				{
					x: xInPixel,
					y: yInPixel,
				},
				this.backgorundRect.width,
				this.backgorundRect.height
			);

			const newPercentageCoordinate = safeHotspotRangePercentage({
				x: getPercentageFromPixel(this.oldCoordinate?.x ?? 0, this.backgorundRect.width),
				y: getPercentageFromPixel(this.oldCoordinate?.y ?? 0, this.backgorundRect.height),
			});
			// console.log("base", this.baseCoordinate)
			// console.log("cliccato", this.oldCoordinate, "nuova perc", newPercentageCoordinate, " distance: ", distance)
			// console.log("più precisamente :",
			// {
			//   x: xInPixel,
			//   y: yInPixel
			// },
			// {
			//   x: getPixelFromPercentage(newPercentageCoordinate.x, this.backgorundRect.width),
			//   y: getPixelFromPercentage(newPercentageCoordinate.y, this.backgorundRect.height)
			// })

			//emetto le distanze "safe" effettuate già in percentuale
			this.newValueEmitted.emit(newPercentageCoordinate);
		}

		//piccolissimo overlay per evitare che si triggheri il click dell'hotspot
		//perchè se si trigghera alla fine del drop si vede aprire il menu delle azioni
		//del singolo hotspot
		setTimeout(() => {
			this.clickable = true;
		}, 50);
	}
}
