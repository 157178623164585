<div class="img-container" [ngClass]="{ readonly: readonly, disabled: disabled }">
	<img *ngIf="displayValue$ | async as source" [dataleanAssetImage]="source" />

	<!-- CONTROLLI PER AGGIUNGERE\MODIFICARE\RIMUOVERE -->
	<div class="controls" *ngIf="!disabled && !readonly">
		<ng-container *ngIf="(hasValue$ | async) === false">
			<button type="button" class="preview-control select" (click)="chooseAsset()"></button>
		</ng-container>
		<ng-container *ngIf="hasValue$ | async">
			<button type="button" class="preview-control edit" (click)="chooseAsset()"></button>
			<button type="button" class="preview-control remove" (click)="removeAsset()"></button>
		</ng-container>
	</div>
</div>
