import { Asset, DataleanEntity, ObjectValuesType } from 'addiction-components';
import { ACTION_TYPE } from './challenge.interface';
import { Course } from '../course';
import { Communication } from '../communication.interface';

export interface ContestAction extends DataleanEntity {
	userUUID: string;
	communicationUUID?: string;
	courseUUID?: string;
	challengeUUID: string;
	actionType: ACTION_TYPE;
	validationStatus: VALIDATION_STATUS;
	course?: Course[],
	communication?: Communication[],
	asset?: Asset
}

export const VALIDATION_STATUS = {
	NOT_VALID: 'not_valid',
	PENDING: 'pending',
	VALID: 'valid',
};

export type VALIDATION_STATUS = ObjectValuesType<typeof VALIDATION_STATUS>;
