import { DataleanEntity, ObjectValuesType } from 'addiction-components';

export interface Conversion extends DataleanEntity {
	name?: string;
	from: SupportedFormats;
	to: SupportedFormats;
	createDate?: string | Date;
}

export const SupportedFormats = {
	JPG: 'jpg',
	JPEG: 'jpeg',
	PNG: 'png',
	WebP: 'webp',
	AVIF: 'avif',
	GIF: 'gif',
	SVG: 'svg',
	TIFF: 'tiff',
	TIF: 'tif',
} as const;

export type SupportedFormats = ObjectValuesType<typeof SupportedFormats>;
