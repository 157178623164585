import { ObjectValuesType } from "./object-values-type";

export const AuditParts = {
  UPDATE_DATE: 'updateDate',
  UPDATE_LIST_DATE: 'updateListDate',
  CREATED_BY: 'createdBy',
  UPDATED_BY: 'updatedBy',
} as const;

export const Parts = {
  ALL: '*',
  EMPTY: '',
  EXPORT: 'export',
  STRUCTURE: 'structures',
  CONFIGURATIONS: 'configurations',
  FULL_MEMBERS: 'fullMembers',
  PARTICIPANTS: 'participantsCount',
  CONTESTSCORE_CONTEST: 'contest',
  CONTESTSCORE_USER: 'user',
  CONTESTSCORE_SCOREACTIONS: 'actions',
  SENSITIVE_CONFIGURATIONS: 'allWithSensitive',
  DISPLAY_CATEGORIES: 'displayCategories',
  AUDIT: 'audit',
  VALUES: 'values',
  CHILDREN: 'children',
  CHILDREN_COUNT: 'childrenCount',
  FEATURE_VALUE_LIST: 'featureValueList',
  RELATED_PRODUCTS_LIST: 'relatedProductsList',
  FEATURE_VALUES: 'featureValues',
  FILTER_OPTIONS: 'filterOptions',
  FEATURES: 'features',
  ASSOCIATED_STRUCTUREFIELDS: 'associatedStructureFields',
  ANSWERS: 'answers',
  ROLES: 'roles',
  ADMIN: 'admin',
  LIST_ADMIN: 'listAdmin',
  CUSTOMIZATIONS: 'customizations',
  MEMBERS: 'members',
  PERMISSIONS: 'permissions',
  AUTHENTICATION_METHODS: 'authenticationMethods',
  COMMUNITY: 'community',
  DEBITS: 'debits',
  TOPUPS: 'topups',
  QUESTION_LIST: 'questionList',
  QUESTIONS: 'questions',
  RESPONSIBLE: 'responsible',
  STRUCTURE_FIELDS: 'structureFields',
  COMMUNITIES: 'communities',
  ASSETS: 'assets',
  LATEST_ACCESS: 'latestAccess',
  LATEST_LOGIN: 'latestLogin',
  ACCESS_COUNT: 'accessCount',
  LOGIN_COUNT: 'loginCount',
  MEMBERS_COUNT: 'membersCount',
  PRODUCTS: 'products',
  COMMUNICATIONS: 'communications',
  ATTACHMENTS: 'attachments',
  RECIPIENTS: 'recipients',
  OPEN_COUNT: 'openCount',
  USED_COUNT: 'usedCount',
  LIKES: 'likesCount',
  CONFIRM_COUNT: 'confirmCount',
  RESET_DEVICE_IDENTIFIER: 'resetDeviceIdentifier',
  TARGETS: 'targets',
  RELATED: 'related',
  COMMUNITY_UUID: 'communityUUID',
  SUBJECT_USERS: 'subjectUsers',
  SUBJECT_USERS_COUNT: 'subjectUsersCount',
  BASIC_ASSET_INFO: 'basicAssetInfo',
  PREVIEW_ASSET_INFO: 'previewAssetInfo',
  FULL_ASSET_INFO: 'fullAssetInfo',
  MAPS_PART: 'maps',
  SINGLE_ANSWERS: 'answer',
  WISHLISTS: 'wishlists',
  APPCONFIG: 'appConfig',
  ENTITY_VERSION: 'entityVersion',
  STATUS: 'status',
  SHARE_DATA: 'shareData',
  RELATED_POSITION: 'relatedPosition',
  RESOURCES: 'resources',
  AVAILABILITY: 'availability',
  BOOKABLEPRODUCTS: 'bookableProducts',
  CONTENT: 'content',
  TAGS: 'tags',
  LESSON_CONTENT_LIST: 'contentList',
  AVAILABILITY_PER_USER: 'availabilityPerUser',
  CREATE_DATE: 'createDate',
  WORKFLOWENTITY: 'workflowEntity',
  COURSES: 'courses',
  LESSONS: 'lessons',
  LINKED_LESSON: 'linked_lesson',
  PROGRESS: 'lms_progress',
  PICTOGRAM: 'pictogram',
  CONTEST: 'contest',
  CHALLENGE: 'challenge',
  CHALLENGE_ACTION_DETAIL: 'action_detail',
  ...AuditParts,
} as const;

export const SubParts = {
  /** Indica di risolvere la dipendenza ed includere le informazioni essenziali aggiuntive come oggetto */
  PREVIEW: 'preview',
  ...AuditParts,
} as const;

export type Parts = ObjectValuesType<typeof Parts>;
export type SubParts = ObjectValuesType<typeof SubParts>;
export type AuditParts = ObjectValuesType<typeof AuditParts>;

