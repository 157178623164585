import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MessageHandler } from 'addiction-components';
import { catchError, map, of, switchMap } from 'rxjs';
import { FeaturesActions } from '../../../core/state/app.actions';
import { FeaturesSelectors } from '../../../core/state/app.selectors';
import { FeaturesService } from '../../services/features.service';

@Injectable()
export class FeaturesEffects {
	private actions$ = inject(Actions);
	private featureSrv = inject(FeaturesService);
	private store = inject(Store);
	private messageHandler = inject(MessageHandler);
	private translateService = inject(TranslateService);

	loadFeature$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				FeaturesActions.loadFeatures,
				FeaturesActions.reloadFeatures,
				FeaturesActions.createFeatureSuccess,
				FeaturesActions.updateFeatureSuccess,
				FeaturesActions.cloneFeatureSuccess,
				FeaturesActions.deleteFeatureSuccess
			),
			concatLatestFrom(() => this.store.select(FeaturesSelectors.selectFeatureType)),
			switchMap(([, featureType]) =>
				this.featureSrv.getFeatures(featureType, this.translateService.currentLang ?? this.translateService.defaultLang).pipe(
					map((features) => FeaturesActions.loadFeaturesSuccess({ features })),
					catchError((error) => of(FeaturesActions.loadFeaturesFailure({ error })))
				)
			)
		)
	);
	// UPDATE ONE
	updateFeature$ = createEffect(() =>
		this.actions$.pipe(
			ofType(FeaturesActions.updateFeature),
			switchMap(({ feature }) =>
				this.featureSrv.updateFeature(feature).pipe(
					map((feature) => {
						this.messageHandler.createToast('FEATURE.SUCCESS_MESSAGE');
						return FeaturesActions.updateFeatureSuccess({ feature });
					}),
					catchError((error) => {
						this.messageHandler.createErrorToast('FEATURE.ERROR_MESSAGE');
						return of(FeaturesActions.updateFeatureFailure({ error }));
					})
				)
			)
		)
	);

	// CREATE ONE
	createFeature$ = createEffect(() =>
		this.actions$.pipe(
			ofType(FeaturesActions.createFeature),
			switchMap(({ feature }) =>
				this.featureSrv.createFeature(feature).pipe(
					map((feature) => {
						this.messageHandler.createToast('FEATURE.SUCCESS_MESSAGE');
						return FeaturesActions.createFeatureSuccess({ feature });
					}),
					catchError((error) => {
						this.messageHandler.createErrorToast('FEATURE.ERROR_MESSAGE');
						return of(FeaturesActions.createFeatureFailure({ error }));
					})
				)
			)
		)
	);

	cloneFeature$ = createEffect(() =>
		this.actions$.pipe(
			ofType(FeaturesActions.cloneFeature),
			switchMap(({ feature }) =>
				this.featureSrv.cloneFeature(feature).pipe(
					map((feature) => {
						this.messageHandler.createToast('FEATURE.SUCCESS_MESSAGE');
						return FeaturesActions.cloneFeatureSuccess({ feature });
					}),
					catchError((error) => {
						this.messageHandler.createErrorToast('FEATURE.ERROR_MESSAGE');
						return of(FeaturesActions.cloneFeatureFailure({ error }));
					})
				)
			)
		)
	);

	// DELETE ONE
	deleteFeature$ = createEffect(() =>
		this.actions$.pipe(
			ofType(FeaturesActions.deleteFeature),
			switchMap(({ uuid }) =>
				this.featureSrv.deleteFeature(uuid).pipe(
					map(() => FeaturesActions.deleteFeatureSuccess({ uuid })),
					catchError((error) => of(FeaturesActions.deleteFeatureFailure({ error })))
				)
			)
		)
	);

	// SORT FEATURE
	changeOrder$ = createEffect(() =>
		this.actions$.pipe(
			ofType(FeaturesActions.changeFeatureOrder),
			switchMap(({ featuresToUpdate }) =>
				this.featureSrv.changeFeatureOrder(featuresToUpdate).pipe(
					map(() => FeaturesActions.reloadFeatures()),
					catchError((error) => of(FeaturesActions.loadFeaturesFailure({ error })))
				)
			)
		)
	);

	featureTypeChanged$ = createEffect(() =>
		this.actions$.pipe(
			ofType(FeaturesActions.featureTypeChanged),
			map(({ featureType }) => FeaturesActions.loadFeatures({ featureType }))
		)
	);

	selectedFeature$ = createEffect(() =>
		this.actions$.pipe(
			ofType(FeaturesActions.changeFeatureSelected),
			map(({ uuid }) => FeaturesActions.selectFeature({ uuid }))
		)
	);

	selectedEditableFeature$ = createEffect(() =>
		this.actions$.pipe(
			ofType(FeaturesActions.changeFeatureSelected),
			map(({ uuid }) => FeaturesActions.editFeature({ uuid }))
		)
	);

	selectNewFeature$ = createEffect(() =>
		this.actions$.pipe(
			ofType(FeaturesActions.createNewFeature),
			map(({ parentUUID }) => FeaturesActions.selectFeature({ uuid: parentUUID }))
		)
	);

	editNewFeature$ = createEffect(() =>
		this.actions$.pipe(
			ofType(FeaturesActions.createNewFeature),
			map(() => FeaturesActions.editFeature({ uuid: 'new' }))
		)
	);

	constructor() {}
}
