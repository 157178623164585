import { createFeatureSelector, createSelector } from '@ngrx/store';
import { } from './surveys.reducer';
import { DATALEAN_SURVEY_COMMUNICATIONS_TAG, SurveyCommunicationsState } from './survey-communication.reducer';

const selectSurveyCommunicationsState = createFeatureSelector<SurveyCommunicationsState>(DATALEAN_SURVEY_COMMUNICATIONS_TAG);

export const selectData = createSelector(selectSurveyCommunicationsState, (state: SurveyCommunicationsState) => state.data);
export const selectPages = createSelector(selectSurveyCommunicationsState, (state: SurveyCommunicationsState) => state.pages);
export const selectPagedData = createSelector(selectData, selectPages, (data, pages) => ({ ...data, pages }));
export const selectGridSearch = createSelector(selectSurveyCommunicationsState, (state: SurveyCommunicationsState) => state.gridSearch);
export const selectStatus = createSelector(selectSurveyCommunicationsState, (state: SurveyCommunicationsState) => state.status);
export const selectSort = createSelector(selectSurveyCommunicationsState, (state: SurveyCommunicationsState) => state.sort);
export const selectCurrentSurveyUUID = createSelector(selectSurveyCommunicationsState, (state: SurveyCommunicationsState) => state.currentSurveyUUID);
