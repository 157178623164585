<datalean-header></datalean-header>

<datalean-header-button *ngIf="showDamHeader"></datalean-header-button>

<div class="main-container" [ngClass]="{ 'header-button-active': showDamHeader }">
	<div class="nav-bar-container" *ngIf="openMenuBoolean">
		<datalean-main-navbar *ngIf="showNavBar"></datalean-main-navbar>
	</div>

	<div class="page-content">
		<router-outlet></router-outlet>
	</div>
</div>
