import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DATALEAN_LESSON_TAG, LessonsState } from './lessons.reducer';

const selectLessonsState = createFeatureSelector<LessonsState>(DATALEAN_LESSON_TAG);

export const selectData = createSelector(selectLessonsState, (state: LessonsState) => state.data);
export const selectPages = createSelector(selectLessonsState, (state: LessonsState) => state.pages);
export const selectCachedPages = createSelector(selectLessonsState, (state) => state.cachedPages);
export const selectPagedData = createSelector(selectData, selectPages, (data, pages) => ({ ...data, pages }));
export const selectGridSearch = createSelector(selectLessonsState, (state: LessonsState) => state.gridSearch);
export const selectStatus = createSelector(selectLessonsState, (state: LessonsState) => state.status);
export const selectSort = createSelector(selectLessonsState, (state: LessonsState) => state.sort);
