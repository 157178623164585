import { Injectable } from '@angular/core';
import { DataleanBaseApiService, Parts, Tag } from 'addiction-components';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class TagsService {
	constructor(private dataleanApiService: DataleanBaseApiService) {}

	createTag(tag: Omit<Tag, 'uuid'>): Observable<Tag> {
		return this.dataleanApiService.createOne(environment.tagUrl, tag);
	}

	getTags(): Observable<Tag[]> {
		return this.dataleanApiService.getMany<Tag>(environment.tagUrl, [Parts.EMPTY]);
	}

	updateTag(tag: Tag): Observable<Tag> {
		const { uuid } = tag;
		return this.dataleanApiService.updateOne<Tag>(environment.tagUrl, uuid, tag, [Parts.EMPTY]);
	}

	deleteTag(tagUUID: string): Observable<object> {
		return this.dataleanApiService.deleteOne(environment.tagUrl, tagUUID);
	}
}
