import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TreeManager } from '../../lib/tree-manager';
import { FeaturesState } from './features.state';
import { FeatureValue } from 'addiction-components';

const tree = new TreeManager<FeatureValue>({
	keyProp: 'uuid',
	childrenProp: 'featureValues',
	parentProp: 'parentUUID',
});

const feature = createFeatureSelector<FeaturesState>('features');

export const selectFeatureType = createSelector(feature, (state) => state.currentType);
export const selectFeatures = createSelector(feature, (state) => state.root.featureValues);
export const selectFeatureRoot = createSelector(feature, (state) => state.root);
export const selectError = createSelector(feature, (state) => state.error);

export const selectSelectedFeature = createSelector(feature, ({ root, selectedUUID }) =>
	selectedUUID ? tree.findNodeByID(root, selectedUUID) : ({ uuid: selectedUUID } as FeatureValue)
);
export const selectPathToSelected = createSelector(feature, ({ root, selectedUUID }) =>
	selectedUUID ? tree.getPathToNode(root, selectedUUID, true) : []
);
export const selectEditingFeature = createSelector(feature, ({ root, editingUUID }) =>
	editingUUID ? tree.findNodeByID(root, editingUUID) ?? { uuid: editingUUID } : undefined
);
export const selectIsEditing = createSelector(feature, ({ editingUUID }) => !!editingUUID);
export const selectHasSelectedFeature = createSelector(feature, ({ selectedUUID }) => !!selectedUUID);
