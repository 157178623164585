export default function ObjDiff(obj1: Record<string, unknown>, obj2: Record<string, unknown>): boolean {
	if (Object.keys(obj1 || {}).length !== Object.keys(obj2 || {}).length) {
		return true;
	}

	for (const key in obj1) {
		if (!['string', 'number', 'undefined', 'null', 'boolean'].includes(typeof obj1[key])) {
			if (Array.isArray(obj1[key])) {
				const uArray1 = obj1[key] as Array<unknown>;
				const uArray2 = obj2[key] as Array<unknown>;
				if (uArray1.length !== uArray2.length) {
					return true;
				}
				const equal = [...uArray1].reduce((val: boolean, item, index: number) => !val || item === uArray2[index], true);
				if (!equal) return true;
			} else {
				const diff = ObjDiff(obj1[key] as Record<string, unknown>, obj2[key] as Record<string, unknown>);
				if (diff) return true;
			}
		} else if (obj1[key] !== obj2?.[key]) {
			return true;
		}
	}

	return false;
}
