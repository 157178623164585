import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MessageHandler } from 'addiction-components';
import { catchError, map, of, switchMap } from 'rxjs';
import { PictogramStructuresService } from '../services/pictogra-structure.service';
import { PictogramStructureActions } from 'src/app/core/state/app.actions';

@Injectable()
export class PictogramStructureEffect {
	private actions$ = inject(Actions);
	private pictogramStructureSrv = inject(PictogramStructuresService);
	private store = inject(Store);
	private messageHandler = inject(MessageHandler);
	private translateService = inject(TranslateService);

	loadPictogramStructure$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				PictogramStructureActions.loadPictogramStructures,
				PictogramStructureActions.reloadPictogramStructures,
				PictogramStructureActions.createPictogramStructureSuccess,
				PictogramStructureActions.updatePictogramStructureSuccess,
				PictogramStructureActions.deletePictogramStructureSuccess
			),
			switchMap(() =>
				this.pictogramStructureSrv.getPictogramStructures().pipe(
					map((pictogramStructures) => PictogramStructureActions.loadPictogramStructuresSuccess({ pictogramStructures })),
					catchError((error) => of(PictogramStructureActions.loadPictogramStructuresFailure({ error })))
				)
			)
		)
	);
	// UPDATE ONE
	updatePictogramStructure$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PictogramStructureActions.updatePictogramStructure),
			switchMap(({ pictogramStructure }) =>
				this.pictogramStructureSrv.updatePictogramStructure(pictogramStructure).pipe(
					map((pictogramStructure) => {
						this.messageHandler.createToast('FEATURE.SUCCESS_MESSAGE');
						return PictogramStructureActions.updatePictogramStructureSuccess({ pictogramStructure });
					}),
					catchError((error) => {
						this.messageHandler.createErrorToast('FEATURE.ERROR_MESSAGE');
						return of(PictogramStructureActions.updatePictogramStructureFailure({ error }));
					})
				)
			)
		)
	);

	// CREATE ONE
	createPictogramStructure$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PictogramStructureActions.createPictogramStructure),
			switchMap(({ pictogramStructure }) =>
				this.pictogramStructureSrv.createPictogramStructure(pictogramStructure).pipe(
					map((pictogramStructure) => {
						this.messageHandler.createToast('FEATURE.SUCCESS_MESSAGE');
						return PictogramStructureActions.createPictogramStructureSuccess({ pictogramStructure });
					}),
					catchError((error) => {
						this.messageHandler.createErrorToast('FEATURE.ERROR_MESSAGE');
						return of(PictogramStructureActions.createPictogramStructureFailure({ error }));
					})
				)
			)
		)
	);


	// DELETE ONE
	deletePictogramStructure$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PictogramStructureActions.deletePictogramStructure),
			switchMap(({ uuid }) =>
				this.pictogramStructureSrv.deletePictogramStructure(uuid).pipe(
					map(() => PictogramStructureActions.deletePictogramStructureSuccess({ uuid })),
					catchError((error) => of(PictogramStructureActions.deletePictogramStructureFailure({ error })))
				)
			)
		)
	);

	selectedPictogramStructure$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PictogramStructureActions.changePictogramStructureSelected),
			map(({ uuid }) => PictogramStructureActions.selectPictogramStructure({ uuid }))
		)
	);

	selectedEditablePictogramStructure$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PictogramStructureActions.changePictogramStructureSelected),
			map(({ uuid }) => PictogramStructureActions.editPictogramStructure({ uuid }))
		)
	);

	editNewPictogramStructure$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PictogramStructureActions.createNewPictogramStructure),
			map(() => PictogramStructureActions.editPictogramStructure({ uuid: 'new' }))
		)
	);

}
