import { createReducer, on } from '@ngrx/store';
import { UserActions } from '../../../core/state/app.actions';
import { UserState } from './user.state';

export const TAG = 'user';

const initialState: UserState = {
	userData: null,
	authInitialized: false,
};

export const counterReducer = createReducer(
	initialState,
	on(UserActions.setUserData, (state, { userData }) => ({ ...state, userData })),
	on(UserActions.authInitialized, (state) => ({ ...state, authInitialized: true }))
);
