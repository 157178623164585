import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CalendarReducers } from 'src/app/core/state/app.reducers';
import { CalendarState } from './calendar.state';

const selectCalendarsState = createFeatureSelector<CalendarState>(CalendarReducers.TAG);

export const selectCalendarsFilters = createSelector(selectCalendarsState, (state) => state?.filters);

export const selectData = createSelector(selectCalendarsState, (state) => state.data);
export const selectPages = createSelector(selectCalendarsState, (state) => state.pages);
export const selectPagedData = createSelector(selectData, selectPages, (data, pages) => ({ ...data, pages }));
export const selectGridSearch = createSelector(selectCalendarsState, (state) => state.gridSearch);
export const selectStatus = createSelector(selectCalendarsState, (state) => state.status);
export const selectSort = createSelector(selectCalendarsState, (state) => state.sort);
export const selectView = createSelector(selectCalendarsState, (state) => state.view);
export const selectCachedPages = createSelector(selectCalendarsState, (state) => state.cachedPages);
