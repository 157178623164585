import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Collection } from 'addiction-components';
import { STATE_STATUS } from 'src/app/shared/models/state-status.enum';

export const setReloadCollectionsKey = '[Collections] Set reload collections'

export const setCollectionsSearch = createAction('[Collections] Set search', props<{ search: string | undefined }>());
export const setCollectionsSuccess = createAction('[Collections] Set fetch success', props<{collections: Collection[][], pages: number[], totalCollectionsCount: number}>());
export const setCollectionsError = createAction('[Collections] Set fetch error', props<{collectionsError: HttpErrorResponse}>());
export const setCollectionsPages = createAction('[Collections] Set pages', props<{ pages: number[] }>());
export const setReloadCollections = createAction(setReloadCollectionsKey);
export const createCollection = createAction('[Collections] Create', props<{ collectionName: string }>());
export const createCollectionError = createAction('[Collections] Create error');
export const createCollectionSuccess = createAction('[Collections] Create success');
export const setFetchCollectionStatus = createAction('[Collections] Set fetchCollecctionStatus', props<{ status: STATE_STATUS }>());

