import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ConfigReducers } from 'src/app/core/state/app.reducers';
import { ConfigState } from './config.state';

const selectConfigState = createFeatureSelector<ConfigState>(ConfigReducers.TAG);

export const selectDefaultLanguage = createSelector(selectConfigState, (state) => state.configurationMap['defaultLocale']?.value as string);
export const selectAvailableLanguages = createSelector(selectConfigState, (state) => state.configurationMap['activeLocales']?.value as string[]);
export const selectConfigMap = createSelector(selectConfigState, (state) => state.configurationMap);
export const selectConfig = (configKey: string) => createSelector(selectConfigState, (state) => state.configurationMap[configKey]);
