import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

@Component({
	selector: 'datalean-paginator',
	templateUrl: './paginator.component.html',
	styleUrls: ['./paginator.component.scss'],
	standalone: true,
	imports: [CommonModule, RouterModule, MatIconModule],
})
export class PaginatorComponent implements OnInit, OnChanges {
	private _currentPage: number = 0;
	pages: Page[] = [];
	@Input() visiblePages: number = 9;
	@Input() totalItems: number = 0;
	@Input() pageSize: number = 15;
	@Input() set currentPage(val: number) {
		this._currentPage = val;
	}
	get currentPage() {
		return this._currentPage;
	}
	@Output() pageChange = new EventEmitter<number>();

	get pageCount() {
		if (this.pageSize == 0) return 0;
		return Math.max(1, Math.ceil(this.totalItems / this.pageSize));
	}

	get canGoForward() {
		return this.currentPage < this.pageCount - 1;
	}

	get canGoBackward() {
		return this.currentPage > 0;
	}

	constructor() {}

	ngOnInit(): void {
		this.updatePages();
	}
	ngOnChanges(): void {
		this.updatePages();
	}
	updatePages() {
		this.pages = this.computePages(this.pageCount, this.currentPage);
	}

	computePages(pageCount: number, currentPage: number) {
		const margin = Math.floor((this.visiblePages - 1 - 2 - 2) / 2); // visiblePages - slot for current page - two possible dots - first and last

		const pages: Page[] = [...Array(pageCount).keys()].map((p) => ({ page: p }));
		if (pages.length == 0) return pages;

		let currentIndex = currentPage;

		if (currentIndex - margin > 1) {
			// collapse left pages
			const { length } = pages.splice(1, currentIndex - margin - 1, { fake: true, page: 0 });
			currentIndex -= length - 1;
		}
		if (currentIndex + margin < pages.length - 2) {
			// collapse right pages
			pages.splice(currentIndex + margin + 1, pages.length - 1 - (currentIndex + margin + 1), { fake: true, page: 0 });
		}
		return pages;
	}

	changePage(page: number) {
		this._currentPage = page;
		this.updatePages();
		this.pageChange.emit(this._currentPage);
	}

	next() {
		if (this.canGoForward) this.changePage(this._currentPage + 1);
	}
	prev() {
		if (this.canGoBackward) this.changePage(this._currentPage - 1);
	}
}

interface Page {
	page: number;
	fake?: boolean;
}
