<div class="form-field {{ formControlStatus$ | async | lowercase }}">
	<label *ngIf="label">{{ label }} <span *ngIf="requiredIcon">*</span></label>
	<div class="form-input-container {{ iconPosition }}" matAutocompleteOrigin #origin="matAutocompleteOrigin">
		<mat-icon class="form-field-icon" *ngIf="hasIcon">{{ icon }}</mat-icon>
		<input
			matInput
			class="hidden"
			[type]="type"
			[readonly]="readonly"
			[name]="name"
			[disabled]="disabled"
			[placeholder]="placeholder"
			[(ngModel)]="_value"
			(ngModelChange)="manageChange($event)"
			(click)="clickInput.emit($event)"
			(keydown)="keydown.emit($event)"
			[matAutocomplete]="auto"
			[matAutocompleteConnectedTo]="origin"
      />
	</div>
</div>
<mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption (optionSelected)="onOptionSelected($event)">
	<mat-option *ngFor="let option of filteredOptions" [value]="option.label">{{ option.label }}</mat-option>
	<mat-optgroup *ngFor="let group of filteredOptionGroups" [label]="group.label">
		<mat-option *ngFor="let option of group.options" [value]="option.label">{{ option.label }}</mat-option>
	</mat-optgroup>
</mat-autocomplete>
