import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Optional } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Asset, AssetType, DndDirective } from 'addiction-components';
import { LottieModule } from 'ngx-lottie';
import { AssetIdentifierService } from 'src/app/shared/services/asset-identifier.service';
import { AssetGridSelectorComponent } from './asset-grid-selector/asset-grid-selector.component';
import { CollectionsComponent } from './collection-selector/collections.component';
import { CollectionSelectorDetailComponent } from './collection-selector/detail/collection-selector-detail.component';

@Component({
	selector: 'datalean-asset-selector-container',
	standalone: true,
	imports: [
		CommonModule,
		AssetGridSelectorComponent,
		CollectionsComponent,
		CollectionSelectorDetailComponent,
		LottieModule,
		DndDirective,
		TranslateModule,
	],
	providers: [AssetIdentifierService],
	templateUrl: './asset-selector-container.component.html',
	styleUrls: ['./asset-selector-container.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetSelectorContainerComponent {
	selectedAsset?: Asset;
	mode: 'collections' | 'grid' = 'grid';
	selectedCollection?: string;

	constructor(
		@Optional() public dialogRef: DialogRef<Asset>,
		@Inject(DIALOG_DATA)
		public data: {
			assetType: AssetType;
			formatRequired: string;
		}
	) {}

	confirmAsset() {
		this.dialogRef.close(this.selectedAsset);
	}
	close() {
		this.dialogRef.close();
	}
}
