<div class="container" *ngIf="formGroup">
	<form class="form-group-container" [formGroup]="formGroup">
		<ng-container *ngFor="let field of structureFields">
			<ng-container *ngIf="getFormControl(field) && !field.hidden">
				<!-- non-repeatable-container -->
				<ng-container *ngIf="!field.repeatable">
					<ng-container [ngSwitch]="field.type">
						<div class="field-container" *ngSwitchCase="types.TEXT" [ngClass]="getClass(field.name)">
							<addiction-input
								[readonly]="readonly || !!field.readOnly"
								[label]="field.label | toLocalizedValuePipe"
								[formControl]="getFormControl(field)"></addiction-input>
						</div>

						<div class="field-container" *ngSwitchCase="types.URLTITLE" [ngClass]="getClass(field.name)">
							<addiction-input
								[readonly]="readonly || !!field.readOnly"
								[label]="field.label | toLocalizedValuePipe"
								type="urlTitle"
								[formControl]="getFormControl(field)"></addiction-input>
						</div>

						<div class="field-container" *ngSwitchCase="types.NUMBER" [ngClass]="getClass(field.name)">
							<addiction-input
								[readonly]="readonly || !!field.readOnly"
								type="number"
								[label]="field.label | toLocalizedValuePipe"
								[formControlName]="field.name"></addiction-input>
						</div>

						<div class="field-container" *ngSwitchCase="types.TEXTAREA" [ngClass]="getClass(field.name)">
							<addiction-textarea
								[readonly]="readonly || !!field.readOnly"
								placeholder=""
								[label]="field.label | toLocalizedValuePipe"
								[formControl]="getFormControl(field)"></addiction-textarea>
						</div>

						<div class="field-container" *ngSwitchCase="types.RELATED_ENTITIES" [ngClass]="getClass(field.name)">
							<datalean-generic-chip-selector
								*ngIf="field.relatedStructureType && getInfo(field) as info"
								[label]="field.label | toLocalizedValuePipe"
								[inputData]="info"
								[formControl]="getFormControl(field)"></datalean-generic-chip-selector>
						</div>
						<div class="field-container" *ngSwitchCase="types.PICTOGRAM" [ngClass]="getClass(field.name)">
							<datalean-pictogram-selector
								[readonly]="readonly || !!field.readOnly || getFormControl(field).disabled"
								[formControl]="getFormControl(field)"
								[label]="field.label | toLocalizedValuePipe"></datalean-pictogram-selector>
						</div>
						<div class="field-container" *ngSwitchCase="types.FEATURE_VALUES_CONTAINER" [ngClass]="getClass(field.name)">
							<datalean-feature-chips-selector
								[formControl]="getFormControl(field)"
								[readonly]="readonly || !!field.readOnly || getFormControl(field).disabled"
								[label]="field.label | toLocalizedValuePipe"
								[detailType]="getExtra(field, 'detailType')"
								[featureValueType]="getExtra(field, 'featureValueType') ?? FeatureValueType.ASSET"
								[emit]="getExtra(field, 'emit') ?? 'uuid'">
							</datalean-feature-chips-selector>
						</div>

						<div class="field-container" *ngSwitchCase="types.HTML" [ngClass]="getClass(field.name)">
							<label>
								{{ field.label | toLocalizedValuePipe }}
								<span *ngIf="field.isRequired">*</span>
							</label>
							<addiction-html-editor [readonly]="readonly || !!field.readOnly" [control]="getFormControl(field)"></addiction-html-editor>
						</div>

						<div class="field-container" *ngSwitchCase="types.BOOLEAN" [ngClass]="getClass(field.name)">
							<addiction-checkbox
								class="column"
								[readonly]="readonly || !!field.readOnly || getFormControl(field).disabled"
								[label]="field.label | toLocalizedValuePipe"
								[formControl]="getFormControl(field)"></addiction-checkbox>
						</div>

						<div class="field-container" *ngSwitchCase="types.PRIVACY" [ngClass]="getClass(field.name)">
							<addiction-checkbox
								class="column"
								[readonly]="readonly || !!field.readOnly"
								[label]="field.label | toLocalizedValuePipe"
								[formControl]="getFormControl(field)"></addiction-checkbox>
						</div>

						<div class="field-container" *ngSwitchCase="types.TOGGLE" [ngClass]="getClass(field.name)">
							<mat-slide-toggle [ariaReadOnly]="readonly || !!field.readOnly" [formControl]="getFormControl(field)">
								{{ field.label | toLocalizedValuePipe }}
							</mat-slide-toggle>
						</div>

						<div class="field-container" *ngSwitchCase="types.PHONE_3" [ngClass]="getClass(field.name)">
							<addiction-phone
								[readonly]="readonly || !!field.readOnly || getFormControl(field).disabled"
								[label]="field.label | toLocalizedValuePipe"
								[formControl]="getFormControl(field)"></addiction-phone>
						</div>

						<div class="field-container" *ngSwitchCase="types.SELECT" [ngClass]="getClass(field.name)">
							<label>
								{{ field.label | toLocalizedValuePipe }}
								<span *ngIf="field.isRequired">*</span>
							</label>
							<addiction-select
								[readonly]="readonly || !!field.readOnly || getFormControl(field).disabled"
								class="column"
								[options]="field.parsedExtra | as"
								[formControl]="getFormControl(field)"></addiction-select>
						</div>

						<div class="field-container" *ngSwitchCase="types.RADIO" [ngClass]="getClass(field.name)">
							<addiction-radio
								[readonly]="readonly || !!field.readOnly"
								class="column"
								[options]="field.parsedExtra | as"
								[label]="field.label | toLocalizedValuePipe"
								[formControl]="getFormControl(field)"></addiction-radio>
						</div>

						<div class="field-container" *ngSwitchCase="types.FILE_3" [ngClass]="getClass(field.name)">
							<datalean-structure-file-selector
								mode="complete"
								[readonly]="readonly || !!field.readOnly || getFormControl(field).disabled"
								[label]="field.label | toLocalizedValuePipe"
								[requiredIcon]="field.isRequired"
								[inputTypeRequired]="field.inputTypeRequired"
								[formatRequired]="field.formatRequired"
								[formControlName]="field.name"></datalean-structure-file-selector>
						</div>

						<div class="field-container" *ngSwitchCase="types.ATTACHMENT_3" [ngClass]="getClass(field.name)">
							<datalean-structure-file-selector
								mode="compact"
								[readonly]="readonly || !!field.readOnly || getFormControl(field).disabled"
								[label]="field.label | toLocalizedValuePipe"
								[requiredIcon]="field.isRequired"
								[inputTypeRequired]="field.inputTypeRequired"
								[formatRequired]="field.formatRequired"
								[formControlName]="field.name"></datalean-structure-file-selector>
						</div>

						<div class="field-container" *ngSwitchCase="types.LESSON_ATTACHMENT" [ngClass]="getClass(field.name)">
							<ng-container *ngIf="field.parentValue !== 'quiz'">
								<datalean-structure-file-selector
									mode="complete"
									[readonly]="readonly || !!field.readOnly || getFormControl(field).disabled"
									[inputTypeRequired]="field.inputTypeRequired"
									[formatRequired]="field.formatRequired"
									[requiredIcon]="field.isRequired"
									[formControl]="getFormControl(field)"></datalean-structure-file-selector>
							</ng-container>
							<ng-container *ngIf="field.parentValue === 'quiz'">
								<label>{{ 'NAVIGATION.LMS.QUIZ' | translate }}</label>
								<datalean-quiz-chips-selector
									[readonly]="readonly || !!field.readOnly || getFormControl(field).disabled"
									[formControl]="getFormControl(field)"
									[label]="field.label | toLocalizedValuePipe"></datalean-quiz-chips-selector>
							</ng-container>
						</div>

						<div
							class="field-container"
							*ngSwitchCase="types.SURVEY_DOMAIN_OF_ANSWERS"
							[ngClass]="getClass(field.name)"
							[formGroup]="getFormGroup(field)">
							<div class="simple-container-container no-border" *ngIf="field.fields && field.fields.length">
								<!--è ripetuto perchè così l'ngif rimuove il componente permettendone la reinizializzazione-->
								<ng-container *ngIf="field.parentValue === SurveyQuestionType.CHOICE">
									<datalean-container
										[structureFields]="getSurveyAnswersFor(field)"
										[readonly]="readonly || !!field.readOnly || getFormControl(field).disabled"
										[defaultValues]="getSubDataset(field.name)">
									</datalean-container>
								</ng-container>
								<ng-container *ngIf="field.parentValue === SurveyQuestionType.RATING_SCALE">
									<datalean-container
										[structureFields]="getSurveyAnswersFor(field)"
										[readonly]="readonly || !!field.readOnly || getFormControl(field).disabled"
										[defaultValues]="getSubDataset(field.name)">
									</datalean-container>
								</ng-container>
								<ng-container *ngIf="field.parentValue === SurveyQuestionType.DATE_TIME">
									<datalean-container
										[structureFields]="getSurveyAnswersFor(field)"
										[readonly]="readonly || !!field.readOnly || getFormControl(field).disabled"
										[defaultValues]="getSubDataset(field.name)">
									</datalean-container>
								</ng-container>
								<ng-container *ngIf="field.parentValue === SurveyQuestionType.TEXT">
									<datalean-container
										[structureFields]="getSurveyAnswersFor(field)"
										[readonly]="readonly || !!field.readOnly || getFormControl(field).disabled"
										[defaultValues]="getSubDataset(field.name)">
									</datalean-container>
								</ng-container>
							</div>
						</div>

						<div class="field-container" *ngSwitchCase="types.ROLES" [ngClass]="getClass(field.name)">
							<datalean-role-chips-selector
								[formControl]="getFormControl(field)"
								[label]="field.label | toLocalizedValuePipe"></datalean-role-chips-selector>
						</div>

						<div class="field-container" *ngSwitchCase="types.EMAIL_3" [ngClass]="getClass(field.name)">
							<addiction-email
								[readonly]="readonly || !!field.readOnly || getFormControl(field).disabled"
								[label]="field.label | toLocalizedValuePipe"
								[formControl]="getFormControl(field)"></addiction-email>
						</div>

						<ng-container *ngSwitchCase="types.SINGLE_RELATED_ENTITY">
							<div class="field-container ratio-100" [ngClass]="getClass(field.name)">
								<addiction-input
									[readonly]="readonly || !!field.readOnly"
									[label]="'name' | toLocalizedValuePipe"
									type="text"
									formControlName="name"></addiction-input>
							</div>

							<div class="field-container ratio-100" [ngClass]="getClass(field.name)">
								<mat-slide-toggle [ariaReadOnly]="readonly || !!field.readOnly" formControlName="active">
									{{ 'ACTIVE' | translate }}
								</mat-slide-toggle>
							</div>

							<div class="field-container ratio-100" [ngClass]="getClass(field.name)">
								<datalean-lesson-chips-selector
									formControlName="linkedLesson"
									[readonly]="readonly || !!field.readOnly || getFormControl(field).disabled"
									[limit]="1"
									[label]="field.label | toLocalizedValuePipe"></datalean-lesson-chips-selector>
							</div>

							<div class="field-container ratio-100" [ngClass]="getClass(field.name)">
								<datalean-lesson-chips-selector
									formControlName="blockedBy"
									[readonly]="readonly || !!field.readOnly || getFormControl(field).disabled"
									[label]="'blocked' | toLocalizedValuePipe"></datalean-lesson-chips-selector>
							</div>
						</ng-container>

						<div class="field-container" *ngSwitchCase="types.DATE" [ngClass]="getClass(field.name)">
							<label>
								{{ field.label | toLocalizedValuePipe }}
								<span *ngIf="field.isRequired">*</span>
							</label>
							<addiction-date-picker
								[readonly]="readonly || !!field.readOnly || getFormControl(field).disabled"
								[formControl]="getFormControl(field)"
								[showDate]="field.parsedExtra ? $any(field.parsedExtra)['showDate'] : true"
								[showTime]="field.parsedExtra ? $any(field.parsedExtra)['showTime'] : false"></addiction-date-picker>
						</div>

						<div class="field-container" *ngSwitchCase="types.CONTAINER" [ngClass]="getClass(field.name)">
							<div class="simple-container-container" *ngIf="field.fields && field.fields.length">
								<div class="container-title accordion">
									<div class="title">
										{{ field.label | toLocalizedValuePipe | translate }}
										<span *ngIf="field.isRequired">*</span>
									</div>
									<button
										class="accordion"
										type="button"
										[ngClass]="{ up: !isCollapsed[field.name], down: isCollapsed[field.name] }"
										(click)="toggleCollapse(field)"></button>
								</div>

								<ng-container [formGroup]="getFormGroup(field)">
									<div class="accordion-content" [ngClass]="{ collapsed: isCollapsed[field.name] }">
										<datalean-container
											[readonly]="readonly || !!field.readOnly || getFormControl(field).disabled"
											[structureFields]="field.fields"
											[depth]="depth + 1"
											[defaultValues]="getSubDataset(field.name)">
										</datalean-container>
									</div>
								</ng-container>
							</div>
						</div>

						<div class="field-container" *ngSwitchCase="types.IMAGE_MAP_CONTAINER_3" [ngClass]="getClass(field.name)">
              <div class="title-fields-container no-space">
                <h2>
                  {{ field.label | toLocalizedValuePipe }}
                  <span *ngIf="field.isRequired">*</span>
                </h2>
              </div>
							<datalean-hotspot-image-container [field]="field" [formControl]="getFormControl(field)"></datalean-hotspot-image-container>
						</div>
					</ng-container>
				</ng-container>

				<div
					class="repeatable-container"
					*ngIf="field.repeatable && getFormArray(field) && getFormArrayControls(field)"
					[formArrayName]="field.name"
					cdkDropList
					[cdkDropListData]="getFormArray(field)"
					(cdkDropListDropped)="drop($event, getFormArray(field))">
					<label>
						{{ field.label | toLocalizedValuePipe | translate }}
						<span *ngIf="field.isRequired">*</span>
					</label>

					<div
						class="single-element"
						*ngFor="let control of getFormArrayControls(field); let i = index"
						cdkDrag
						[cdkDragDisabled]="!field.repeatable || readonly || field.readOnly || getFormControl(field).disabled"
						cdkDragPreviewContainer="parent"
						cdkDragBoundary=".repeatable-container"
						[id]="'anchor-' + field.name + '-' + i"
						[ngClass]="{ collapsed: isFieldCollapsed[i], expanded: !isFieldCollapsed[i] }"
						[cdkDragData]="control">
						<div class="drag-placeholder" *cdkDragPlaceholder></div>

						<div class="drag-container" cdkDragHandle>
							<span class="number">{{ i + 1 }}</span>
						</div>

						<ng-container [ngSwitch]="field.type">
							<div
								[ngClass]="{ collapsed: isFieldCollapsed[i], expanded: !isFieldCollapsed[i] }"
								class="repeatable-fields-container {{ getClass(field.name) }}"
								*ngSwitchCase="types.FILE_3">
								<datalean-structure-file-selector
									mode="complete"
									[readonly]="readonly || !!field.readOnly"
									[inputTypeRequired]="field.inputTypeRequired"
									[formatRequired]="field.formatRequired"
									[formControlName]="i"
									[requiredIcon]="field.isRequired"
									[label]="field.label | toLocalizedValuePipe">
								</datalean-structure-file-selector>
							</div>

							<div
								class="repeatable-fields-container {{ getClass(field.name) }}"
								*ngSwitchCase="types.ATTACHMENT_3"
								[ngClass]="{ collapsed: isFieldCollapsed[i], expanded: !isFieldCollapsed[i] }">
								<datalean-structure-file-selector
									mode="compact"
									[readonly]="readonly || !!field.readOnly || getFormControl(field).disabled"
									[inputTypeRequired]="field.inputTypeRequired"
									[formatRequired]="field.formatRequired"
									[formControlName]="i"
									[requiredIcon]="field.isRequired"
									[label]="field.label | toLocalizedValuePipe"></datalean-structure-file-selector>
							</div>

							<div
								class="repeatable-fields-container {{ getClass(field.name) }}"
								*ngSwitchCase="types.CONTAINER"
								[ngClass]="{ collapsed: isFieldCollapsed[i], expanded: !isFieldCollapsed[i] }">
								<datalean-container
									[formControlName]="i"
									[readonly]="readonly"
									[structureFields]="field.fields ?? []"
									[depth]="depth + 1"
									[defaultValues]="getSubDataset(field.name, i)">
								</datalean-container>
							</div>

							<div
								class="repeatable-fields-container {{ getClass(field.name) }}"
								*ngSwitchCase="types.AVAILABILITY_OPTION"
								[ngClass]="{ collapsed: isFieldCollapsed[i], expanded: !isFieldCollapsed[i] }">
								<datalean-availability-option [formControlName]="i"></datalean-availability-option>
							</div>

							<div
								class="repeatable-fields-container {{ getClass(field.name) }}"
								*ngSwitchCase="types.AVAILABILITY_RULE"
								[ngClass]="{ collapsed: isFieldCollapsed[i], expanded: !isFieldCollapsed[i] }">
								<datalean-availability-rule-list [formControlName]="i"></datalean-availability-rule-list>
							</div>
						</ng-container>
						<div class="buttons-container">
							<!-- <button class="clone"></button>-->
							<button
								class="accordion"
								type="button"
								[ngClass]="{ up: !isFieldCollapsed[i], down: isFieldCollapsed[i] }"
								(click)="toggleCollapseField(i)"></button>
							<button
								class="delete"
								type="button"
								[disabled]="getFormControl(field).disabled"
								*ngIf="field.repeatable && !readonly && !field.readOnly && !isFieldCollapsed[i]"
								(click)="removeChild(field, i)"></button>
						</div>
					</div>

					<button
						class="add-element"
						type="button"
						*ngIf="field.repeatable && !readonly && !field.readOnly"
						[disabled]="isDisabledAddChild(field)"
						(click)="addChild(field)">
						<span>{{ 'ADD_ELEMENT' | translate }}</span>
					</button>
				</div>
			</ng-container>
		</ng-container>
	</form>
</div>
