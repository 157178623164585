<div class="asset-selector-popup-container">
	<div class="header-container">
		<div class="title">
			{{ 'MEDIA_LIBRARY.SELECT_IMAGE' | translate }}
		</div>
		<div class="close-container">
			<button class="close" (click)="close()"></button>
		</div>
	</div>
	<div class="body-container">
		<datalean-asset-grid-selector
			*ngIf="mode === 'grid'"
			selectionMode="single"
			[assetType]="data.assetType"
			[formatRequired]="data.formatRequired"
			(selectedAsset)="selectedAsset = $event"
			(collectionClick)="mode = 'collections'"></datalean-asset-grid-selector>

		<ng-container *ngIf="mode === 'collections'">
			<datalean-collections
				*ngIf="!selectedCollection"
				(selectedCollections)="selectedCollection = $event.uuid"
				(back)="mode = 'grid'"></datalean-collections>
			<datalean-collection-selector-detail
				*ngIf="selectedCollection"
				[collectionUUID]="selectedCollection"
				(selectedAsset)="selectedAsset = $event"
				(back)="selectedCollection = undefined"></datalean-collection-selector-detail>
		</ng-container>
	</div>
	<div class="buttons-container">
		<button class="confirm" (click)="confirmAsset()" [disabled]="!selectedAsset">
			<span>{{ 'CONFIRM' | translate }}</span>
		</button>
	</div>
</div>
