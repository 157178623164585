import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, Output, inject } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatTabsModule } from '@angular/material/tabs';
import { NavigationExtras, Router } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { FilterSort, SelectFieldComponent } from 'addiction-components';
import { ContextStore } from 'context-store';
import { Subject, combineLatest, filter, first, map } from 'rxjs';
import { HeaderActions, MediaLibraryActions } from 'src/app/core/state/app.actions';
import { MediaLibrarySelectors } from 'src/app/core/state/app.selectors';
import { ConfigurationService } from 'src/app/shared/services/configuration.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'datalean-header-with-input',
	templateUrl: './header-with-input.component.html',
	styleUrls: ['./header-with-input.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		MatTabsModule,
		MatIconModule,
		MatTabsModule,
		MatFormFieldModule,
		MatSelectModule,
		MatSliderModule,
		MatButtonToggleModule,
		SharedModule,
		LetDirective,
		SelectFieldComponent,
	],
})
export class HeaderWithInputComponent implements OnDestroy {
	private ctxStore = inject(ContextStore);
	private router = inject(Router);
	private configurationService = inject(ConfigurationService);

	private destroySubject$ = new Subject();

	/** Dimensione del tile della griglia */
	protected gridSize: number = 6;
	protected minGridSize: number = 1;
	protected maxGridSize: number = 8;

	@Output() sortChange = new EventEmitter<FilterSort>();

	@Input() assetdisplayMode: 1 | 2 = 1;

	//input che verrà passato dal padre per fare il metodo di selectFolder
	@Input('sortValue') sortValue?: FilterSort;
	@Input('sortOptions') sortOptions: FilterSort[] = environment.sortOptions;

	hasDam$ = this.configurationService.isReady.pipe(
		filter((isReady) => isReady),
		map(() => this.configurationService.getConfigValue('hasDAM'))
	);

	constructor() {}

	ngOnDestroy() {
		this.destroySubject$.next(true);
		this.destroySubject$.complete();
	}

	setGridViewOptions(assetdisplayMode?: 1 | 2) {
		this.assetdisplayMode = assetdisplayMode ?? 1;
		this.ctxStore.dispatch(
			HeaderActions.setMediaLibraryGridViewOptions({ options: { assetWidth: this.gridSize, assetdisplayMode: assetdisplayMode ?? 1 } })
		);
	}

	openUploadDialog() {
		this.ctxStore.dispatch(MediaLibraryActions.resetSelectedAsset({}));
		combineLatest([
			this.ctxStore.select(MediaLibrarySelectors.selectCurrentFeatureValuePath),
			this.ctxStore.select(MediaLibrarySelectors.selectFeatureValues),
		])
			.pipe(
				first(),
				map(([path, features]) => {
					const navigationExtras: NavigationExtras = {
						queryParams: {
							currentFeatureValues: [...path].map((fv) => [...(features ?? [])].find((f) => f.uuid === fv)),
							enableUpload: true,
						},
					};
					this.router.navigate(['/', 'media-library', 'new'], navigationExtras);
				})
			)
			.subscribe();
	}

	onChangeSort($event?: string) {
		const sortOpt = this.sortOptions.find((el) => el.value == $event)?.value.split('#');
		const [sortBy, sortDirection] = sortOpt || ['', ''];
		this.ctxStore.dispatch(MediaLibraryActions.setSort({ sort: { sortBy, sortDirection } }));
	}

	goToCollections() {
		this.router.navigateByUrl('/collections');
	}
}
