import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import {
	AbstractControl,
	ControlValueAccessor,
	FormControl,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ReactiveFormsModule,
	ValidationErrors,
	Validator,
	Validators,
} from '@angular/forms';

@Component({
	selector: 'addiction-textarea',
	templateUrl: './textarea.component.html',
	standalone: true,
	imports: [CommonModule, ReactiveFormsModule],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: TextareaComponent },
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: forwardRef(() => TextareaComponent),
		},
	],
})
export class TextareaComponent implements ControlValueAccessor, Validator, OnInit {
	@Input() value? = '';
	@Input() disabled: boolean = false;
	@Input() name?: string;
	@Input() label: string = '';
	@Input() placeholder: string = '';
	@Input() requiredIcon?: boolean = false;
	@Input() readonly?: boolean = false;

	@Output() valueChange = new EventEmitter<string>();
	@Output() click = new EventEmitter<Event>();
	@Input() keydown = new EventEmitter<KeyboardEvent>();

	formControl = new FormControl();

	constructor() {}

	ngOnInit(): void {
		this.formControl.patchValue(this.value);
		if (this.disabled) this.formControl.disable();
		this.formControl.valueChanges.subscribe((val) => {
			this.valueChange.emit(val);
		});
	}

	public onTouched: () => void = () => {};

	onValidatorChange = () => {};

	registerOnValidatorChange(fn: () => void): void {
		this.onValidatorChange = fn;
	}

	validate(c: AbstractControl): ValidationErrors | null {
		if (!this.requiredIcon && c.hasValidator(Validators.required)) {
			this.requiredIcon = true;
		}
		return c.invalid || this.formControl.invalid ? { ...c.errors, ...this.formControl.errors } : null;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	writeValue(obj: string | undefined): void {
		this.formControl.patchValue(obj);
	}

	registerOnChange(fn: (val: string | undefined) => void): void {
		this.formControl.valueChanges.subscribe(fn);
	}

	setDisabledState(disabled: boolean): void {
		if (disabled) this.formControl.disable({ emitEvent: false });
		else this.formControl.enable({ emitEvent: false });
	}
}
