/* eslint-disable @typescript-eslint/no-explicit-any */
import { CdkDrag, CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { Component, DestroyRef, EventEmitter, Input, OnChanges, Optional, Output, inject } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, debounceTime, map, merge, share, shareReplay, startWith, Subject, tap } from 'rxjs';
import { LetDirective } from '@ngrx/component';
import { concatLatestFrom } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';

import {
	TreeBrowserButton,
	TreeBrowserConfig,
	TreeBrowserNode,
	TreeExternalDropEvent,
	TreeRemoveEvent,
	TreeRenameEvent,
} from './tree-browser.models';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ObservableInputs } from '../../utils/observable-input';
import { DeepPartial } from '../../utils/deep-partial';
import { NodeMovement, TreeKeyOptions, TreeNode } from '../../models/tree.interface';
import { createEmitter } from '../../utils/create-emitter';
import { ToLocalizedValuePipe } from '../../pipes/to-localized-value.pipe';
import { SharedDragDropService } from '../../services/shared-drag-drop.service';
import { LocalizedString } from '@angular/compiler';
import { SharedModule } from '../../modules/shared.module';
import { ForbidCharsDirective } from '../../directives/forbid-chars.directive';
import { ChipComponent } from '../chip/chip.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare type Obj = { [key: string]: any }; // in questo caso è davvero ANY

@Component({
	selector: 'addiction-tree-browser',
	templateUrl: './tree-browser.component.html',
	standalone: true,
	imports: [
		CommonModule,
		DragDropModule,
		SharedModule,
		FormsModule,
		DragDropModule,
		LetDirective,
		ForbidCharsDirective,
		ChipComponent,
		MatIconModule,
		MatMenuModule,
	],
})
export class TreeBrowserComponent<T = unknown> implements OnChanges {
	private readonly _inputs = new ObservableInputs(this);
	readonly defaultConfig: TreeBrowserConfig = {
		keys: {
			children: 'children',
			key: 'uuid',
			title: 'name',
			parentKey: 'parentUUID',
		},
		rootLabelKey: 'TREE_BROWSER.ROOT_LABEL',
	};
	protected readonly forbidChars = ['/', '\\', '.', '#'];
	protected inputConfig$ = this._inputs.observe(() => this.config);
	protected inputSelectedKey$ = this._inputs.observe(() => this.selectedKey, { startWithDefault: false });
	protected inputCurrentKey$ = this._inputs.observe(() => this.currentKey);
	protected inputNodes$ = this._inputs.observe(() => this.nodes);
	protected config$ = this.inputConfig$.pipe(
		map((cfg) => {
			const target = structuredClone(this.defaultConfig);
			const source = structuredClone(cfg ?? {});
			//faccio una copia 'profonda' nelle chiavi altrimenti con object.assign(target, source) potrei perdermi
			//dei campi poichè copia il riferimento dell'oggetto keys e non fa il merge profondo
			const res = {
				...target,
				...source,
				keys: {
					...target.keys,
					...(source?.keys ?? {}),
				},
			};
			return res;
		})
	);

	/** Rappresenta il nodo attualmente mostrato (di cui vengono visuaizzati i figli). NON è il nodo "selezionato" */
	private userCurrentUUID$ = new BehaviorSubject<string | undefined>(undefined);
	protected currentUUID$ = merge(this.userCurrentUUID$, this.inputCurrentKey$);

	/** Rappresenta il nodo selezionato, che può essere diverso dal nodo mostrato */
	private userSelectedUUID$ = new BehaviorSubject<string | undefined>(undefined);
	protected selectedUUID$ = merge(this.userSelectedUUID$, this.inputSelectedKey$);
	private selectedPath$ = new BehaviorSubject<T[]>([]);

	/** Gestione check the nodi */
	protected inputCheckedKeys$ = this._inputs.observe(() => this.checkedKeys, { startWithDefault: false });
	protected userCheckedKeys$ = new BehaviorSubject<string[]>([]);

	protected checkedKeys$ = merge(this.inputCheckedKeys$, this.userCheckedKeys$).pipe(shareReplay({ bufferSize: 1, refCount: false }));
	protected checkedNodes: TreeBrowserNode<T>[] = [];
	protected checkedNodes$ = this.checkedKeys$.pipe(
		concatLatestFrom(() => this.tree$),
		map(([keys, tree]) => this.findNodes(tree, (node) => keys.includes(node.id))),
		tap((nodes) => (this.checkedNodes = nodes))
	);

	/** Gestione ricerca */
	protected userSearch$ = new Subject<string>();
	protected search$ = this.userSearch$.pipe(
		debounceTime(300),
		tap(() => {
			return this.userCurrentUUID$.next(undefined);
		}),
		share(),
		startWith('')
	);

	/* Main Tree Root */
	protected tree$ = combineLatest([this.config$, this.inputNodes$, this.checkedKeys$, this.search$]).pipe(
		map(([cfg, nodes, checked, search]) => {
			const tree = this.mapNestedTree(nodes, cfg, checked);
			if (!search) return tree;

			return this.searchTree(tree, search);
		})
	);

	protected currentNode$ = combineLatest([this.tree$, this.currentUUID$]).pipe(
		map(([root, currentId]) => (currentId ? this.findNode(currentId, root) : root))
	);

	private destroyRef = inject(DestroyRef);

	@Input() editing: boolean = false;

	/** Nodi di input. Si raccomanda di usare degli immutabili (tipo store) per ragioni di performance */
	@Input() nodes: T[] = [];
	@Input() config?: DeepPartial<TreeBrowserConfig>;
	@Input() sortable: boolean = false;
	@Input() clonable: boolean = false;
	@Input() removable: boolean = false;
	@Input() renameble: boolean = false;
	@Input() checkable: boolean = false;
	@Input() selectable: boolean = true;
	@Input() addable: boolean = false;
	@Input() selectedKey?: string;
	@Input() currentKey?: string;
	@Input() checkedKeys: string[] = [];
	@Input() allowSearch: boolean = false;
	@Input() customButtons: TreeBrowserButton[] = [];

	@Output() selected = new EventEmitter<T>();
	@Output() selectedPathChange = new EventEmitter<T[]>();
	@Output() sorted = new EventEmitter<NodeMovement<T>>();
	@Output() cloned = new EventEmitter<T>();
	@Output() removed = new EventEmitter<TreeRemoveEvent<T>>();
	@Output() renamed = new EventEmitter<TreeRenameEvent<T>>();
	@Output() moved = new EventEmitter<NodeMovement<T>>();
	@Output() checked = createEmitter(
		this.destroyRef,
		this.userCheckedKeys$.pipe(
			concatLatestFrom(() => this.tree$),
			map(([keys, tree]) => this.findNodes(tree, (node) => keys.includes(node.id))),
			map((nodes) => nodes.map((node) => node.data))
		)
	);
	@Output() added = new EventEmitter<{ parent: string }>();
	@Output() externalItemDropped = new EventEmitter<TreeExternalDropEvent<T>>();

	get editable() {
		return this.sortable || this.clonable || this.removable || this.renameble;
	}

	constructor(
		private translate: TranslatePipe,
		private localization: ToLocalizedValuePipe,
		private toast: ToastrService,
		@Optional() private sharedDragDrop: SharedDragDropService
	) {}

	ngOnChanges() {
		this._inputs.onChanges();
	}

	toggleEditing() {
		this.editing = !this.editing;
	}
	add(parent: string) {
		this.added.emit({ parent });
	}
	findNode(id: string, root: TreeBrowserNode<T>): TreeBrowserNode<T> | undefined {
		const results = this.findNodes(root, (node) => node.id === id);
		return results.length > 0 ? results[0] : undefined;
	}

	findNodes(root: TreeBrowserNode<T>, predicate: (note: TreeBrowserNode) => boolean): TreeBrowserNode<T>[] {
		const found: TreeBrowserNode<T>[] = [];
		if (predicate(root)) found.push(root);
		for (const child of root.children) {
			found.push(...this.findNodes(child, predicate));
		}
		return found;
	}
	findNodesById(id: string, root: TreeBrowserNode<T>): TreeBrowserNode<T>[] {
		return this.findNodes(root, (node) => node.id === id);
	}

	customBtnClick(btn: TreeBrowserButton) {
		if (btn.onClick) btn.onClick();
	}

	protected clone(node: TreeBrowserNode<T>) {
		this.cloned.emit(node.data);
	}
	protected remove(node: TreeBrowserNode<T>) {
		const { data, parent, index } = node;
		this.removed.emit({
			node: data,
			parent: parent?.data,
			index,
		});
	}
	protected nodeRenamed(node: TreeBrowserNode<T>, target: EventTarget, siblings: TreeBrowserNode<T>[]) {
		const input = target as HTMLInputElement;
		const newName = input.value;
		const validity = this.checkNameValidity(newName, siblings);
		if (validity == 'ok') {
			// name is ok
			node.title = newName;
			this.renamed.emit({
				name: newName,
				node: node.data,
			});
			return;
		}
		// reset the title
		input.value = node.title;

		if (validity === 'name_taken') this.toast.error(this.translate.transform('TREE.ERRORS.NAME_TAKEN'));
	}

	searchTree(root: TreeBrowserNode<T>, search: string): TreeBrowserNode<T> {
		const results = this.findNodes(root, (node) => node.title.toLowerCase().includes(search.toLowerCase()));
		const newRoot = { ...root, children: results };
		return newRoot;
	}

	mapNestedTree(sourceNodes: T[], cfg: TreeBrowserConfig, checkedKeys?: string[]) {
		return this.mapTree(this.buildFakeRoot(sourceNodes, cfg), 0, undefined, cfg.keys, checkedKeys);
	}

	/** Esplora e rimappa l'albero, staticizzando i campi dinamici   */
	mapTree(
		sourceNode: T,
		index: number,
		prevNode: TreeBrowserNode<T> | undefined,
		keyMap: TreeKeyOptions,
		checkedKeys?: string[]
	): TreeBrowserNode<T> {
		const id = this.getID(sourceNode, keyMap);
		const node: TreeBrowserNode<T> = {
			id,
			title: this.getTitle(sourceNode, keyMap),
			children: [],
			parent: prevNode,
			isRoot: !prevNode,
			data: sourceNode,
			error: false,
			index,
			type: 'tree-browser-node',
			checked: checkedKeys?.includes(id) ?? false,
		};
		node.children = this.getChildren(sourceNode, keyMap).map((child, i) => this.mapTree(child, i, node, keyMap, checkedKeys));

		return node;
	}

	checkNameValidity(name: string, siblings: TreeBrowserNode<T>[]): 'name_taken' | 'invalid' | 'ok' {
		if (!name || name.trim().length < 1) return 'invalid';

		for (const char of name) if (this.forbidChars.includes(char)) return 'invalid';

		if (siblings.find((node) => node.title === name)) return 'name_taken';

		return 'ok';
	}
	/** Costruisce un nodo finto che contiene i nodi di primo livello (comodo per l'esplorazione dell'albero e non dover gestire casi particolari
	 * se si è al primo livello) */
	buildFakeRoot(nodes: T[], cfg: TreeBrowserConfig): T {
		return {
			[cfg.keys.children]: nodes,
			[cfg.keys.title]: this.translate.transform(cfg.rootLabelKey),
		} as T;
	}

	onDrop(event: CdkDragDrop<TreeBrowserNode<T>, TreeBrowserNode<T>>, currentNode: TreeBrowserNode<T>) {
		const { item, currentIndex, previousIndex } = event;
		if (item.data && item.data.type === 'tree-browser-node') {
			// Sorted a node
			if (currentIndex === previousIndex) return;

			const node = item.data as TreeBrowserNode<T>;

			this.sorted.emit({
				newIndex: currentIndex,
				node: node.data as TreeNode<T>,
				newParent: node.parent?.data as TreeNode<T>,
			});
		} else {
			// dropped a node from outside
			if (!this.sharedDragDrop.lastDropTarget) return;

			// get drop target node (è un po' hackish, ma non ho trovato altre soluzioni, le API di Drag&Drop fanno schifo)
			const id = this.sharedDragDrop.lastDropTarget.getAttribute('data-id');

			const targetNode = id ? this.findNode(id, currentNode) : currentNode;
			if (!targetNode) return;

			this.externalItemDropped.emit({
				droppedItem: item.data,
				target: targetNode.data as TreeNode<T>,
			});
		}
	}

	onMoveToFolder(evt: CdkDragDrop<TreeNode<T>>, node: TreeBrowserNode<T>) {
		const draggedNode = evt.item.data as TreeBrowserNode<T>;
		this.moved.emit({
			newIndex: 0, // non ha senso
			node: draggedNode.data as TreeNode<T>,
			newParent: node.data as TreeNode<T>,
		});
	}

	/**
	 * evento scatenato quando viene selezionato un nodo dell'albero
	 * la navigazione avviene solo se il nodo ha dei figli
	 * @param select Se TRUE, oltre a navigare emette anche l'evento di selezione completata
	 */
	selectAndGoTo(node: TreeBrowserNode<T>, select: boolean) {
		//sezione NAVIGAZIONE
		if (node.children.length)
			//zero equivale a false
			this.userCurrentUUID$.next(node.id);

		//sezione SELECT
		if (select) this.select(node);
	}

	private select(node: TreeBrowserNode<T>) {
		if (this.editing || !this.selectable) return;
		if (this.userSelectedUUID$.value === undefined || this.userSelectedUUID$.value != node.id) this.selected.emit(node.data);

		//#region selectedPath
		const newValue = this.selectedPath$.value;
		//backward
		if (node.children.some((c) => c.id === this.userSelectedUUID$.value)) {
			newValue.pop();
		}
		//forward
		else {
			newValue.push(node.data);
		}
		this.selectedPath$.next(newValue);
		this.selectedPathChange.emit(this.selectedPath$.value);
		//#endregion

		this.userSelectedUUID$.next(node.id);
	}

	// Getters using dynamic object keys
	getChildren(node: T, keys: TreeKeyOptions) {
		const { children } = keys;
		const n = node as Obj;

		if (!Array.isArray(n[children])) return [];

		return n[children] as T[];
	}
	getParent(node: T, keys: TreeKeyOptions) {
		const { parentKey } = keys;
		if (!parentKey) return undefined;

		return (node as Obj)[parentKey] as string;
	}
	getID(node: T, keys: TreeKeyOptions) {
		const { key } = keys;
		return (node as Obj)[key] as string;
	}

	getTitle(node: T, keys: TreeKeyOptions) {
		const { title } = keys;
		const value = (node as Obj)[title] as string | LocalizedString;

		if (typeof value === 'string') return value;

		return this.localization.transform(value);
	}

	// openMoveDialog(node: TreeBrowserNode<T>) {
	// 	//TODO emettere output con dato del nodo per fa aprire una modale da chi usa il componente
	// }

	sortPredicate(index: number, item: CdkDrag<any>) {
		return item.data && item.data.type === 'tree-browser-node';
	}

	toggleChecked(node: TreeBrowserNode<T>, override?: boolean) {
		// if override is undefined, toggle the value. Otherwise just set it
		node.checked = override != undefined ? override : !node.checked;

		const currentKeys = this.checkedNodes.map((n) => n.id);
		if (node.checked) {
			// adds to selection nodes from root to this node
			const parents = this.getPathToRoot(node).map((n) => n.id);
			const selection = [...parents, node.id];
			this.userCheckedKeys$.next(
				Array.from(new Set([...currentKeys, ...selection]).values()) //distinct
			);
		} else this.userCheckedKeys$.next(currentKeys.filter((key) => key !== node.id));
	}

	getPathToRoot(node: TreeBrowserNode<T>) {
		const path: TreeBrowserNode<T>[] = [];
		let current = node.parent;
		while (current && !current.isRoot) {
			path.push(current);
			current = current.parent;
		}
		return path.reverse();
	}
}
