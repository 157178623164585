import { Injectable } from '@angular/core';
import { DataleanBaseApiService, Parts, SimpleObject } from 'addiction-components';
import { environment } from 'src/environments/environment';
import { Role } from '../models';

@Injectable()
export class RolesService {
	constructor(private baseApi: DataleanBaseApiService) {}

	getRoles(filters?: SimpleObject) {
		return this.baseApi.getManyPaged<Role>(environment.rolesUrl, [Parts.ROLES], { additionalParams: filters });
	}
}
