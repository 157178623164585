import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, shareReplay } from 'rxjs';
import { ConfigSelector } from '../../../core/state/app.selectors';

@Component({
	selector: 'datalean-language-selector',
	templateUrl: './language-selector.component.html',
	styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent {
	@Input() compiledLocales: string[] | null = [];
	@Input() selectedLocale?: string | null;
	@Input() availableLocales: string[] | null = [];
	@Input() showPlusButton: boolean | null = true;
	@Input() showRemoveButton: boolean | null = true;
	@Output() addedLocale: EventEmitter<string> = new EventEmitter<string>();
	@Output() removedLocale: EventEmitter<string> = new EventEmitter<string>();
	@Output() clickedLocale: EventEmitter<string> = new EventEmitter<string>();

	private store = inject(Store);

	protected defaultLocale$ = this.store.select(ConfigSelector.selectDefaultLanguage).pipe(
		filter((v) => !!v),
		shareReplay({ refCount: true, bufferSize: 1 })
	);

	constructor() {}

	getPossibleLocales() {
		// TODO: refactorizzare questa funzione perchè attualmente triggera una doppia iterazione ad ogni render del component
		return this.availableLocales?.filter((l) => !this.compiledLocales?.includes(l));
	}

	addLocale(locale: string) {
		this.addedLocale.emit(locale);
	}

	changeLang(locale: string) {
		this.clickedLocale.emit(locale);
	}

	deleteLang(locale: string) {
		this.removedLocale.emit(locale);
	}
}
