import { HttpErrorResponse } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { Communication } from 'src/app/shared/models';
import { STATE_STATUS } from 'src/app/shared/models/state-status.enum';

export const setForceRefreshSurveyCommunications = createAction('[Datalean SurveyCommunications] Set Force Refresh');
export const setSurveyCommunications = createAction('[Datalean SurveyCommunications] Set Datalean SurveyCommunications');
export const setSurveyCommunicationsSuccess = createAction(
	'[Datalean SurveyCommunications] Set Datalean SurveyCommunications Success',
  props<{ data: { totalCommunicationsCount: number; pages: number[]; communications: Communication[][], hasResponse: boolean } }>()
);
export const setSurveyCommunicationsError = createAction('[Datalean SurveyCommunications] Set Datalean SurveyCommunications Error', props<{ error: HttpErrorResponse }>());
export const setStatus = createAction('[Datalean SurveyCommunications] Set status', props<{ status: STATE_STATUS }>());
export const setPages = createAction('[Datalean SurveyCommunications] Set pages', props<{ pages: number[] }>());
export const setSort = createAction('[Datalean SurveyCommunications] Set sort', props<{ sort: Sort }>());
export const setGridSearch = createAction('[Datalean SurveyCommunications] Set search', props<{ search: string | null }>());
export const setCurrentSurveyUUID = createAction('[Datalean SurveyCommunications] Set survey uuid', props<{ uuid: string | null}>());

