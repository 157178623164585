<div class="page-title">
  <span class="title">{{ title | translate }}</span>
</div>
<div class="datalean-list-container">
  <mat-list *ngFor="let item of list; index as i">
      <mat-list-item>
          <div class="list-item">
              <div class="left-container">
                  <span class="number">{{ i + 1 }}</span>
              </div>
              <div class="details-container">
                  <div class="single-detail" *ngFor="let key of properties">
                      <span class="name">{{ translationString + key | uppercase | translate }}</span>
                      <span class="value">{{ item[key] }}</span>
                  </div>
              </div>
              <div class="right-container">
                  <button class="button-delete" (click)="deleteItem.emit(item)"></button>
              </div>
          </div>
      </mat-list-item>
  </mat-list>
</div>
