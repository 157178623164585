import { ChangeDetectorRef, Directive, ElementRef, HostBinding, HostListener, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Directive({
	selector: '[dataleanAspectRatio]',
})
export class AspectRatioDirective implements OnInit {
	private _height: number = 0;

	@HostBinding('style.height')
	get height() {
		return this._height + 'px';
	}

	@Input('dataleanAspectRatio')
	aspectRatio: number = 1;

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.updateHeight();
	}

	constructor(private el: ElementRef, private cdr: ChangeDetectorRef) {}

	ngOnInit(): void {
		this.updateHeight();

		this.createResizeObserver(this.el)
			.pipe(takeUntilDestroyed(), throttleTime(50))
			.subscribe(() => this.updateHeight());
	}

	protected updateHeight() {
		const width = this.el.nativeElement.offsetWidth;
		this._height = width * this.aspectRatio;
		this.cdr.detectChanges();
	}

	protected createResizeObserver(element: ElementRef) {
		return new Observable((obs) => {
			const resizeObs = new ResizeObserver((entries) => {
				if (entries.length) obs.next(entries[0].target);
			});
			resizeObs.observe(element.nativeElement);

			return () => resizeObs.disconnect();
		});
	}
}
