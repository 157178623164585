<div class="asset-selector mode-{{ mode }}" [formGroup]="formGroup">
	<div class="label-container">
		<label *ngIf="label">{{ label }} <span *ngIf="requiredIcon">*</span></label>
	</div>
	<div class="preview" *ngIf="mode === 'complete' || mode === 'image'">
		<label>{{ 'STRUCTURE_FIELDS.FILE' | translate }} <span *ngIf="requiredIcon">*</span></label>
		<datalean-asset-selector
			[value]="value"
			[readonly]="readonly"
			[disabled]="disabled"
			[assetType]="assetTypeInput"
			[formatRequired]="formatRequired"
			(changed)="assetSelected($event)"
			emit="object"></datalean-asset-selector>
	</div>
	<div class="file" *ngIf="mode === 'compact'">
		<label>{{ 'STRUCTURE_FIELDS.FILE' | translate }}</label>
		<div class="file-selector" [ngClass]="{ readonly: readonly }">
			<button *ngIf="!readonly" type="button" class="dialog-button" (click)="chooseAsset()">{{ 'STRUCTURE_FIELDS.CHOOSE_ASSET' | translate }}</button>
			<addiction-input [readonly]="readonly" formControlName="name"></addiction-input>
		</div>
	</div>
	<div class="info" *ngIf="mode !== 'image'">
		<div class="alt-text" *ngIf="mode === 'complete'">
			<addiction-input
				[readonly]="readonly"
				label="{{ 'STRUCTURE_FIELDS.ASSET_ALT_TEXT' | translate }}"
				[readonly]="!formGroup.value.name || disabled"
				formControlName="altText"></addiction-input>
		</div>
		<div class="url">
			<addiction-input
				[readonly]="readonly"
				label="{{ 'STRUCTURE_FIELDS.ASSET_PATH' | translate }}"
				[readonly]="true"
				formControlName="url"
				[copy]="true"></addiction-input>
		</div>
	</div>
</div>
