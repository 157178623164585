import { Asset, ObjectValuesType } from 'addiction-components';

export class LessonContent {
	name = '';
	type = ContentType.VIDEO;
	duration = new LessonDuration();
	active = false;
	//blockingContentList = [''];
	file?: Asset;
	uuid = '';
}

export const ContentType = {
	VIDEO: 'video',
	DOCUMENT: 'document',
	QUIZ: 'quiz',
} as const;

export type ContentType = ObjectValuesType<typeof ContentType>;

export const UnitFormatLesson = {
	MINUTE: 'minutes',
	HOUR: 'hours',
} as const;
export type UnitFormatLesson = ObjectValuesType<typeof UnitFormatLesson>;

export class LessonDuration {
	unitQuantity = 0;
	unitFormat = UnitFormatLesson.MINUTE;
}
