import { HttpErrorResponse } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { ApplicationUserSmartGroup } from 'src/app/shared/models';
import { STATE_STATUS } from 'src/app/shared/models/state-status.enum';

export const setForceRefreshSmartGroups = createAction('[SmartGroups] Set Force Refresh');
export const setSmartGroups = createAction('[SmartGroups] Set SmartGroups');
export const setSmartGroupsSuccess = createAction(
	'[SmartGroups] Set SmartGroups Success',
	props<{ data: { totalSmartGroupsCount: number; pages: number[]; groups: ApplicationUserSmartGroup[][] } }>()
);
export const setSmartGroupsError = createAction('[SmartGroups] Set SmartGroups Error', props<{ error: HttpErrorResponse }>());
export const setDeleteDataleanSmartGroup = createAction('[SmartGroups] Set Delete SmartGroups', props<{ group: ApplicationUserSmartGroup }>());
export const setDeleteDataleanSmartGroupSuccess = createAction('[SmartGroups] Set Delete SmartGroups Success');
export const setDeleteDataleanSmartGroupError = createAction('[SmartGroups] Set Delete SmartGroups Error', props<{ error: HttpErrorResponse }>());
export const setStatus = createAction('[SmartGroups] Set status', props<{ status: STATE_STATUS }>());
export const setPages = createAction('[SmartGroups] Set pages', props<{ pages: number[] }>());
export const setCachedPages = createAction('[SmartGroups] Set cached pages', props<{ pages: number[] }>());
export const resetCache = createAction('[SmartGroups] reset cache');
export const setSort = createAction('[SmartGroups] Set sort', props<{ sort: Sort }>());
export const setGridSearch = createAction('[SmartGroups] Set search', props<{ search: string | null }>());
