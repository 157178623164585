import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, catchError, combineLatestWith, debounce, distinctUntilChanged, filter, first, map, of, switchMap, tap } from 'rxjs';
import { LessonsActions } from 'src/app/core/state/app.actions';
import { CommunitySelectSelectors } from 'src/app/core/state/app.selectors';
import { STATE_STATUS } from 'src/app/shared/models';
import { LessonsService } from '../services/lessons.service';
import { setDeleteLessons, setDeleteLessonsError, setDeleteLessonsSuccess, setLessonsSuccess } from './lessons.actions';
import { selectCachedPages, selectGridSearch, selectPagedData, selectPages, selectSort, selectStatus } from './lessons.selectors';

@Injectable()
export class LessonsEffects {
	private actions$ = inject(Actions);
	private store = inject(Store);
	private translateService = inject(TranslateService);
	private lessonService = inject(LessonsService);

	constructor() {}

	fetchLessons$ = createEffect(() =>
		this.actions$.pipe(
			//quando selezioni una categoria o fai una ricerca, viene triggherato il DamFilter, quindi ci registriamo ad
			//un suo eventuale successo per richiamare gli assets
			ofType(LessonsActions.setPages, LessonsActions.setForceRefreshLessons),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(CommunitySelectSelectors.selectLastCommunitySelectedForApiRest),
				this.store.select(selectCachedPages),
			]),
			debounce(() => this.store.select(selectStatus).pipe(filter((stat) => !stat || stat !== 'loading'))),
			//non voglio caricare se non ho le pagine pronte
			filter(([, pages]) => !!pages?.length),
			tap(() => {
				this.store.dispatch(LessonsActions.setStatus({ status: STATE_STATUS.LOADING }));
			}),
			switchMap(([, pages, sort, gridSearch, communityUUID, cachedPages]) => {
				// console.log(pages, cachedPages);
				const actualPages = pages.filter((p) => !cachedPages.includes(p));
				if (!actualPages.length) return EMPTY;

				return this.lessonService
					.fetchLessons(actualPages, sort, this.translateService.currentLang ?? this.translateService.defaultLang, gridSearch, communityUUID)
					.pipe(
						combineLatestWith(this.store.select(selectPagedData)),
						first(),
						map(([data, startingResult]) => {
							// console.log(startingResult);
							startingResult.pages = cachedPages;
							const result = data.reduce((acc, item) => {
								if (item.paginationInfo) {
									acc.pages.push(item.paginationInfo.pageNumber);
									acc.totalLessonsCount = item.paginationInfo.totalElements ?? 0;
									if (item.result) {
										acc.lessons[item.paginationInfo.pageNumber] = item.result.map((lesson) => ({
											...lesson,
											features: lesson.featureValueList.map((f) => f.name || f.label),
											contents: lesson.contentList?.length ?? 0,
										}));
									}
								}
								return acc;
							}, structuredClone(startingResult));

							// console.log(result);

							return LessonsActions.setLessonsSuccess({ data: result });
						}),
						catchError((error: HttpErrorResponse) => {
							console.error(error);
							return of(LessonsActions.setLessonsError({ error }));
						})
					);
			})
		)
	);

	lessonSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setLessonsSuccess),
			map(({ data: { pages } }) => LessonsActions.setCachedPages({ pages }))
		)
	);

	deleteLessons$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setDeleteLessons),
			switchMap(({ uuids }) => {
				return this.lessonService.delete(uuids).pipe(
					map(() => {
						return setDeleteLessonsSuccess();
					}),
					catchError((error: HttpErrorResponse) => {
						return of(setDeleteLessonsError({ error }));
					})
				);
			})
		)
	);

	resetCache$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LessonsActions.setGridSearch, LessonsActions.setSort),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(CommunitySelectSelectors.selectLastCommunitySelectedForApiRest),
				this.store.select(selectCachedPages),
			]),
			//non voglio caricare se i dati delle azioni sono gli stessi
			distinctUntilChanged(([, pagesPrev, sortPrev, gridSearchPrev], [, pagesCurr, sortCurr, gridSearchCurr, communityUUIDCurr, cachedPages]) => {
				// console.log(action);
				return (
					pagesPrev.length === pagesCurr.length &&
					pagesPrev.every((f) => pagesCurr.includes(f)) &&
					sortCurr?.active === sortPrev?.active &&
					sortCurr?.direction === sortPrev?.direction &&
					gridSearchCurr === gridSearchPrev &&
					pagesPrev.filter((f) => !cachedPages.includes(f)).length === 0
				);
			}),
			map(() => LessonsActions.resetCache())
		)
	);

	deleteLesson$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LessonsActions.setDeleteLessonsSuccess, LessonsActions.setDeleteLessonsError),
			map(() => LessonsActions.resetCache())
		)
	);

	forceRefreshTable$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LessonsActions.resetCache),
			map(() => LessonsActions.setForceRefreshLessons())
		)
	);
}
