import { ObjectValuesType } from 'addiction-components';
import { WidgetAssetCount, WidgetWithOnlyCount } from './widgets-count';
import { createDeepTypeGuard } from '../../lib/utils/type-guard';

export const WidgetTypes = {
	USER_GROUP: 'widgetUsersGroup',
	USER_SMARTGROUP: 'widgetUserSmartGroups',
	APPLICATION_USERS: 'widgetApplicationUsers',
	PRODUCTS: 'widgetProducts',
	WISHLIST: 'widgetWishList',
	FEATURE: 'widgetFeauture',
	COMMUNITIES: 'widgetCommunities',
	COMMUNICATIONS: 'widgetCommunications',
	MEDIA: 'widgetMedia',
	CHART: 'widgetChart',
} as const;

export type WidgetTypes = ObjectValuesType<typeof WidgetTypes>;

export const isWidgetWithOnlyCount = createDeepTypeGuard<WidgetWithOnlyCount>('count', 'number');
export const isWidgetAssetCount = createDeepTypeGuard<WidgetAssetCount>('imageCount', 'number');
