<div class="buttons-container" *ngIf="showFilterDelete">
	<button class="delete" (click)="filterDelete.emit()">
		<span>{{ 'FILTER_MENU.DELETE' | translate }}</span>
	</button>
</div>

<div class="checkbox-filters-container">
	<div class="single-filter" *ngFor="let section of filterMenuSection; trackBy: trackSection">
		<ng-container *ngIf="section.sectionFilterType === menuType.checkbox">
			<span class="filter-title">{{ section.titleSection | translate }}</span>
			<!-- MOSTRO SOLO I PRIMI 3 FIELDS-->
			<div class="filter-options">
				<ng-container *ngFor="let menuItem of section.fields; let index = index; trackBy: trackFields">
					<div class="single-filter-option" *ngIf="index < 3 || section.isExpanded">
						<mat-checkbox [(ngModel)]="menuItem.checked" (change)="emitOnChangeFilter()">
							{{ menuItem.label | translate }}
						</mat-checkbox>
					</div>
				</ng-container>

				<div class="buttons-container" *ngIf="section.fields.length > 3">
					<button (click)="section.isExpanded = !section.isExpanded">
						{{ (section.isExpanded ? 'FILTER_MENU.SHOW_MOST_USED' : 'FILTER_MENU.SHOW_MORE') | translate }}
					</button>
				</div>
			</div>
		</ng-container>

		<div class="single-filter-option tag" *ngIf="section.sectionFilterType === menuType.tags">
			<form [formGroup]="form">
				<span class="filter-title">{{ section.titleSection | translate }}</span>
				<div class="chip-container">
					<input
						[formControlName]="section.id"
						[matChipInputFor]="chipGrid"
						[matAutocomplete]="auto"
						(matChipInputTokenEnd)="addChip($event, section)" />
					<mat-chip-grid #chipGrid>
						<div class="single-chip" *ngFor="let tag of section.fields">
							<mat-chip-row *ngIf="tag.checked" (removed)="removeChip(asString(tag.value), section)">
								<div class="text">{{ tag.label }}</div>
								<button class="remove" matChipRemove></button>
							</mat-chip-row>
						</div>
					</mat-chip-grid>
					<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedChip($event, section)">
						<mat-option *ngFor="let option of section.optionsTags" [value]="option.value">
							{{ option.label }}
						</mat-option>
					</mat-autocomplete>
				</div>
			</form>
		</div>

		<div class="single-filter-option category" *ngIf="section.sectionFilterType === menuType.select">
			<form [formGroup]="form">
				<span class="filter-title">{{ section.titleSection | translate }}</span>
				<addiction-autocomplete
					[formControlName]="section.id"
					[optionGroups]="section.optionsSelectAutocomplete ?? []"
					(valueSelected)="valueChangedSelectAutocomplete(section, $event)">
				</addiction-autocomplete>
			</form>
		</div>

		<div *ngIf="section.sectionFilterType === menuType.rangeDate">
			<form [formGroup]="form" class="dates-filters-container">
				<div class="single-filter">
					<span class="filter-title">{{ section.titleSection | translate }}</span>
					<addiction-date-picker
						[defaultDate]="undefined"
						[formControlName]="section.id"
						[maxDate]="form.value[section.id + 'TO'] ?? undefined"
						[showTime]="false"
						(dateChanged)="onDateRangeChange($event, section, true)"></addiction-date-picker>
				</div>
				<div class="single-filter">
					<span class="filter-title">{{ section.secondTitleSection ?? '' | translate }}</span>
					<addiction-date-picker
						[formControlName]="section.id + 'TO'"
						[defaultDate]="undefined"
						[minDate]="form.value[section.id] ?? undefined"
						[showTime]="false"
						(dateChanged)="onDateRangeChange($event, section, false)"></addiction-date-picker>
				</div>
			</form>
		</div>
	</div>
</div>
