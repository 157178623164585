import { ObjectValuesType } from "addiction-components";

export interface OperationPermission {
  entityUUID?: string; //id dell'entità per cui si crea l'operatonPermission (es. id dell'asset)
  entityType: OperationPermissionEntityType;
  subjectUUID: string;
  subjectType: OperationPermissionSubjectType;
  operationList: OperationPermissionEnum[];
}

export const OperationPermissionEntityType = {
  ASSET: 'asset',
} as const;

export const OperationPermissionSubjectType = {
  APPLICATION_GROUP: 'applicationGroup',
  APPLICATION_SMART_GROUP: 'applicationSmartGroup',
  APPLICATION_USER: 'applicationUser',
} as const;

export const OperationPermissionEnum = {
  DOWNLOADS: 'downloads',
  SHARE: 'share',
  OPEN_IN_APP: 'openInApp', //momentaneamento non utilizzato
  // ADDABLE_IN_COLLECTION: 'addableInCollection',  //momentaneamento non utilizzato
} as const;

export type OperationPermissionSubjectType = ObjectValuesType<typeof OperationPermissionSubjectType>;
export type OperationPermissionEntityType = ObjectValuesType<typeof OperationPermissionEntityType>;
export type OperationPermissionEnum = ObjectValuesType<typeof OperationPermissionEnum>;
