<div class="form-field checkbox-privacy" [class.disabled]="disabled">
  <div class="checkbox-privacy-default" *ngIf="formControl">
    <input #checkbox type="checkbox" [formControl]="formControl" />
    <div class="privacy-text">
      <span *ngFor="let textPart of privacyLabelSections" [ngClass]="textPart.includes('<u>') ? 'link' : ''"
        (click)="textPart.includes('<u>') ? openPrivacy(privacyConfig.url) : checkbox.click()" [innerHTML]="textPart">
      </span>
    </div>
  </div>
</div>
