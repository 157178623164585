import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DATALEAN_QUIZ_TAG, QuizState } from './quiz.reducer';

const selectQuizState = createFeatureSelector<QuizState>(DATALEAN_QUIZ_TAG);

export const selectData = createSelector(selectQuizState, (state: QuizState) => state.data);
export const selectPages = createSelector(selectQuizState, (state: QuizState) => state.pages);
export const selectPagedData = createSelector(selectData, selectPages, (data, pages) => ({ ...data, pages }));
export const selectGridSearch = createSelector(selectQuizState, (state: QuizState) => state.gridSearch);
export const selectStatus = createSelector(selectQuizState, (state: QuizState) => state.status);
export const selectSort = createSelector(selectQuizState, (state: QuizState) => state.sort);
export const selectCachedPages = createSelector(selectQuizState, (state) => state.cachedPages);
