<a
	class="tab-option"
	*ngFor="let opt of options"
	[routerLink]="opt.link"
	[class.active]="selected?.name === opt.name"
	routerLinkActive="active"
	(click)="select(opt)">
	<span class="text">{{ opt.name }}</span>
	<span class="count">{{ opt.count }}</span>
</a>
