import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { AsPipe, ToLocalizedValuePipe } from '../pipes';

@NgModule({
	declarations: [],
	imports: [CommonModule, TranslateModule, ToLocalizedValuePipe, FormsModule, ReactiveFormsModule, AsPipe],
	exports: [CommonModule, TranslateModule, ToLocalizedValuePipe, FormsModule, ReactiveFormsModule, AsPipe],
	providers: [ToLocalizedValuePipe, TranslatePipe, AsPipe],
})
export class SharedModule {}
