import { Component, DestroyRef, OnDestroy, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { transformTypeClassIntoInterface } from 'addiction-components';
import { combineLatestWith, filter, map, shareReplay, tap } from 'rxjs';
import { HeaderLocaleService } from 'src/app/components';
import { HeaderActions } from 'src/app/core/state/app.actions';
import { ConfigSelector } from 'src/app/core/state/app.selectors';

@Component({
	selector: 'datalean-localizable-field-editor',
	template: '',
})
export class LocalizableFieldEditorComponent<T = Record<string, unknown>> implements OnInit, OnDestroy {
	protected store = inject(Store);
	protected hs = inject(HeaderLocaleService);
	protected destroyRef = inject(DestroyRef);

	private removedLocale$ = this.hs.removedLocale$.pipe(
		combineLatestWith(this.hs.selectedLocales$),
		tap(([d, active]) => {
			// console.log(d, active);
			if (this.activeLanguage === d) {
				if (this.defaultLanguage && active.includes(this.defaultLanguage)) {
					this.hs.changeActiveLocale(this.defaultLanguage);
				} else {
					this.hs.changeActiveLocale(active[0]);
				}
			}
		}),
		takeUntilDestroyed(this.destroyRef)
	);

	protected localizableEntity: {
		[key: string]: {
			[K in keyof transformTypeClassIntoInterface<T>]: T[K];
		} & Record<string, unknown>;
	} = {};
	protected defaultLanguage?: string;
	protected activeLanguage?: string;
	protected localizableFields: Array<string> = [];
	protected activeLocale$ = this.hs.activeLocale$.pipe(
		// tap((d) => console.log(d)),
		filter((l) => !!(l && l !== '' && l !== this.activeLanguage)),
		map<string, [string | undefined, string]>((l) => [this.activeLanguage, l]),
		tap(([, l]) => {
			this.activeLanguage = l;
		}),
		takeUntilDestroyed(this.destroyRef),
		shareReplay({ bufferSize: 1, refCount: true })
	);
	protected manualHeaderActivation = false;

	protected activateHeader = () => {
		this.store.dispatch(HeaderActions.setShowConfig({ communitySelect: false, localeSelect: true }));
	};

	protected deactivateHeader = () => {
		this.store.dispatch(HeaderActions.setConfig({ config: { showCommunitySelect: false, showLocales: false, showBackButton: false } }));
	};

	constructor() {
		this.store
			.select(ConfigSelector.selectDefaultLanguage)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe({
				next: (defaultLanguage) => {
					// console.log('LessonsEditorComponent defaultLanguage', defaultLanguage);
					this.defaultLanguage = defaultLanguage;
					this.hs.addConfig({ activeLocale: this.defaultLanguage, selectedLocales: [this.defaultLanguage] });
				},
			});
	}

	ngOnInit(): void {
		if (!this.manualHeaderActivation) {
			this.activateHeader();
		}
		this.removedLocale$.subscribe();
	}

	ngOnDestroy(): void {
		this.deactivateHeader();
	}

	protected getLocalizableEntity(): T & {
		localizedValues: Array<{ locale: string; [key: string]: unknown }>;
	};
	protected getLocalizableEntity<
		A = {
			[key: string]: {
				[K in keyof transformTypeClassIntoInterface<T>]: T[K];
			} & Record<string, unknown>;
		},
		B extends { localizedValues?: Array<{ locale: string; [key: string]: unknown }> } = A & {
			localizedValues: Array<{ locale: string; [key: string]: unknown }>;
		}
	>(): B;
	protected getLocalizableEntity<
		A = {
			[key: string]: {
				[K in keyof transformTypeClassIntoInterface<T>]: T[K];
			} & Record<string, unknown>;
		},
		B extends { localizedValues?: Array<{ locale: string; [key: string]: unknown }> } = A & {
			localizedValues: Array<{ locale: string; [key: string]: unknown }>;
		}
	>(): B {
		return Object.entries(this.localizableEntity).reduce((acc, [key, value]) => {
			const localizedValues = acc.localizedValues ?? [];
			const locale = key;
			const localizedValue: { locale: string; [key: string]: unknown } = { locale };
			for (const field of this.localizableFields) {
				if (field in value && value[field] !== undefined) {
					localizedValue[field] = value[field];
					delete value[field];
				}
			}
			localizedValues.push(localizedValue);
			if (key === this.defaultLanguage) {
				acc = { ...acc, ...value, localizedValues };
			}
			return { ...acc, localizedValues };
		}, {} as B);
	}

	protected syncNonLocalizableFields(newLanguage: string): void {
		if (!this.defaultLanguage || !this.localizableEntity[this.defaultLanguage]) return;
		if (!this.localizableEntity[newLanguage]) this.localizableEntity[newLanguage] = {} as T & Record<string, unknown>;
		if (this.defaultLanguage && newLanguage !== this.defaultLanguage) {
			for (const key of Object.keys(this.localizableEntity[this.defaultLanguage])) {
				if (!this.localizableFields.includes(key)) {
					this.localizableEntity[newLanguage] = { ...this.localizableEntity[newLanguage], [key]: this.localizableEntity[this.defaultLanguage][key] };
				}
			}
		}
	}

	public isReadonly(fieldName: string): boolean {
		// console.log('LocalizableFieldEditorComponent isReadonly', fieldName);
		// console.log('LocalizableFieldEditorComponent isReadonly', this.activeLanguage !== this.defaultLanguage);
		// console.log('LocalizableFieldEditorComponent isReadonly', !this.localizableFields.includes(fieldName));
		return this.activeLanguage !== this.defaultLanguage && !this.localizableFields.includes(fieldName);
	}
}
