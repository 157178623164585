import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Optional, Output, inject } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import {
	Asset,
	AssetType,
	DamHeaderComponent,
	DndDirective,
	FeatureValue,
	FilterMenuComponent,
	FilterMenuFieldDateLabel,
	FilterMenuState,
	GridComponent,
	ListComponent,
	TreeBrowserComponent,
	TreeBrowserConfig,
} from 'addiction-components';
import { environment } from 'src/environments/environment';
import { MediaLibraryFilters, QueueStatus } from '../../../../pages/media-library/state/media-library.state';
import { AssetSelectorService } from './service/asset-selector.service';
import { TranslateModule } from '@ngx-translate/core';
import { ToIconPipe } from 'src/app/shared/pipes/to-icon.pipe';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ContextStore } from 'context-store';
import { first, map, skip } from 'rxjs';
import { MediaLibrarySelectors } from 'src/app/core/state/app.selectors';
import { createFilterObject } from 'src/app/shared/helpers/filter-parameter-utils';
import { AssetSaveData } from 'src/app/shared/models';
import { AssetIdentifierService } from 'src/app/shared/services/asset-identifier.service';
import { LottieModule } from 'ngx-lottie';
import { selectIsUploading } from 'src/app/pages/media-library/state/media-library.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { assetUpdateQueue } from 'src/app/pages/media-library/state/media-library.actions';
import { STATE_STATUS } from 'src/app/shared/models/state-status.enum';
import { MediaLibraryActions } from '../../../../core/state/app.actions';
@Component({
	selector: 'datalean-asset-grid-selector',
	templateUrl: './asset-grid-selector.component.html',
	styleUrls: ['./asset-grid-selector.component.scss'],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		CommonModule,
		DamHeaderComponent,
		FilterMenuComponent,
		TreeBrowserComponent,
		ListComponent,
		GridComponent,
		LetDirective,
		TranslateModule,
		ToIconPipe,
		MatCheckboxModule,
		LottieModule,
		DndDirective,
	],
	providers: [AssetSelectorService, AssetIdentifierService],
})
export class AssetGridSelectorComponent {
	readonly STATE_STATUS = STATE_STATUS;
	showAddModal = false;
	showFilterMenu = false;
	showNavigationTree = false;
	viewMode: 'grid' | 'list' = 'grid';
	sliderValue = 4;
	pageSize = environment.pageSize;
	selectedFeaturePath: FeatureValue[] = [];

	service = inject(AssetSelectorService);
	ctxStore = inject(ContextStore);
	identifier = inject(AssetIdentifierService);

	@Input() selectionMode: 'single' | 'multi' | 'off' = 'off';
	@Input() assetType: AssetType | undefined;
	@Input() formatRequired: string | undefined;

	@Output() selectedAsset = new EventEmitter<Asset>();
	@Output() confirmedAssets = new EventEmitter<string[]>();
	@Output() selectionChange = new EventEmitter<string[]>();
	//TODO: gestire modalità popup?
	@Output() collectionClick = new EventEmitter<void>();

	treeConfig: TreeBrowserConfig = {
		keys: {
			key: 'uuid',
			parentKey: 'parentUUID',
			title: 'name',
			children: 'featureValues',
		},
		rootLabelKey: 'MEDIA_LIBRARY.ROOT',
	};

	readonly sortOptions = environment.sortOptions;

	constructor(
		@Optional() public dialogRef: DialogRef<string[]>,
		@Optional() @Inject(DIALOG_DATA) data?: { selectionMode?: 'single' | 'multi' | 'off'; assetType: AssetType; formatRequired: string }
	) {
		if (data?.selectionMode) {
			this.selectionMode = data.selectionMode;
		}
		if (data?.assetType) {
			this.assetType = data.assetType;
			this.service.setAssetType(this.assetType);
		} else {
			this.service.setAssetType(AssetType.DEFAULT);
		}

		if (data?.formatRequired) {
			this.formatRequired = data.formatRequired;
			this.service.setFormat(this.formatRequired);
		}

		// console.log('AssetGridSelectorComponent constructor', this.service.format());

		this.ctxStore
			.select(selectIsUploading)
			.pipe(skip(1), takeUntilDestroyed())
			.subscribe((is) => {
				if (!is) {
					this.service.reload$.next(true);
				}
			});
	}

	//mostro il pulsante 'elimina filtri' solo se ne ho almeno
	//uno impostato
	showFilterDelete$ = this.ctxStore.select(MediaLibrarySelectors.selectFilters).pipe(
		map((filters) => {
			const filtersKeys = Object.keys(filters ?? []).filter((key): key is keyof MediaLibraryFilters => !!key);
			if (filters && filtersKeys.length > 0) {
				for (const key of filtersKeys) {
					if (
						key !== 'showAllFormat' &&
						key !== 'showAllAssetType' &&
						((Array.isArray(filters[key]) && filters[key]?.length) || (!Array.isArray(filters[key]) && filters[key]))
					) {
						return true;
					}
				}
			}
			return false;
		})
	);

	reset() {
		this.ctxStore.dispatch(MediaLibraryActions.setViewMode({ filters: {}, viewMode: 'browse' }));
		this.service.updateActiveFilters({});
	}

	getAccepted() {
		const format = this.service.format();
		let assetType = this.service.assetType();

		if (assetType === AssetType.DEFAULT) {
			assetType = undefined;
		}

		// console.log('getAccepted', format, assetType);

		if (!format && !assetType) {
			return '*/*';
		}
		if (!format) {
			return assetType + '/*';
		}

		if (!assetType) {
			// console.log('this.identifier.getMimeType(format)', this.identifier.getMimeType(format));
			return this.identifier.getMimeType(format);
		}

		return assetType + '/' + format;
	}

	manageFilesDropped(drop: File[] | Event) {
		let files: File[] = [];
		if (drop instanceof Event) {
			if (drop.target && 'files' in drop.target) files = drop.target.files as File[];
		} else {
			files = drop;
		}
		this.ctxStore
			.select(MediaLibrarySelectors.getQueue)
			.pipe(first())
			.subscribe((queue) => {
				const featureUUID = this.selectedFeaturePath.map((sfp) => sfp.uuid); //l'ultimo elemento è la categorie selezionata
				const filters = createFilterObject(this.service.activeFilters(), featureUUID.pop());
				for (const fileToUpload of files) {
					const asset: AssetSaveData = {
						name: fileToUpload.name,
						file: fileToUpload,
						assetType: this.identifier.getAssetType(fileToUpload.type),
						tags: filters.tags?.split(','),
					};
					if (featureUUID) asset['featureValueList'] = featureUUID;

					queue = [...queue, { assetInfo: asset, status: QueueStatus.WAITING, progress: 0 }];
				}
				this.ctxStore.dispatch(assetUpdateQueue({ queue }));
			});
	}

	onFilterChanged(filters: FilterMenuState) {
		const creationFrom: Date | undefined = filters.sections
			.find((sec) => sec.id === 'dateCreation')
			?.fields?.find((menuItem) => menuItem.label === FilterMenuFieldDateLabel.FROM)?.value as Date | undefined;
		const creationTo: Date | undefined = filters.sections
			.find((sec) => sec.id === 'dateCreation')
			?.fields?.find((menuItem) => menuItem.label === FilterMenuFieldDateLabel.TO)?.value as Date | undefined;
		const updateFrom: Date | undefined = filters.sections
			.find((sec) => sec.id === 'dateUpdate')
			?.fields?.find((menuItem) => menuItem.label === FilterMenuFieldDateLabel.FROM)?.value as Date | undefined;
		const updateTo: Date | undefined = filters.sections
			.find((sec) => sec.id === 'dateUpdate')
			?.fields?.find((menuItem) => menuItem.label === FilterMenuFieldDateLabel.TO)?.value as Date | undefined;

		const tags = filters.sections
			.find((sec) => sec.id === 'tags')
			?.fields?.filter((menuItem) => menuItem.checked)
			?.map((menuItem) => menuItem.value);
		const tagsFilter = tags?.length ? tags?.join(',') : undefined;

		const format = filters.sections
			.find((sec) => sec.id === 'format')
			?.fields?.filter((menuItem) => menuItem.checked)
			?.map((menuItem) => menuItem.value as string);
		const formatFilter = format?.length ? format?.join(',') : undefined;

		const mediaLibraryFilters: MediaLibraryFilters = {};
		if (creationFrom) {
			mediaLibraryFilters.createFrom = creationFrom?.toISOString();
		}
		if (creationTo) {
			mediaLibraryFilters.createTo = creationTo?.toISOString();
		}
		if (updateFrom) {
			mediaLibraryFilters.updateFrom = updateFrom?.toISOString();
		}
		if (updateTo) {
			mediaLibraryFilters.updateTo = updateTo?.toISOString();
		}
		const assetTypes = filters.sections
			.find((sec) => sec.id === 'assetType')
			?.fields?.filter((menuItem) => menuItem.checked)
			?.map((menuItem) => menuItem.value as string);
		if (assetTypes) {
			mediaLibraryFilters.assetType = assetTypes;
		}
		if (tagsFilter) {
			mediaLibraryFilters.tags = tagsFilter;
		}
		if (formatFilter) {
			mediaLibraryFilters.format = formatFilter;
		}
		this.ctxStore.dispatch(MediaLibraryActions.setViewMode({ filters: mediaLibraryFilters, viewMode: 'search' }));
		this.service.updateActiveFilters(mediaLibraryFilters);
	}

	onCardClick(event: Asset) {
		if (this.selectionMode === 'single') {
			this.service.toggleSelectedElement(event.uuid);
			if (this.service.selectedElements().has(event.uuid)) {
				this.selectedAsset.emit(event);
			} else {
				this.selectedAsset.emit(undefined);
			}
		}
		if (this.selectionMode === 'multi') {
			this.service.toggleSelectedElements(event.uuid);
			this.selectionChange.emit(Array.from(this.service.selectedElements()));
		}
	}

	onSave() {
		if (this.selectionMode !== 'off') {
			const assets = Array.from(this.service.selectedElements());
			this.confirmedAssets.emit(assets);
			this.dialogRef?.close(assets);
		}
	}
}
