import { HttpErrorResponse } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { ApplicationUserGroup } from 'src/app/shared/models';
import { STATE_STATUS } from 'src/app/shared/models/state-status.enum';

export const setForceRefreshGroups = createAction('[Groups] Set Force Refresh');
export const setGroups = createAction('[Groups] Set Groups');
export const setGroupsSuccess = createAction(
	'[Groups] Set Groups Success',
	props<{ data: { totalGroupsCount: number; pages: number[]; groups: ApplicationUserGroup[][] } }>()
);
export const setGroupsError = createAction('[Groups] Set Groups Error', props<{ error: HttpErrorResponse }>());
export const setDeleteDataleanGroup = createAction('[Groups] Set Delete Groups', props<{ group: ApplicationUserGroup }>());
export const setDeleteDataleanGroupSuccess = createAction('[Groups] Set Delete Groups Success');
export const setDeleteDataleanGroupError = createAction('[Groups] Set Delete Groups Error', props<{ error: HttpErrorResponse }>());
export const setStatus = createAction('[Groups] Set status', props<{ status: STATE_STATUS }>());
export const setPages = createAction('[Groups] Set pages', props<{ pages: number[] }>());
export const setCachedPages = createAction('[Groups] Set cached pages', props<{ pages: number[] }>());
export const resetCache = createAction('[Groups] reset cache');
export const setSort = createAction('[Groups] Set sort', props<{ sort: Sort }>());
export const setGridSearch = createAction('[Groups] Set search', props<{ search: string | null }>());
