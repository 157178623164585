import { Communication, Course, ResolvedAssetReference } from 'src/app/shared/models';
import { BaseLocalizedValues, DataleanEntity, UnknownObject, ObjectValuesType } from 'addiction-components';

interface DataleanChallenge extends DataleanEntity {
	startDate: Date;
	endDate: Date;
	limitScore: number;
	action: Action;
	localizedValues?: ChallengeLocalizedValues[];
	contests?: { uuid: string; name: string }[];
	usersScore?: { uuid: string; score: number }[];
}

export type Challenge = DataleanChallenge &
	Partial<{ [key in keyof ChallengeLocalizedValues]: ChallengeLocalizedValues[key] }> & { [key: string]: unknown | UnknownObject };

export const ACTION_TYPE = {
	OPEN_COMMUNICATION: 'communication',
	UPLOAD_FILE: 'upload_file',
	UPLOAD_PROFILE_PIC: 'profile_pic',
	COMPLETE_COURSE: 'course',
	COMPLETE_SURVEY: 'survey',
};

export type ACTION_TYPE = ObjectValuesType<typeof ACTION_TYPE>;

export interface Action {
	type: ACTION_TYPE;
	needValidation?: boolean;
	payload: {
		communicationUUID?: string[];
		communications?: Communication[];

		courseUUID?: string[];
		courses?: Course[];
	};
	score: number;
}

export type ChallengeLocalizedValues = BaseLocalizedValues & {
	name?: string;
	image?: Partial<ResolvedAssetReference>;
	description?: string;
};
