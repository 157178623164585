import { HttpErrorResponse } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { Page } from 'addiction-components';
import { Challenge, STATE_STATUS } from 'src/app/shared/models';
import { ChallengeError } from './challenge.state';

export const updatePage = createAction('[Challenges] Update Page', props<{ updatedPage: Partial<Page> }>());
export const deleteChallenge = createAction('[Challenges] Delete Challenges', props<{ uuid: string }>());
export const disableChallenges = createAction('[Challenges] Disable Challenges', props<{ uuids: string[] }>());

export const setForceRefreshChallenges = createAction('[Challenges] Set Force Refresh');
export const setChallenges = createAction('[Challenges] Set Challenges');
export const setChallengesSuccess = createAction(
	'[Challenges] Set Challenges Success',
	props<{ data: { totalChallengesCount: number; pages: number[]; challenges: Challenge[][] } }>()
);
export const setChallengesError = createAction('[Challenges] Set Challenges Error', props<{ error: HttpErrorResponse }>());
export const setDeleteChallenges = createAction('[Challenges] Set Delete Challenges', props<{ challenge: Challenge }>());
export const setDeleteChallengesSuccess = createAction('[Challenges] Set Delete Challenges Success');
export const setDeleteChallengesError = createAction('[Challenges] Set Delete Challenges Error', props<{ error: HttpErrorResponse }>());
export const setStatus = createAction('[Challenges] Set status', props<{ status: STATE_STATUS }>());
export const setPages = createAction('[Challenges] Set pages', props<{ pages: number[] }>());
export const setSort = createAction('[Challenges] Set sort', props<{ sort: Sort }>());
export const setGridSearch = createAction('[Challenges] Set search', props<{ search: string | null }>());
export const setCachedPages = createAction('[Challenges] Set cached pages', props<{ pages: number[] }>());
export const resetCache = createAction('[Challenges] reset cache');

// Error
export const error = createAction('[Challenges] Error', props<{ error: ChallengeError }>());
