import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BookableProductReducers } from 'src/app/core/state/app.reducers';
import { BookableProductState } from './bookable-product.state';

const selectBookableProductsState = createFeatureSelector<BookableProductState>(BookableProductReducers.TAG);

export const selectBookableProductsFilters = createSelector(selectBookableProductsState, (state) => state?.filters);
export const selectBookableProductsFeatures = createSelector(selectBookableProductsState, (state) => state?.features);
export const selectBookableProductsStructures = createSelector(selectBookableProductsState, (state) => state?.structures);

export const selectData = createSelector(selectBookableProductsState, (state) => state.data);
export const selectPages = createSelector(selectBookableProductsState, (state) => state.pages);
export const selectPagedData = createSelector(selectData, selectPages, (data, pages) => ({ ...data, pages }));
export const selectGridSearch = createSelector(selectBookableProductsState, (state) => state.gridSearch);
export const selectStatus = createSelector(selectBookableProductsState, (state) => state.status);
export const selectSort = createSelector(selectBookableProductsState, (state) => state.sort);
export const selectCachedPages = createSelector(selectBookableProductsState, (state) => state.cachedPages);
