import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ContextState, createContextSelector } from 'context-store';
import { MediaLibraryReducers } from 'src/app/core/state/app.reducers';
import { findFolder } from './media-library.reducers';
import { MediaLibraryState, QueueStatus } from './media-library.state';

const selectML = createFeatureSelector<ContextState<MediaLibraryState>>(MediaLibraryReducers.TAG);
const getMediaLibraryState = createFeatureSelector<MediaLibraryState>(MediaLibraryReducers.TAG);

export const selectAssets = createContextSelector(selectML, (state) => state.data);
export const selectFolders = createContextSelector(selectML, (state) => state.folderRoot.children); // il fake root ha sempre dei children
export const selectFeatureValues = createContextSelector(selectML, (state) => state.featureValuesRoot.featureValues); // il fake root ha sempre dei children
export const selectPagination = createContextSelector(selectML, (state) => state.pagination);
export const selectSorting = createContextSelector(selectML, (state) => state.sort);
export const selectFilters = createContextSelector(selectML, (state) => state.filters);
export const selectHasFilters = createContextSelector(selectML, (state) => !!state.filters);
export const selectCurrentPath = createContextSelector(selectML, (state) => state.currentPath);
export const selectCurrentFeatureValuePath = createContextSelector(selectML, (state) => state.currentFeatureValuePath);
export const selectSelectedFolder = createContextSelector(selectML, (state) => findFolder(state.currentPath, state.folderRoot));
export const selectSelectedAsset = createContextSelector(selectML, (state) => state.selectedAsset);

export const selectViewMode = createContextSelector(selectML, (state) => state.viewMode);
export const getThumbnailConfigs = createSelector(getMediaLibraryState, (state) => state?.thumbnailConfigs);
export const selectConversions = createSelector(getMediaLibraryState, (state) => state?.conversions);

export const getQueue = createContextSelector(selectML, (state) => state.queue);
export const interruptUpload = createContextSelector(selectML, ({ interruptUpload, queue }) => ({
	interruptUpload,
	asset: queue.filter((q) => q.status === QueueStatus.INTERRUPTED).map((q) => q.assetInfo),
}));

// joins together all info required for fetching
export const selectAssetsFetchParams = createContextSelector(
	selectML,
	selectCurrentFeatureValuePath,
	selectPagination,
	selectSorting,
	selectFilters,
	selectViewMode,
	(state, currentPath, pagination, sorting, filters, viewMode) => {
		return { currentPath, pagination, sorting, filters, viewMode };
	}
);

export const selectLoading = createContextSelector(selectML, (state) => !!state.loading);
export const selectUploadProgress = createContextSelector(selectML, (state) => (state.uploading ? state.uploadProgress : undefined));
export const selectIsUploading = createContextSelector(selectML, (state) => state.uploading);
export const selectError = createContextSelector(selectML, (state) => state.error);

export const selectAvailableFilterStatus = createContextSelector(selectML, (state) => state.availableFilterStatus);
export const selectAvailableFilters = createContextSelector(selectML, (state) => state.availableFilter?.filterData);
export const selectOldFilters = createContextSelector(selectML, (state) => state.oldFilters);
export const selectOldData = createContextSelector(selectML, (state) => state.oldData);
export const selectStatus = createContextSelector(selectML, (state) => state.assetStatusLoading);
export const selectAssetUpdated = createContextSelector(selectML, (state) => state.uploadProgress === 100 && state.selectedAsset);
