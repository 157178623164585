<addiction-search (search)="filterBy($event)"></addiction-search>

<ng-container *ngrxLet="tableRows$ as data">
	<addiction-list
		(pageChanges)="onPageChanged($event)"
		[data]="data"
		[pageSize]="env.pageSize"
		[itemSize]="50"
		[tableHeaderTemplate]="tableHeaderTemplate"
		[rowTemplate]="rowTemplate"
		[noItemsTemplate]="noItemsTemplate"></addiction-list>
</ng-container>

<ng-template #tableHeaderTemplate>
	<div class="single-row-header" matSort>
		<div class="single-column checkbox">
			<span class="checkbox"></span>
			<mat-checkbox [checked]="gcs.selectAllChecked" (change)="onSelectionChange('all', $event.checked)" [indeterminate]="gcs.selectAllIndeterminate">
			</mat-checkbox>
		</div>
		<div class="single-column double first-name" mat-sort-header="name">
			<span>{{ 'LIST_HEADER.NAME' | translate }}</span>
		</div>
		<div class="single-column options">
			<span class="options"></span>
		</div>
	</div>
</ng-template>

<ng-template #rowTemplate let-item="item">
	<div class="single-row" *ngIf="item" matSort>
		<div class="single-column checkbox">
			<span class="checkbox"></span>
			<mat-checkbox
				(click)="$event.stopPropagation()"
				class="checkbox"
				[checked]="gcs.checkboxMap[item.uuid] === undefined ? gcs.selectAllChecked : gcs.checkboxMap[item.uuid]"
				(change)="onSelectionChange(item.uuid, $event.checked)">
			</mat-checkbox>
		</div>
		<div class="single-column double first-name">
			<span class="highlight">{{ item.name }}</span>
		</div>
		<div class="single-column options"></div>
	</div>
</ng-template>

<ng-template #noItemsTemplate>
	<div class="no-results-container">
		<ng-lottie [options]="{ path: './assets/lottie/icn-dam-nocontent.json' }"></ng-lottie>
		<div class="title">
			{{ 'GRID.NO_RESULTS_TITLE' | translate }}
		</div>
		<div class="text">
			{{ 'GRID.NO_RESULTS_TEXT' | translate }}
		</div>
	</div>
</ng-template>

<div class="buttons-container">
	<button class="confirm" (click)="close()">
		<span>{{ 'CONFIRM' | translate }}</span>
	</button>
</div>
