import { Role } from './role.interface';
import { UserStatus } from './users-and-groups/user-status.enum';

export interface AuthenticationMethod {
	username: string;
	password: string;
	type: 'USERNAME_PASSWORD';
}

export interface BaseUser {
	uuid: string;
	firstName: string;
	lastName: string;
	username: string;
	email: string;
	status: UserStatus;
	responsible: unknown[];
	language?: string;
	authenticationMethods?: AuthenticationMethod[];
}

export interface DataleanUser extends BaseUser {
	roles: Role[];
	[key: string]: unknown;
}
