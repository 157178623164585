import { createAction, props } from '@ngrx/store';
import { Pictogram, PictogramStructure } from 'src/app/shared/models/pictogram/pictogram.interface';

/* READ */
export const loadPictograms = createAction('[Pictograms] Load Pictograms');
export const loadPictogramStructuress = createAction('[Pictograms] Load Pictogram Structures');
export const reloadPictograms = createAction('[Pictograms] Reload Pictograms');
export const loadPictogramsSuccess = createAction('[Pictograms] Load Pictograms Success', props<{ pictograms: Pictogram[] }>());
export const loadPictogramsFailure = createAction('[Pictograms] Load Pictograms Failure', props<{ error: unknown }>());
export const loadPictogramStructuresSuccess = createAction('[Pictograms] Load Pictogram Structures Success', props<{ structures: PictogramStructure[] }>());
export const loadPictogramStructuresFailure = createAction('[Pictograms] Load Pictogram Structures Failure', props<{ error: unknown }>());

/* CREATE */
export const createPictogram = createAction('[Pictograms] Create Pictogram', props<{ pictogram: Pictogram }>());
export const createPictogramSuccess = createAction('[Pictograms] Create Pictogram Success', props<{ pictogram: Pictogram }>());
export const createPictogramFailure = createAction('[Pictograms] Create Pictogram Failure', props<{ error: unknown }>());

/* UPDATE */
export const updatePictogram = createAction('[Pictograms] Update Pictogram', props<{ pictogram: Pictogram; uuid: string }>());
export const updatePictogramSuccess = createAction('[Pictograms] Update Pictogram Success', props<{ pictogram: Pictogram }>());
export const updatePictogramFailure = createAction('[Pictograms] Update Pictogram Failure', props<{ error: unknown }>());

/* DELETE */
export const deletePictogram = createAction('[Pictograms] Delete Pictogram', props<{ uuid: string }>());
export const deletePictogramSuccess = createAction('[Pictograms] Delete Pictogram Success', props<{ uuid: string }>());
export const deletePictogramFailure = createAction('[Pictograms] Delete Pictogram Failure', props<{ error: unknown }>());

/* SELECTION */
export const selectPictogram = createAction('[Pictograms] Select Pictogram', props<{ pictogram?: Pictogram, structure?: PictogramStructure }>());
export const editPictogram = createAction('[Pictograms] Edit Pictogram', props<{ uuid: string }>());

export const changePictogramSelected = createAction('[Pictograms] Change Pictogram Selected', props<{ pictogram: Pictogram }>());
export const createNewPictogram = createAction('[Pictograms] Create New Pictogram',  props<{ structure?: string }>());
