import { Injectable, inject } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import {
	DataleanBaseApiService,
	DataleanPagedResult,
	FeatureValue,
	PaginationInfo,
	Parts,
	SimpleObject,
	SortInfo,
	Structure,
	StructureType,
} from 'addiction-components';
import { Observable, Subject, forkJoin } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { HeaderLocaleService } from 'src/app/components';
import { ContentsActions } from 'src/app/core/state/app.actions';
import { CommunitySelectSelectors } from 'src/app/core/state/app.selectors';
import { Content } from 'src/app/shared/models';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ContentService {
	private store = inject(Store);
	private headerSrv = inject(HeaderLocaleService);
	private baseApi = inject(DataleanBaseApiService);

	private destroyed$ = new Subject();
	private lastCommunitySelected$ = this.store.select(CommunitySelectSelectors.selectLastCommunitySelected).pipe(takeUntil(this.destroyed$));

	constructor() {}

	init() {
		this.lastCommunitySelected$.pipe(takeUntil(this.destroyed$)).subscribe((communityUUID) => {
			this.getStructureAndFeatures(communityUUID);
		});
	}

	destroy(): void {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	getStructureAndFeatures(communityUUID?: string) {
		const structureAdditionalParams: {
			type?: StructureType;
		} = { type: StructureType.CONTENT };

		const featureAdditionalParams: {
			featureValueCommunityUUID?: string;
		} = {};

		// TODO ATTIVARE QUANDO GESTITE LE COMUNITA'
		// if (communityUUID !== undefined) {
		// 	featureAdditionalParams.featureValueCommunityUUID = communityUUID;
		// } else if (this.lastCommunitySelected !== undefined) {
		// 	featureAdditionalParams.featureValueCommunityUUID = this.lastCommunitySelected;
		// }

		this.baseApi
			.getMany<Structure>(environment.structureUrl, [Parts.EMPTY], { additionalParams: structureAdditionalParams })
			.subscribe((structures) => {
				this.store.dispatch(ContentsActions.setStructures({ structures }));
			});
		this.baseApi
			.getMany<FeatureValue>(environment.featureValueUrl, [Parts.FEATURE_VALUES], {
				additionalParams: featureAdditionalParams,
			})
			.subscribe((features) => {
				this.store.dispatch(ContentsActions.setFeatures({ features }));
			});
	}

	getContents(options: {
		parts?: Parts[];
		locale?: string;
		pagination?: PaginationInfo;
		sort?: SortInfo;
		filters?: { uuids?: string[]; structureUUID?: string; query?: string, communityUUID?: string };
	}) {
		const { filters, parts, locale, pagination, sort } = options;

		const params: SimpleObject = {
			locale: locale ?? this.headerSrv.getActiveLocale(),
			active: true,
		};

		// TODO: generalizzare questa logica in una funzione reutilizzabile
		if (filters?.structureUUID) params['structureUUID'] = filters.structureUUID;
		if (filters?.query) {
			params['q'] = filters.query;
			params['searchFields'] = ['name']; // parametrizzare i campi di ricerca??
		}
    if (filters?.communityUUID) params['communityUUID'] = filters.communityUUID

		if (filters?.uuids) params['uuid'] = filters.uuids.join(',');

		return this.baseApi.getManyPaged<Content>(environment.contentUrl, parts ?? [Parts.ALL], {
			pagination,
			sort,
			additionalParams: params,
		});
	}

	getStructures(parts: Parts[]) {
		if (!parts) parts = [Parts.EMPTY];

		return this.baseApi.getManyPaged<Structure>(environment.structureUrl, parts);
	}

	countContents(filters: { structureUUID?: string }) {
		return this.baseApi.getOne<{ count: number }>(environment.contentUrl + 'count', '', [], {
			organizationUUID: environment.organizationUUID,
			...filters,
			active: true,
		});
	}

	public multipleUpdate(bulkPayload: { uuidList: string[]; delete?: boolean; active?: boolean }) {
		return this.baseApi.updateBulk(environment.contentUrl + 'multipleUpdate', bulkPayload).pipe(map(() => true));
	}

	fetchContents(
		pages: number[],
		sort?: Sort,
		locale?: string,
		gridSearch?: string | null,
		filters?: { featureValue?: string[]; structureUUID?: string, communityUUID?: string }
	): Observable<DataleanPagedResult<Content>[]> {
		const obs: Observable<DataleanPagedResult<Content>>[] = [];

		const params: {
			sortBy?: string;
			q?: string;
			searchFields?: string;
			locale?: string;
			active: boolean;
			structureUUID?: string;
			featureValueList?: string;
      communityUUID?: string
		} = {
			locale: locale ?? this.headerSrv.getActiveLocale(),
			active: true,
		};

		// TODO: generalizzare questa logica in una funzione reutilizzabile
		if (filters?.structureUUID) params['structureUUID'] = filters.structureUUID;
    if (filters?.communityUUID) params.communityUUID = filters.communityUUID
		if (filters?.featureValue?.length) params['featureValueList'] = filters.featureValue.join(',') + '#and';

		//SORT
		if (sort) params.sortBy = `${sort.active}#${sort.direction}`;
		if (gridSearch) {
			params.q = gridSearch;
			params.searchFields = 'name,description';
		}

		for (const page of pages) {
			const pagination: PaginationInfo = new PaginationInfo(environment.pageSize, page);

			obs.push(
				this.baseApi.getManyPaged<Content>(environment.contentUrl, [Parts.FEATURE_VALUE_LIST, Parts.UPDATE_DATE], {
					pagination,
					additionalParams: params,
				})
			);
		}
		return forkJoin(obs);
	}
}
