import { Asset, BaseLocalizedValues, DataleanEntity, FeatureValue } from 'addiction-components';
import { Recipient } from './recipient';

export class Course extends DataleanEntity {
	image?: Asset | null;
	name?: string;
	recipient?: (string | Recipient)[];
	override localizedValues?: CourseLocalizedValues[] = [];
	lessonList?: LessonRelated[] = [];
	locale?: string;
	featureValues?: Array<string | FeatureValue>;

	constructor(public description: string) {
		super();
	}
}

export interface CourseLocalizedValues extends BaseLocalizedValues {
	name: string;
}

export interface LessonRelated {
	uuid: string;
	name: string;
	active: boolean;
	linkedLesson?: string;
	blockedBy?: string;
}
