import { CommonModule, Location } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: 'datalean-editor-header',
	templateUrl: './editor-header.component.html',
	styleUrls: ['./editor-header.component.scss'],
	standalone: true,
	imports: [CommonModule, TranslateModule],
})
export class EditorHeaderComponent {
	protected location = inject(Location);

	@Input() showRemoveButton: boolean = false;
	@Input() showAddToCollectionButton: boolean = false;
	@Input() showPermissionButton: boolean = true;
	@Input() isCreateMode: boolean = false;

	@Output() saveItem = new EventEmitter();
	@Output() deleteItem = new EventEmitter();
	@Output() permission = new EventEmitter();
	@Output() replaceItem = new EventEmitter();
	@Output() addButtonClick = new EventEmitter();
	@Output() removeButtonClick = new EventEmitter();

	constructor() {}

	goBack() {
		this.location.back();
	}
}
