import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StructuresState } from './structures.state';

const structureState = createFeatureSelector<StructuresState>('structures');

export const selectData = createSelector(structureState, (state: StructuresState) => state.data);
export const selectPages = createSelector(structureState, (state: StructuresState) => state.pages);
export const selectStructureType = createSelector(structureState, (state: StructuresState) => state.structureType);
export const selectPagedData = createSelector(selectData, selectPages, (data, pages) => ({
	...data,
	pages,
}));
export const selectGridSearch = createSelector(structureState, (state: StructuresState) => state.gridSearch);
export const selectStatus = createSelector(structureState, (state: StructuresState) => state.status);
export const selectSort = createSelector(structureState, (state: StructuresState) => state.sort);
export const selectStructureField = createSelector(structureState, (state: StructuresState) => state.currentStructureField);
export const selectStructureFieldPath = createSelector(structureState, (state: StructuresState) => state.paths);
export const selectCachedPages = createSelector(structureState, (state: StructuresState) => state.cachedPages);
