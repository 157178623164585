import { Pipe, PipeTransform } from '@angular/core';
import { LocalizableField } from '../models/localizable-field';
import { TranslateService } from '@ngx-translate/core';
import { BaseLocalizedValues } from '../models';

@Pipe({
	name: 'toLocalizedValuePipe',
	pure: false,
	standalone: true,
})
export class ToLocalizedValuePipe implements PipeTransform {
	constructor(private translator: TranslateService) {}

	transform(
		value: string | { value: string; language: string }[] | LocalizableField | number | (BaseLocalizedValues & Record<string, unknown>)[] | any,
		forcedLocale?: string
	) {
		if (value === undefined || value === null) {
			return '';
		}
		const defaultLan = this.translator.defaultLang;
		const activeLan = forcedLocale || this.translator.currentLang || defaultLan;
		if (value instanceof LocalizableField) {
			return value.getLocalizedValue(activeLan, defaultLan);
		}
		if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
			return value;
		}
		if (Array.isArray(value)) {
			if (this.isALocalizedObject(value)) {
				const res = value.find((v) => v.locale === (activeLan || defaultLan)) || '';
				return res;
			}
			// console.log('toLocalizedValuePipe', activeLan, defaultLan, value);
			return value.find((v) => v.language === (activeLan || defaultLan))?.value || '';
		}
		return value?.value ?? value?.[activeLan] ?? value?.[defaultLan] ?? '';
	}

	isALocalizedObject(obj: any): obj is { locale: string; [key: string]: unknown }[] {
		return Array.isArray(obj) && obj.every((o) => 'locale' in o);
	}
}
