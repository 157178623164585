import { Sort } from '@angular/material/sort';
import { Action, createReducer, on } from '@ngrx/store';
import { SurveysActions } from 'src/app/core/state/app.actions';
import { BESurvey, STATE_STATUS } from 'src/app/shared/models';

export const DATALEAN_SURVEYS_TAG = 'surveys';

export interface SurveysState {
	gridSearch: string | null;
	sort?: Sort;
	data: { totalSurveyCount: number; surveys: BESurvey[][] };
	pages: number[];
	status: STATE_STATUS;
	deleteStatus: STATE_STATUS;
	cachedPages: number[];
}

const initialState: SurveysState = {
	status: STATE_STATUS.UNKNOWN,
	deleteStatus: STATE_STATUS.UNKNOWN,
	gridSearch: null,
	pages: [],
	data: { totalSurveyCount: 0, surveys: [] },
	cachedPages: [],
};

export const reducers = createReducer(
	initialState,
	on(SurveysActions.setSort, (state: SurveysState, { sort }) => ({
		...state,
		sort,
	})),
	on(SurveysActions.setPages, (state: SurveysState, { pages }) => ({
		...state,
		pages,
	})),
	on(SurveysActions.setSurveysSuccess, (state: SurveysState, { data }) => {
		return {
			...state,
			status: STATE_STATUS.READY,
			data: {
				surveys: data.surveys,
				totalSurveyCount: data.totalSurveyCount,
			},
			pages: data.pages,
		};
	}),
	on(SurveysActions.setSurveysError, (state: SurveysState) => ({
		...state,
		status: STATE_STATUS.ERROR,
	})),
	on(SurveysActions.setStatus, (state: SurveysState, { status }) => {
		return {
			...state,
			status,
		};
	}),
	on(SurveysActions.setGridSearch, (state: SurveysState, { search }) => {
		return {
			...state,
			gridSearch: search,
		};
	}),
	on(SurveysActions.setDeleteSurvey, (state: SurveysState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.LOADING,
		};
	}),
	on(SurveysActions.setDeleteSurveysSuccess, (state: SurveysState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.READY,
		};
	}),
	on(SurveysActions.setDeleteSurveysError, (state: SurveysState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.ERROR,
		};
	}),
	on(SurveysActions.setCachedPages, (state: SurveysState, { pages }) => ({
		...state,
		cachedPages: [...new Set([...state.cachedPages, ...pages])],
	})),
	on(SurveysActions.resetCache, (state: SurveysState) => ({
		...state,
    status: STATE_STATUS.READY,
		cachedPages: [],
		pages: [0],
		data: { totalSurveyCount: 0, surveys: [] },
	}))
);

export const SurveyReducer = (state: SurveysState | undefined, action: Action) => {
	return reducers(state, action);
};
