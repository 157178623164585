import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationMessageComponent } from '../components';
import { ConfirmationMessageModel } from '../models/confirm-message-model';
import { ToastType } from '../models/toast.type.enum';
import { ModalService } from './modal.service';

@Injectable({ providedIn: 'root' })
export class MessageHandler extends ModalService {
	private toastr = inject(ToastrService);
	private translateService = inject(TranslateService);

	constructor() {
		super();
	}

	createConfirm({ title, htmlMessage, buttons, successMessage, failureMessage }: ConfirmationMessageModel) {
		const dialogComponent = this.openDialog(
			ConfirmationMessageComponent,
			{ title },
			{
				data: {
					htmlMessage: htmlMessage,
					buttons: buttons.map((button) => ({
						label: button.label,
						handler: () => {
							if (button.label === 'NO') {
								return this.closeDialog(dialogComponent.id, 'CANCEL', null);
							}
							try {
								if (button.handler) button.handler();
								if (successMessage) this.toastr.success(this.translateService.instant(successMessage));
							} catch (error) {
								if (failureMessage) this.toastr.error(this.translateService.instant(failureMessage));
							}
							return this.closeDialog(dialogComponent.id, 'COMPLETE', null);
						},
					})),
				},
			}
		);
		return dialogComponent;
	}

	createToast(message: string) {
		this.toastr.success(this.translateService.instant(message));
	}

	createErrorToast(message: string) {
		this.toastr.error(this.translateService.instant(message));
	}

	/**
	 * @param toastType - specificare che tipo di toast si vuole visualizzare utilizzando il type ToastType
	 * @param message - Inserire se si vuole mostrare un messaggio diverso da quello di default.
	 * Default success message: The operation was successful.
	 * Default error message: An error has occurred.
	 * Default warning message: Warning!.
	 * @param optionalMessage - Messaggio che non deve essere tradotto. Es: se vuoi aggiungere informazioni
	 * sul tipo di errore senza per forza dovere usare il translateService.
	 */
	showToast(toastType: ToastType, message?: string, optionalMessage?: string) {
		let toastMessage: string | undefined = undefined;

		if (message) {
			toastMessage = this.translateService.instant(message);
		}
		if (optionalMessage) {
			toastMessage = toastMessage ? `${toastMessage} - ${optionalMessage}` : optionalMessage;
		}
		switch (toastType) {
			case ToastType.SUCCESS_TOAST:
				this.toastr.success(toastMessage || this.translateService.instant('TOAST.SUCCESS_GENERIC'));
				break;
			case ToastType.ERROR_TOAST:
				this.toastr.error(toastMessage || this.translateService.instant('TOAST.ERROR_GENERIC'));
				break;
			case ToastType.WARNING_TOAST:
				this.toastr.warning(toastMessage || this.translateService.instant('TOAST.WARNING_GENERIC'));
				break;
			default:
				break;
		}
	}
}
