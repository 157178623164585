<ng-container *ngrxLet="(tree$ | async)! as root">
	<ng-container *ngrxLet="(currentNode$ | async)! as node">
		<div class="tree-browser">
			<div class="current-node">
				<button *ngIf="node.parent" class="back" (click)="selectAndGoTo(node.parent, true)"></button>
				<span class="current-node-title" (click)="selectAndGoTo(node, true)" drop-target [attr.data-id]="node.id">{{ node.title }}</span>
			</div>
			<div class="children" cdkDropList (cdkDropListDropped)="onDrop($event, node)" [cdkDropListSortPredicate]="sortPredicate">
				<div
					class="child"
					*ngFor="let child of node.children"
					[class.selected]="child.id === (selectedUUID$ | async)"
					cdkDrag
					cdkDragPreviewContainer="parent"
					cdkDragBoundary=".children"
					[cdkDragDisabled]="!sortable || !editing"
					[cdkDragData]="child"
					[attr.data-id]="child.id"
					drop-target>
          <div class="drag-placeholder" *cdkDragPlaceholder></div>
					<div class="single-child-container" cdkDragHandle>
						<input type="checkbox" *ngIf="checkable" [checked]="child.checked" (change)="toggleChecked(child)" />
						<div class="title" (click)="selectAndGoTo(child, true)">
              <span class="folder"></span>
							<span *ngIf="!renameble || !editing" class="label">
								{{ child.title }}
							</span>
							<span *ngIf="renameble && editing">
								<input
									type="text"
									class="node-rename"
									[class.error]="child.error"
									[value]="child.title"
									[addictionForbidChars]="forbidChars"
									(change)="nodeRenamed(child, $event.target!, node.children)" />
							</span>
						</div>
					</div>

					<div class="controls">
						<ng-container *ngIf="editing">
							<button class="menu" [matMenuTriggerFor]="editMenu" [ngClass]="{'more-space' : !(child.children.length)}"></button>
							<mat-menu #editMenu>
								<button mat-menu-item class="clone" *ngIf="clonable" (click)="clone(child)">
                  {{'TREE_BROWSER.CLONE' | translate}}
                </button>
								<button mat-menu-item class="remove" *ngIf="removable" (click)="remove(child)">
                  {{'TREE_BROWSER.DELETE' | translate}}
                </button>
								<!-- <button mat-menu-item class="move" (click)="openMoveDialog(child)">
                  {{'TREE_BROWSER.MOVE' | translate}}
                </button> -->
							</mat-menu>
						</ng-container>
						<ng-container #navigation>
							<span class="navigate" (click)="selectAndGoTo(child, true)" *ngIf="child.children.length"></span>
						</ng-container>
					</div>
				</div>

				<div class="placeholder" *ngIf="!node.children || node.children.length === 0">
<!--          {{'TREE_BROWSER.NO_ITEMS' | translate}}-->
        </div>
			</div>
		</div>

		<div class="checked-nodes" *ngIf="checkable">
			<addiction-chip *ngFor="let node of checkedNodes$ | async" [label]="node.title" (remove)="toggleChecked(node, false)"></addiction-chip>
		</div>
	</ng-container>
</ng-container>
