export interface HeaderState {
	showLocales: boolean;
	showCommunitySelect: boolean;
	showBackButton: boolean;
  showWarningOnBack: boolean
  showPlusButton: boolean;
  showRemoveButton: boolean;
	backButtonUrl?: string[];
	options: { assetWidth: number; assetdisplayMode: number };
}
