import { Component, DestroyRef, inject } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { ConfirmationMessageComponent, MessageHandler } from 'addiction-components';
import { ContextStore } from 'context-store';
import { map } from 'rxjs';
import { MediaLibraryActions } from 'src/app/core/state/app.actions';
import { MediaLibrarySelectors } from 'src/app/core/state/app.selectors';
import { fetchAll } from 'src/app/pages/media-library/state/media-library.actions';
import { AssetQueue, QueueStatus } from 'src/app/pages/media-library/state/media-library.state';

@Component({
	selector: 'datalean-upload-progress',
	templateUrl: './upload-progress.component.html',
	styleUrls: ['./upload-progress.component.scss'],
})
export class UploadProgressComponent {
	private ctxStore = inject(ContextStore);
	private translatePipe = inject(TranslatePipe);
	private messageHandler = inject(MessageHandler);

	protected assetUploading$ = this.ctxStore.select(MediaLibrarySelectors.getQueue).pipe(
		map((queue) => {
			const hasSomeUploadingAssets = queue.some((q) => q.status === QueueStatus.UPLOADING);
			if (!hasSomeUploadingAssets) {
				this.ctxStore.dispatch(MediaLibraryActions.resolveQueue({ process: true, queue }));
			}
			this.finishedUpload = !hasSomeUploadingAssets && !queue.some((q) => q.status === QueueStatus.WAITING);
			if (this.finishedUpload) this.ctxStore.dispatch(fetchAll({}));
			queue = queue.filter((q) => q.status !== QueueStatus.INTERRUPTED);
			return queue.map((q) => ({ ...q, className: q.assetInfo.assetType }));
		})
	);
	showList = true;
	destroyRef = inject(DestroyRef);
	finishedUpload = false;

	constructor() {}

	clearQueue() {
		this.ctxStore.dispatch(MediaLibraryActions.clearQueue({ clear: true }));
	}

	stopQueue(queue: AssetQueue[]) {
		const openMessage = this.messageHandler.openDialog(
			ConfirmationMessageComponent,
			{
				title: 'ERRORS.GENERIC_TITLE',
			},
			{
				data: {
					htmlMessage: this.translatePipe.transform('GRID.STOP_ALL_MESSAGE').replace('${number}', queue.length),
					buttons: [
						{
							label: 'OK',
							handler: () => {
								//Se devo interrompere qualcosa in upload, attendo che termini la sua interruzione
								if (uploadingQueue) {
									this.interruptUpload(uploadingQueue, queue);
								}
								queue = queue.map((q) => {
									if (q.status === QueueStatus.WAITING) {
										return {
											...q,
											status: QueueStatus.INTERRUPTED,
										};
									}
									return q;
								});
								this.ctxStore.dispatch(MediaLibraryActions.stopQueue({ stop: true, queue }));
								this.clearQueue();
								this.messageHandler.closeDialog(openMessage.id, 'COMPLETE', null);
							},
						},
						{
							label: 'CANCEL',
							handler: () => {
								this.messageHandler.closeDialog(openMessage.id, 'CANCEL', null);
							},
						},
					],
				},
			}
		);
		const uploadingQueue = queue.find((q) => q.status === QueueStatus.UPLOADING);
	}

	stopAssetInQueue(queueOperation: AssetQueue, queue: AssetQueue[]) {
		queue = queue.map((q) => {
			if (queueOperation.assetInfo.name === q.assetInfo.name) {
				return {
					...q,
					status: QueueStatus.INTERRUPTED,
				};
			}
			return q;
		});
		this.ctxStore.dispatch(MediaLibraryActions.assetUpdateQueue({ queue }));
	}

	interruptUpload(queueOperation: AssetQueue, queue: AssetQueue[]) {
		queue = queue.map((q) => {
			if (q.assetInfo.name === queueOperation.assetInfo?.name) {
				return {
					...q,
					status: QueueStatus.INTERRUPTED,
				};
			}
			return q;
		});
		this.ctxStore.dispatch(MediaLibraryActions.interruptUpload({ interruptUpload: true, queue }));
	}

	switchOffQueue(queueOperation: AssetQueue, queue: AssetQueue[]) {
		switch (queueOperation.status) {
			case QueueStatus.UPLOADING:
				{
					this.interruptUpload(queueOperation, queue);
				}
				break;
			case QueueStatus.WAITING:
				{
					this.stopAssetInQueue(queueOperation, queue);
				}
				break;
		}
	}

	assetByName(index: number, asset: AssetQueue) {
		return asset.assetInfo.name;
	}
}
