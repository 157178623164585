import { ObjectValuesType } from '../object-values-type';

export const FeatureValueType = {
	PRODUCT: 'product',
	PRODUCTVARIANTS: 'product_variants',
	COMMUNICATION: 'communication',
	ASSET: 'asset',
	MEDIA_LIBRARY: 'mediaLibrary',
	BOOKABLE_PRODUCT: 'bookableProduct',
	LMS: 'lms',
	CONTENT: 'content',
};

export type FeatureValueType = ObjectValuesType<typeof FeatureValueType>;
