import { Injectable, inject } from '@angular/core';
import { DataleanBaseApiService, Parts, SimpleObject } from 'addiction-components';
import { Observable, map } from 'rxjs';
import { PictogramStructure } from 'src/app/shared/models/pictogram/pictogram.interface';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class PictogramStructuresService {
	private baseApi = inject(DataleanBaseApiService);
	private pictogramUrl = environment.pictogramUrl ? `${environment.pictogramUrl}pictogram-structure` : undefined
	private readonly NO_PICTOGRAM_URL_ERROR = new Error('Please provide a pictogram url');


	getPictogramStructures(uuids?: string[]): Observable<PictogramStructure[]> {
		if(this.pictogramUrl) {
			const additionalParams: SimpleObject = {};

			additionalParams['sortBy'] = 'name1#asc';
	
			if (uuids && uuids.length) additionalParams['uuids'] = uuids.join(',');
	
	
			return this.baseApi.getMany<PictogramStructure>(this.pictogramUrl, [Parts.PREVIEW_ASSET_INFO, Parts.CHILDREN_COUNT], {
				additionalParams,
			});
		} else {
			throw this. NO_PICTOGRAM_URL_ERROR;
		}
	}

	getPictogramStructure(uuid: string) {
		if(this.pictogramUrl) {
			return this.baseApi.getOne<PictogramStructure>(this.pictogramUrl, uuid, [Parts.PREVIEW_ASSET_INFO, Parts.CHILDREN_COUNT]);
		} else {
			throw this. NO_PICTOGRAM_URL_ERROR;
		}
	}

	updatePictogramStructure(pictogramStructure: PictogramStructure): Observable<PictogramStructure> {
		if(this.pictogramUrl) {
			const { uuid } = pictogramStructure;
			const pictorgramToUpdate = {
				...pictogramStructure,
				simbol: pictogramStructure.simbol.uuid ? {uuid: pictogramStructure.simbol.uuid} : pictogramStructure.simbol
			}
			return this.baseApi.updateOne<PictogramStructure>(this.pictogramUrl, uuid, pictorgramToUpdate, [Parts.EMPTY]);
		} else {
			throw this. NO_PICTOGRAM_URL_ERROR;
		}
	}

	createPictogramStructure(pictogramStructure: PictogramStructure): Observable<PictogramStructure> {
		if(this.pictogramUrl) {
			const pictorgramToCreate = {
				...pictogramStructure,
				simbol: pictogramStructure.simbol.uuid ? {uuid: pictogramStructure.simbol.uuid} : pictogramStructure.simbol
			}
			return this.baseApi.createOne<PictogramStructure>(this.pictogramUrl, pictorgramToCreate, [Parts.EMPTY]);
		} else {
			throw this. NO_PICTOGRAM_URL_ERROR;
		}
		
	}


	deletePictogramStructure(uuid: string): Observable<true> {
		if(this.pictogramUrl) {
			return this.baseApi.deleteOne(this.pictogramUrl, uuid).pipe(map(() => true));
		} else {
			throw this. NO_PICTOGRAM_URL_ERROR;
		}
		
	}

}
