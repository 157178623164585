import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommunitySelectReducers } from 'src/app/core/state/app.reducers';
import { CommunitySelectState } from './community-select.state';

const selectCommunitySelectorState = createFeatureSelector<CommunitySelectState>(CommunitySelectReducers.TAG);

export const selectData = createSelector(selectCommunitySelectorState, (state) => state?.data);
export const selectLastCommunitySelected = createSelector(selectCommunitySelectorState, (state) => state?.lastCommunitySelected);
export const selectLastCommunitySelectedForApiRest = createSelector(selectCommunitySelectorState,
  (state) => state?.lastCommunitySelected === "all" || !state?.lastCommunitySelected ? "" : state.lastCommunitySelected
);
export const selectFilters = createSelector(selectCommunitySelectorState, (state) => state?.filters);
export const selectShowNoCommunityChoice = createSelector(selectCommunitySelectorState, (state) => state?.showNoCommunityChoice);
export const selectMapCommunityPage = createSelector(selectCommunitySelectorState, (state) => state?.mapLastComunityPerPage);
