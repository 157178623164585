import { FeatureValue } from 'addiction-components';
import { UnknownObject } from './unknown-object.interface';
import { v4 } from 'uuid';

export class Content {
	uuid: string = v4();
	order: number = 0;
	locale?: string = '';
	structure?: { name: string }[] = [];
  active: boolean = true;
	featureValueList: Array<FeatureValue> = [];
	related?: Array<Content> = [];
	communityUUID?: string = '';
	containerId?: string = '';
	localizedValues?: { locale: string; [key: string]: unknown }[] = [];
	updateDate?: string = '';
	[key: string]: unknown | UnknownObject;

	constructor(public structureUUID: string = '', public name: string = '') {}
}
export type ContentUpdate = Omit<Content, 'featureValueList' | 'related'> & {
	uuid?: string;
	featureValueList: Array<string>;
	related?: Array<string>;
	localizedValues: { locale: string; [key: string]: unknown }[];
};
