import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TabOption } from '../../models';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
	selector: 'datalean-tabs-selector',
	templateUrl: './tabs-selector.component.html',
	styleUrls: ['./tabs-selector.component.scss'],
	standalone: true,
	imports: [CommonModule, RouterModule],
})
export class TabsSelectorComponent implements OnInit {
	@Input() options: TabOption[] = [];
	@Input() selected?: TabOption | null;

	@Output() selectedChange = new EventEmitter<TabOption>();

	constructor() {}

	ngOnInit(): void {
		if (!this.selected) this.select(this.options[0]);
	}

	select(option: TabOption) {
		if (this.selected != option) {
			this.selected = option;
			this.selectedChange.emit(option);
		}
	}
}
