import { Injectable, inject } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { DataleanBaseApiService, DataleanPagedResult, PaginationInfo, Parts, SimpleObject, SortInfo } from 'addiction-components';
import { Observable, forkJoin } from 'rxjs';
import { HeaderLocaleService } from 'src/app/components';
import { Challenge } from 'src/app/shared/models';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ChallengeService {
	private headerSrv = inject(HeaderLocaleService);
	private baseApi = inject(DataleanBaseApiService);

	getChallenges(options: {
		parts?: Parts[];
		locale?: string;
		pagination?: PaginationInfo;
		sort?: SortInfo;
		filters?: { uuids?: string[]; query?: string };
	}) {
		const { filters, parts, locale, pagination, sort } = options;

		const params: SimpleObject = {
			locale: locale ?? this.headerSrv.getActiveLocale(),
			active: true,
		};

		// TODO: generalizzare questa logica in una funzione reutilizzabile
		if (filters?.query) {
			params['q'] = filters.query;
			params['searchFields'] = ['name', 'description']; // parametrizzare i campi di ricerca??
		}

		if (filters?.uuids) params['uuid'] = filters.uuids.join(',');

		return this.baseApi.getManyPaged<Challenge>(environment.challengeUrl, parts ?? [], {
			pagination,
			sort,
			additionalParams: params,
		});
	}

	deleteChallenge(uuid: string) {
		return this.baseApi.deleteEntity(environment.challengeUrl, { uuid });
	}

	fetchChallenges(pages: number[], sort?: Sort, locale?: string, gridSearch?: string | null): Observable<DataleanPagedResult<Challenge>[]> {
		const obs: Observable<DataleanPagedResult<Challenge>>[] = [];

		const params: {
			sortBy?: string;
			q?: string;
			searchFields?: string;
			locale?: string;
			active: boolean;
		} = {
			locale: locale ?? this.headerSrv.getActiveLocale(),
			active: true,
			sortBy: 'name#asc',
		};

		//SORT
		if (sort) params.sortBy = `${sort.active}#${sort.direction}`;
		if (gridSearch) {
			params.q = gridSearch;
			params.searchFields = 'name,description';
		}

		for (const page of pages) {
			const pagination: PaginationInfo = new PaginationInfo(environment.pageSize, page);

			obs.push(
				this.baseApi.getManyPaged<Challenge>(environment.challengeUrl, [Parts.CREATED_BY, Parts.CONTEST], {
					pagination,
					additionalParams: params,
				})
			);
		}
		return forkJoin(obs);
	}
}
