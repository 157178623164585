import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FeatureValue, SimpleObject, Structure, StructureField, StructureFieldType } from 'addiction-components';
import { AbstractObject, CustomForm, LocalizableField } from 'src/app/shared/models';
import { createDeepTypeGuard } from './utils/type-guard';

export type formType = AbstractObject | AbstractObject[] | Record<string, AbstractObject> | FeatureValue[] | undefined;

export class StructureService {
	private structureFields: StructureField[] | undefined;

	constructor(structure: StructureField[]) {
		this.structureFields = structure;
	}

	findValue(name: string, dataset: Record<string, formType | unknown>, activeLanguage?: string): formType {
		if (dataset && !(name in dataset)) {
			const datasetContainers = Object.values(dataset).filter((v) => typeof v === 'object' || Array.isArray(v));
			if (datasetContainers.length) {
				for (const container of datasetContainers) {
					const foundValueInner = this.findValue(name, container as Record<string, formType>, activeLanguage);
					if (foundValueInner !== undefined) {
						return foundValueInner;
					}
				}
			}
		}
		return dataset?.[name] as formType;
	}

	//TODO rimpiazzare con un pipe o qualcosa del genere per evitare calcoli sull'onChange
	getClass(formKey: string) {
		if (this.structureFields) {
			const ratio = this.structureFields.find((sf) => sf.name === formKey)?.desktopRatio;
			if (ratio !== undefined) {
				return `ratio-${ratio}`;
			}
		}
		return 'ratio-100';
	}

	createForm(
		structureFields: StructureField[],
		dataset: Record<string, formType | unknown> = {},
		newEntity: boolean,
		activeLanguage?: string,
		defaultLanguage?: string
	): Array<CustomForm> {
		return structureFields.map((structureField) => {
			// console.log('createForm', structureField);
			const { name, fields, isRequired, repeatable } = structureField;

			const entityValue = this.findValue(name, dataset, activeLanguage);

			const validators = isRequired ? [Validators.required] : [];

			const lastOrder: number = structureFields.reduce((num: number, fc: StructureField) => (num < (fc?.order ?? 0) ? fc?.order ?? 0 : num), 0);
			let control: AbstractControl = new UntypedFormControl();
			control.addValidators(validators);
			if (newEntity && Array.isArray(structureField.predefinedValue) && activeLanguage) {
				control.patchValue(new LocalizableField(structureField.predefinedValue).getLocalizedValue(activeLanguage as string));
			}

			if (fields?.length) {
				const form = this.createForm(fields ?? [], dataset, newEntity, activeLanguage, defaultLanguage);
				const customForm: { [key: string]: AbstractControl } = form.reduce((obj, f) => ({ ...obj, [f.name]: f.form }), {});
				if (name in customForm) control = customForm[name];
				else control = new UntypedFormGroup(customForm);
			}
			if (repeatable) {
				if (Array.isArray(entityValue)) {
					if (entityValue.length) {
						if (structureField.type === StructureFieldType.CONTAINER) {
							// REPEATABLE CONTAINER
							control = new UntypedFormArray(
								entityValue.map((v) => {
									const controls = (control as UntypedFormGroup).controls;
									const newControl = new UntypedFormGroup(
										Object.entries(controls).reduce(
											(obj, [key]) => ({
												...obj,
												[key]: new UntypedFormControl((v as SimpleObject)[key] ?? ''),
											}),
											{}
										)
									);
									return newControl;
								})
							);
						} else {
							// REPEATABLE SIMPLE
							control = new UntypedFormArray(
								entityValue.map((v) => {
									return new UntypedFormControl(v);
								})
							);
						}
					} else {
						if (structureField.type === StructureFieldType.CONTAINER) {
							control = new UntypedFormArray([control]);
							control.patchValue(entityValue);
						} else {
							// REPEATABLE SIMPLE EMPTY([])
							const valueArray = new Array<UntypedFormControl>();
							if (newEntity && Array.isArray(structureField.predefinedValue) && activeLanguage) {
								valueArray.push(
									new UntypedFormControl(new LocalizableField(structureField.predefinedValue).getLocalizedValue(activeLanguage as string))
								);
							}
							control = new UntypedFormArray(valueArray);
						}
					}
				} else {
					if (structureField.type === StructureFieldType.CONTAINER) {
						if (control.value) {
							control = new UntypedFormArray([control]);
						} else {
							control = new UntypedFormArray([]);
						}
						control.patchValue(entityValue);
					} else {
						// REPEATABLE SIMPLE NOT DEFINED
						const valueArray = new Array<UntypedFormControl>();
						if (newEntity && Array.isArray(structureField.predefinedValue) && activeLanguage) {
							valueArray.push(
								new UntypedFormControl(new LocalizableField(structureField.predefinedValue).getLocalizedValue(activeLanguage as string))
							);
						}
						control = new UntypedFormArray(valueArray);
					}
				}
			}

			if (
				(!structureField.localizable && activeLanguage !== undefined && defaultLanguage !== undefined && activeLanguage !== defaultLanguage) ||
				structureField.fixed ||
				structureField.readOnly
			) {
				control.disable();
			}

			if (control instanceof UntypedFormControl || control instanceof UntypedFormArray) {
				return { form: control, order: lastOrder + 1, name };
			}
			return { form: control, order: lastOrder + 1, name };
		});
	}
}
export const isStructureField = createDeepTypeGuard<StructureField>('fields');
export const isStructure = createDeepTypeGuard<Structure>('structureFields');
