import { Component, Input, OnChanges } from '@angular/core';
import { BaseModalContent } from 'addiction-components';
import { AssetDetail } from '../../models/media-library/asset-details-data';
import { TreeBrowserConfig } from '../tree-browser/tree-browser.models';

@Component({
	selector: 'datalean-feature-selector-dialog',
	templateUrl: './feature-selector-dialog.component.html',
	styleUrls: ['./feature-selector-dialog.component.scss'],
})
export class FeatureSelectorDialogComponent<T extends Record<string, unknown>> extends BaseModalContent<{ selected: Array<T> }> implements OnChanges {
	@Input() list: Array<T> = [];
	@Input() selectedUUIDs: string[] = [];
	@Input() detailType: AssetDetail = AssetDetail.CATEGORY;

	config: TreeBrowserConfig = {
		keys: {
			children: this.detailType == AssetDetail.CATEGORY ? 'featureValues' : 'tags',
			key: 'uuid',
			title: this.detailType == AssetDetail.CATEGORY ? 'label' : 'name',
		},
		rootLabelKey: 'Root',
	};

	protected selectedFeatures: Array<T> = [];

	constructor() {
		super();
	}

	onChecked(checked: Array<T>) {
		this.selectedFeatures = checked;
	}

	confirm() {
		this.closeDialog({ selected: this.selectedFeatures });
	}

	close() {
		this.closeDialog({ selected: this.selectedFeatures });
	}

	// Importante per la gestione di attributi dinamici
	ngOnChanges(): void {
		this.config = {
			keys: {
				children: this.detailType == AssetDetail.CATEGORY ? 'featureValues' : 'tags',
				key: 'uuid',
				title: this.detailType == AssetDetail.CATEGORY ? 'label' : 'name',
			},
			rootLabelKey: 'Root',
		};
	}
}
