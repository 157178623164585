import { Component, EventEmitter, Input, Output, forwardRef, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import { Observable, filter, first, map, switchMap } from 'rxjs';
import { CloseReason, DataleanBaseApiService, ModalService, Parts } from 'addiction-components';
import { GenericSelectorDialogComponent } from '../generic-selector-dialog/generic-selector-dialog.component';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { CdkDropList, CdkDrag, CdkDragDrop } from '@angular/cdk/drag-drop';
import { Pictogram } from '../../models/pictogram/pictogram.interface';
import { PictogramPreviewComponent } from 'src/app/pages/pictogram/components/pictogram-preview/pictogram-preview.component';

@Component({
	selector: 'datalean-pictogram-selector',
	standalone: true,
	imports: [CommonModule, CdkDropList, CdkDrag, TranslateModule, PictogramPreviewComponent],
	templateUrl: './pictogram-selector.component.html',
	styleUrls: ['./pictogram-selector.component.scss'],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: PictogramSelectorComponent },
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: forwardRef(() => PictogramSelectorComponent),
		},
		TranslatePipe,
	],
})
export class PictogramSelectorComponent implements ControlValueAccessor, Validator {
	@Input('value') items?: Pictogram[] = [];
	protected disabled: boolean = false;

	@Input() label: string = '';
	@Input() requiredIcon?: boolean = false;
	@Input() readonly: boolean = false;

	@Output() changed = new EventEmitter<string[]>();

	protected onChangeFn: (value: string[]) => void = () => {};
	protected onTouchedFn: () => void = () => {};

	private modalSrv = inject(ModalService);
	private dataleanService = inject(DataleanBaseApiService);
	private translateService = inject(TranslatePipe);

	onValidatorChange = () => {};

	registerOnValidatorChange(fn: () => void): void {
		this.onValidatorChange = fn;
	}

	validate(c: AbstractControl): ValidationErrors | null {
		// console.log('validate', c);
		if (!this.requiredIcon && c.hasValidator(Validators.required)) {
			this.requiredIcon = true;
		}
		return c.invalid ? { ...c.errors } : null;
	}

	writeValue(val: Pictogram[]): void {
		this.items = val;
    this.onChangeFn(this.getOutputValue());
	}

	registerOnChange(fn: (val: string[]) => void): void {
		this.onChangeFn = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouchedFn = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	protected valueChanged() {
		this.changed.emit(this.getOutputValue());
		this.onChangeFn(this.getOutputValue());
		this.onTouchedFn();
	}
	protected clear() {
		this.items = [];
		this.valueChanged();
	}

	protected addValues(values: Pictogram[]) {
		const newValues = values.filter((val) => !this.items?.some((item) => item.uuid === val.uuid));
		if (this.items) this.items = [...this.items, ...newValues];
		else this.items = newValues;
		this.valueChanged();
	}

	protected removeValues(uuids: string[]) {
		this.items = this.items?.filter(({ uuid }) => !uuids.includes(uuid));
		this.valueChanged();
	}

	protected setValues(values: Pictogram[]) {
		this.items = values;
		this.valueChanged();
	}

	getOutputValue() {
		return this.items?.map(item => item.uuid) ?? [];
	}

	/** Apre il dialogo di selezione nuovi elementi e deve restituire gli elementi selezionati formattati correttamente */
	openDialog(currentValues: Pictogram[]): Observable<Pictogram[] | undefined> {
		const selected = (currentValues || []).map(({ uuid }) => uuid);

		return this.modalSrv
			.openDialog(
				GenericSelectorDialogComponent,
				{ title: this.translateService.transform('PICTOGRAMS.PICTOGRAMS') },
				{
					negativeReflection: false,
					endpoint: environment.pictogramUrl + 'pictogram',
					selectedUUIDs: selected,
				}
			)
			.result$.pipe(
				filter((result): result is { reason: CloseReason; data: { selected: string[] } } => result?.reason === 'COMPLETE' && !!result.data),
				switchMap(({ data }) => {
					const params: { uuid: string[]; communityUUID?: string } = {
						uuid: data?.selected,
					};

					return this.dataleanService.getManyPaged<Pictogram>(environment.pictogramUrl + 'pictogram', [Parts.PREVIEW_ASSET_INFO], {
						additionalParams: params,
					});
					// switch (this.inputData.entityType) {
					// 	case EntityType.PRODUCT:
					// 		return this.productService.getProducts({ parts: [], filters: { uuids: data?.selected, communityUUID: this.lastCommunitySelectedUUID } });
					// 	case EntityType.COMMUNICATION:
					// 		return this.communicationService.getCommunications({ parts: [], filters: { uuids: data?.selected, communityUUID: this.lastCommunitySelectedUUID } });
					// 	case EntityType.CONTENT:
					// 		return this.contentService.getContents({ parts: [], filters: { uuids: data?.selected, communityUUID: this.lastCommunitySelectedUUID } });
					// }
				}),
				map((result) => result.result)
			);
	}

	add() {
		this.openDialog(this.items ?? [])
			.pipe(first())
			.subscribe((result) => {
				if (result) {
					this.setValues(result);
				}
			});
	}

	removeItem(item: Pictogram) {
		if (this.disabled) return;
		this.removeValues([item.uuid]);
	}

  drop(event: CdkDragDrop<Pictogram[] | undefined>) {
		if (this.items) {
			const el = this.items[event.previousIndex];
			this.items.splice(event.previousIndex, 1);
			this.items.splice(event.currentIndex, 0, el);
      this.valueChanged();
		}
	}

}
