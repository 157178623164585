import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TypedAction } from '@ngrx/store/src/models';
import { filter, map } from 'rxjs';
import { HeaderActions } from 'src/app/core/state/app.actions';

@Injectable()
export class HeaderEffect {
	private actions$ = inject(Actions);

	setShowCommunitySelect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(HeaderActions.setShowConfig),
			map((config) => {
				return HeaderActions.setShowCommunitySelect({ show: config.communitySelect });
			})
		)
	);

	setShowLocaleSelect$ = createEffect(() =>
		this.actions$.pipe(
			ofType(HeaderActions.setShowConfig),
			map((config) => {
				return HeaderActions.setShowLocales({ show: config.localeSelect });
			})
		)
	);

	showBackButton$ = createEffect(() =>
		this.actions$.pipe(
			ofType(HeaderActions.activateBackButton),
			map((activateOption) => {
				return HeaderActions.setShowBackButton({ showBackButton: activateOption.showBackButton });
			})
		)
	);

	showPlusButton$ = createEffect(() =>
		this.actions$.pipe(
			ofType(HeaderActions.activatePlusButton),
			map((activateOption) => {
				return HeaderActions.setShowPlusButton({ showPlusButton: activateOption.showPlusButton });
			})
		)
	);

	showRemoveButton$ = createEffect(() =>
		this.actions$.pipe(
			ofType(HeaderActions.activateRemoveButton),
			map((activateOption) => {
				return HeaderActions.setRemoveButton({ showRemoveButton: activateOption.showRemoveButton });
			})
		)
	);

	setBackButtonUrl$ = createEffect(() =>
		this.actions$.pipe(
			ofType(HeaderActions.activateBackButton),
			filter(
				(
					activateOption
				): activateOption is {
					showBackButton: boolean;
					backButtonUrl: string[];
				} & TypedAction<'[Header] Activate Back Button'> => !!activateOption.backButtonUrl
			),
			map((activateOption) => {
				return HeaderActions.setBackButtonUrl({ backButtonUrl: activateOption.backButtonUrl });
			})
		)
	);

	constructor() {}
}
