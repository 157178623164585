import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatMenuCustomComponent } from '../mat-menu-custom.component';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import { FeatureValue } from 'addiction-components';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'datalean-feature-value-mat-menu',
	templateUrl: './feature-value-mat-menu.component.html',
	standalone: true,
	imports: [CommonModule, MatMenuModule, MatCheckboxModule],
})
export class FeatureValueMatMenuComponent extends MatMenuCustomComponent<FeatureValue> {
	@Input() items: FeatureValue[] = [];
	@ViewChild('childMenu', { static: true }) public childMenu!: MatMenu;
	@Input() itemsSelected?: string[];
	@Output() featureValueChange = new EventEmitter<{ event: MatCheckboxChange; featureValueUUID: string }>();

	constructor() {
		super();
	}

	featureValueChanged(event: MatCheckboxChange, featureValueUUID: string) {
		this.featureValueChange.emit({ event: event, featureValueUUID: featureValueUUID });
	}

	featureValueAlreadySelected(featureValueUUID: string) {
		return this.itemsSelected ? this.itemsSelected.indexOf(featureValueUUID) > -1 : false;
	}
}
