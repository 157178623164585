<ng-container *ngrxLet="coordinateInPx$ as coord">
	<div
		*ngIf="coord && coordinate.x >= 0 && coordinate.x <= 100 && coordinate.y >= 0 && coordinate.y <= 100"
		class="single-hotspot-container"
		cdkDrag
		[cdkDragBoundary]="classBboundaryContainer"
		(cdkDragEnded)="endedDrag()"
		[style.left.px]="coord.x"
		[style.top.px]="coord.y">

		<span class="number">
			{{ label }}
		</span>
	</div>
</ng-container>
