import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { SharedModule } from 'addiction-components';
import { OAuthModule } from 'angular-oauth2-oidc';
import { ContextStoreModule } from 'context-store';
import { LottieModule, provideLottieOptions } from 'ngx-lottie';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UploadProgressComponent } from './core/components/upload-progress/upload-progress.component';
import { AuthJWTInterceptor } from './core/interceptors/auth-jwt.interceptor';
import { HttpErrorInterceptor } from './core/interceptors/http-error.interceptor';
import { AppEffects, AppReducers } from './core/state/app.state';
import { authServiceInit } from './shared/services/auth/auth-initializer';
import { AuthService } from './shared/services/auth/auth.service';
import { translateLoader } from './shared/services/translate-configuration.class';

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
	return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

@NgModule({
	declarations: [AppComponent, UploadProgressComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		NoopAnimationsModule,
		ToastrModule.forRoot({
			positionClass: 'toast-bottom-center',
		}),
		OAuthModule.forRoot(),
		StoreModule.forRoot(AppReducers),
		EffectsModule.forRoot(AppEffects),
		StoreDevtoolsModule.instrument({
			maxAge: 25,
			trace: !environment.production,
			logOnly: environment.production,
			name: 'Datalean',
		}),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: translateLoader,
				deps: [HttpClient],
			},
			defaultLanguage: environment.activeLanguage,
		}),
		//questo va dichiarato in app module perchè le dialog hanno bisogno essere qui
		MatDialogModule,
		ContextStoreModule.forRoot(),
		LottieModule.forRoot({ player: playerFactory }),
		SharedModule,
	],
	providers: [
		{ provide: 'environment', useValue: environment },
		{ provide: HTTP_INTERCEPTORS, useClass: AuthJWTInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
		{ provide: APP_INITIALIZER, useFactory: authServiceInit, deps: [AuthService], multi: true },
		TranslatePipe,
		provideLottieOptions({
			player: () => import(/* webpackChunkName: 'lottie-web' */ 'lottie-web'),
		}),
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor() {}
}
