import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
	CustomHeader,
	DataleanEntity,
	EnvironmentConfiguration,
	ISortInfo,
	PaginationInfo,
	Parts,
	SearchInfo,
	SimpleObject,
	SortInfo,
	SubParts,
} from '../models';
import { ApiOptions } from '../models/api-options.interface';
import { DataleanPagedResult } from '../models/datalean-paged-result';
import { Page } from '../models/table.interface';

export type Url = string | string[];

export type PartParam = Parts | Partial<Record<Parts, SubParts[]>>;

@Injectable()
/** Servizio base di accesso alle API REST */
export class DataleanApiService {
	protected http = inject(HttpClient);

	constructor(@Inject('environment') protected environment: EnvironmentConfiguration) {}

	protected get organizationUUID() {
		return this.environment.organizationUUID;
	}

	protected get defaultQueryParams() {
		return { organizationUUID: this.organizationUUID };
	}

	protected url(urlParts: Url | Url[]): string {
		const parts = Array.isArray(urlParts) ? urlParts.flatMap((p) => (Array.isArray(p) ? p : [p])) : [urlParts];
		const validParts = parts.filter((p) => !!p).map((p) => p.trim());

		if (validParts.length < 1) return '';

		const [base, ...segments] = validParts.filter((p) => !!p);
		return (
			this.postfix(base, '/') +
			segments
				.map((p) => {
					let clean = p;
					if (clean.slice(-1) === '/') clean = clean.substring(0, clean.length - 1);
					if (clean[0] === '/') clean = clean.substring(1);
					return clean;
				})
				.join('/')
		);
	}

	private postfix(str: string, char: string) {
		if (str.slice(-1) != char) return str + char;
		return str;
	}

	protected paginationParams(pagination: PaginationInfo | Partial<Page>): SimpleObject {
		if (!pagination) return {};

		if (pagination instanceof PaginationInfo) {
			return {
				numberOfPage: (pagination.numberOfPage ?? 0) + 1,
				numberOfPageElements: pagination.numberOfElementsOnPage ?? 0,
			};
		}

		return {
			numberOfPage: (pagination.pageNumber ?? 0) + 1,
			numberOfPageElements: pagination.pageSize ?? 0,
		};
	}

	protected sortParams(sort: SortInfo | SortInfo[] | ISortInfo | ISortInfo[]): SimpleObject {
		const clauses = Array.isArray(sort) ? sort : [sort];
		const validClauses = clauses.map((s) => this.serializeSort(s)).filter((s) => !!s);

		if (validClauses.length < 1) return { sortBy: '' };

		return {
			sortBy: validClauses.join(','),
		};
	}
	private serializeSort(sort: SortInfo | ISortInfo) {
		if (!sort || !sort.sortBy || sort.sortBy.trim().length < 1) return undefined;

		return sort.sortBy + '#' + (sort.sortDirection || 'asc');
	}
	protected searchParams(searchInfo: SearchInfo): SimpleObject {
		return {
			[searchInfo.searchValueParamName]: searchInfo.query,
			[searchInfo.searchFieldsParamName]: searchInfo.searchFields,
		};
	}

	protected partsParams(parts: PartParam[]): SimpleObject {
		return {
			parts: parts.map((p) => (typeof p === 'string' ? p : this.subPartsParam(p))).join(','),
		};
	}
	private subPartsParam(subparts: Partial<Record<Parts, SubParts[]>>) {
		return Object.entries(subparts)
			.map(([part, value]) => value.map((subpart) => `${part}.${subpart}`).join(','))
			.join(',');
	}

	getMany<T>(endpoint: Url, parts: PartParam[], options?: ApiOptions): Observable<T[]> {
		let params: SimpleObject = {
			...this.defaultQueryParams,
		};
		if (options) {
			if (options.pagination) params = { ...params, ...this.paginationParams(options.pagination) };
			if (options.sort) params = { ...params, ...this.sortParams(options.sort) };
			if (options.search) params = { ...params, ...this.searchParams(options.search) };
			if (options.additionalParams) params = { ...params, ...options.additionalParams };
		}

		if (parts) params = { ...params, ...this.partsParams(parts) };

		return this.http.get<T[]>(this.url(endpoint), { params });
	}

	getManyPaged<T>(endpoint: Url, parts: PartParam[], options?: ApiOptions): Observable<DataleanPagedResult<T>> {
		let params: SimpleObject = {
			...this.defaultQueryParams,
		};
		if (options) {
			if (options.pagination) params = { ...params, ...this.paginationParams(options.pagination) };
			if (options.sort) params = { ...params, ...this.sortParams(options.sort) };
			if (options.search) params = { ...params, ...this.searchParams(options.search) };
			if (options.additionalParams) params = { ...params, ...options.additionalParams };
		}

		if (parts) params = { ...params, ...this.partsParams(parts) };

		return this.http.get<T[]>(this.url(endpoint), { params, observe: 'response' }).pipe(
			filter((resp) => resp instanceof HttpResponse),
			map((data) => {
				const response = data;

				const rows: T[] = response.body ?? [];
				let paginationInfoResult: Page = { pageNumber: 0, pageSize: 25, totalElements: rows.length };

				if (!!options?.pagination && !!response.headers) {
					paginationInfoResult = {
						pageSize: parseInt(response.headers.get(CustomHeader.pageSize) ?? '25'),
						pageNumber: parseInt(response.headers.get(CustomHeader.pageIndex) ?? '1') - 1,
						totalElements: parseInt(response.headers.get(CustomHeader.totalCount) ?? '0') ?? rows.length,
					};
				}

				return {
					result: rows,
					paginationInfo: paginationInfoResult,
				};
			})
		);
	}

	getOne<T>(endpoint: Url, uuid: string, parts: PartParam[], addictionalParams: { [key: string]: unknown } = {}): Observable<T> {
		const params = {
			...this.defaultQueryParams,
			...this.partsParams(parts),
			...addictionalParams,
		};
		return this.http.get<T>(this.url([endpoint, uuid]), { params });
	}

	/**
   *
   Enpoint che permette di modificare il responseType
   */
	getOneS(
		endpoint: Url,
		uuid: string,
		parts: PartParam[],
		addictionalParams: { [key: string]: unknown } = {},
		options?:
			| Parameters<HttpClient['get']>[1]
			| { responseType?: 'json'; observe: 'body' }
			| { responseType: 'text'; observe: 'body' }
			| { responseType: 'blob'; observe: 'body' }
			| { responseType: 'arraybuffer'; observe: 'body' }
	): Observable<string | Object> {
		const params = {
			...this.defaultQueryParams,
			...this.partsParams(parts),
			...addictionalParams,
		};
		if (options?.responseType === 'text') {
			return this.http.get(this.url([endpoint, uuid]), { ...options, params });
		}
		if (options?.responseType === 'blob') {
			return this.http.get(this.url([endpoint, uuid]), { ...options, params });
		}
		if (options?.responseType === 'arraybuffer') {
			return this.http.get(this.url([endpoint, uuid]), { ...options, params });
		}
		return this.http.get(this.url([endpoint, uuid]), { ...options, params });
	}

	updateOne<T, TRet = T>(endpoint: Url, uuid: string, entity: T, parts: PartParam[] = []) {
		if (!uuid) return throwError(() => 'Cannot update entity without UUID');
		const params = {
			...this.defaultQueryParams,
			...this.partsParams(parts),
		};
		return this.http.put<TRet>(this.url([endpoint, uuid]), entity, { params });
	}

	createOne<T extends Record<string, any> | Array<Record<string, any>> = SimpleObject, TRet = T>(
		endpoint: Url,
		entity: T,
		parts: PartParam[] = [],
		additionalParams: SimpleObject = {}
	) {
		// delete mongodb id if present
		if (entity && !Array.isArray(entity)) {
			delete entity['_id'];
		}

		const params = {
			...this.defaultQueryParams,
			...this.partsParams(parts),
			...additionalParams,
		};
		return this.http.post<TRet>(this.url(endpoint), entity, { params });
	}

	/** Esegue un'operazione PATCH su un solo elemento */
	patchOne<T>(endpoint: Url, uuid: string, entity: Partial<T>): Observable<T> {
		return this.http.patch<T>(this.url([endpoint, uuid]), entity, { params: this.defaultQueryParams });
	}

	/** Esegue un'operazione PATCH su N elementi */
	patchMany<T extends DataleanEntity>(endpoint: Url, entities: Partial<T>[]): Observable<T[]> {
		return this.http.patch<T[]>(this.url(endpoint), entities, { params: this.defaultQueryParams });
	}

	updateMany<T extends DataleanEntity>(endpoint: Url, entities: Partial<T>[]): Observable<T[]> {
		return this.http.put<T[]>(this.url(endpoint), entities, { params: this.defaultQueryParams });
	}

	deleteOne(endpoint: Url, uuid: string, additionalParams: SimpleObject = {}) {
		const params = { ...this.defaultQueryParams, ...additionalParams };

		return this.http.delete(this.url([endpoint, uuid]), { params });
	}

	deleteMany(endpoint: Url, additionalParams: SimpleObject = {}) {
		const params = { ...this.defaultQueryParams, ...additionalParams };

		return this.http.delete(this.url([endpoint]), { params });
	}

	// deleteCourses(endpoint: Url,  uuidList: string[]) {
	// 	console.log('lista:',uuidList)
	// 	return this.http.delete(this.url([endpoint]), { params: {...this.defaultQueryParams, uuidList: uuidList} });
	// }
}
