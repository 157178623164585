import { ObjectValuesType } from 'addiction-components';

export const BulkActionEnum = {
	DELETE: 'delete',
	DISABLE: 'disable',
	ADD_TO_COLLECTION: 'addToCollection',
	SET_OPERATION_PERMISSIONS: 'setOperationPermissions',
	REGENERATE_THUMBNAILS: 'regenerateThumbnails',
	ADD_CATEGORIE_TAG: 'addCategorieTag',
	ADD_TAGS: 'addTags',
	ADD_REMOVE_LESSONS_CATEGORIES: 'lessons_feature',
	ADD_CATEGORIE: 'addCategorie',
	ADD_PERMISSIONS: 'addPermissions',
} as const;

export type BulkActionEnum = ObjectValuesType<typeof BulkActionEnum>;
