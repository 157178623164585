import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ChallengeReducers } from 'src/app/core/state/app.reducers';
import { ChallengeState } from './challenge.state';

const selectChallengesState = createFeatureSelector<ChallengeState>(ChallengeReducers.TAG);

export const selectData = createSelector(selectChallengesState, (state) => state.data);
export const selectPages = createSelector(selectChallengesState, (state) => state.pages);
export const selectPagedData = createSelector(selectData, selectPages, (data, pages) => ({ ...data, pages }));
export const selectGridSearch = createSelector(selectChallengesState, (state) => state.gridSearch);
export const selectStatus = createSelector(selectChallengesState, (state) => state.status);
export const selectSort = createSelector(selectChallengesState, (state) => state.sort);
export const selectCachedPages = createSelector(selectChallengesState, (state) => state.cachedPages);
