import { CommonModule } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { AddictionComponentsModule, BaseModalContent, DataleanSelectOption, SelectFieldComponent } from 'addiction-components';
import { MediaLibraryService } from 'src/app/pages/media-library/services/media-library.service';
import { FormModalConversion } from 'src/app/shared/models/form-modal-conversion';
import { Conversion, SupportedFormats } from 'src/app/shared/models/media-library/conversion';

@Component({
	selector: 'datalean-modal-conversion',
	templateUrl: './modal-conversion.component.html',
	styleUrls: ['./modal-conversion.component.scss'],
	standalone: true,
	imports: [CommonModule, TranslateModule, FormsModule, ReactiveFormsModule, MatDialogModule, AddictionComponentsModule, SelectFieldComponent],
})
export class ModalConversionComponent extends BaseModalContent<Conversion> implements OnInit {
	readonly baseOutputFormat = [SupportedFormats.WebP, SupportedFormats.JPG, SupportedFormats.PNG];
	protected destroyRef = inject(DestroyRef);

	showErrorMandatoryField = false;
	showErrorHttp = false;

	supportedFormats: DataleanSelectOption[] = Object.values(SupportedFormats).map((el) => ({ value: el, label: el }));
	outputFormats: Array<SupportedFormats> = [SupportedFormats.WebP, SupportedFormats.PNG];

	defaultValueFrom = { label: SupportedFormats.JPG, value: SupportedFormats.JPG };
	defaultValueTo = { label: SupportedFormats.WebP, value: SupportedFormats.WebP };

	formModalConversion = new FormGroup<FormModalConversion>({
		name: new FormControl('', { nonNullable: true, validators: Validators.required }),
		from: new FormControl(this.defaultValueFrom.value, { nonNullable: true, validators: Validators.required }),
		to: new FormControl(this.defaultValueTo.value, { nonNullable: true, validators: Validators.required }),
	});

	constructor(private mediaLibraryService: MediaLibraryService) {
		super();
	}

	ngOnInit(): void {
		this.formModalConversion.controls.from.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((x) => {
			this.outputFormats = [...this.baseOutputFormat].filter((y) => y !== x);
		});
	}

	saveConversion() {
		//reset errore
		this.showErrorMandatoryField = false;
		this.showErrorHttp = false;

		this.formModalConversion.updateValueAndValidity();
		if (this.formModalConversion.invalid) {
			this.showErrorMandatoryField = true;
			return;
		}
		const { name, from, to } = this.formModalConversion.getRawValue();
		const conversion = {
			name,
			from,
			to,
		};

		this.mediaLibraryService.createConversion(conversion).subscribe({
			next: (x) => {
				this.mediaLibraryService.getConversions();
				this.closeDialog(x);
			},
			error: () => {
				this.showErrorHttp = true;
			},
		});
	}
}
