import { Injectable } from '@angular/core';
import { DataleanBaseApiService, Parts, SimpleObject } from 'addiction-components';
import { environment } from 'src/environments/environment';
import { ApplicationUser } from '../models';

@Injectable()
export class AppUserService {
	constructor(private baseApi: DataleanBaseApiService) {}

	getUsers(filters?: SimpleObject) {
		return this.baseApi.getManyPaged<ApplicationUser>(environment.applicationUsersUrl, [Parts.EMPTY], { additionalParams: filters });
	}
}
