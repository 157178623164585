import { transformTypeClassIntoInterface } from "../utils";

export class SearchInfo {
  constructor(
    private _searchFields: string = '',
    private _query: string = '',
    private _searchFieldsParamName: string = 'searchFields',
    private _searchValueParamName: string = 'query'
  ) {}

  get searchFields(): string {
    return this._searchFields;
  }

  set searchFields(value: string) {
    this._searchFields = value;
  }

  get query(): string {
    return this._query;
  }

  set query(value: string) {
    this._query = value;
  }

  get searchFieldsParamName(): string {
    return this._searchFieldsParamName;
  }

  get searchValueParamName(): string {
    return this._searchValueParamName;
  }
}

export type ISearchInfo = transformTypeClassIntoInterface<SearchInfo>;
