import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommunicationReducers } from 'src/app/core/state/app.reducers';
import { CommunicationState } from './communication.state';

const selectCommunicationsState = createFeatureSelector<CommunicationState>(CommunicationReducers.TAG);

export const selectCommunicationsFilters = createSelector(selectCommunicationsState, (state) => state?.filters);
export const selectCommunicationsFeatures = createSelector(selectCommunicationsState, (state) => state?.features);
export const selectCommunicationsStructures = createSelector(selectCommunicationsState, (state) => state?.structures);

export const selectCachedPages = createSelector(selectCommunicationsState, (state) => state.cachedPages);
export const selectData = createSelector(selectCommunicationsState, (state) => state.data);
export const selectPages = createSelector(selectCommunicationsState, (state) => state.pages);
export const selectPagedData = createSelector(selectData, selectPages, (data, pages) => ({ ...data, pages }));
export const selectGridSearch = createSelector(selectCommunicationsState, (state) => state.gridSearch);
export const selectStatus = createSelector(selectCommunicationsState, (state) => state.status);
export const selectSort = createSelector(selectCommunicationsState, (state) => state.sort);
export const selectRecipients = createSelector(selectCommunicationsState, (state: CommunicationState) => state.recipients);
