<mat-tab-group>
	<mat-tab *ngIf="showImport" label="{{ 'MODALS.IMPORT_EXPORT.IMPORT' | translate }}"
		><ng-container [ngTemplateOutlet]="importTable"></ng-container
	></mat-tab>
	<mat-tab *ngIf="showExport" label="{{ 'MODALS.IMPORT_EXPORT.EXPORT' | translate }}"
		><ng-container *ngTemplateOutlet="exportTable; context: { response: response }"></ng-container
	></mat-tab>
</mat-tab-group>

<ng-template #importTable>
	<form [formGroup]="importForm">
		<div class="field-container ratio-100" *ngIf="showSelectedStrcutureUUIDImport">
			<label>{{ 'MODALS.IMPORT_EXPORT.STRUCTURE' | translate }}<span>*</span></label>
			<addiction-select formControlName="selectedStrcutureUUID" [options]="structures"></addiction-select>
		</div>
		<div class="field-container ratio-100" *ngIf="showSelectedLangImport">
			<label>{{ 'MODALS.IMPORT_EXPORT.LANGUAGE' | translate }}<span>*</span></label>
			<addiction-select formControlName="selectedLang" [options]="availableLang$ | async"></addiction-select>
		</div>
		<div class="field-container ratio-100" *ngIf="showRecipientEmailImport">
			<addiction-input formControlName="recipientEmail" label="{{ 'MODALS.IMPORT_EXPORT.EMAIL' | translate }}"></addiction-input>
		</div>
		<div class="field-container ratio-100" *ngIf="showInputFileImport">
			<label>{{ 'MODALS.IMPORT_EXPORT.FILE' | translate }}<span>*</span></label>
			<div class="external-file-selector">
				<input #inputFile formControlName="inputFile" type="file" />
			</div>
		</div>
		<div class="buttons-container">
			<button class="confirm" type="button" [disabled]="!this.importForm.valid" (click)="import()">
				<span>{{ 'MODALS.IMPORT_EXPORT.CONFIRM' | translate }}</span>
			</button>
		</div>
	</form>
</ng-template>

<ng-template #exportTable let-response="response">
	<form [formGroup]="exportForm" *ngIf="!response">
		<div class="field-container ratio-100" *ngIf="showSelectedStrcutureUUIDExport">
			<label>{{ 'MODALS.IMPORT_EXPORT.STRUCTURE' | translate }}<span>*</span></label>
			<addiction-select formControlName="selectedStrcutureUUID" [options]="structures"></addiction-select>
		</div>
		<div class="field-container ratio-100" *ngIf="showSelectedLangExport">
			<label>{{ 'MODALS.IMPORT_EXPORT.LANGUAGE' | translate }}<span>*</span></label>
			<addiction-select formControlName="selectedLang" [options]="availableLang$ | async"></addiction-select>
		</div>
		<div class="field-container ratio-100" *ngIf="showRecipientEmailExport">
			<addiction-input formControlName="recipientEmail" label="{{ 'MODALS.IMPORT_EXPORT.EMAIL' | translate }}"></addiction-input>
		</div>
		<div class="buttons-container">
			<button class="confirm" type="button" [disabled]="!this.exportForm.valid" (click)="export()">
				<span>{{ 'MODALS.IMPORT_EXPORT.CONFIRM' | translate }}</span>
			</button>
		</div>
	</form>

	<div *ngIf="response" class="url-export">
		<div class="field-container ratio-100">
			<div class="info-container">
				<div class="label">{{ 'EXPORT.URL' | translate }}</div>
				<div class="value">
					<a [href]="response">{{ response }}</a>
				</div>
			</div>
		</div>
	</div>
</ng-template>
