<ngx-datatable
	#table
	class="material"
	*ngIf="configuration"
	[headerHeight]="headerHeight"
	[footerHeight]="footerHeight"
	[rows]="inputRows$ | async"
	[columns]="columns"
	[columnMode]="ColumnMode.force"
	rowHeight="auto"
	[externalPaging]="externalPaging"
	[count]="page.totalElements"
	[offset]="page.pageNumber"
	[limit]="page.pageSize"
	[loadingIndicator]="loading"
	[externalSorting]="externalSorting"
	(sort)="onSort($event)"
	[selectionType]="SelectionType.checkbox"
	[sorts]="configuration.defaultSort ?? []"
	[selectAllRowsOnPage]="selectAllRowsOnPage"
	[selected]="selected"
	[displayCheck]="configuration.displayCheck ?? displayCheck"
	[selectCheck]="rowIdentity"
	(select)="onSelect($event)"
	(activate)="onActivate($event)">
	<ngx-datatable-footer>
		<ng-template
			ngx-datatable-footer-template
			let-rowCount="rowCount"
			let-pageSize="pageSize"
			let-selectedCount="selectedCount"
			let-curPage="curPage"
			let-offset="offset">
			<datalean-paginator
				[pageSize]="pageSize"
				[currentPage]="curPage - 1"
				[totalItems]="rowCount"
				(pageChange)="onPageChange($event)"></datalean-paginator>
		</ng-template>
	</ngx-datatable-footer>
</ngx-datatable>

<ng-template #hdrTpl let-column="column">
	<div *ngIf="filtersConfig?.length">
		<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
			<svg
				enable-background="new 0 0 36 30"
				height="30px"
				id="Layer_1"
				version="1.1"
				viewBox="0 0 36 30"
				width="36px"
				xml:space="preserve"
				xmlns="http://www.w3.org/2000/svg"
				xmlns:xlink="http://www.w3.org/1999/xlink">
				<polygon fill="#231F20" points="14,30 22,25 22,17 35.999,0 17.988,0 0,0 14,17 " />
			</svg>
		</button>
		<mat-menu #menu="matMenu">
			<button mat-menu-item *ngFor="let item of filtersConfig" (click)="item.action()">
				<mat-icon>{{ item.icon }}</mat-icon>
				<span>{{ item.label }}</span>
			</button>
		</mat-menu>
	</div>
</ng-template>

<ng-template #editTmpl let-row="row" let-value="value">
	<div *ngIf="value?.length">
		<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
			<mat-icon>more_vert</mat-icon>
		</button>
		<mat-menu #menu="matMenu">
			<button mat-menu-item *ngFor="let item of value" (click)="item.action()">
				<mat-icon>{{ item.icon }}</mat-icon>
				<span>{{ item.label }}</span>
			</button>
		</mat-menu>
	</div>
</ng-template>
