import { Injectable, inject } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { DataleanBaseApiService, DataleanSelectOption, ModalService, PaginationInfo, Parts, SimpleObject, UserStructure } from 'addiction-components';
import { Observable, forkJoin, map } from 'rxjs';
import { ImportExportDialogComponent } from 'src/app/shared/components/import-export-dialog/import-export-dialog.component';
import { ApplicationUser } from 'src/app/shared/models';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ApplicationUsersService {
	private modalSrv = inject(ModalService);
	private translate = inject(TranslateService);
	fetchUserStructures(filters?: SimpleObject, parts: Parts[] = [Parts.EMPTY]) {
		return this.dataleanApi.getEntities<UserStructure[]>(environment.userStructureUrl, filters, parts);
	}

	fetchCountForEachStructure(structureUUIDs: string[]) {
		return forkJoin(
			structureUUIDs.map((structureUUID) => {
				return this.dataleanApi.getEntities<{ count: number }>(environment.applicationUsersUrl + 'count', { structureUUID }, [Parts.EMPTY]).pipe(
					map(({ count }) => {
						return {
							structureUUID,
							count,
						};
					})
				);
			})
		);
	}

	constructor(private dataleanApi: DataleanBaseApiService) {}

	fetchUsers(
		pages: number[],
		structureUUID: string,
		sort?: Sort,
		gridSearch?: string | null,
		communityUUID?: string
	): Observable<{ result: ApplicationUser[] | null; paginationInfo?: PaginationInfo }[]> {
		const obs: Observable<{ result: ApplicationUser[] | null; paginationInfo?: PaginationInfo }>[] = [];

		const params: { structureUUID: string; sortBy?: string; q?: string; searchFields?: string; communityUUID?: string } = {
			structureUUID,
		};

		//SORT
		if (sort) params.sortBy = `${sort.active}#${sort.direction}`;
		if (gridSearch) {
			params.q = gridSearch;
			params.searchFields = 'firstName,lastName,email,username';
		}
		if (communityUUID) params.communityUUID = communityUUID;

		for (const page of pages) {
			const pagination = new PaginationInfo(environment.pageSize, page);

			obs.push(
				this.dataleanApi.getEntitiesWithPaginationData<ApplicationUser>(environment.applicationUsersUrl, params, [], undefined, pagination, undefined)
			);
		}
		return forkJoin(obs);
	}

	deleteUser(user: ApplicationUser) {
		return this.dataleanApi.deleteEntity(environment.applicationUsersUrl, user);
	}

	openImportExportModal(structures: DataleanSelectOption<string>[] | null | undefined) {
		const $openModalRes = this.modalSrv.openDialog(
			ImportExportDialogComponent,
			{ title: this.translate.instant('MODALS.IMPORT_EXPORT.MODAL_TITLE') },
			{
				showExport: true,
				showImport: true,
				endpointImport: environment.applicationUsersUrl + 'import/',
				endpointExport: environment.applicationUsersUrl + 'export/',
				structures: structures,
				negativeReflection: true,
			}
		);
		return $openModalRes.result$;
	}
}
