import { ObjectValuesType } from '../../../models';
import { AutocompleteOptionGroup } from '../../autocomplete/model/autocomplete-model';

export interface FilterMenuField<T> {
	label: string;
	checked: boolean;
	value: T;
}

//identifica il tipo di filtro della sezione
export const FilterMenuSectionType = {
	checkbox: 'CHECKBOX',
	tags: 'TAGS',
	select: 'SELECT',
	rangeDate: 'RANGE_DATE',
} as const;
export type FilterMenuSectionType = ObjectValuesType<typeof FilterMenuSectionType>;

export const FilterMenuFieldDateLabel = {
	TO: 'TO',
	FROM: 'FROM',
} as const;
export type FilterMenuFieldDateLabel = ObjectValuesType<typeof FilterMenuFieldDateLabel>;

export interface FilterMenuSection<T = any> {
	id: string;
	fields: FilterMenuField<T>[];
	isExpanded: boolean;
	titleSection: string;
	secondTitleSection?: string; //può essere utilizzato per passarsi una seconda stringa, utile per componenti tipo dateRange
	sectionFilterType: FilterMenuSectionType;
	optionsTags?: FilterMenuField<string>[]; //utilizzato per mostrare le chip selezionabili
	optionsSelectAutocomplete?: AutocompleteOptionGroup[]; //utilizzato per mostrare i campi selezionabili della select
}

export interface FilterMenuState {
	sections: FilterMenuSection<unknown>[];
	creationFrom?: string;
	creationTo?: string;
	updateFrom?: string;
	updateTo?: string;
}
