<label *ngIf="label">{{ label }} <span *ngIf="requiredIcon">*</span></label>

<div
class="pictogram-container"
cdkDropList
[cdkDropListData]="items"
(cdkDropListDropped)="drop($event)">

  <div
    class="single-element"
    *ngFor="let item of items; let i = index"
    cdkDrag
    [cdkDragDisabled]="readonly || disabled"
    cdkDragPreviewContainer="parent"
    cdkDragBoundary=".pictogram-container"
    [id]="item.uuid"
    [cdkDragData]="item">

    <div class="drag-placeholder" *cdkDragPlaceholder></div>

    <div class="drag-container" cdkDragHandle>
      <span class="number">{{ i + 1 }}</span>
    </div>

    <div class="pictogram">
      <span class="pictogram-name">{{ item.name}}</span>
      <datalean-pictogram-preview [pictogram]="item"></datalean-pictogram-preview>
    </div>

    <div class="buttons-container">
      <button
        class="delete"
        type="button"
        *ngIf="!readonly && !disabled"
        (click)="removeItem(item)"></button>
    </div>
  </div>

  <button
    class="add-element"
    type="button"
    *ngIf="!readonly && !disabled"
    (click)="add()">
    <span>{{ 'ADD_ELEMENT' | translate }}</span>
  </button>
</div>
