import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'toIcon',
  standalone: true,
})
export class ToIconPipe implements PipeTransform {
	transform(format?: string) {
		let iconName = 'placeholder-image.svg';
		if (format) {
			switch (format.toLowerCase()) {
				case 'psd':
					iconName = 'placeholder-image.svg';
					break;
				case 'ai':
					iconName = 'placeholder-vector.svg';
					break;
				case 'dwg':
					iconName = 'placeholder-3d.svg';
					break;
				case 'indd':
					iconName = 'placeholder-publishing.svg';
					break;
				case 'doc':
				case 'docx':
				case 'txt':
				case 'odt':
					iconName = 'placeholder-text.svg';
					break;
				case 'ppt':
				case 'pptx':
					iconName = 'placeholder-presentation.svg';
					break;
				case 'xls':
				case 'xlsx':
					iconName = 'placeholder-sheet.svg';
					break;
				case 'xml':
				case 'json':
					iconName = 'placeholder-code.svg';
					break;
				case 'ogg':
				case 'mov':
				case 'avi':
				case 'mp4':
				case 'mpg':
				case 'wmv':
				case 'flv':
					iconName = 'placeholder-video.svg';
					break;
				case 'zip':
				case 'rar':
				case 'tgz':
					iconName = 'placeholder-archive.svg';
					break;
				case 'mp3':
				case 'wav':
				case 'm4a':
					iconName = 'placeholder-audio.svg';
					break;
				default:
					iconName = 'placeholder-image.svg';
					break;
			}
		}

		return 'assets/images/placeholder/' + iconName;
	}
}
