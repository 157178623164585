import { HttpErrorResponse } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { Page } from 'addiction-components';
import { Contest, STATE_STATUS } from 'src/app/shared/models';
import { ContestError } from './contest.state';

export const updatePage = createAction('[Contests] Update Page', props<{ updatedPage: Partial<Page> }>());
export const deleteContest = createAction('[Contests] Delete Contests', props<{ uuid: string }>());

export const setForceRefreshContests = createAction('[Contests] Set Force Refresh');
export const setContests = createAction('[Contests] Set Contests');
export const setContestsSuccess = createAction(
	'[Contests] Set Contests Success',
	props<{ data: { totalContestsCount: number; pages: number[]; contests: Contest[][] } }>()
);
export const setContestsError = createAction('[Contests] Set Contests Error', props<{ error: HttpErrorResponse }>());
export const setDeleteContests = createAction('[Contests] Set Delete Contests', props<{ contest: Contest }>());
export const setDeleteContestsSuccess = createAction('[Contests] Set Delete Contests Success');
export const setDeleteContestsError = createAction('[Contests] Set Delete Contests Error', props<{ error: HttpErrorResponse }>());
export const setStatus = createAction('[Contests] Set status', props<{ status: STATE_STATUS }>());
export const setPages = createAction('[Contests] Set pages', props<{ pages: number[] }>());
export const setSort = createAction('[Contests] Set sort', props<{ sort: Sort }>());
export const setGridSearch = createAction('[Contests] Set search', props<{ search: string | null }>());
export const setCachedPages = createAction('[Contests] Set cached pages', props<{ pages: number[] }>());
export const resetCache = createAction('[Contests] reset cache');

// Error
export const error = createAction('[Contests] Error', props<{ error: ContestError }>());
