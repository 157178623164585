import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToLocalizedValuePipe } from 'addiction-components';
import { EMPTY, catchError, filter, of, switchMap } from 'rxjs';
import { combineLatestWith, debounce, distinctUntilChanged, first, map, tap } from 'rxjs/operators';
import { BookableProductActions } from 'src/app/core/state/app.actions';
import { STATE_STATUS } from 'src/app/shared/models';
import { BookableProductService } from '../services/bookable-product.service';
import {
	deleteBookableProduct,
	setBookableProductsSuccess,
	setDeleteBookableProductsError,
	setDeleteBookableProductsSuccess,
} from './bookable-product.actions';
import {
	selectBookableProductsFilters,
	selectCachedPages,
	selectGridSearch,
	selectPagedData,
	selectPages,
	selectSort,
	selectStatus,
} from './bookable-product.selectors';

@Injectable()
export class BookableProductEffect {
	private store = inject(Store);
	private actions$ = inject(Actions);
	private bookableProductService = inject(BookableProductService);
	private translateService = inject(TranslateService);
	private localizablePipe = inject(ToLocalizedValuePipe);

	fetchProducts$ = createEffect(() =>
		this.actions$.pipe(
			//quando selezioni una categoria o fai una ricerca, viene triggherato il DamFilter, quindi ci registriamo ad
			//un suo eventuale successo per richiamare gli assets
			ofType(BookableProductActions.setPages, BookableProductActions.setForceRefreshBookableProducts),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(selectBookableProductsFilters),
				this.store.select(selectCachedPages),
			]),
			debounce(() => this.store.select(selectStatus).pipe(filter((stat) => !stat || stat !== 'loading'))),
			//non voglio caricare se non ho le pagine pronte
			filter(([, pages]) => !!pages?.length),
			//non voglio caricare se i dati delle azioni sono gli stessi

			tap(() => this.store.dispatch(BookableProductActions.setStatus({ status: STATE_STATUS.LOADING }))),
			switchMap(([, pages, sort, gridSearch, filters, cachedPages]) => {
				const actualPages = pages.filter((p) => !cachedPages.includes(p));
				if (!actualPages.length) return EMPTY;

				return this.bookableProductService.fetchProducts(actualPages, sort, this.translateService.currentLang, gridSearch, filters).pipe(
					combineLatestWith(this.store.select(selectPagedData)),
					first(),
					map(([data, startingResult]) => {
						startingResult.pages = cachedPages;

						const result = data.reduce((acc, item) => {
							if (item.paginationInfo) {
								acc.pages.push(item.paginationInfo.pageNumber);
								acc.totalBookableProductsCount = item.paginationInfo.totalElements ?? 0;
								if (item.result) {
									acc.bookableProducts[item.paginationInfo.pageNumber] = item.result.map((product) => ({
										...product,
										features: product.featureValueList?.map((f) => (typeof f === 'string' ? '' : this.localizablePipe.transform(f.label || f.name))),
									}));
								}
							}
							return acc;
						}, structuredClone(startingResult));

						return BookableProductActions.setBookableProductsSuccess({ data: result });
					}),
					catchError((error: HttpErrorResponse) => {
						console.error(error);
						return of(BookableProductActions.setBookableProductsError({ error }));
					})
				);
			})
		)
	);

	resetCache$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				BookableProductActions.setGridSearch,
				BookableProductActions.setSort,
				BookableProductActions.updateFilters,
				BookableProductActions.addFeatureValueToFilters,
				BookableProductActions.removeFeatureValueFromFilters
			),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(selectBookableProductsFilters),
				this.store.select(selectCachedPages),
			]),
			// //non voglio caricare se i dati delle azioni sono gli stessi
			distinctUntilChanged(
				(
					[, pagesPrev, sortPrev, gridSearchPrev, { featureValue, active, structureUUID }],
					[
						,
						pagesCurr,
						sortCurr,
						gridSearchCurr,
						{ featureValue: featureValueCurr, active: activeCurr, structureUUID: structureUUIDCurr },
						cachedPages,
					]
				) => {
					return (
						pagesPrev.length === pagesCurr.length &&
						pagesPrev.every((f) => pagesCurr.includes(f)) &&
						sortCurr?.active === sortPrev?.active &&
						sortCurr?.direction === sortPrev?.direction &&
						gridSearchCurr === gridSearchPrev &&
						featureValueCurr?.length === featureValue?.length &&
						activeCurr === active &&
						structureUUIDCurr === structureUUID &&
						pagesPrev.filter((f) => !cachedPages.includes(f)).length === 0
					);
				}
			),
			map(() => BookableProductActions.resetCache())
		)
	);

	deleteBProduct$ = createEffect(() =>
		this.actions$.pipe(
			ofType(deleteBookableProduct),
			switchMap(({ uuid }) => {
				return this.bookableProductService.deleteProduct(uuid).pipe(
					map(() => {
						return setDeleteBookableProductsSuccess();
					}),
					catchError((error: HttpErrorResponse) => {
						return of(setDeleteBookableProductsError({ error }));
					})
				);
			})
		)
	);

	deleteBookableProduct$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				BookableProductActions.setDeleteBookableProductsSuccess,
				BookableProductActions.setDeleteBookableProductsError,
			),
			map(() => BookableProductActions.resetCache())
		)
	);

	bookableProductSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setBookableProductsSuccess),
			map(({ data: { pages } }) => BookableProductActions.setCachedPages({ pages }))
		)
	);

	forceRefreshTable$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BookableProductActions.resetCache),
			map(() => BookableProductActions.setForceRefreshBookableProducts())
		)
	);
	constructor() {}
}
