export const createDeepTypeGuard =
	<T extends object, P extends keyof T | string = keyof T | string, K extends T = T>(
		prop: P,
		// il type è una stringa o una classe
		type?: string | (new (...args: unknown[]) => unknown)
	) =>
	(obj?: object | null): obj is K => {
		if (!obj) return false;
		if (typeof prop === 'string') {
			if (prop.includes('.')) {
				//assuming property is a nested property, so repeating the type guard to the nested object
				const [first, ...rest] = prop.split('.');
				if (first in obj) {
					const ref: Record<string, unknown> = structuredClone({ ...obj });
					const value = ref[first];
					if (typeof value === 'object' && rest) {
						if (Array.isArray(value)) {
							return value.every((v) => createDeepTypeGuard<Record<string, unknown>>(rest.join('.'), type)(v));
						}
						return createDeepTypeGuard<Record<string, unknown>>(rest.join('.'), type)(value);
					}
				}
			}
			//checks whether the property exists in the object and if the type matches
			if (!type || typeof type === 'string') {
				return prop in obj && (!type || typeof dataleanAssert<Record<string, unknown>>(obj)[prop] === type);
			}
			return prop in obj && (!type || dataleanAssert<Record<string, unknown>>(obj)[prop] instanceof type);
		}
		throw new Error('Assertion failed');
	};

//takes an unknown item and tries to assert that it is of type T.
export const dataleanAssert = <T>(item: unknown): T => {
	if (genericTypeGuard<T>(item)) {
		return item;
	}
	throw new Error('Assertion failed');
};

//checks if the input item is not undefined or null.
export const genericTypeGuard = <T>(item: unknown): item is T => item !== undefined && item !== null;
