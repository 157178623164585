export interface WidgetAssetCount {
	fileCount: number;
	folderCount: number;
	imageCount: number;
	pdfCount: number;
	rawCount: number;
	size: number;
	videoCount: number;
}
export interface WidgetWithOnlyCount {
	count: number ;
}
