import { Directive, ViewContainerRef, inject } from '@angular/core';

@Directive({
	selector: '[modalTarget]',
  exportAs: 'modalTarget',
	standalone: true,
})
export class ModalTargetDirective {
	public viewContainerRef = inject(ViewContainerRef);

	constructor() {}
}
