import { Asset, BaseLocalizedValues, DataleanEntity, LocalizableField, ObjectValuesType } from 'addiction-components';
import { v4 } from 'uuid';
import { Survey } from './survey';

export const QuizQuestionType = {
	SINGLE_CHOICE: 'singleChoice',
	MULTIPLE_CHOICE: 'multipleChoice',
} as const;

export type QuizQuestionType = ObjectValuesType<typeof QuizQuestionType>;

export class BEQuiz extends DataleanEntity {
	public score: number = 0;
	public questionList?: QuizQuestion[] = [];
	override localizedValues?: QuizLocalizedValues[] = [];

	constructor() {
		super();
	}
}

export class Quiz implements Omit<Survey, 'communityUUID' | 'questionList'> {
	public locale?: string;
	public uuid: string = v4();
	public score: number = 0;
	public image?: Asset;
	public questionList?: QuizQuestion[] = [];

	constructor(public name: string) {}
}

export interface QuizLocalizedValues extends BaseLocalizedValues {
	name: string;
}

export class QuizQuestion {
	public uuid: string = v4();
	public score: number = 0;

	constructor(
		public order: number,
		public required: boolean,
		public type: QuizQuestionType,
		public text: LocalizableField,
		public domainOfAnswers: { id: string; text: LocalizableField; isCorrect: boolean }[] = [],
		public answerList: { id: string; text: LocalizableField; isCorrect: boolean }[] = []
	) {}
}

export type QuizForm = Omit<Quiz, 'questionList'> & {
	questionList?: Array<Omit<QuizQuestion, 'text' | 'domainOfAnswers'> & { text: string; domainOfAnswers?: Array<{ id: string; text: string }> }>;
};
