import { ObjectValuesType, EntityStatuses, DataleanEntity } from 'addiction-components';

export class AvailabilityRule extends DataleanEntity {
	name?: string;
	type: AvailabilityRuleType = AvailabilityRuleType.GENERIC;
	isActive = true;
	dates?: TimeRange[] = [];
	availabilityOptions: AvailabilityOption[] = [];
}

export interface AvailabilityOption {
	day: Days | 'ALL';
	type: 'CAN_RESERVE' | 'CAN_NOT_RESERVE';
	hourControl: 'FULL_DAY' | 'CUSTOM';
	workshifts?: TimeRange[];
}

export interface TimeRange {
	from: Date | string;
	to: Date | string;
}

export type BookableStatus = ObjectValuesType<Pick<typeof EntityStatuses, 'ACTIVE' | 'DISABLED'>>;

export const AvailabilityRuleType = {
	SPECIFIC: 'specific',
	GENERIC: 'generic',
} as const;

export type AvailabilityRuleType = ObjectValuesType<typeof AvailabilityRuleType>;

export const Days = {
	SUNDAY: 'sunday',
	MONDAY: 'monday',
	TUESDAY: 'tuesday',
	WEDNESDAY: 'wednesday',
	THURSDAY: 'thursday',
	FRIDAY: 'friday',
	SATURDAY: 'saturday',
} as const;

export type Days = ObjectValuesType<typeof Days>;

export const UnitModeArray: UnitMode[] = ['unique', 'multiple'];

export const UnitMode = {
	UNIQUE: 'unique',
	MULTIPLE: 'multiple',
} as const;

export type UnitMode = ObjectValuesType<typeof UnitMode>;

export const UnitFormatArray: UnitFormat[] = ['minutes', 'hours', 'days', 'months'];

export const UnitFormat = {
	MINUTE: 'minutes',
	HOUR: 'hours',
	DAY: 'days',
	MONTH: 'months',
} as const;

export type UnitFormat = ObjectValuesType<typeof UnitFormat>;
