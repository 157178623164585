import { Component } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, skip } from 'rxjs/operators';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class ObservableInput<T = any> {
	constructor(private readonly _subject: Subject<T>, private readonly _value: () => T) {}

	onChanges() {
		this._subject.next(this._value());
	}
}

export class ObservableInputs<TComp = Component> {
	private readonly _inputs: ObservableInput[] = [];

	constructor(private component: TComp) {}

	onChanges() {
		for (const input of this._inputs) input.onChanges();
	}

	observe<T>(value: () => T, options?: Partial<ObservableInputConfig>): Observable<T> {
		const { startWithDefault } = Object.assign(structuredClone(defaultOptions), structuredClone(options ?? {}));

		const subject: Subject<T> = new BehaviorSubject<T>(value());
		const observableInput = new ObservableInput(subject, value);
		this._inputs.push(observableInput);

		let obs$ = subject.asObservable();
		if (!startWithDefault) obs$ = obs$.pipe(skip(1));

		return obs$.pipe(distinctUntilChanged());
	}
}

export interface ObservableInputConfig {
	startWithDefault: boolean;
}

const defaultOptions: ObservableInputConfig = {
	startWithDefault: true,
};
