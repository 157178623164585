import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService, TokenResponse } from 'angular-oauth2-oidc';
import { ToastrService } from 'ngx-toastr';
import {
	BehaviorSubject,
	Observable,
	Subject,
	Subscription,
	catchError,
	combineLatest,
	exhaustMap,
	filter,
	first,
	from,
	map,
	merge,
	of,
	share,
	switchMap,
	take,
	tap,
} from 'rxjs';
import { UserActions } from 'src/app/core/state/app.actions';
import { AuthenticationToken } from '../../models/auth/authentication-token.model';
import { authConfig } from './auth.config';

@Injectable({ providedIn: 'root' })
export class AuthService {
	networkStatus: boolean = false;
	private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
	public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();
	private isDoneLoadingSubject$ = new BehaviorSubject<boolean>(false);
	public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();
	public loaded$ = this.isDoneLoading$.pipe(
		filter((done) => done),
		take(1)
	);

	/**
	 * Publishes `true` if and only if (a) all the asynchronous initial
	 * login calls have completed or errorred, and (b) the user ended up
	 * being authenticated.
	 *
	 * In essence, it combines:
	 *
	 * - the latest known state of whether the user is authorized
	 * - whether the ajax calls for initial log in have all been done
	 */
	public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([this.isAuthenticated$, this.isDoneLoading$]).pipe(
		map((values) => values.every((b) => b))
	);

	autoRefreshSubs?: Subscription;
	stopAutomaticRefresh() {
		this.autoRefreshSubs?.unsubscribe();
	}
	startAutomaticRefresh() {
		if (this.autoRefreshSubs) {
			this.stopAutomaticRefresh();
		}
		this.autoRefreshSubs = this.oauthService.events
			.pipe(
				// tap(console.log),
				filter((e) => 'token_expires' === e.type && 'info' in e && e.info === 'access_token'),
				switchMap(() => this.tryToRefreshToken())
			)
			.subscribe(() => {});
	}

	constructor(
		private oauthService: OAuthService,
		private store: Store,
		private router: Router,
		private toast: ToastrService,
		private injector: Injector
	) {
		// configure the service
		this.oauthService.configure(authConfig);

		// inform the store when authentication has been initialized
		this.loaded$.subscribe(() => this.store.dispatch(UserActions.authInitialized()));

		// This is tricky, as it might cause race conditions (where access_token is set in another
		// tab before everything is said and done there.
		// TODO: Improve this setup. See: https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards/issues/2
		window.addEventListener('storage', (event) => {
			// The `key` is `null` if the event was caused by `.clear()`
			if (event.key !== 'access_token' && event.key !== null) {
				return;
			}

			console.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');
			this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

			if (!this.oauthService.hasValidAccessToken()) {
				this.navigateToLoginPage();
			}
		});

		//resto in ascolto sull'evento che viene emesso da un errore del logout
		this.oauthService.events.pipe(filter((e) => ['token_revoke_error'].includes(e.type))).subscribe(() => {
			//utilizzo questa funzione per poter successivamente iniettare il TranslateService senza creare problemi di circular dependency
			this.showErrorLoginToast('LOGOUT.ERROR');
		});

		this.oauthService.events.subscribe(() => {
			this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
		});

		this.oauthService.events.pipe(filter((e) => ['token_received'].includes(e.type))).subscribe(() => this.oauthService.loadUserProfile());

		this.oauthService.events
			.pipe(filter((e) => ['session_terminated', 'session_error'].includes(e.type)))
			.subscribe(() => this.navigateToLoginPage());

		this.startAutomaticRefresh();
	}

	private _exchangeToken$ = new Subject<boolean>();

	private _refreshToken$ = this._exchangeToken$.pipe(
		filter((x) => !!x),
		exhaustMap(() => from(this.oauthService.refreshToken())),
		share()
	);

	tryToRefreshToken(): Observable<TokenResponse | null> {
		this.isDoneLoadingSubject$.next(false);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const startToken$: Observable<any> = of(true).pipe(
			tap((x) => this._exchangeToken$.next(x)),
			filter(() => false)
		);
		return merge(this._refreshToken$.pipe(first()), startToken$).pipe(
			catchError(() => {
				this.isDoneLoadingSubject$.next(true);
				return of(null);
			})
		);
	}

	public runInitialLoginSequence(): Promise<void> {
		if (location.hash) {
			// console.log('Encountered hash fragment, plotting as table...');
			console.table(
				location.hash
					.substr(1)
					.split('&')
					.map((kvp) => kvp.split('='))
			);
		}

		// 0. LOAD CONFIG:
		// First we have to check to see how the IdServer is
		// currently configured:
		return (
			this.oauthService
				.loadDiscoveryDocument()
				// 1. HASH LOGIN:
				// Try to log in via hash fragment after redirect back
				// from IdServer from initImplicitFlow:
				.then(() => this.oauthService.tryLogin())

				.then(() => {
					if (this.oauthService.hasValidAccessToken()) {
						return Promise.resolve();
					}

					// 2. SILENT LOGIN:
					// Try to log in via a refresh because then we can prevent
					// needing to redirect the user:
					return this.oauthService
						.refreshToken()
						.then(() => Promise.resolve())
						.catch((result) => {
							// Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
							// Only the ones where it's reasonably sure that sending the
							// user to the IdServer will help.
							const errorResponsesRequiringUserInteraction = [
								'interaction_required',
								'login_required',
								'account_selection_required',
								'consent_required',
							];

							if (result && result.reason && errorResponsesRequiringUserInteraction.indexOf(result.reason.error) >= 0) {
								// 3. ASK FOR LOGIN:
								// At this point we know for sure that we have to ask the
								// user to log in, so we redirect them to the IdServer to
								// enter credentials.
								//
								// Enable this to ALWAYS force a user to login.
								// this.login();
								//
								// Instead, we'll now do this:
								console.warn('User interaction is needed to log in, we will wait for the user to manually log in.');
								return Promise.resolve();
							}

							// We can't handle the truth, just pass on the problem to the
							// next handler.
							return Promise.reject(result);
						});
				})

				.then(() => {
					// WORKFLOW DI AUTH COMPLETATO
					const token = this.getAuthToken();
					const uuid = token.getTokenParam<string>('userUUID');
					if (uuid) this.store.dispatch(UserActions.fetchUserData({ uuid }));
					else console.error(`Cannot find uuid on token `, token);

					this.isDoneLoadingSubject$.next(true);

					// Check for the strings 'undefined' and 'null' just to be sure. Our current
					// login(...) should never have this, but in case someone ever calls
					// initImplicitFlow(undefined | null) this could happen.
					if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
						let stateUrl = this.oauthService.state;
						if (stateUrl.startsWith('/') === false) {
							stateUrl = decodeURIComponent(stateUrl);
						}
						// console.log(`There was state of ${this.oauthService.state}, so we are sending you to: ${stateUrl}`);
						this.router.navigateByUrl(stateUrl !== '/login' ? stateUrl : '/');
					}
				})
				.catch(() => {
					this.isDoneLoadingSubject$.next(true);
				})
		);
	}

	public navigateToLoginPage() {
		this.router.navigateByUrl('/login');
	}

	public login(targetUrl?: string) {
		// Note: before version 9.1.0 of the library you needed to
		// call encodeURIComponent on the argument to the method.
		this.oauthService.initLoginFlow(targetUrl || this.router.url);
	}

	public logout() {
		//faccio il logout
		this.oauthService.logOut();
		//controllo se l'utente è online e se ha il token autorizzato, in quel caso
		//lo ridireziono al login
		if (navigator.onLine && this.isAuthorized()) this.navigateToLoginPage();
		//altrimenti se l'utente è offline gli mostro un messaggio
		if (!navigator.onLine) this.showErrorLoginToast('OFFLINE');
	}

	public isAuthorized() {
		return this.oauthService.hasValidAccessToken();
	}

	public getAuthToken() {
		return new AuthenticationToken(this.idToken);
	}

	public get accessToken() {
		return this.oauthService.getAccessToken();
	}

	public get refreshToken() {
		return this.oauthService.getRefreshToken();
	}

	public get identityClaims() {
		return this.oauthService.getIdentityClaims();
	}

	public get idToken() {
		return this.oauthService.getIdToken();
	}

	public get logoutUrl() {
		return this.oauthService.logoutUrl;
	}

	public showErrorLoginToast(messsage: string) {
		//innietto il transalteService
		const translate = this.injector.get(TranslateService);
		this.toast.error(translate.instant(messsage));
	}
}
