import { on } from '@ngrx/store';
import { Collection } from 'addiction-components';
import { createContextReducer } from 'context-store';
import { STATE_STATUS } from 'src/app/shared/models/state-status.enum';
import * as CollectionActions from './collections.actions';

export const COLLECTIONS_TAG = 'collections';

export interface CollectionState {
	gridSearch: string | undefined;
	//every asset is an array of collections where the first index represents the page
	data: { totalCollectionsCount: number; collections: Collection[][] };
	pages: number[];
	fetchCollectionStatus: STATE_STATUS;
}

const initialState: CollectionState = {
	fetchCollectionStatus: STATE_STATUS.UNKNOWN,
	gridSearch: undefined,
	pages: [0],
	data: { totalCollectionsCount: 0, collections: [] },
};

export const CollectionReducer = createContextReducer(
	initialState,
	on(CollectionActions.setCollectionsPages, (state: CollectionState, { pages }) => ({
		...state,
		// status: STATE_STATUS.LOADING,
		pages,
	})),
	on(CollectionActions.setCollectionsSuccess, (state: CollectionState, { collections, pages, totalCollectionsCount }) => ({
		...state,
		fetchCollectionStatus: STATE_STATUS.READY,
		data: {
			collections,
			totalCollectionsCount,
		},
		pages,
	})),
	on(CollectionActions.setCollectionsError, (state: CollectionState) => ({
		...state,
		fetchCollectionStatus: STATE_STATUS.ERROR,
	})),
	on(CollectionActions.setCollectionsSearch, (state: CollectionState, { search }) => ({
		...state,
		gridSearch: search,
	}))
);
