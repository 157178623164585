import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bytes'
})
/** Trasforma una dimensione in byte nell'unità di misura più vicina (leggibile)
 * TODO: supportare anche un input non in byte
 */
export class BytesPipe implements PipeTransform {

  transform(sizeInBytes: number | undefined, decimals:number=0): unknown {
    if (!sizeInBytes && sizeInBytes !== 0)
      return undefined;

    if (sizeInBytes === 0) 
      return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(sizeInBytes) / Math.log(k));

    return parseFloat((sizeInBytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

}
