import { ObjectValuesType } from "./object-values-type";

export const EntityStatuses = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    DELETED: 'DELETED',
    DISABLED: 'DISABLED',
    PUBLISHED: 'PUBLISHED',
    DRAFT: 'DRAFT',
    ARCHIVED: 'ARCHIVED',
} as const;

export type EntityStatuses = ObjectValuesType<typeof EntityStatuses>;
