import { Clipboard, ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, forwardRef, inject } from '@angular/core';
import {
	AbstractControl,
	ControlValueAccessor,
	FormControl,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
	Validator,
	Validators,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { Subject, map, shareReplay, takeUntil } from 'rxjs';
import { SharedModule } from '../../modules/shared.module';

@Component({
	selector: 'addiction-input',
	templateUrl: './input.component.html',
	standalone: true,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => InputComponent),
		},
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: forwardRef(() => InputComponent),
		},
	],
	imports: [CommonModule, SharedModule, MatIconModule, ClipboardModule],
})
/** Componenete generico per gestire i form-input con varie opzioni di visualizzazione */
export class InputComponent implements ControlValueAccessor, Validator, OnInit, OnDestroy {
	@Input() type: 'text' | 'password' | 'email' | 'number' | 'urlTitle' = 'text';
	@Input() name?: string;
	@Input() label: string = '';
	@Input() placeholder: string = '';
	@Input() icon: string | null = null;
	@Input() iconPosition: 'prepend' | 'append' = 'prepend';
	@Input() requiredIcon?: boolean = false;
	@Input() copy: boolean = false;
	@Input() readonly: boolean = false;
	@Input() disabled: boolean = false;
	@Input() value?: string | number;
	@Input() min?: number;

	@Output() valueChange = new EventEmitter<string | number>();
	// eslint-disable-next-line @angular-eslint/no-output-native
	@Output() click = new EventEmitter<Event>();
	@Input() keydown = new EventEmitter<KeyboardEvent>();

	formControl = new FormControl();

	private clipboard = inject(Clipboard);
	private destroy$: Subject<void> = new Subject<void>();
	private _valueChange$ = this.formControl.valueChanges.pipe(
		map((value: string) => {
			if (this.type === 'urlTitle' && value) {
				const val = value.replace(/(\!|\*|\'|\(|\)|\;|\:|\@|\&|\=|\$|\,|\/|\?|\%|\#|\[|\]|\s)/gi, '');
				this.formControl.patchValue(val, { emitEvent: false });
				return val;
			}
			if (this.type === 'number' && value) {
				return +value;
			}
			return value;
		}),
		shareReplay({ refCount: true, bufferSize: 1 }),
		takeUntil(this.destroy$)
	);

	get hasIcon() {
		return !!this.icon;
	}

	constructor() {}

	ngOnDestroy(): void {
		this.destroy$.next();
	}

	ngOnInit(): void {
		if (this.value) {
			this.formControl.patchValue(this.value);
		}
		if (this.disabled) this.formControl.disable();
		if (typeof this.min === 'number') this.formControl.addValidators(Validators.min(this.min));

		this._valueChange$.subscribe({
			next: (value) => this.valueChange.emit(value),
		});
	}

	public onTouched: () => void = () => {};

	onValidatorChange = () => {};

	registerOnValidatorChange(fn: () => void): void {
		this.onValidatorChange = fn;
	}

	validate(c: AbstractControl): ValidationErrors | null {
		if (!this.requiredIcon && c.hasValidator(Validators.required)) {
			this.requiredIcon = true;
		}
		return c.invalid || this.formControl.invalid ? { ...c.errors, ...this.formControl.errors } : null;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	writeValue(obj: string | undefined): void {
		this.formControl.patchValue(obj);
	}

	registerOnChange(fn: (val: string | number | undefined) => void): void {
		this._valueChange$.subscribe(fn);
	}

	setDisabledState(disabled: boolean) {
		if (disabled) this.formControl.disable({ emitEvent: false });
		else this.formControl.enable({ emitEvent: false });
	}

	copyToClipboard() {
		if (!!this.formControl.value) this.clipboard.copy(this.formControl.value.toString());
	}
}
