<div class="form-field {{ formControl.status | lowercase }}">
	<label *ngIf="label">{{ label }} <span *ngIf="requiredIcon">*</span></label>
	<div class="form-input-container {{ iconPosition }} {{ type }}">
		<mat-icon class="form-field-icon" *ngIf="hasIcon">{{ icon }}</mat-icon>
		<input
			[type]="type === 'urlTitle' ? 'text' : type"
			[readonly]="readonly"
			[name]="name"
			[placeholder]="placeholder"
			[formControl]="formControl"
			[autocomplete]="type === 'password' ? 'new-password' : 'off'"
			(click)="click.emit($event)"
			(keydown)="keydown.emit($event)"
			(mousedown)="$event.stopPropagation()" />
		<mat-icon class="append-icon" *ngIf="copy && formControl.value" (click)="copyToClipboard()">content_copy</mat-icon>
	</div>
</div>
