export interface ChartData {
	data: { x: string; y: number }[];
	label: string;
	backgroundColor: string;
	borderColor: string;
  pointBackgroundColor: string;
  pointBorderdColor: string;
	pointRadius: number;
	pointStyle: string;
	lineTension: number;
}
