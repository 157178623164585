/* eslint-disable @ngrx/prefix-selectors-with-select */
import { Selector, createSelector, MemoizedSelector, DefaultProjectorFn } from "@ngrx/store";
import { ContextState } from "../context-state.interface";
import { NGRX_CONTEXT_SELECTOR_META } from "../meta";

type InnerState<G> = G extends ContextState<infer I> ? I : never;
type ContextSelector<TIn, TOut> = (ctx: string) => Selector<TIn, TOut>


/* OVERLOADING FUNZIONI (grazie javascript...) */

export function createContextSelector<CtxState extends ContextState<InState>, S2, S3, S4, S5, S6, S7, S8, Result, InState = InnerState<CtxState>>(
    featureSel: Selector<object, CtxState>,
    s2: ContextSelector<CtxState, S2>,
    s3: ContextSelector<CtxState, S3>,
    s4: ContextSelector<CtxState, S4>,
    s5: ContextSelector<CtxState, S5>,
    s6: ContextSelector<CtxState, S6>,
    s7: ContextSelector<CtxState, S7>,
    s8: ContextSelector<CtxState, S8>,
    projector: (state: InState, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7, s8: S8) => Result
): (ctx: string) => MemoizedSelector<object, Result, DefaultProjectorFn<Result>>;
export function createContextSelector<CtxState extends ContextState<InState>, S2, S3, S4, S5, S6, S7, Result, InState = InnerState<CtxState>>(
    featureSel: Selector<object, CtxState>,
    s2: ContextSelector<CtxState, S4>,
    s3: ContextSelector<CtxState, S3>,
    s4: ContextSelector<CtxState, S4>,
    s5: ContextSelector<CtxState, S5>,
    s6: ContextSelector<CtxState, S6>,
    s7: ContextSelector<CtxState, S7>,
    projector: (state: InState, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7) => Result
): (ctx: string) => MemoizedSelector<object, Result, DefaultProjectorFn<Result>>;
export function createContextSelector<CtxState extends ContextState<InState>, S2, S3, S4, S5, S6, Result, InState = InnerState<CtxState>>(
    featureSel: Selector<object, CtxState>,
    s2: ContextSelector<CtxState, S2>,
    s3: ContextSelector<CtxState, S3>,
    s4: ContextSelector<CtxState, S4>,
    s5: ContextSelector<CtxState, S5>,
    s6: ContextSelector<CtxState, S6>,
    projector: (state: InState, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6) => Result
): (ctx: string) => MemoizedSelector<object, Result, DefaultProjectorFn<Result>>;
export function createContextSelector<CtxState extends ContextState<InState>, S2, S3, S4, S5, Result, InState = InnerState<CtxState>>(
    featureSel: Selector<object, CtxState>,
    s2: ContextSelector<CtxState, S2>,
    s3: ContextSelector<CtxState, S3>,
    s4: ContextSelector<CtxState, S4>,
    s5: ContextSelector<CtxState, S5>,
    projector: (state: InState, s2: S2, s3: S3, s4: S4, s5: S5) => Result
): (ctx: string) => MemoizedSelector<object, Result, DefaultProjectorFn<Result>>;
export function createContextSelector<CtxState extends ContextState<InState>, S2, S3, S4, Result, InState = InnerState<CtxState>>(
    featureSel: Selector<object, CtxState>,
    s2: ContextSelector<CtxState, S2>,
    s3: ContextSelector<CtxState, S3>,
    s4: ContextSelector<CtxState, S4>,
    projector: (state: InState, s2: S2, s3: S3, s4: S4) => Result
): (ctx: string) => MemoizedSelector<object, Result, DefaultProjectorFn<Result>>;
export function createContextSelector<CtxState extends ContextState<InState>, S2, S3, Result, InState = InnerState<CtxState>>(
    featureSel: Selector<object, CtxState>,
    s2: ContextSelector<CtxState, S2>,
    s3: ContextSelector<CtxState, S3>,
    projector: (state: InState, s2: S2, s3: S3) => Result
): (ctx: string) => MemoizedSelector<object, Result, DefaultProjectorFn<Result>>;
export function createContextSelector<CtxState extends ContextState<InState>, S2, Result, InState = InnerState<CtxState>>(
    featureSel: Selector<object, CtxState>,
    s2: ContextSelector<CtxState, S2>,
    projector: (state: InState, s2: S2) => Result
): (ctx: string) => MemoizedSelector<object, Result, DefaultProjectorFn<Result>>;
export function createContextSelector<CtxState extends ContextState<InState>, Result, InState = InnerState<CtxState>>(
    featureSel: Selector<object, CtxState>,
    projector: (state: InState) => Result
): (ctx: string) => MemoizedSelector<object, Result, DefaultProjectorFn<Result>>;

export function createContextSelector<CtxState extends ContextState<InState>, S2, S3, S4, S5, S6, S7, S8, Result, InState = InnerState<CtxState>>(
    featureSel: Selector<CtxState, CtxState>,
    s2?: (ContextSelector<CtxState, S2>) | ((s1: InState, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7, s8: S8) => Result),
    s3?: (ContextSelector<CtxState, S3>) | ((s1: InState, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7, s8: S8) => Result),
    s4?: (ContextSelector<CtxState, S4>) | ((s1: InState, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7, s8: S8) => Result),
    s5?: (ContextSelector<CtxState, S5>) | ((s1: InState, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7, s8: S8) => Result),
    s6?: (ContextSelector<CtxState, S6>) | ((s1: InState, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7, s8: S8) => Result),
    s7?: (ContextSelector<CtxState, S7>) | ((s1: InState, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7, s8: S8) => Result),
    s8?: (ContextSelector<CtxState, S8>) | ((s1: InState, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7, s8: S8) => Result),
    s9?: (s1: InState, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7, s8: S8) => Result
): (ctx: string) => MemoizedSelector<CtxState, Result, DefaultProjectorFn<Result>> {
    // se 🤮 JavaScript 🤮 supportasse l'overload dei parametri, questo SCHIFO non sarebbe necessario...
    
    let result: (ctx: string) => MemoizedSelector<CtxState, Result, DefaultProjectorFn<Result>>;


    if (!s2)
        throw 'E\' necessario specificare la funzione di proiezione';

    if (!s3) {
        // primo overload
        const p = s2 as (s1: InState) => Result;
        result = (ctx: string) => createSelector(featureSel,
            (state) => p(state.contexts[ctx]));
    } else if (!s4) {
        // feature + 1 selector
        const p = s3 as (state: InState, s2: S2) => Result;
        const actualS2 = s2 as ContextSelector<CtxState,S2>;
        result = (ctx: string) => createSelector(featureSel, actualS2(ctx),
            (state, _s2) => p(state.contexts[ctx], _s2));
    } else if (!s5) {
        // feature + 2 selector
        const p = s4 as (state: InState, s2: S2, s3: S3) => Result;
        const actualS2 = s2 as ContextSelector<CtxState,S2>;
        const actualS3 = s3 as ContextSelector<CtxState,S3>;
        result = (ctx: string) => createSelector(featureSel, actualS2(ctx), actualS3(ctx),
            (state, _s2, _s3) => p(state.contexts[ctx], _s2, _s3));
    } else if (!s6) {
        // feature + 3 selector
        const p = s5 as (state: InState, s2: S2, s3: S3, s4: S4) => Result;
        const actualS2 = s2 as ContextSelector<CtxState,S2>;
        const actualS3 = s3 as ContextSelector<CtxState,S3>;
        const actualS4 = s4 as ContextSelector<CtxState,S4>;
        result = (ctx: string) => createSelector(featureSel, actualS2(ctx), actualS3(ctx), actualS4(ctx),
            (state, _s2, _s3, _s4) => p(state.contexts[ctx], _s2, _s3, _s4));
    } else if (!s7) {
        // feature + 4 selector
        const p = s6 as (state: InState, s2: S2, s3: S3, s4: S4, s5: S5) => Result;
        const actualS2 = s2 as ContextSelector<CtxState,S2>;
        const actualS3 = s3 as ContextSelector<CtxState,S3>;
        const actualS4 = s4 as ContextSelector<CtxState,S4>;
        const actualS5 = s5 as ContextSelector<CtxState,S5>;
        result = (ctx: string) => createSelector(featureSel, actualS2(ctx), actualS3(ctx), actualS4(ctx), actualS5(ctx),
            (state, _s2, _s3, _s4, _s5) => p(state.contexts[ctx], _s2, _s3, _s4, _s5));
    } else if (!s8) {
        // feature + 5 selector
        const p = s7 as (state: InState, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6) => Result;
        const actualS2 = s2 as ContextSelector<CtxState,S2>;
        const actualS3 = s3 as ContextSelector<CtxState,S3>;
        const actualS4 = s4 as ContextSelector<CtxState,S4>;
        const actualS5 = s5 as ContextSelector<CtxState,S5>;
        const actualS6 = s6 as ContextSelector<CtxState,S6>;
        result = (ctx: string) => createSelector(featureSel, actualS2(ctx), actualS3(ctx), actualS4(ctx), actualS5(ctx), actualS6(ctx),
            (state, _s2, _s3, _s4, _s5, _s6) => p(state.contexts[ctx], _s2, _s3, _s4, _s5, _s6));
    } else if (!s9) {
        // feature + 6 selector
        const p = s8 as (state: InState, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7) => Result;
        const actualS2 = s2 as ContextSelector<CtxState,S2>;
        const actualS3 = s3 as ContextSelector<CtxState,S3>;
        const actualS4 = s4 as ContextSelector<CtxState,S4>;
        const actualS5 = s5 as ContextSelector<CtxState,S5>;
        const actualS6 = s6 as ContextSelector<CtxState,S6>;
        const actualS7 = s7 as ContextSelector<CtxState,S7>;
        result = (ctx: string) => createSelector(featureSel, actualS2(ctx), actualS3(ctx), actualS4(ctx), actualS5(ctx), actualS6(ctx), actualS7(ctx),
            (state, _s2, _s3, _s4, _s5, _s6, _s7) => p(state.contexts[ctx], _s2, _s3, _s4, _s5, _s6, _s7));
    } else {
        // feature + 7 selector
        const p = s8 as (state: InState, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7, s8: S8) => Result;
        const actualS2 = s2 as ContextSelector<CtxState,S2>;
        const actualS3 = s3 as ContextSelector<CtxState,S3>;
        const actualS4 = s4 as ContextSelector<CtxState,S4>;
        const actualS5 = s5 as ContextSelector<CtxState,S5>;
        const actualS6 = s6 as ContextSelector<CtxState,S6>;
        const actualS7 = s7 as ContextSelector<CtxState,S7>;
        const actualS8 = s8 as ContextSelector<CtxState,S8>;
        result = (ctx: string) => createSelector(featureSel, actualS2(ctx), actualS3(ctx), actualS4(ctx), actualS5(ctx), actualS6(ctx), actualS7(ctx), actualS8(ctx),
            (state, _s2, _s3, _s4, _s5, _s6, _s7, _s8) => p(state.contexts[ctx], _s2, _s3, _s4, _s5, _s6, _s7, _s8));
    }

    // "tagga" la funzione così che possa essere riconosciuta in caso di polimorfismo
    Object.defineProperty(result, NGRX_CONTEXT_SELECTOR_META, { value: true });

    return result;
}
