import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'as',
	standalone: true,
})
export class AsPipe implements PipeTransform {
	transform<T>(input?: unknown, baseItem?: T): T {
		return input as T;
	}
}
