import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, take } from 'rxjs';
import { UserSelector } from 'src/app/core/state/app.selectors';
import { Permission } from 'src/app/shared/models';

@Injectable({ providedIn: 'root' })
export class PermissionViewGuard {
	constructor(private store: Store, private router: Router) {}

	canActivate() {
		return this.store.select(UserSelector.selectPermissions).pipe(
			filter((data) => data.length > 0),
			take(1),
			map((data) => {
				if (data.some((permission) => permission.name === Permission.READ_DASHBOARD)) {
					this.router.navigateByUrl('/dashboard');
				} else if (data.some((permission) => permission.name === Permission.READ_PRODUCTS)) {
					this.router.navigateByUrl('/products');
				} else if (data.some((permission) => permission.name === Permission.READ_MEDIA_LIBRARY)) {
					this.router.navigateByUrl('/media-library');
				} else {
					this.router.navigateByUrl('/profile');
				}
				return false;
			})
		);
	}
}
