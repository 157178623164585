import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: 'datalean-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss'],
	standalone: true,
	imports: [CommonModule, MatListModule, TranslateModule],
})
export class ListComponent<T extends object, K extends keyof T & string = keyof T & string> {
	@Input() list: Array<T> = [];
	@Input() translationString: string = '';
	@Input() title: string = '';
	//proprietà dell'oggetto che si vogliono visualizzare
	@Input() properties: Array<K> = [];
	//proprietà dell'oggetto che non si vogliono visualizzare
	@Input() set exludeProperties(value: K[]) {
		//controllo se le stringhe passate sono parte delle keys dell'oggetto passato in list, in quel
		//caso filtro l'oggetto in modo da rimuoverle
		this.properties = Object.keys(this.list[0]).filter((v): v is K => !value.some((vv) => vv === v));
	}

	@Output() deleteItem = new EventEmitter<T>();

	constructor() {}
}
