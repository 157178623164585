import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { DataleanHTTPError } from '../../shared/models/datalean-error-interface';

@Injectable({ providedIn: 'root' })
export class HttpErrorInterceptor implements HttpInterceptor {
	constructor() {}
	intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(req).pipe(
			catchError((resp: { error?: { Errors?: unknown }; status: number }) => {
				let errorCodes: string[] = [];
				if (resp && resp.error && resp.error.Errors) {
					const errorArray = resp.error.Errors;
					if (Array.isArray(errorArray)) {
						errorCodes = errorArray.filter((error) => !!error.message).map((error) => error.message);
					}
				}

				return throwError(() => new DataleanHTTPError(errorCodes, resp, resp.status));
			})
		);
	}
}
