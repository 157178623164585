import 'reflect-metadata';
// 👇 black magic fuckery
const bindDecoKey = Symbol('map-to');
export const Bind = (propName: string) => {
	return Reflect.metadata(bindDecoKey, propName);
};
const getBindedPropName = <T>(instance: T, propName: string) => {
	// eslint-disable-next-line @typescript-eslint/ban-types
	const metadata = Reflect.getMetadata(bindDecoKey, instance as Object, propName);
	return (metadata as string) || propName;
};

// funzione di mapping con supporto a decoratore
export const mapEntity = <T extends { [key: string]: unknown }>(type: { new (): T }, data: Record<string, unknown>) => {
	const instance: { [key: string]: unknown } = new type();

	for (const key of Object.keys(data)) {
		const sourceKey = getBindedPropName(instance, key);
		instance[sourceKey] = data[key];
	}

	return instance;
};
