export enum DataleanErrorType {
  None = 0,
  Notice = 1,
  Warning = 2,
  Error = 3,
  Exception = 4,
}

export interface DataleanErrorItem {
  type: DataleanErrorType;
  message: string | undefined;
  detail: string | undefined | { fieldKey: string, unspecifiedPattern: { [key: string]: number | string } };
  path?: string | undefined;
}

export interface IDataleanErrorManager {
  Errors: DataleanErrorItem[];
  HasException: boolean;
  HasError: boolean;
  HasNotice: boolean;
  HasWarning: boolean;
  IsSuccess: boolean;
  OnlyWarning: boolean;
}


/** Interface standard per la memorizzazione dei messaggi di errore a livello applicativo */
export class DataleanHTTPError {
  constructor(
    /** Codici di errore standard 
     * https://addiction.atlassian.net/wiki/spaces/DOCTEC/pages/2257125381/Codici+Errori+Infrastruttura
     */
    public errorCodes: string[] = [],
    /** Errore originale */
    public source: unknown = null,
    /** Status code http (se applicabile) */
    public httpStatus?:number
  ){}

  /** Restituisce se l'errore contiene uno dei codici di errore standard
   * (https://addiction.atlassian.net/wiki/spaces/DOCTEC/pages/2257125381/Codici+Errori+Infrastruttura)
  */
  public hasError(errorCode:string){
    return this.errorCodes.includes(errorCode);
  }
}


