import { HttpErrorResponse } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { Quiz } from 'src/app/shared/models';
import { STATE_STATUS } from 'src/app/shared/models/state-status.enum';

export const setForceRefreshQuiz = createAction('[Datalean Quiz] Set Force Refresh');
export const setQuiz = createAction('[Datalean Quiz] Set Datalean Quiz');
export const setQuizSuccess = createAction(
	'[Datalean Quiz] Set Datalean Quiz Success',
	props<{ data: { totalQuizCount: number; pages: number[]; quiz: Quiz[][] } }>()
);
export const setQuizError = createAction('[Datalean Quiz] Set Datalean Quiz Error', props<{ error: HttpErrorResponse }>());
export const setDeleteQuiz = createAction('[Datalean Quiz] Set Delete Datalean Quiz', props<{ quiz: Quiz }>());
export const setDeleteQuizSuccess = createAction('[Datalean Quiz] Set Delete Datalean Quiz Success');
export const setDeleteQuizError = createAction('[Datalean Quiz] Set Delete Datalean Quiz Error', props<{ error: HttpErrorResponse }>());
export const setStatus = createAction('[Datalean Quiz] Set status', props<{ status: STATE_STATUS }>());
export const setPages = createAction('[Datalean Quiz] Set pages', props<{ pages: number[] }>());
export const setSort = createAction('[Datalean Quiz] Set sort', props<{ sort: Sort }>());
export const setGridSearch = createAction('[Datalean Quiz] Set search', props<{ search: string | null }>());
export const setCachedPages = createAction('[Datalean Quiz] Set cached pages', props<{ pages: number[] }>());
export const resetCache = createAction('[Datalean Quiz] reset cache');
