import { ObjectValuesType, ThumbnailConfig } from 'addiction-components';

export type ThumbnailConfigList = Omit<ThumbnailConfig, 'method' | 'verticalPosition' | 'horizontalPosition'> & { method: string };

export const MethodType = {
	CROP: 'crop',
	FILL: 'fill',
} as const;
export type MethodType = ObjectValuesType<typeof MethodType>;

export const VerticalPositionType = {
	TOP: 'top',
	CENTER: 'center',
	BOTTOM: 'bottom',
} as const;
export type VerticalPositionType = ObjectValuesType<typeof VerticalPositionType>;

export const HorizontalPositionType = {
	LEFT: 'left',
	CENTER: 'center',
	RIGHT: 'right',
} as const;
export type HorizontalPositionType = ObjectValuesType<typeof HorizontalPositionType>;
