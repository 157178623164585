import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ResourceState } from './resource.state';
import { ResourceReducers } from 'src/app/core/state/app.reducers';

const selectResourcesState = createFeatureSelector<ResourceState>(ResourceReducers.TAG);

export const selectResourcesFilters = createSelector(selectResourcesState, (state) => state?.filters);
export const selectResourcesStructures = createSelector(selectResourcesState, (state) => state?.structures);

export const selectData = createSelector(selectResourcesState, (state) => state.data);
export const selectPages = createSelector(selectResourcesState, (state) => state.pages);
export const selectPagedData = createSelector(selectData, selectPages, (data, pages) => ({ ...data, pages }));
export const selectGridSearch = createSelector(selectResourcesState, (state) => state.gridSearch);
export const selectStatus = createSelector(selectResourcesState, (state) => state.status);
export const selectSort = createSelector(selectResourcesState, (state) => state.sort);
export const selectCachedPages = createSelector(selectResourcesState, (state) => state.cachedPages);
