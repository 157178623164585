import { createReducer, on } from '@ngrx/store';
import { HeaderActions } from 'src/app/core/state/app.actions';
import { HeaderState } from './header.state';

export const TAG = 'header';

const initialState: HeaderState = {
	showCommunitySelect: false,
	showLocales: false,
	showBackButton: false,
  showPlusButton: true,
  showRemoveButton: true,
  showWarningOnBack: true,
	options: { assetWidth: 6, assetdisplayMode: 1 },
};

export const counterReducer = createReducer(
	initialState,
	on(HeaderActions.setShowCommunitySelect, (state: HeaderState, { show }) => ({
		...state,
		showCommunitySelect: show,
	})),
	on(HeaderActions.setShowLocales, (state: HeaderState, { show }) => ({
		...state,
		showLocales: show,
	})),
	on(HeaderActions.setShowBackButton, (state: HeaderState, { showBackButton }) => ({
		...state,
		showBackButton,
	})),
  on(HeaderActions.setShowPlusButton, (state: HeaderState, { showPlusButton }) => ({
		...state,
		showPlusButton,
	})),
  on(HeaderActions.setRemoveButton, (state: HeaderState, { showRemoveButton }) => ({
		...state,
		showRemoveButton,
	})),
	on(HeaderActions.setBackButtonUrl, (state: HeaderState, { backButtonUrl }) => ({
		...state,
		backButtonUrl,
	})),
	on(HeaderActions.deactivateBackButton, (state: HeaderState) => ({
		...state,
		backButtonUrl: undefined,
		showBackButton: false,
	})),
  on(HeaderActions.deactivatePlusButton, (state: HeaderState) => ({
		...state,
		showPlusButton: true,
	})),
  on(HeaderActions.deactivateRemoveButton, (state: HeaderState) => ({
		...state,
		showRemoveButton: true,
	})),
	on(HeaderActions.setMediaLibraryGridViewOptions, (state, { options }) => ({
		...state,
		options: { ...options },
	})),
  on(HeaderActions.setConfig, (_state, { config }) => ({
    ...initialState,
    ...config,
  }) )
);
