import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { TranslateModule } from '@ngx-translate/core';
import { FilterSort } from '../../models';
import { SearchComponent } from '../search/search.component';
import { SelectFieldComponent } from '../select-field/select-field.component';
@Component({
	selector: 'addiction-dam-header',
	standalone: true,
	imports: [
		CommonModule,
		SearchComponent,
		MatSliderModule,
		TranslateModule,
		MatFormFieldModule,
		MatSelectModule,
		MatButtonToggleModule,
		SelectFieldComponent,
		FormsModule,
	],
	templateUrl: './dam-header.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DamHeaderComponent {
	@Input() showBackButton: boolean = false;
	@Input() showMenuButton: boolean = false;
	@Input() showNavigationButton: boolean = false;
	@Input() showFilterButton: boolean = false;
	@Input() showSearchField: boolean = false;
	@Input() showSizeSlider: boolean = false;
	@Input() sliderValue?: number | null;
	@Input() showViewModeButtons: boolean = false;
	@Input() showSortField: boolean = false;
	@Input() showCollectionButton: boolean = false;
	@Input() showDeleteCollectionButton: boolean = false;
	@Input() collectionButtonClass: string | { [klass: string]: boolean } = '';
	@Input() menuButtonClass: string | { [klass: string]: boolean } = '';
	@Input() navigationButtonClass: string | { [klass: string]: boolean } = '';
	@Input() filterButtonClass: string | { [klass: string]: boolean } = '';
	@Input() showAddButton: boolean = false;
	@Input() sortOptions: FilterSort[] = [];
	@Input() viewMode: 'grid' | 'list' = 'grid';
	@Input() searchValue? = '';

	@Output() deleteCollectionButtonClick = new EventEmitter<void>();
	@Output() backButtonClick = new EventEmitter<void>();
	@Output() menuButtonClick = new EventEmitter<void>();
	@Output() filterButtonClick = new EventEmitter<void>();
	@Output() navigationButtonClick = new EventEmitter<void>();
	@Output() search = new EventEmitter<string | null>();
	@Output() sliderValueChange = new EventEmitter<number>();
	@Output() viewModeChange = new EventEmitter<'grid' | 'list'>();
	@Output() sortChange = new EventEmitter<FilterSort>();
	@Output() collectionButtonClick = new EventEmitter<void>();
	@Output() addButtonClick = new EventEmitter<void>();

	onChangeSort($event?: string) {
		this.sortChange.emit(this.sortOptions.find((el) => el.value == $event));
	}
}
