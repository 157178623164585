import { SubjectType } from './user.interface';

export interface Recipient {
	uuid: string;
	type: SubjectType;
	name?: string;
	username?: string;
	order?: number;
	firstName?: string;
	lastName?: string;
}
