<div class="registration-container">
	<div class="close-button-container" *ngIf="showCloseButton">
		<button class="close" (click)="onCloseButtonClicked()"></button>
	</div>

	<div class="form-container" *ngIf="(showRegistrationConfirm | async) === false">
		<form [formGroup]="form" (ngSubmit)="onConfirm()">
			<div class="name-container">
				<div class="input-container">
					<label class="required" position="stacked">{{ 'SIMPLE_REGISTRATION.NAME' | translate }}</label>
					<input class="form-input" formControlName="name" type="text" />
					<span class="error" *ngIf="form.controls.name?.invalid && form.controls.name?.touched">
						{{ 'SIMPLE_REGISTRATION.MANDATORY_FIELD' | translate }}
					</span>
				</div>
			</div>

			<div class="surname-container">
				<div class="input-container">
					<label class="required" position="stacked">{{ 'SIMPLE_REGISTRATION.SURNAME' | translate }}</label>
					<input class="form-input" formControlName="surname" type="text" />
					<span class="error" *ngIf="form.controls.surname?.invalid && form.controls.surname?.touched">
						{{ 'SIMPLE_REGISTRATION.MANDATORY_FIELD' | translate }}
					</span>
				</div>
			</div>

			<div class="username-container">
				<div class="input-container">
					<label class="required" position="stacked">{{ 'SIMPLE_REGISTRATION.USERNAME' | translate }}</label>
					<input class="form-input" formControlName="username" type="text" />
					<span class="error" *ngIf="form.controls.username?.invalid && form.controls.username?.touched">
						{{ 'SIMPLE_REGISTRATION.MANDATORY_FIELD' | translate }}
					</span>
				</div>
			</div>

			<div class="email-container">
				<div class="input-container">
					<label class="required" position="stacked">{{ 'SIMPLE_REGISTRATION.EMAIL' | translate }}</label>
					<input class="form-input" formControlName="email" type="text" />
					<span class="error" *ngIf="form.controls.email?.invalid && form.controls.email?.touched">
						{{ 'SIMPLE_REGISTRATION.MANDATORY_FIELD' | translate }}
					</span>
				</div>
			</div>

			<div class="psw-container">
				<div class="input-container">
					<label class="required" position="stacked">{{ 'SIMPLE_REGISTRATION.PASSWORD' | translate }}</label>
					<input class="form-input" formControlName="psw" type="password" />
				</div>
			</div>

			<ul class="requirements-list" *ngIf="form.controls.psw?.invalid && form.controls.psw?.touched">
				<li *ngFor="let check of passwordChecks">
					<span [class.error]="hasPwPolicyError(check)">{{ 'SIMPLE_REGISTRATION.PASSWORD_POLICY.' + check | translate }}</span>
				</li>
			</ul>

			<div class="confirm-psw-container">
				<div class="input-container">
					<label class="required" position="stacked">{{ 'SIMPLE_REGISTRATION.CONFIRM_PASSWORD' | translate }}</label>
					<input class="form-input" formControlName="confirmPsw" type="password" />
				</div>
			</div>

      <div class="change-password-error" *ngIf="showMismatchError && !showFieldsError">
        {{ 'SIMPLE_REGISTRATION.PASSWORD_MISMATCH' | translate }}
      </div>

			<div class="checkbox-container" *ngFor="let privacyField of privacyFields; let i = index">
        <addiction-checkbox-privacy
        *ngIf="privacyField.extra"
        class="column"
        [privacyConfig]="parseJSON(privacyField.extra)!"
        [formControl]="form.controls[privacyField.name]"></addiction-checkbox-privacy>
			</div>

      <div class="change-password-error" *ngIf="showFieldsError">
        {{ 'SIMPLE_REGISTRATION.FIELDS_ERROR' | translate }}
      </div>

      <div class="buttons-container">
        <!-- [ngClass]="disableButton ? 'disabled':''" -->
        <button class="confirm-button" type="submit">
          <span>{{ 'SIMPLE_REGISTRATION.CONFIRM_BUTTON' | translate }}</span>
        </button>
      </div>
		</form>
	</div>

	<!-- PAGINA REGISTRAZIONE CON SUCCESSO -->
	<div class="registration-result-page" *ngIf="showRegistrationConfirm | async">
		<div class="results-container">
			<ng-lottie [options]="{ path: './assets/lottie/icn-sent.json' }"></ng-lottie>
			<div class="title">
				{{ 'SIMPLE_REGISTRATION.CONGRATULATIONS' | translate }}
			</div>
			<div class="text">
				{{ 'SIMPLE_REGISTRATION.COMPLETED_MESSAGE' | translate }}
				<span>{{ form.value.email }}</span>
			</div>
		</div>

		<div class="result-button-container">
			<button class="confirm-button" (click)="navigateEmit()">
        <span>{{ 'SIMPLE_REGISTRATION.RESULT_CONFIRM_BUTTON' | translate }}</span>
      </button>
		</div>
	</div>
</div>
