<mat-accordion>
	<mat-expansion-panel [routerLink]="navLink.path" *ngFor="let navLink of links$ | async" [disabled]="!navLink.children">
		<mat-expansion-panel-header [ngClass]="{ parent: navLink.children }">
			<mat-panel-title>
				{{ navLink.label | translate }}
			</mat-panel-title>
		</mat-expansion-panel-header>
		<ng-container *ngrxLet="activePath$ as activePath">
			<button
				*ngFor="let button of navLink.children; let i = index"
				[routerLink]="button.path"
				[ngClass]="{ active: button.path === activePath }"
				(click)="selectedMenu(button)">
				{{ button.label | translate }}
			</button>
		</ng-container>
	</mat-expansion-panel>
</mat-accordion>
