import { createReducer, on } from '@ngrx/store';
import { ResourceActions } from 'src/app/core/state/app.actions';
import { STATE_STATUS } from 'src/app/shared/models';
import { ResourceState } from './resource.state';

export const TAG = 'resources';

export interface FiltersType {
	structureUUID?: string;
	featureValue?: string[];
	communityUUID?: string;
	active?: boolean;
	updateDate?: string;
	communityEntityUUID?: string;
	[key: string]: unknown;
}

const initialState: ResourceState = {
	filters: {
		featureValue: [],
		active: true,
	},
	sort: undefined,
	pages: [0],
	data: { totalResourcesCount: 0, resources: [] },
	structures: [],
	status: STATE_STATUS.UNKNOWN,
	gridSearch: null,
	deleteStatus: 'unknown',
	cachedPages: [],
};

export const counterReducer = createReducer(
	initialState,
	/**
	 * Parte standard di un reducer
	 */
	on(ResourceActions.setSort, (state: ResourceState, { sort }) => ({
		...state,
		sort,
	})),
	on(ResourceActions.setPages, (state: ResourceState, { pages }) => ({
		...state,
		pages,
	})),
	on(ResourceActions.setResourcesSuccess, (state: ResourceState, { data }) => {
		return {
			...state,
			status: STATE_STATUS.READY,
			data: {
				resources: data.resources,
				totalResourcesCount: data.totalResourcesCount,
			},
			pages: data.pages,
		};
	}),
	on(ResourceActions.setResourcesError, (state: ResourceState) => ({
		...state,
		status: STATE_STATUS.ERROR,
	})),
	on(ResourceActions.setStatus, (state: ResourceState, { status }) => {
		return {
			...state,
			status,
		};
	}),
	on(ResourceActions.setGridSearch, (state: ResourceState, { search }) => {
		return {
			...state,
			gridSearch: search,
		};
	}),
	on(ResourceActions.setDeleteResources, (state: ResourceState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.LOADING,
		};
	}),
	on(ResourceActions.setDeleteResourcesSuccess, (state: ResourceState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.READY,
		};
	}),
	on(ResourceActions.setDeleteResourcesError, (state: ResourceState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.ERROR,
		};
	}),
	/**
	 * PARTE SPECIFICA DEL REDUCER
	 */
	on(ResourceActions.setFilters, (state: ResourceState, { filters }) => ({
		...state,
		filters: {
			...filters,
			structureUUID: filters.structureUUID !== 'allStructures' ? filters.structureUUID : undefined,
			communityUUID: filters.communityEntityUUID,
		},
	})),
	on(ResourceActions.setStructures, (state: ResourceState, { structures }) => ({
		...state,
		structures,
	})),
	on(ResourceActions.updateFilters, (state: ResourceState, { updatedFilters }) => ({
		...state,
		filters: {
			...state.filters,
			...updatedFilters,
		},
	})),
	on(ResourceActions.setCachedPages, (state: ResourceState, { pages }) => ({
		...state,
		cachedPages: [...new Set([...state.cachedPages, ...pages])],
	})),
	on(ResourceActions.resetCache, (state: ResourceState) => ({
		...state,
		cachedPages: [],
		status: STATE_STATUS.READY,
		pages: [0],
		data: { totalResourcesCount: 0, resources: [] },
	}))
);
