import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DATALEAN_USERS_TAG, DataleanUsersState } from './datalean-users.reducer';

const selectDataleanUsersState = createFeatureSelector<DataleanUsersState>(DATALEAN_USERS_TAG);

export const selectData = createSelector(selectDataleanUsersState, (state: DataleanUsersState) => state.data);
export const selectPages = createSelector(selectDataleanUsersState, (state: DataleanUsersState) => state.pages);
export const selectPagedData = createSelector(selectData, selectPages, (data, pages) => ({ ...data, pages }));
export const selectGridSearch = createSelector(selectDataleanUsersState, (state: DataleanUsersState) => state.gridSearch);
export const selectStatus = createSelector(selectDataleanUsersState, (state: DataleanUsersState) => state.status);
export const selectSort = createSelector(selectDataleanUsersState, (state: DataleanUsersState) => state.sort);
export const selectCachedPages = createSelector(selectDataleanUsersState, (state: DataleanUsersState) => state.cachedPages);
