import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserReducers } from 'src/app/core/state/app.reducers';
import { UserState } from './user.state';

export const selectUserState = createFeatureSelector<UserState>(UserReducers.TAG);

export const selectPermissions = createSelector(selectUserState, (state) => {
	if (!state.userData || !state.userData.roles) return [];
	return state.userData.roles.map((r) => r.permissions).flat();
});

export const selectUserData = createSelector(selectUserState, (state) => {
	return state.userData;
});
