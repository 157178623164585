import { HttpErrorResponse } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { Page, Structure } from 'addiction-components';
import { Recipient, STATE_STATUS } from 'src/app/shared/models';
import { Communication } from '../../../shared/models/communication.interface';
import { FiltersType } from './useful-links.reducers';
import { CommunicationError } from './useful-links.state';

export const setFilters = createAction('[UsefulLinks] Set Filters', props<{ filters: FiltersType }>());
export const updateFilters = createAction('[UsefulLinks] Update Filters', props<{ updatedFilters: FiltersType }>());
export const updatePage = createAction('[UsefulLinks] Update Page', props<{ updatedPage: Partial<Page> }>());
export const deleteUsefulLinks = createAction('[UsefulLinks] Delete UsefulLinks', props<{ uuids: string }>());
export const disableUsefulLinks = createAction('[UsefulLinks] Disable UsefulLinks', props<{ uuids: string[] }>());
 export const setStructures = createAction('[UsefulLinks] Set UsefulLinks Structures', props<{ structures: Array<Structure> }>());

export const setForceRefreshUsefulLinks = createAction('[UsefulLinks] Set Force Refresh');
export const setUsefulLinks = createAction('[UsefulLinks] Set Datalean UsefulLinks');
export const setUsefulLinksSuccess = createAction(
	'[UsefulLinks] Set Datalean UsefulLinks Success',
	props<{ data: { totalUsefulLinksCount: number; pages: number[]; usefulLinks: Communication[][] } }>()
);
export const setUsefulLinksError = createAction('[UsefulLinks] Set Datalean UsefulLinks Error', props<{ error: HttpErrorResponse }>());
export const setDeleteUsefulLinks = createAction('[UsefulLinks] Set Delete Datalean UsefulLinks', props<{ usefulLinks: Communication }>());
export const setDeleteUsefulLinksSuccess = createAction('[UsefulLinks] Set Delete Datalean UsefulLinks Success');
export const setDeleteUsefulLinksError = createAction('[UsefulLinks] Set Delete Datalean UsefulLinks Error', props<{ error: HttpErrorResponse }>());
export const setDisableUsefulLinksSuccess = createAction('[UsefulLinks] Set Delete Datalean UsefulLinks Success');
export const setDisableeUsefulLinksError = createAction('[UsefulLinks] Set Delete Datalean UsefulLinks Error', props<{ error: HttpErrorResponse }>());
export const setStatus = createAction('[UsefulLinks] Set status', props<{ status: STATE_STATUS }>());
export const setPages = createAction('[UsefulLinks] Set pages', props<{ pages: number[] }>());
export const setSort = createAction('[UsefulLinks] Set sort', props<{ sort: Sort }>());
export const setGridSearch = createAction('[UsefulLinks] Set search', props<{ search: string | null }>());
export const setRecipients = createAction('[UsefulLinks] Set recipients', props<{ recipients: Recipient[] }>());
export const setCachedPages = createAction('[UsefulLinks] Set cached pages', props<{ pages: number[] }>());
export const resetCache = createAction('[UsefulLinks] reset cache');

// Error
export const error = createAction('[UsefulLinks] Error', props<{ error: CommunicationError }>());
