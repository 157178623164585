import { HttpErrorResponse } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { BESurvey } from 'src/app/shared/models';
import { STATE_STATUS } from 'src/app/shared/models/state-status.enum';

export const setForceRefreshSurveys = createAction('[Datalean Surveys] Set Force Refresh');
export const setSurveys = createAction('[Datalean Surveys] Set Datalean Surveys');
export const setSurveysSuccess = createAction(
	'[Datalean Surveys] Set Datalean Surveys Success',
	props<{ data: { totalSurveyCount: number; pages: number[]; surveys: BESurvey[][] } }>()
);
export const setSurveysError = createAction('[Datalean Surveys] Set Datalean Surveys Error', props<{ error: HttpErrorResponse }>());
export const setDeleteSurvey = createAction('[Datalean Surveys] Set Delete Datalean Surveys', props<{ survey: BESurvey }>());
export const setDeleteSurveysSuccess = createAction('[Datalean Surveys] Set Delete Datalean Surveys Success');
export const setDeleteSurveysError = createAction('[Datalean Surveys] Set Delete Datalean Surveys Error', props<{ error: HttpErrorResponse }>());
export const setStatus = createAction('[Datalean Surveys] Set status', props<{ status: STATE_STATUS }>());
export const setPages = createAction('[Datalean Surveys] Set pages', props<{ pages: number[] }>());
export const setSort = createAction('[Datalean Surveys] Set sort', props<{ sort: Sort }>());
export const setGridSearch = createAction('[Datalean Surveys] Set search', props<{ search: string | null }>());
export const setCachedPages = createAction('[Datalean Surveys] Set cached pages', props<{ pages: number[] }>());
export const resetCache = createAction('[Datalean Surveys] reset cache');
