import { BaseLocalizedValues, ObjectValuesType } from 'addiction-components';
import { v4 } from 'uuid';
import { LocalizableField } from './localizable-field';
import { Communication } from './communication.interface';
import { SurveyAnswer } from '../../pages/surveys/survey-communication-detail/survey-communication-detail.component';

export const SurveyQuestionType = {
	// SELECT: 'select',
	CHOICE: 'choice',
	TEXT: 'text',
	DATE_TIME: 'dateTime',
	// IMAGE_PICKER: 'imagePicker',
	RATING_SCALE: 'ratingScale',
	// MATRIX: 'matrix',
	// SELECT: 'select',
	// FILE: 'file',
	// REVIEW : 99
} as const;

export type SurveyQuestionType = ObjectValuesType<typeof SurveyQuestionType>;

export const TextType = {
	TEL: 'tel',
	EMAIL: 'email',
	TEXT: 'text',
	TEXTAREA: 'textarea',
	NUMBER: 'number',
} as const;

export type TextType = ObjectValuesType<typeof TextType>;

export interface ISurveyChoice {
	id: number;
	text: LocalizableField;
}
export class BESurvey {
	//mantenere le proprietà all'interno del costruttore (altrimenti ParseEntity non è in grado di utilizzare correttamente la struttura della classe)
	constructor(
		public uuid: string = v4(),
		public questionList: BESurveyQuestion[] = [],
		public communityUUID: string | null = null,
		public localizedValues: SurveyLocalizedValues[] = []
	) {}
}

export class Survey implements Omit<BESurvey, 'questionList' | 'uuid' | 'localizedValues'> {
	public locale?: string;
	public uuid: string = v4();
	public communityUUID: string | null = null;
	public questionList?: FormQuestion[] = [];
	public communications?: Array<Partial<Communication>>;
	public response?: Array<Partial<SurveyAnswer>>;

	constructor(public name: string) {}
}

export interface SurveyLocalizedValues extends BaseLocalizedValues {
	name: string;
}

export interface BESurveyQuestion {
	domainOfAnswers: (number | ISurveyChoice)[] | undefined;
	order: number;
	required: boolean;
	type: SurveyQuestionType;
	text: LocalizableField;
	textType?: TextType;
	// negativeLabel?: LocalizableField; //  localized string
	// postiveLabel?: LocalizableField; // localized string
	dateTimeType?: DateTimeType;
	multiple?: boolean;
	uuid?: string;
}

export type FormQuestion = Omit<BESurveyQuestion, 'text' | 'domainOfAnswers'> & {
	text: string;
	domainOfAnswers?: FormDomainOfAnswers;
};

export type FormDomainOfAnswers = {
	answers?: { text: string }[];
	showDate?: boolean;
	showTime?: boolean;
	multiple?: boolean;
	min?: number;
	max?: number;
	textType?: TextType;
};

export const DateTimeType = {
	MONTH_DAY_YEAR: 'MONTH_DAY_YEAR', // normal date
	MILITAR: 'MILITAR', // normal hour
	DATE_MILITAR: 'DATE_MILITAR', // normal date with hour
} as const;
export type DateTimeType = ObjectValuesType<typeof DateTimeType>;
