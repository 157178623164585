import { Injectable } from '@angular/core';
import { DataleanBaseApiService } from 'addiction-components';
import { environment } from 'src/environments/environment';
import { OperationPermission, OperationPermissionMultipleAdd, OperationPermissionMultipleAddBody } from '../models';

@Injectable({
  providedIn: 'root',
})
export class OperationPermissionService {

  constructor(private dataleanApi: DataleanBaseApiService) {}

  fetchOperationPermissionWithFilters(params: OperationPermissionFilterBody) {
    const body: OperationPermissionFilterBody = {
      assetsUUIDs: params.assetsUUIDs,
      assetsUUIDsMode: params.assetsUUIDsMode
    };

    if (params.entityFilter) {
      body.entityFilter = params.entityFilter;
    }

    return this.dataleanApi.createEntity<OperationPermission[]>(environment.operationPermissionUrl, body, []);
  }

  fetchOperationPermissionWithCounter(params: OperationPermissionFilterBody) {
    const body: OperationPermissionFilterBody = {
      assetsUUIDs: params.assetsUUIDs,
      assetsUUIDsMode: params.assetsUUIDsMode
    };

    if (params.entityFilter) {
      body.entityFilter = params.entityFilter;
    }

    return this.dataleanApi.createEntity<{ assetsCount: number; operationPermissions: OperationPermission[]}>(environment.operationPermissionUrl+"counter", body, []);
  }

  setMultipleOperationPermissions(
    assetsUUIDs: string[],
    assetsUUIDsMode: 'exclusive' | 'inclusive',
    //DATI DELLA TABELLA
    operationPermissionList: OperationPermissionMultipleAdd[],
    filters?: { [p: string]: string }
  ){
    const body: OperationPermissionMultipleAddBody = {
      entityUUIDs: assetsUUIDs,
      entityUUIDsMode: assetsUUIDsMode,
      operationPermissionList: operationPermissionList,
      entityFilter: filters
    }

    return this.dataleanApi.createEntity(environment.operationPermissionUrl+"multiple", body, []);
  }
}

export interface OperationPermissionFilterBody {
  assetsUUIDs: string[];
  assetsUUIDsMode: 'exclusive' | 'inclusive';
  entityFilter?: { [p: string]: string };
}
