import { ModuleWithProviders, NgModule } from "@angular/core";
import { ContextStore, ContextStoreToken } from "./context-store.service";

@NgModule({

})
export class ContextStoreModule {
    public static forRoot(): ModuleWithProviders<ContextStoreModule> {
        return {
            ngModule: ContextStoreModule,
            providers: [ContextStore]
        }
    }

    public static forChild(context:string): ModuleWithProviders<ContextStoreModule> {
        return {
            ngModule: ContextStoreModule,
            providers: [ContextStore, { provide: ContextStoreToken, useValue: context }]
        }
    }
}