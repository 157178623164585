import { createReducer, on } from '@ngrx/store';
import { CommunityActions } from 'src/app/core/state/app.actions';
import { STATE_STATUS } from 'src/app/shared/models';
import { CommunityState } from './community.state';

export const TAG = 'communities';

const initialState: CommunityState = {
	sort: undefined,
	pages: [0],
	data: { totalCommunitiesCount: 0, communities: [] },
	status: STATE_STATUS.UNKNOWN,
	gridSearch: null,
	deleteStatus: 'unknown',
	cachedPages: [],
};

export const counterReducer = createReducer(
	initialState,
	/**
	 * Parte standard di un reducer
	 */
	on(CommunityActions.setSort, (state: CommunityState, { sort }) => ({
		...state,
		sort,
	})),
	on(CommunityActions.setPages, (state: CommunityState, { pages }) => ({
		...state,
		pages,
	})),
	on(CommunityActions.setCommunitiesSuccess, (state: CommunityState, { data }) => {
		return {
			...state,
			status: STATE_STATUS.READY,
			data: {
				communities: data.communities,
				totalCommunitiesCount: data.totalCommunitiesCount,
			},
			pages: [...state.cachedPages, ...data.pages],
		};
	}),
	on(CommunityActions.setCommunitiesError, (state: CommunityState) => ({
		...state,
		status: STATE_STATUS.ERROR,
	})),
	on(CommunityActions.setStatus, (state: CommunityState, { status }) => {
		return {
			...state,
			status,
		};
	}),
	on(CommunityActions.setGridSearch, (state: CommunityState, { search }) => {
		return {
			...state,
			gridSearch: search,
		};
	}),
	on(CommunityActions.setDeleteCommunities, (state: CommunityState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.LOADING,
		};
	}),
	on(CommunityActions.setDeleteCommunitiesSuccess, (state: CommunityState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.READY,
		};
	}),
	on(CommunityActions.setDeleteCommunitiesError, (state: CommunityState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.ERROR,
		};
	}),
	/**
	 * PARTE SPECIFICA DEL REDUCER
	 */
	on(CommunityActions.setForceRefreshCommunities, (state: CommunityState) => ({
		...state,
		status: STATE_STATUS.FORCED_REFRESH,
	})),
	on(CommunityActions.resetCache, (state: CommunityState) => {
		return {
			...state,
			pages: [0],
      status: STATE_STATUS.READY,
			cachedPages: [],
			data: { totalCommunitiesCount: 0, communities: [] },
		};
	}),
  on(CommunityActions.setCachedPages, (state: CommunityState, { pages }) => ({
		...state,
		cachedPages: [...new Set([...state.cachedPages, ...pages])],
	})),
);
