import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { DataleanBaseApiService, PaginationInfo } from 'addiction-components';
import { Observable, forkJoin } from 'rxjs';
import { DataleanUser } from 'src/app/shared/models';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class DataleanUserService {
	constructor(private dataleanApi: DataleanBaseApiService) {}

	fetchUsers(
		pages: number[],
		sort?: Sort,
		gridSearch?: string | null
	): Observable<{ result: DataleanUser[] | null; paginationInfo?: PaginationInfo }[]> {
		const obs: Observable<{ result: DataleanUser[] | null; paginationInfo?: PaginationInfo }>[] = [];

		const params: { sortBy?: string; q?: string; searchFields?: string } = {};

		//SORT
		if (sort) params.sortBy = `${sort.active}#${sort.direction}`;
		if (gridSearch) {
			params.q = gridSearch;
			params.searchFields = 'firstName,lastName,email,username';
		}

		for (const page of pages) {
			const pagination = new PaginationInfo(environment.pageSize, page);

			obs.push(this.dataleanApi.getEntitiesWithPaginationData<DataleanUser>(environment.usersUrl, params, [], undefined, pagination, undefined));
		}
		return forkJoin(obs);
	}

	deleteUser(user: DataleanUser) {
		return this.dataleanApi.deleteEntity(environment.usersUrl, user);
	}
}
