import { Injectable, inject } from '@angular/core';
import { DataleanBaseApiService, Parts, SimpleObject } from 'addiction-components';
import { Observable, map } from 'rxjs';
import { Pictogram } from 'src/app/shared/models/pictogram/pictogram.interface';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class PictogramService {
	private baseApi = inject(DataleanBaseApiService);
	private pictogramUrl = environment.pictogramUrl ? `${environment.pictogramUrl}pictogram` : undefined
	private readonly NO_PICTOGRAM_URL_ERROR = new Error('Please provide a pictogram url');


	getPictograms(uuids?: string[]): Observable<Pictogram[]> {
		if(this.pictogramUrl) {
			const additionalParams: SimpleObject = {};

			additionalParams['sortBy'] = 'name1#asc';
	
			if (uuids && uuids.length) additionalParams['uuids'] = uuids.join(',');
	
	
			return this.baseApi.getMany<Pictogram>(this.pictogramUrl, [Parts.PREVIEW_ASSET_INFO], {
				additionalParams,
			});
		} else {
			throw this. NO_PICTOGRAM_URL_ERROR;
		}
	}

	getPictogram(uuid: string) {
		if(this.pictogramUrl) {
			return this.baseApi.getOne<Pictogram>(this.pictogramUrl, uuid, [Parts.PREVIEW_ASSET_INFO]);
		} else {
			throw this. NO_PICTOGRAM_URL_ERROR;
		}
	}

	updatePictogram(Pictogram: Pictogram): Observable<Pictogram> {
		if(this.pictogramUrl) {
			const { uuid } = Pictogram;
			const pictorgramToUpdate = {
				...Pictogram,
				simbol: Pictogram.simbol.uuid ? {uuid: Pictogram.simbol.uuid} : Pictogram.simbol
			}
			return this.baseApi.updateOne<Pictogram>(this.pictogramUrl, uuid, pictorgramToUpdate, [Parts.EMPTY]);
		} else {
			throw this. NO_PICTOGRAM_URL_ERROR;
		}
	}

	createPictogram(Pictogram: Pictogram): Observable<Pictogram> {
		if(this.pictogramUrl) {
			const pictorgramToCreate = {
				...Pictogram,
				simbol: Pictogram.simbol.uuid ? {uuid: Pictogram.simbol.uuid} : Pictogram.simbol
			}
			return this.baseApi.createOne<Pictogram>(this.pictogramUrl, pictorgramToCreate, [Parts.EMPTY]);
		} else {
			throw this. NO_PICTOGRAM_URL_ERROR;
		}
		
	}


	deletePictogram(uuid: string): Observable<true> {
		if(this.pictogramUrl) {
			return this.baseApi.deleteOne(this.pictogramUrl, uuid).pipe(map(() => true));
		} else {
			throw this. NO_PICTOGRAM_URL_ERROR;
		}
		
	}

}
