import { HttpErrorResponse } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { FeatureValue, Page, Structure } from 'addiction-components';
import { STATE_STATUS } from 'src/app/shared/models';
import { Product } from '../../../shared/models/product.interface';
import { FiltersType } from './product-variants.reducers';
import { ProductVariantsError } from './product-variants.state';

export const setFilters = createAction('[ProductsVariants] Set Filters', props<{ filters: FiltersType }>());
// export const setSort = createAction('[ProductsVariants] Set Sort', props<{ sort: SortInfo }>());
export const setStructures = createAction('[ProductsVariants] Set Products Structures', props<{ structures: Array<Structure> }>());
export const setFeatures = createAction('[ProductsVariants] Set Products Features', props<{ features: Array<FeatureValue> }>());
export const updateFilters = createAction('[ProductsVariants] Update Filters', props<{ updatedFilters: FiltersType }>());
export const addFeatureValueToFilters = createAction('[ProductsVariants] Add FeatureValue To Filters', props<{ featureValueUUID: string }>());
export const removeFeatureValueFromFilters = createAction(
	'[ProductsVariants] Remove FeatureValue From Filters',
	props<{ featureValueUUID: string }>()
);
export const updatePage = createAction('[ProductsVariants] Update Page', props<{ updatedPage: Partial<Page> }>());
export const deleteProducts = createAction('[ProductsVariants] Delete Products', props<{ uuids: string[] }>());
export const disableProducts = createAction('[ProductsVariants] Disable Products', props<{ uuids: string[] }>());

export const setForceRefreshProducts = createAction('[ProductsVariants] Set Force Refresh');
export const setProducts = createAction('[ProductsVariants] Set Products');
export const setProductsSuccess = createAction(
	'[ProductsVariants] Set Products Success',
	props<{ data: { totalProductsCount: number; pages: number[]; products: Product[][] } }>()
);
export const setProductsError = createAction('[ProductsVariants] Set Products Error', props<{ error: HttpErrorResponse }>());
export const setDeleteProducts = createAction('[ProductsVariants] Set Delete Products', props<{ product: Product }>());
export const setDeleteProductsSuccess = createAction('[ProductsVariants] Set Delete Products Success');
export const setDeleteProductsError = createAction('[ProductsVariants] Set Delete Products Error', props<{ error: HttpErrorResponse }>());
export const setStatus = createAction('[ProductsVariants] Set status', props<{ status: STATE_STATUS }>());
export const setPages = createAction('[ProductsVariants] Set pages', props<{ pages: number[] }>());
export const setSort = createAction('[ProductsVariants] Set sort', props<{ sort: Sort }>());
export const setGridSearch = createAction('[ProductsVariants] Set search', props<{ search: string | null }>());
export const setCachedPages = createAction('[ProductsVariants] Set cached pages', props<{ pages: number[] }>());
export const resetCache = createAction('[ProductsVariants] reset cache');

// Error
export const error = createAction('[ProductsVariants] Error', props<{ error: ProductVariantsError }>());
