<div class="header-container">

  <div class="left-container">
    <ng-container *ngIf="showBackButton">
      <button class="back" (click)="backButtonClick.emit()"></button>
    </ng-container>
    <ng-container *ngIf="showMenuButton">
      <button class="menu-button" (click)="menuButtonClick.emit()" [ngClass]="menuButtonClass"></button>
    </ng-container>
    <ng-container *ngIf="showNavigationButton">
      <button class="navigation-button" (click)="navigationButtonClick.emit()" [ngClass]="navigationButtonClass"></button>
    </ng-container>
    <ng-container *ngIf="showFilterButton">
      <button class="filters-button" (click)="filterButtonClick.emit()" [ngClass]="filterButtonClass"></button>
    </ng-container>
    <addiction-search [value]="searchValue" *ngIf="showSearchField" (search)="search.emit($event)"></addiction-search>
  </div>

  <div class="right-container">
    <div class="size-container" *ngIf="showSizeSlider">
      <label>{{ 'DAM_HEADER.RESIZE_LABEL' | translate }}</label>
      <mat-slider [min]="2" [max]="8" step="1">
        <input matSliderThumb [ngModel]="sliderValue" (ngModelChange)="sliderValueChange.emit($event)" />
      </mat-slider>
    </div>

    <div class="view-mode-container" *ngIf="showViewModeButtons">
      <button class="view-button grid-view" [ngClass]="{ active: viewMode === 'grid' }" (click)="viewModeChange.emit('grid')"></button>
      <button class="view-button list-view" [ngClass]="{ active: viewMode === 'list' }" (click)="viewModeChange.emit('list')"></button>
    </div>

    <div class="order-container" *ngIf="showSortField">
      <div class="select-container" *ngIf="sortOptions.length">
        <label>{{ 'DAM_HEADER.ORDER' | translate }}</label>
        <addiction-select
          [value]="sortOptions[0].value"
          [options]="sortOptions"
          (valueChange)="onChangeSort($event)">
        </addiction-select>
      </div>
    </div>

    <div class="collections-container" *ngIf="showCollectionButton">
      <!-- TODO DEVS - Manca da sistemare l'active sotto -->
      <button class="collections-button" (click)="collectionButtonClick.emit()" [ngClass]="collectionButtonClass">
        <span class="label">{{ 'DAM_HEADER.COLLECTIONS' | translate }}</span>
      </button>
    </div>

    <div class="delete-container" *ngIf="showDeleteCollectionButton">
      <button class="delete" (click)="deleteCollectionButtonClick.emit()"></button>
    </div>

    <div class="create-new-container" *ngIf="showAddButton">
      <button class="add-new" (click)="addButtonClick.emit()"></button>
    </div>
  </div>

</div>
