import { createAction, props } from '@ngrx/store';
import { Community } from '../../models';
import { FiltersType } from './community-select.state';

export const setData = createAction('[CommunitySelect] Set Data', props<{ data: Array<Community> }>());

export const setLastCommunitySelected = createAction('[CommunitySelect] Set LastCommunitySelected', props<{ lastCommunitySelected: string }>());

export const setFilters = createAction('[CommunitySelect] Set Filters', props<{ filters: FiltersType }>());

export const setShowNoCommunityChoice = createAction('[CommunitySelect] Set showNoCommunityChoice', props<{ showNoCommunityChoice: boolean }>());

export const setLastCommunityFromPath = createAction('[CommunitySelect] Set LastCommunitySelected per Path', props<{ activePath?: string }>());
