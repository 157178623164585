import { ObjectValuesType } from 'addiction-components';

export const STATE_STATUS = {
	UNKNOWN: 'unknown',
	LOADING: 'loading',
	ERROR: 'error',
	READY: 'ready',
  FORCED_REFRESH: 'forced_refresh',
} as const;

export type STATE_STATUS = ObjectValuesType<typeof STATE_STATUS>;
