<ng-container *ngIf="!optGroup">
	<ng-container *ngIf="!materialStyle">
		<label *ngIf="label">{{ label | translate }}</label>
		<div class="select-container">
			<select [formControl]="formControl" [ariaReadOnly]="readonly">
				<ng-container *ngIf="!!placeholder">
					<option value="" disabled selected>{{placeholder}}</option>
				</ng-container>
				<option *ngFor="let option of options" [value]="option.value">{{ option.label | toLocalizedValuePipe | translate }}</option>
			</select>
		</div>
	</ng-container>
	<mat-form-field appearance="fill" *ngIf="materialStyle">
		<mat-label>{{ label }}</mat-label>
		<mat-select disableRipple [formControl]="formControl" [ariaReadOnly]="readonly" [placeholder]="placeholder">
			<mat-option *ngFor="let option of options" [value]="option.value">{{ option.label | toLocalizedValuePipe | translate }}</mat-option>
		</mat-select>
	</mat-form-field>
</ng-container>
<mat-form-field appearance="fill" *ngIf="optGroup">
	<mat-label>{{ label }}</mat-label>
	<mat-select disableRipple [formControl]="formControl" [ariaReadOnly]="readonly" [placeholder]="placeholder">
		<mat-optgroup *ngFor="let options of optGroupOptions" [label]="options.name">
			<mat-option *ngFor="let opt of options.options" [value]="opt.value">
				{{ opt.label | toLocalizedValuePipe | translate }}
			</mat-option>
		</mat-optgroup>
	</mat-select>
</mat-form-field>
