import { HttpErrorResponse } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { FeatureValue, Page, Structure } from 'addiction-components';
import { STATE_STATUS } from 'src/app/shared/models';
import { FiltersType } from './bookable-product.reducers';
import { ProductError } from './bookable-product.state';
import { BookableProduct } from 'src/app/shared/models/reservations/bookable-product.interface';

export const setFilters = createAction('[BookableProducts] Set Filters', props<{ filters: FiltersType }>());
// export const setSort = createAction('[BookableProducts] Set Sort', props<{ sort: SortInfo }>());
export const setStructures = createAction('[BookableProducts] Set BookableProducts Structures', props<{ structures: Array<Structure> }>());
export const setFeatures = createAction('[BookableProducts] Set BookableProducts Features', props<{ features: Array<FeatureValue> }>());
export const updateFilters = createAction('[BookableProducts] Update Filters', props<{ updatedFilters: FiltersType }>());
export const addFeatureValueToFilters = createAction('[BookableProducts] Add FeatureValue To Filters', props<{ featureValueUUID: string }>());
export const removeFeatureValueFromFilters = createAction('[BookableProducts] Remove FeatureValue From Filters', props<{ featureValueUUID: string }>());
export const updatePage = createAction('[BookableProducts] Update Page', props<{ updatedPage: Partial<Page> }>());
export const deleteBookableProduct = createAction('[BookableProducts] Delete BookableProduct', props<{ uuid: string }>());

export const setForceRefreshBookableProducts = createAction('[BookableProducts] Set Force Refresh');
export const setBookableProducts = createAction('[BookableProducts] Set BookableProducts');
export const setBookableProductsSuccess = createAction(
	'[BookableProducts] Set BookableProducts Success',
	props<{ data: { totalBookableProductsCount: number; pages: number[]; bookableProducts: BookableProduct[][] } }>()
);
export const setBookableProductsError = createAction('[BookableProducts] Set BookableProducts Error', props<{ error: HttpErrorResponse }>());
export const setDeleteBookableProducts = createAction('[BookableProducts] Set Delete BookableProducts', props<{ product: BookableProduct }>());
export const setDeleteBookableProductsSuccess = createAction('[BookableProducts] Set Delete BookableProducts Success');
export const setDeleteBookableProductsError = createAction('[BookableProducts] Set Delete BookableProducts Error', props<{ error: HttpErrorResponse }>());
export const setStatus = createAction('[BookableProducts] Set status', props<{ status: STATE_STATUS }>());
export const setPages = createAction('[BookableProducts] Set pages', props<{ pages: number[] }>());
export const setSort = createAction('[BookableProducts] Set sort', props<{ sort: Sort }>());
export const setGridSearch = createAction('[BookableProducts] Set search', props<{ search: string | null }>());
export const setCachedPages = createAction('[BookableProducts] Set cached pages', props<{ pages: number[] }>());
export const resetCache = createAction('[BookableProducts] reset cache');
// Error
export const error = createAction('[BookableProducts] Error', props<{ error: ProductError }>());
