export class LocalizableField extends Array<{ language: string; value: string }> {
	constructor(localizedValuesMap: Array<{ language: string; value: string }> = []) {
		super();
		this.push(...localizedValuesMap);
	}

	static createWithLocalizedValue(localizedValue: { language: string; value: string }) {
		return new LocalizableField([localizedValue]);
	}

	get locale(): string {
		return this[0]?.language;
	}

	getLocalizedValue(locale: string, defaultLocale?: string): string {
		return (this.find((value) => value.language === locale) || this.find((value) => value.language === defaultLocale))?.value ?? '';
	}

	addLocalizedValue(language: string, value: string): void {
		const existing = this.find((v) => v.language === language);
		if (existing) {
			existing.value = value;
		} else {
			this.push({ language, value });
		}
	}
}
