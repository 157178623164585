import { createAction, props } from '@ngrx/store';
import { Tag } from 'addiction-components';

/* READ */
export const loadTags = createAction('[Tags] Reload Tags');
export const loadTagsSuccess = createAction('[Tags] Load Tags Success', props<{ tags: Tag[] }>());
export const loadTagsFailure = createAction('[Tags] Load Tags Failure', props<{ error: unknown }>());

/* CREATE */
export const createTag = createAction('[Tags] Create Tag', props<{ tag: Omit<Tag, 'uuid'> }>());
export const createTagSuccess = createAction('[Tags] Create Tag Success', props<{ tag: Tag }>());
export const createTagFailure = createAction('[Tags] Create Tag Failure', props<{ error: unknown }>());

/* UPDATE */
export const updateTag = createAction('[Tags] Update Tag', props<{ tag: Tag; uuid: string }>());
export const updateTagSuccess = createAction('[Tags] Update Tag Success', props<{ tag: Tag }>());
export const updateTagFailure = createAction('[Tags] Update Tag Failure', props<{ error: unknown }>());

/* SELECTION */
export const selectTag = createAction('[Tags] Select Tag', props<{ uuid?: string }>());
export const editTag = createAction('[Tags] Edit Tag', props<{ uuid: string }>());
