import { Asset, FeatureValue } from 'addiction-components';
import { v4 } from 'uuid';
import { UnknownObject } from './unknown-object.interface';
import { Recipient } from './recipient';

export class Communication {
	uuid: string = v4();
	locale?: string = '';
	active?: boolean = true;
	structure?: { name: string };
	featureValueList?: Array<FeatureValue> = [];
	related?: Array<Communication> = [];
	entityVersion?: number;
	status?: string;
	tutorialCommunications?: boolean = false;
	requestConfirmation?: boolean = false;
	highlights?: boolean = false;
	localizedValues?: { locale: string; [key: string]: unknown }[] = [];
	updateDate?: string = '';
	recipients?: (string | Recipient)[];
	image?: Asset | null;
	[key: string]: unknown | UnknownObject;

	constructor(public structureUUID: string = '', public name: string = '') {}
}

export type UsefulLinks = Communication & Omit<Communication, 'featureValueList' | 'active' | 'structure' | 'structureUUID'>;

export class CommunicationFE extends Communication {
	publicationDate?: string;
	expireDate?: string;
}

export type CommunicationUpdate = Omit<Communication, 'featureValueList' | 'related'> & {
	uuid?: string;
	featureValueList: Array<string>;
	related?: Array<string>;
	localizedValues: { locale: string; [key: string]: unknown }[];
};
