import { createAction, props } from '@ngrx/store';
import { Configuration } from 'addiction-components';

export const fetchConfig = createAction('[Config] fetch Configs');
export const fetchConfigSuccess = createAction(
	'[Config] fetch Configs success',
	props<{
		configurationMap: {
			[key: string]: Configuration;
		};
	}>()
);
export const fetchConfigError = createAction('[Config] fetch Configs error', props<{ error: unknown }>());

/* CREATE */
export const createConfig = createAction('[Configs] Create Config', props<{ Config: Configuration }>());
export const createConfigSuccess = createAction('[Configs] Create Config Success', props<{ Config: Configuration }>());
export const createConfigFailure = createAction('[Configs] Create Config Failure', props<{ error: unknown }>());

/* UPDATE */
export const updateConfig = createAction('[Configs] Update Config', props<{ Config: Configuration; uuid: string }>());
export const updateConfigSuccess = createAction('[Configs] Update Config Success', props<{ Config: Configuration }>());
export const updateConfigFailure = createAction('[Configs] Update Config Failure', props<{ error: unknown }>());

/* DELETE */
export const deleteConfig = createAction('[Configs] Delete Config', props<{ uuid: string }>());
export const deleteConfigSuccess = createAction('[Configs] Delete Config Success', props<{ uuid: string }>());
export const deleteConfigFailure = createAction('[Configs] Delete Config Failure', props<{ error: unknown }>());
