import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs';
import { ApplicationActions } from '../app.actions';

@Injectable()
export class ApplicationEffect {
	private actions$ = inject(Actions);
	private translateService = inject(TranslateService);

	// additional operation when set default language
	setActiveLanguage$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(ApplicationActions.setActiveLanguage),
				tap(({ activeLanguage }) => {
					this.translateService.use(activeLanguage);
				})
			),
		{ dispatch: false }
	);

	constructor() {}
}
