<div class="header-container" xmlns="http://www.w3.org/1999/html">
	<div class="left-container">
		<button class="back-button" (click)="goBack()"></button>

		<!-- TODO - Da implementare traduzioni -->
		<div class="languages-container" style="display: none">
			<div class="single-language it-IT active">
				<div class="label">{{ 'LANGUAGES.it-IT' | translate }}</div>
				<div class="remove"></div>
			</div>
			<div class="single-language en-US">
				<div class="label">{{ 'LANGUAGES.en-US' | translate }}</div>
				<div class="remove"></div>
			</div>
			<div class="single-language fr-FR">
				<div class="label">{{ 'LANGUAGES.fr-FR' | translate }}</div>
				<div class="remove"></div>
			</div>
			<button class="add-language-button"></button>
		</div>
	</div>
	<div class="right-container">
		<!-- TODO - Da implementare versionamento -->
		<div class="version-container" style="display: none">
			<span class="label">{{ 'VERSION_DATALEAN_INIT' | translate }}</span>
			<span class="version">1.1</span>
		</div>
		<button class="versioning-button" style="display: none"></button>

		<button *ngIf="!isCreateMode" (click)="deleteItem.emit()" class="delete-button"></button>
		<button *ngIf="showPermissionButton && !isCreateMode" (click)="permission.emit()" class="permissions-button"></button>
		<button *ngIf="!isCreateMode" (click)="replaceItem.emit()" class="replace-button"></button>
		<button *ngIf="showAddToCollectionButton && !isCreateMode" (click)="addButtonClick.emit()" class="add-collection-button">
			<span>{{ 'COLLECTIONS.ADD_TO_COLLECTION' | translate }}</span>
		</button>
		<button *ngIf="showRemoveButton" (click)="removeButtonClick.emit()" class="text-btn remove-collection-button">
			<span>{{ 'COLLECTIONS.REMOVE_FROM_COLLECTION' | translate }}</span>
		</button>
		<button (click)="saveItem.emit()" class="text-btn save-button">
			<span>{{ 'OPTIONS_BUTTONS.SAVE' | translate }}</span>
		</button>
	</div>
</div>
