import { ModuleWithProviders, NgModule } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { DatePickerComponent, SelectFieldComponent } from './components';
import { InputComponent } from './components/input/input.component';
import { PasswordFormComponent } from './components/password-form/password-form.component';
import { SearchComponent } from './components/search/search.component';
import { SimpleRegistrationComponent } from './components/simple-registration/simple-registration.component';
import { TreeBrowserComponent } from './components/tree-browser/tree-browser.component';
import { DndDirective } from './directives/dnd.directive';
import { EnvironmentConfiguration } from './models';
import { ToLocalizedValuePipe } from './pipes/to-localized-value.pipe';
import { TextareaComponent } from './components/textarea/textarea.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ModalTargetDirective } from './directives';

@NgModule({
	providers: [TranslatePipe, ToastrService],
	imports: [
		ToLocalizedValuePipe,
		InputComponent,
		TreeBrowserComponent,
		SearchComponent,
		ToastrModule.forRoot({
			positionClass: 'toast-bottom-center',
		}),
		SimpleRegistrationComponent,
		DndDirective,
		ModalTargetDirective,
		PasswordFormComponent,
		SelectFieldComponent,
		DatePickerComponent,
		TextareaComponent,
		CheckboxComponent,
	],
	exports: [
		ToLocalizedValuePipe,
		InputComponent,
		TreeBrowserComponent,
		SearchComponent,
		SimpleRegistrationComponent,
		DndDirective,
		ModalTargetDirective,
		PasswordFormComponent,
		SelectFieldComponent,
		DatePickerComponent,
		TextareaComponent,
		CheckboxComponent,
	],
})
export class AddictionComponentsModule {
	static forRoot(env: EnvironmentConfiguration): ModuleWithProviders<AddictionComponentsModule> {
		return {
			ngModule: AddictionComponentsModule,
			providers: [{ provide: 'env', useValue: env }],
		};
	}
}
