import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const authConfig: AuthConfig = {
	issuer: environment.authenticationUrl,
	customQueryParams: {
		organizationUUID: environment.organizationUUID,
		userType: 'User',
		prompt: 'login',
	},
	requireHttps: environment.production,
	clientId: 'dataleanFeIntegration', // The "Auth Code + PKCE" client
	responseType: 'code',
	redirectUri: window.location.origin,
	logoutUrl: window.location.origin,
	scope: 'openid profile email',
	timeoutFactor: 0.75, // For faster testing
	sessionChecksEnabled: false,
	showDebugInformation: !environment.production,
	clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
	nonceStateSeparator: 'semicolon', // Real semicolon gets mangled by IdentityServer's URI encoding
};
