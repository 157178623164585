/* eslint-disable @typescript-eslint/no-explicit-any */
import { inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ActionCreator, Creator, Action } from "@ngrx/store";
import { map, mergeMap, Observable, of, OperatorFunction } from "rxjs";

/** Crea un effetto con contesto, ha una sintassi leggermente diversa da NGRX 
 * @param allowedTypes I tipi di azioni da osservare per scatenare questo effect
 * @param source Una funzione che riceve in input l'observable dell'azione che ha emesso e deve restituire una pipeline valida
 * 
 * @example 
 * createContextEffect([addRow, deleteRow, reloadRows], ($) => 
 *      $.pipe(
 *          switchMap(params => this.service.fetchRows(params)
 *      )
 * )
*/
export function createContextEffect<AC extends ActionCreator<string, Creator>[], U extends Action = Action, V = ReturnType<AC[number]>>(
    allowedTypes:AC,
    source: (($:Observable<ReturnType<AC[number]>>) => Observable<unknown>)
): OperatorFunction<U, V> {
    
    const action$ = inject(Actions); // thanks Angular 14 😘
    const filter = action$.pipe(
        ofType(...allowedTypes), 
        mergeMap((sourceAction) => source(of(sourceAction)).pipe(
            map((res:any) => ({...res, context:(sourceAction as any).context}))
        ))) as any;
    return createEffect(() => filter)    
}