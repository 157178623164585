import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { switchMap, tap } from 'rxjs';
import { AuthService } from '../../../../shared/services/auth/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationGuard {
	constructor(private authService: AuthService) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		return this.authService.loaded$.pipe(
			switchMap(() => this.authService.canActivateProtectedRoutes$),
			tap((allowed) => {
				if (!allowed) this.authService.login(state.url);
			})
		);
	}
}
