import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { InputComponent } from 'addiction-components';

@Component({
  selector: 'datalean-modal-input',
  standalone: true,
  imports: [CommonModule, FormsModule, TranslateModule, InputComponent],
  templateUrl: './modal-input.component.html',
  styleUrls: ['./modal-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalInputComponent {

  inputValue = ''
  constructor(
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: {title: string, bodyMessage?: string, inputLabel: string, confirmLabel: string, placeholder?: string, initialValue?: string}
  ) {
    this.inputValue = data.initialValue || '';
  }

  onSubmit() {
    if(this.inputValue) {
      this.dialogRef.close(this.inputValue);
    }
  }
}
