import { createDeepTypeGuard } from '../../lib/utils/type-guard';
import { BaseUser } from '../base-user.interface';
import { Role } from '../role.interface';

export interface ApplicationUser extends BaseUser {
	structureUUID: string
	smartCoUser: boolean
  cataleanUser: boolean
  configuratorUser: boolean
  communityUUIDList: string[]
	[key: string]: unknown;
}

export interface UserFormValue {
	firstName: string;
	lastName: string;
	username: string;
	password: string;
	email: string;
	roles: Role[];
}

export const isApplicationUser = createDeepTypeGuard<ApplicationUser>('structureUUID', 'string');
//based on firstName
export const isAppUser = createDeepTypeGuard<BaseUser>('firstName', 'string');

