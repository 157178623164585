/** Minimum data required to display the preview of an Asset */
export interface AssetPreviewData {
	uuid: string;
	format: string;
	name?: string;
	preview?: {
		url?: string;
		format?: string;
	};
	url?: string;
}
