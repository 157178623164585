import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CloseReason } from './modal.service';

@Injectable({ providedIn: 'root' })
export class ActiveModalService {
	public _close$ = new Subject<{ dialogId: string; reason: CloseReason; data: unknown }>();

	constructor() {}

	/** Chiude la modal aperta in questo momento */
	public closeModal<TRes>(dialogId: string, reason: CloseReason, data?: TRes) {
		this._close$.next({ dialogId, reason, data });
	}
}
