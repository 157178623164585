import { v4 } from 'uuid';
import { FeatureValue } from 'addiction-components';
import { LessonContent } from './lesson-content';

export class Lesson {
	localizedValues?: LessonLocalizedValues[] = [];
	featureValueList: Array<FeatureValue> = [];
	score = 0;
	contentList?: Array<LessonContent> = [];
	uuid = v4();
	locale?: string;
  communityUUID?: string = "";

	constructor(public name: string, public description: string) {}
}

export class LessonLocalizedValues {
	constructor(public name: string, public description: string, public locale: string) {}
}
