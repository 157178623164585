import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasePictogramEntity, PictogramPosition } from 'src/app/shared/models/pictogram/pictogram.interface';

@Component({
  selector: 'datalean-pictogram-preview',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './pictogram-preview.component.html',
  styleUrls: ['./pictogram-preview.component.scss'],
})
export class PictogramPreviewComponent implements OnChanges {
  @Input({ required: true }) pictogram!: BasePictogramEntity;
  topTextArray: string[] = [];
  bottomText: string[] = [];
  bottomAdjacenArray: string[] = [];
  topSideText: string[] = [];
  centerSideText: string[] = [];

  PICTOGRAM_POSITION = PictogramPosition;

  getPositionText(position: PictogramPosition) {
    return this.pictogram.attributes.reduce((acc, attribute) => {
      if(attribute.position === position) {
        acc += attribute.text + ' ';
      }

      return acc;
    }, '')
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['pictogram']) {
      const topTextArray = [];
      const bottomText = [];
      const bottomAdjacenArray = [];
      const topSideText = [];
      const centerSideText = [];
      for(const attribute of this.pictogram.attributes) {
        switch(attribute?.position) {
          case PictogramPosition.TOP:
            topTextArray.push(attribute.text);
            break;
          case PictogramPosition.BOTTOM:
            bottomText.push(attribute.text);
            break;
          case PictogramPosition.BOTTOM_ADJACENT:
            bottomAdjacenArray.push(attribute.text);
            break;
          case PictogramPosition.CENTER_SIDE:
            centerSideText.push(attribute.text);
            break;
          case PictogramPosition.TOP_SIDE:
            topSideText.push(attribute.text);
            break;
        }
      }

      this.bottomText = bottomText;
      this.topSideText = topSideText;
      this.topTextArray = topTextArray;
      this.centerSideText = centerSideText;
      this.bottomAdjacenArray = bottomAdjacenArray;
    }
  }
}
