import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LocalizedString } from '../../models/localized-fields.model';
import { SharedModule } from '../../modules/shared.module';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'addiction-chip',
	templateUrl: './chip.component.html',
	standalone: true,
	imports: [
	  CommonModule,
    SharedModule
  ],
})
export class ChipComponent {
	@Input() label: string | LocalizedString = '';
	@Input() removable = true;
	@Output() remove = new EventEmitter<void>();

	onRemove() {
		if (!this.removable) return;
		this.remove.emit();
	}
}
