<ng-container *ngIf="lineChartData?.length">
	<div class="chart-container">
		<div class="header-container">
			<h2 class="title">{{ 'PAGES.DASHBOARD.ROWS.CHART.TITLE' | translate }}</h2>
			<!--TODO: gestire integrazioni successivamente-->
			<!-- <div class="select-container" *ngIf="integrations?.length">
          <mat-select [value]="selectedIntegration" class="structure" (selectionChange)="onChangeIntegration($event)">
            <mat-option [value]="ALL_COMMUNITIES_SELECT_VALUE" *ngIf="showAllCommunityChoice">{{'FILTERS.ALL' |translate}}</mat-option>
              <mat-option value=""> {{ 'FILTERS.NO_COMMUNITY' | translate }}</mat-option>
              <mat-option *ngFor="let integration of integrations" [value]="integration.uuid">
                {{ integration.name | toLocalizedValuePipe }}
            </mat-option>
          </mat-select> -->
		</div>
		<!-- {{ '' // TODO: rimuovere il *ngIf="false" una volta sistemata la pagina interna per il funzionamento con il nuovo be }}
      <grid-options *ngIf="false" [options]="gridOptions" [entityUUID]="gridOptions[0].operation" (event)="manageOptionEvent()">
      </grid-options> -->
    <div class="months-number-container">
      <label *ngFor="let item of monthsNumbers" [ngClass]="monthsNumber === item ? 'selected' : ''" (click)="changeUnit(item)">
        {{ item === 12 ? '1 ' + ('PAGES.DASHBOARD.ROWS.CHART.YEAR' | translate) : item + ' ' + ('PAGES.DASHBOARD.ROWS.CHART.MONTHS' | translate) }}
      </label>
    </div>
    <canvas baseChart [datasets]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptions" [legend]="lineChartLegend" [type]="'line'"></canvas>
  </div>
</ng-container>
