import { CommonModule } from '@angular/common';
import { Component, forwardRef } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { CheckboxMultipleService, ChipComponent, ModalService, transformTypeClassIntoInterface } from 'addiction-components';
import { Observable, filter, map } from 'rxjs';
import { Role } from '../../models';
import { RoleSelectorDialogComponent } from '../role-selector-dialog/role-selector-dialog.component';
import { BaseChipsSelectorComponent, ChipValue } from './base-chips-selector.component';
import { environment } from 'src/environments/environment';

// ℹ️ Segue la documentazione: https://addiction.atlassian.net/wiki/spaces/DOCTEC/pages/2269315073/ChipsSelector

@Component({
	selector: 'datalean-role-chips-selector',
	templateUrl: './chips-selector.component.html',
	styleUrls: ['./chips-selector.component.scss'],
	standalone: true,
	imports: [CommonModule, ChipComponent, ReactiveFormsModule],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: RoleChipsSelectorComponent },
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: forwardRef(() => RoleChipsSelectorComponent),
		},
	],
})
export class RoleChipsSelectorComponent extends BaseChipsSelectorComponent {
	constructor(private modalSrv: ModalService, private gcs: CheckboxMultipleService, private translatePipe: TranslatePipe) {
		super();
	}

	override openDialog(currentValues: ChipValue[]): Observable<{ selected: ChipValue[]; replace?: boolean | undefined }> {
		this.gcs.reset();
		const selectedUUIDs: string[] = currentValues.map(({ uuid }) => uuid);
		return this.modalSrv
			.openDialog(
				RoleSelectorDialogComponent<transformTypeClassIntoInterface<Role>>,
				{ title: this.translatePipe.transform('ROLES') },
				{
					selectedUUIDs: selectedUUIDs,
					endpoint: environment.rolesUrl,
				}
			)
			.result$.pipe(
				filter((result) => result.reason === 'COMPLETE' && !!result.data),
				map(({ data }) => {
					if (!data || !Array.isArray(data.selected)) {
						return { selected: [] };
					}

					const chipValues: ChipValue[] = data.selected
						.filter((row) => typeof row === 'object' && typeof row['uuid'] === 'string')
						.map((row) => {
							return {
								uuid: row['uuid'],
								name: row['name'],
							};
						});

					if (chipValues.length) chipValues;

					this.clear();

					return { selected: chipValues };
				})
			);
	}
}
