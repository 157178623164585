import { Component, Input, OnInit, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { PaginationInfo, Parts, TableRow } from 'addiction-components';
import { BehaviorSubject, Subject, combineLatest, debounceTime, map, shareReplay, startWith, switchMap, tap } from 'rxjs';
import { ApplicationSelector } from 'src/app/core/state/app.selectors';
import { LessonsService } from 'src/app/pages/lms/lessons/services/lessons.service';
import { Lesson } from '../../models';
import { EntitySelectorDialogComponent } from '../entity-selector-dialog/entity-selector-dialog.component';

@Component({
	selector: 'datalean-lessons-selector-dialog',
	templateUrl: './lessons-selector-dialog.component.html',
	styleUrls: ['./lessons-selector-dialog.component.scss'],
})
export class LessonsSelectorDialogComponent extends EntitySelectorDialogComponent implements OnInit {
	private lessonsSrv = inject(LessonsService);
	private store = inject(Store);

	@Input() override selectedUUIDs: string[] = [];
	@Input() limit?: number;

	structureUUID$ = new Subject<string>(); // <-- this filter is mandatory

	fetchParamsLesson$ = combineLatest([this.currentPage$, this.search$, this.sort$, this.store.select(ApplicationSelector.selectActiveLanguage)]).pipe(
		debounceTime(150)
	);

	override tableRows$ = this.fetchParamsLesson$.pipe(
		tap(() => (this.loading = true)),
		// recupera i dati dal server
		switchMap(([pagination, search, sort, locale]) =>
			this.lessonsSrv.getLessons({ parts: [Parts.EMPTY], locale, pagination, sort, filters: { query: search } })
		),
		// notifica cambiamento pagina alla tabella
		tap(({ paginationInfo }) => this.changePage(paginationInfo)),
		map(({ result }) => this.mapData(result)),
		tap((rows) => {
			this.loading = false;
			this._currentPageUUIDs = rows.map((r) => r['uuid'] as string);
		}),
		shareReplay({ refCount: true, bufferSize: 1 })
	);

	private updateSelected$ = new BehaviorSubject<void>(undefined);

	override selectedRows$ = combineLatest([this.tableRows$, this.updateSelected$.asObservable()]).pipe(
		map(([rows]) => rows),
		startWith([]),
		map((rows) => rows.filter((row) => this.selectedUUIDs.includes(row['uuid'] as string)))
	);

	constructor() {
		super();
	}

	ngOnInit(): void {
		// quando cambiano alcuni filtri, deve tornare ad inizio pagina
		combineLatest([this.sort$]).subscribe(() => this.goToPage(0));

		this.loading = true;
	}

	goToPage(pageNumber: number) {
		this.currentPage$.next(new PaginationInfo(this.pageSize, pageNumber));
	}

	filterByName(name: string | null) {
		this.search$.next(name ?? undefined);
	}

	override selectionChanged(selectedRows: TableRow[]) {
		if (this.limit) {
			const selectedUUIDs = selectedRows.filter((r) => !this.selectedUUIDs.some((uuid) => uuid === r['uuid']));
			const changed = [...this.selectedUUIDs.map((uuid) => ({ uuid })), ...selectedUUIDs];
			if (changed.length > this.limit) {
				super.selectionChanged([...this.selectedUUIDs.map((uuid) => ({ uuid })), ...selectedUUIDs].slice(-this.limit));
				return this.updateSelected$.next();
			}
		}
		super.selectionChanged(selectedRows);
		this.updateSelected$.next();
	}

	override mapData(lessons: Lesson[]): TableRow[] {
		// console.log('mapData', lessons);
		return lessons.map((prod) => ({
			uuid: prod.uuid,
			name: prod.name,
		}));
	}

	override close() {
		// console.log('close', this.selectedUUIDs);
		this.closeDialog({ uuids: this.selectedUUIDs });
	}
}
