import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { selectActivePath } from '../navigation/navigation.selectors';
import { CommunitySelectActions, MediaLibraryActions } from 'src/app/core/state/app.actions';
import { mergeMap } from 'rxjs';
import { CommunityRoutes, getReloadActionFromPath } from '../../helpers/routes-utils';
import { ContextStore } from 'context-store';

@Injectable()
export class CommunitySelectEffect {
	private store = inject(Store);
	private ctxStore = inject(ContextStore);
	private actions$ = inject(Actions);

	constructor() {}

	//per evitare di effettuare il triggher di mille effect
	//abbiamo questa bellissima azione globale per l'app che in base alla pagina
	//in cui mi trovo forzerò il reload della pagina perchè è stata cambiata la community
	//e di conseguenza i dati devono essere refreshati (Jakilinho addicted)
	switchoneDiReload$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CommunitySelectActions.setLastCommunitySelected),
			concatLatestFrom(() => [this.store.select(selectActivePath)]),
			mergeMap(([, path]) => {
				//gestione speciale per i path che usano il contextStore
				if (path === CommunityRoutes.MediaLibrary) {
					this.ctxStore.dispatch(MediaLibraryActions.setForceRefreshAssets({}));
					return [CommunitySelectActions.setLastCommunityFromPath({ activePath: path })];
				}
				const action = getReloadActionFromPath(path);
				//aggiorno la mappa con [community - pathPagina], e poi lancio l'azione di force reload
				return [CommunitySelectActions.setLastCommunityFromPath({ activePath: path }), action()];
			})
		)
	);
}
