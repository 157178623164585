import { OperationPermissionEntityType, OperationPermissionEnum, OperationPermissionSubjectType } from "./operation-permission";

export interface OperationPermissionMultipleAddBody {
  entityUUIDs?: string[];
  entityUUIDsMode: 'exclusive' | 'inclusive';
  entityFilter?: { [p: string]: string };
  //DATI DELLA TABELLA
  operationPermissionList: OperationPermissionMultipleAdd[];
}

export interface OperationPermissionMultipleAdd {
  //entityUUid deve essere calcolato a BE
  subjectType: OperationPermissionSubjectType;
  entityType: OperationPermissionEntityType;
  subjectUUID: string; //id gruppo o smartgroup
  operationPermissionChange: Record<OperationPermissionEnum, boolean>;
}
