import { v4 } from 'uuid';
import { EntityStatuses } from './entity-status';
import { ParamType } from './abstract.interface';

export class BaseDataleanEntity {
	organizationUUID?: string;
	updateDate?: string | Date;
	active?: boolean;
	createDate?: string | Date;
	status?: EntityStatuses;

	constructor(public uuid = v4()) {
		this.createDate = new Date();
	}
}

export class DataleanEntity extends BaseDataleanEntity {
	localizedValues?: BaseLocalizedValues[];
	structureUUID?: string;
	communityUUID?: string;

	constructor() {
		super();
	}
}

export interface BaseLocalizedValues {
	locale: string;
}

export type DataleanDatabaseEntity<T = DataleanEntity> = T & { _id?: string };

export type SimpleObject = { [param: string]: ParamType };
