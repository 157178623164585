import { Point } from "@angular/cdk/drag-drop";

/**
 * prende una coordinata(x o y) in pixel e la traduce in percentuale usando la base
 * @param pixel x in pixel o y in pixel
 * @param base width( in pixel ) per quando usi le x, o la height per quando usi le y
 * @returns la coorinata tradotta in percentuale
 */
export function getPercentageFromPixel(pixel: number|string, base: number){
  const pixelNumber = Number(pixel);
  return (pixelNumber * 100)/base
}

/**
 * prende una coordinata(x o y) in percentuale e la traduce in pixel usando la base (in pixel)
 * @param percentage x in percentuale o y in percentuale
 * @param base width( in pixel ) per quando usi le x, o la height per quando usi le y
 * @returns la coorinata tradotta in pixel
 */
export function getPixelFromPercentage(percentage: number|string, base: number){
  const percentageNumber = Number(percentage);
  return (percentageNumber * base)/100
}

/**
 * preso un punto con coordinate in percentuale, ritorna un punto "safe" con coordinate sicuramente valide
 * @param point punto da rendere "safe"
 * @returns un punto con coordinate nel range [0,100]
 */
export function safeHotspotRangePercentage(point: Point|undefined): Point{
  if(!point) return {x:0,y:0};
  point.x = safeCoordinateRangePercentage(point.x);
  point.y = safeCoordinateRangePercentage(point.y);

  return point;
}

/**
 * preso un punto {x,y} ritorna una coordinata "safe" in base alle dimensioni passate {width, height}
 * @param point punto che deve essere contenuto nel contenitore {width}x{height}
 * @param width ampiezza del contentore
 * @param height altezza del contenitore
 * @returns coordinata sicuramente contenuta nel contenitore {width}x{height}, in caso di punti fuoriuscenti ci si avvicina al limite più vicino
 */
export function safeHotspotRangePixel(point: Point|undefined, width: number, height: number): Point{
  if(!point) return {x:0,y:0};
  if(point.x < 0) point.x = 0;
  if(point.x > width) point.x = width;
  if(point.y < 0) point.y = 0;
  if(point.y > height) point.y = height;

  point.x = Number(point.x.toFixed(6))
  point.y = Number(point.y.toFixed(6))

  return point;
}

/**
 * presa una coordinata in percentuale fa i controlli di range [0,100] e seta una precisione
 * massima di 6 decimali oltre la virgola
 * @param coordinate coordinata in percentuale
 * @returns ritorna una coordinata "safe", se viene passato un valore oltre il range [0,100] viene settato il valore dell'estremo più vicino
 */
export function safeCoordinateRangePercentage(coordinate: number|undefined): number {
  if(!coordinate) return 0;
  if(coordinate < 0) return 0
  else if (coordinate > 100) return 100

  return Number(coordinate.toFixed(6));
}
