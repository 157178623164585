import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { DataleanBaseApiService, DataleanPagedResult, PaginationInfo, Parts, SimpleObject, SortInfo } from 'addiction-components';
import { Observable, forkJoin } from 'rxjs';
import { HeaderLocaleService } from 'src/app/core/components/header/service/header-locale.service';
import { BESurvey, Communication } from 'src/app/shared/models';
import { environment } from 'src/environments/environment';
import { SurveyAnswer } from '../survey-communication-detail/survey-communication-detail.component';

@Injectable({
	providedIn: 'root',
})
export class SurveysService {
	constructor(private headerSrv: HeaderLocaleService, private baseApi: DataleanBaseApiService) {}

	fetchSurveys(pages: number[], sort?: Sort, locale?: string, gridSearch?: string | null, communityUUID?: string): Observable<DataleanPagedResult<BESurvey>[]> {
		const obs: Observable<DataleanPagedResult<BESurvey>>[] = [];

		const params: {
      sortBy?: string;
      q?: string;
      searchFields?: string;
      locale?: string;
      type: 'survey' | 'quiz',
      communityUUID?: string
    } = { locale, type: 'survey' };

		//SORT
		if (sort) {
			params.sortBy = `${sort.active}#${sort.direction}`;
		} else {
			params.sortBy = `updateDate#desc`;
		}
		if (gridSearch) {
			params.q = gridSearch;
			params.searchFields = 'name,description';
		}
    if (communityUUID) params.communityUUID = communityUUID

		for (const page of pages) {
			const pagination: PaginationInfo = new PaginationInfo(environment.pageSize, page);

			obs.push(
				this.baseApi.getManyPaged<BESurvey>(environment.surveyUrl, [Parts.CREATED_BY, Parts.UPDATE_DATE], {
					pagination,
					additionalParams: params,
				})
			);
		}
		return forkJoin(obs);
	}

	fetchSurveyCommunications(surveyUUID: string, pages: number[], sort?: Sort, locale?: string, gridSearch?: string | null) {
		const obs: Observable<DataleanPagedResult<Communication>>[] = [];

		const params: { sortBy?: string; q?: string; searchFields?: string; locale?: string; surveyUUID: string } = { locale, surveyUUID };

		//SORT
		if (sort) {
			params.sortBy = `${sort.active}#${sort.direction}`;
		} else {
			params.sortBy = `updateDate#desc`;
		}
		if (gridSearch) {
			params.q = gridSearch;
			params.searchFields = 'name,description';
		}

		for (const page of pages) {
			const pagination: PaginationInfo = new PaginationInfo(environment.pageSize, page);

			obs.push(
				this.baseApi.getManyPaged<Communication>(environment.communicationUrl, [Parts.CREATED_BY], {
					pagination,
					additionalParams: params,
				})
			);
		}
		return forkJoin(obs);
	}

  getSurveyResponse(surveyUUID: string, communicationUUID: string,  parts: Array<Parts>) {
    return this.baseApi.getEntities<SurveyAnswer[]>(environment.surveyResponseUrl + 'getQuestionAnswers', { surveyUUID, communicationUUID }, parts);
	}

  getSurvey(surveyUUID: string, parts: Array<Parts>) {
    return this.baseApi.getOne<BESurvey>(environment.surveyUrl, surveyUUID, parts);
  }

	getSurveys(options: {
		parts?: Parts[];
		locale?: string;
		pagination?: PaginationInfo;
		sort?: SortInfo;
		filters?: { uuids?: string[]; query?: string };
	}) {
		const { filters, parts = [], locale, pagination, sort } = options;

		const params: SimpleObject = {
			locale: locale ?? this.headerSrv.getActiveLocale(),
			active: true,
		};

		// TODO: generalizzare questa logica in una funzione reutilizzabile
		if (filters?.query) {
			params['q'] = filters.query;
			params['searchFields'] = ['name']; // parametrizzare i campi di ricerca??
		}

		if (filters?.uuids) params['uuid'] = filters.uuids.join(',');
		return this.baseApi.getManyPaged<BESurvey>(environment.surveyUrl, parts, {
			pagination,
			sort: sort ?? new SortInfo('updateDate', 'desc'),
			additionalParams: params,
		});
	}

	deleteSurvey(survey: BESurvey) {
		return this.baseApi.deleteOne(environment.surveyUrl, survey.uuid);
	}
}
