import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../../shared.module';
import { TreeBrowserComponent } from './tree-browser.component';
import { LetDirective } from '@ngrx/component';
import { ChipComponent } from 'addiction-components';

@NgModule({
	declarations: [TreeBrowserComponent],
	imports: [CommonModule, DragDropModule, SharedModule, MatCheckboxModule, FormsModule, DragDropModule, LetDirective, ChipComponent],
	exports: [TreeBrowserComponent],
})
export class TreeBrowserModule {}
