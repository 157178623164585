import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { catchError, map, of, switchMap } from 'rxjs';
import { ConfigActions } from '../../../core/state/app.actions';
import { ConfigurationService } from '../../services/configuration.service';
import { Configuration, MessageHandler } from 'addiction-components';

@Injectable()
export class ConfigEffect {
	private actions$ = inject(Actions);
	private translateService = inject(TranslateService);
	private configService = inject(ConfigurationService);
	private messageHandler = inject(MessageHandler);

	constructor() {}

	fetchConfigs$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ConfigActions.fetchConfig, ConfigActions.createConfigSuccess, ConfigActions.updateConfigSuccess, ConfigActions.deleteConfigSuccess),
			switchMap(() => this.configService.fetchConfig()),
			map((configurations: Configuration[]) => {
				for (const config of configurations) {
					switch (config.key) {
						case 'activeLocales':
							this.translateService.addLangs(config.value as string[]);
							break;
						case 'defaultLocale':
							this.translateService.setDefaultLang(config.value as string);
							break;
						default:
							break;
					}
				}

				const configurationMap: { [key: string]: Configuration } = configurations.reduce((map: { [key: string]: Configuration }, config) => {
					map[config.key] = config;
					return map;
				}, {});

				return ConfigActions.fetchConfigSuccess({ configurationMap });
			}),
			catchError((error: unknown) => of(ConfigActions.fetchConfigError({ error })))
		)
	);

		// UPDATE ONE
		updateConfig$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ConfigActions.updateConfig),
			switchMap(({ Config }) =>
				this.configService.updateConfig(Config).pipe(
					map((Config) => {
						this.messageHandler.createToast('SETTINGS.CONFIGURATIONS.SUCCESS_MESSAGE');
						return ConfigActions.updateConfigSuccess({ Config });
					}),
					catchError((error) => {
						this.messageHandler.createErrorToast('SETTINGS.CONFIGURATIONSERROR_MESSAGE');
						return of(ConfigActions.updateConfigFailure({ error }));
					})
				)
			)
		)
	);

	// CREATE ONE
	createConfig$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ConfigActions.createConfig),
			switchMap(({ Config }) =>
				this.configService.createConfig(Config).pipe(
					map((Config) => {
						this.messageHandler.createToast('SETTINGS.CONFIGURATIONS.SUCCESS_MESSAGE');
						return ConfigActions.createConfigSuccess({ Config });
					}),
					catchError((error) => {
						this.messageHandler.createErrorToast('SETTINGS.CONFIGURATIONS.ERROR_MESSAGE');
						return of(ConfigActions.createConfigFailure({ error }));
					})
				)
			)
		)
	);

	// DELETE ONE
	deleteConfig$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ConfigActions.deleteConfig),
			switchMap(({ uuid }) =>
				this.configService.deleteConfig(uuid).pipe(
					map(() => ConfigActions.deleteConfigSuccess({ uuid })),
					catchError((error) => of(ConfigActions.deleteConfigFailure({ error })))
				)
			)
		)
	);
}
