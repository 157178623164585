import { HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { ParamType } from '../models';
import { PaginationInfo, Parts, SearchInfo, SimpleObject, SortInfo } from 'addiction-components';

/** Classe helper per costruire URL per accedere alle risorse REST
 * @deprecated Utilizzare le API base di DataleanApiService
 */
export class UrlBuilder {
	private _parts = new Array<Parts>();
	private _httpParams:
		| { headers?: HttpHeaders | Record<string, string | Array<string>>; context?: HttpContext; params?: HttpParams | SimpleObject }
		| undefined;
	private requestUrl: string = '';

	constructor(protected url: string | string[] = '') {
		if (Array.isArray(url)) this.requestUrl = url.map((e) => (e.slice(-1) === '/' ? e.slice(0, -1) : e)).join('/');
		else this.requestUrl = url;
	}

	withQueryParam(queryParam: string, value: ParamType) {
		const { params } = this._httpParams ?? {};
		this._httpParams = { ...this._httpParams, params: { ...params, [queryParam]: value } };
		return this;
	}

	withOrganizationUUID(organizationUUID: string) {
		this.withQueryParam('organizationUUID', organizationUUID);
		return this;
	}

	withPaginationInfo(paginationInfo: PaginationInfo) {
		return this.withQueryParam('numberOfPage', (paginationInfo.numberOfPage + 1).toFixed()).withQueryParam(
			'numberOfPageElements',
			paginationInfo.numberOfElementsOnPage.toFixed(0)
		);
	}

	withSearchFilter(searchInfo: SearchInfo) {
		this.withQueryParam(searchInfo.searchValueParamName, searchInfo.query);
		this.withQueryParam(searchInfo.searchFieldsParamName, searchInfo.searchFields);
		return this;
	}

	withParts(parts: Parts[]) {
		this._parts = parts;
		return this;
	}

	withAdditionalParameter(parameter: SimpleObject): UrlBuilder {
		for (const key in parameter) {
			if (parameter[key] !== undefined) {
				this.withQueryParam(key, parameter[key]);
			}
		}
		return this;
	}

	withSortClause(sortingField: string, sortingDirection: string) {
		this.withQueryParam('sortBy', sortingField + '#' + sortingDirection);
		return this;
	}

	withSortInfo(sortInfo: SortInfo[] | SortInfo) {
		let sortString = '';
		if (Array.isArray(sortInfo)) {
			sortInfo.forEach((sort) => {
				if (sortString.length > 0) {
					sortString += ',';
				}
				sortString += sort.sortBy + '#' + sort.sortDirection;
			});
		} else {
			sortString = sortInfo.sortBy + '#' + sortInfo.sortDirection;
		}
		this.withQueryParam('sortBy', sortString);
		return this;
	}

	withSortClauses(sortingClauses: Map<string, string>) {
		const params = new Array<string>();
		sortingClauses.forEach((sortingDirectionValue: string, sortingFieldKey: string) => {
			params.push(encodeURIComponent(sortingFieldKey + '#' + sortingDirectionValue));
		});
		this.withQueryParam('sortBy', params.join(','));

		return this;
	}

	withHttpParams(params: HttpParams | SimpleObject) {
		this._httpParams = { ...this._httpParams, ...params };
		return this;
	}

	build() {
		this.withQueryParam('parts', this._parts.join(','));
		return { url: this.requestUrl, ...this._httpParams };
	}
}
