import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Page } from 'addiction-components';

type Flatten<Type> = Type extends Array<infer Item> ? Item : Type;
class TypedFormArray<T> extends FormArray<FormControl<T> | TypedFormGroup<T>> {}
class TypedFormGroup<T> extends FormGroup<ModelForm<T>> {}

export type ModelForm<T> = {
  [K in keyof T]: T[K] extends Array<unknown>
    ? TypedFormArray<Flatten<T[K]>> | FormControl<T[K]>
    : FormControl<T[K]>;
};


export const isPage = (obj: unknown): obj is Page => {
	if (!obj) return false;
	if (typeof obj !== 'object') return false;
	if (obj instanceof Page) return true;

	if ('pageNumber' in obj && 'pageSize' in obj && 'totalElements' in obj) return true;

	return false;
};
