import { HttpErrorResponse } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { FeatureValue, Page, Structure } from 'addiction-components';
import { Recipient, STATE_STATUS } from 'src/app/shared/models';
import { Communication } from '../../../shared/models/communication.interface';
import { FiltersType } from './communication.reducers';
import { CommunicationError } from './communication.state';

export const setFilters = createAction('[Communications] Set Filters', props<{ filters: FiltersType }>());
// export const setSort = createAction('[Communications] Set Sort', props<{ sort: SortInfo }>());
export const setStructures = createAction('[Communications] Set Communications Structures', props<{ structures: Array<Structure> }>());
export const setFeatures = createAction('[Communications] Set Communications Features', props<{ features: Array<FeatureValue> }>());
export const updateFilters = createAction('[Communications] Update Filters', props<{ updatedFilters: FiltersType }>());
export const addFeatureValueToFilters = createAction('[Communications] Add FeatureValue To Filters', props<{ featureValueUUID: string }>());
export const removeFeatureValueFromFilters = createAction('[Communications] Remove FeatureValue From Filters', props<{ featureValueUUID: string }>());
export const updatePage = createAction('[Communications] Update Page', props<{ updatedPage: Partial<Page> }>());
export const deleteCommunications = createAction('[Communications] Delete Communications', props<{ uuids: string }>());
export const disableCommunications = createAction('[Communications] Disable Communications', props<{ uuids: string[] }>());

export const setForceRefreshCommunications = createAction('[Communications] Set Force Refresh');
export const setCommunications = createAction('[Communications] Set Datalean Communications');
export const setCommunicationsSuccess = createAction(
	'[Communications] Set Datalean Communications Success',
	props<{ data: { totalCommunicationsCount: number; pages: number[]; communications: Communication[][] } }>()
);
export const setCommunicationsError = createAction(
	'[Communications] Set Datalean Communications Error',
	props<{ error: HttpErrorResponse }>()
);
export const setDeleteCommunications = createAction(
	'[Communications] Set Delete Datalean Communications',
	props<{ communication: Communication }>()
);
export const setDeleteCommunicationsSuccess = createAction('[Communications] Set Delete Datalean Communications Success');
export const setDeleteCommunicationsError = createAction(
	'[Communications] Set Delete Datalean Communications Error',
	props<{ error: HttpErrorResponse }>()
);
export const setDisableCommunicationsSuccess = createAction('[Communications] Set Delete Datalean Communications Success');
export const setDisableCommunicationsError = createAction('[Communications] Set Delete Datalean Communications Success');

export const setStatus = createAction('[Communications] Set status', props<{ status: STATE_STATUS }>());
export const setPages = createAction('[Communications] Set pages', props<{ pages: number[] }>());
export const setSort = createAction('[Communications] Set sort', props<{ sort: Sort }>());
export const setGridSearch = createAction('[Communications] Set search', props<{ search: string | null }>());
export const setRecipients = createAction('[Communications] Set recipients', props<{ recipients: Recipient[] }>());
export const setCachedPages = createAction('[Communications] Set cached pages', props<{ pages: number[] }>());
export const resetCache = createAction('[Communications] Reset cached pages');

// Error
export const error = createAction('[Communications] Error', props<{ error: CommunicationError }>());
