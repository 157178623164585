import { Sort } from '@angular/material/sort';
import { Action, createReducer, on } from '@ngrx/store';
import { SurveyCommunicationsActions } from 'src/app/core/state/app.actions';
import { Communication, STATE_STATUS } from 'src/app/shared/models';

export const DATALEAN_SURVEY_COMMUNICATIONS_TAG = 'surveyCommunications';

export interface SurveyCommunicationsState {
	gridSearch: string | null;
	sort?: Sort;
	data: { totalCommunicationsCount: number; communications: Communication[][]; hasResponse: boolean };
	pages: number[];
	status: STATE_STATUS;
	deleteStatus: STATE_STATUS;
	currentSurveyUUID: string | null;
}

const initialState: SurveyCommunicationsState = {
	status: STATE_STATUS.UNKNOWN,
	deleteStatus: STATE_STATUS.UNKNOWN,
	gridSearch: null,
	pages: [],
	data: { totalCommunicationsCount: 0, communications: [], hasResponse: false },
	currentSurveyUUID: null,
};

export const reducers = createReducer(
	initialState,
	on(SurveyCommunicationsActions.setSort, (state: SurveyCommunicationsState, { sort }) => ({
		...state,
		sort,
	})),
	on(SurveyCommunicationsActions.setPages, (state: SurveyCommunicationsState, { pages }) => ({
		...state,
		pages,
	})),
	on(SurveyCommunicationsActions.setCurrentSurveyUUID, (state: SurveyCommunicationsState, { uuid }) => ({
		...state,
		currentSurveyUUID: uuid,
	})),
	on(SurveyCommunicationsActions.setSurveyCommunicationsSuccess, (state: SurveyCommunicationsState, { data }) => {
		return {
			...state,
			status: STATE_STATUS.READY,
			data: {
				communications: data.communications,
				totalCommunicationsCount: data.totalCommunicationsCount,
				hasResponse: data.hasResponse,
			},
			pages: data.pages,
		};
	}),
	on(SurveyCommunicationsActions.setSurveyCommunicationsError, (state: SurveyCommunicationsState) => ({
		...state,
		status: STATE_STATUS.ERROR,
	})),
	on(SurveyCommunicationsActions.setStatus, (state: SurveyCommunicationsState, { status }) => {
		return {
			...state,
			status,
		};
	}),
	on(SurveyCommunicationsActions.setGridSearch, (state: SurveyCommunicationsState, { search }) => {
		return {
			...state,
			gridSearch: search,
		};
	})
);

export const SurveyCommunicationsReducer = (state: SurveyCommunicationsState | undefined, action: Action) => {
	return reducers(state, action);
};
