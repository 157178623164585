import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HeaderReducer } from 'src/app/core/state/app.reducers';
import { HeaderState } from './header.state';

const selectHeaderState = createFeatureSelector<HeaderState>(HeaderReducer.TAG);

export const getShowLocales = createSelector(selectHeaderState, (state) => state.showLocales);
export const getShowCommunitySelect = createSelector(selectHeaderState, (state) => state.showCommunitySelect);
export const selectShowBackButton = createSelector(selectHeaderState, (state) => state.showBackButton);
export const selectShowPlusButton = createSelector(selectHeaderState, (state) => state.showPlusButton);
export const selectBackButtonUrl = createSelector(selectHeaderState, (state) => state.backButtonUrl);
export const selectShowWaringOnBack = createSelector(selectHeaderState, (state) => state.showWarningOnBack);
export const selectRemoveButton = createSelector(selectHeaderState, (state) => state.showRemoveButton);
export const selectMediaLibraryGridViewOptions = createSelector(selectHeaderState, (state) => state.options);
