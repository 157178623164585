import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, DestroyRef, Input, OnDestroy, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { Editor, NgxEditorModule, NgxEditorService, Toolbar, Validators } from 'ngx-editor';

//documentation: https://sibiraj-s.github.io/ngx-editor/#/
@Component({
	selector: 'addiction-html-editor',
	templateUrl: './html-editor.component.html',
	standalone: true,
	imports: [CommonModule, FormsModule, ReactiveFormsModule, NgxEditorModule],
})
export class HtmlEditorComponent implements AfterViewInit, OnDestroy {
	editor: Editor = new Editor();
	toolbar: Toolbar = [
		['bold', 'italic', 'underline', 'strike'],
		['ordered_list', 'bullet_list'],
		[{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
		['link'],
		['text_color', 'background_color'],
		['align_left', 'align_center', 'align_right', 'align_justify'],
	];

	@Input() control!: UntypedFormControl;
	@Input() readonly: boolean = false;

	form = new FormGroup({
		editorContent: new FormControl(null, [Validators.required()]),
	});

	protected destroyRef = inject(DestroyRef);

	constructor(private ngxEditorService: NgxEditorService) {
		this.ngxEditorService.config.locals = {
			heading: 'Heading',
			h1: 'Header 1',
			h2: 'Header 2',
			h3: 'Header 3',
			h4: 'Header 4',
			h5: 'Header 5',
			h6: 'Header 6',
			url: 'URL',
			text: 'Testo',
			openInNewTab: 'Apri in una nuova tab',
			insert: 'Insert',
			remove: 'Rimuovi',
		};
	}

	ngAfterViewInit(): void {
		// console.log(this.readonly, this.control?.disabled);
		if (this.readonly || this.control?.disabled) {
			this.form.disable();
		}

		// console.log(this.control)
		if (this.control?.value) {
			// console.log(this.control.value);
			this.form.patchValue({ editorContent: this.control.value });
		}

		// this.control?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef), tap(console.log)).subscribe({
		//   // next: (v) => this.form.patchValue({ editorContent: v }),
		// });

		this.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
			next: (v) => this.control.patchValue(v.editorContent),
		});
	}

	ngOnDestroy(): void {
		this.editor.destroy();
	}
}
