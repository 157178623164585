import { CdkDragMove } from '@angular/cdk/drag-drop';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { distinctUntilChanged, map, shareReplay, Subject, throttleTime } from 'rxjs';

/** Service used to shared drag&drop events between connected lists */
@Injectable()
export class SharedDragDropService {
	private readonly _moveEvents$ = new Subject<CdkDragMove<unknown>>();
	public readonly moveEvents$ = this._moveEvents$.asObservable().pipe(throttleTime(200));

	public lastDropTarget?: Element;
	public readonly dropTarget$ = this.moveEvents$.pipe(
		map((evt) => {
			const hoveringEl = this.document.elementFromPoint(evt.pointerPosition.x, evt.pointerPosition.y);
			if (!hoveringEl) return undefined;

			const dropTarget = hoveringEl.hasAttribute('drop-target') ? hoveringEl : hoveringEl.closest('[drop-target]');
			return dropTarget ?? undefined;
		}),
		distinctUntilChanged(),
		shareReplay({ refCount: true, bufferSize: 1 })
	);

	constructor(@Inject(DOCUMENT) private document: Document) {
		this.dropTarget$.subscribe((item) => (this.lastDropTarget = item));
	}

	/** Broadcast a move event to any other shared list connected */
	public registerMoveEvent(event: CdkDragMove<unknown>) {
		this._moveEvents$.next(event);
	}
}
