import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../shared/services/auth/auth.service';
import { DataleanHTTPError } from 'src/app/shared/models';

@Injectable({ providedIn: 'root' })
export class AuthJWTInterceptor implements HttpInterceptor {
	constructor(private authSrv: AuthService) {}
	intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (!req.url.includes(environment.authenticationUrl))
			req = req.clone({
				setHeaders: {
					Authorization: 'Bearer ' + this.authSrv.idToken,
				},
			});

		return next.handle(req).pipe(
			catchError((error: unknown) => {
				if (error instanceof HttpErrorResponse) {
					if ((error.status === 401 || error.status === 403) && !req.url.includes(environment.authenticationUrl)) {
						this.authSrv.logout();
					}
				}
				if (error instanceof DataleanHTTPError) {
					if (error.httpStatus === 401 && !req.url.includes(environment.authenticationUrl)) {
						this.authSrv.navigateToLoginPage();
					}
				}
				return throwError(() => error);
			})
		);
	}
}
