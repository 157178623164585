import { createReducer, on } from '@ngrx/store';
import { UsefulLinksActions } from 'src/app/core/state/app.actions';
import { STATE_STATUS } from 'src/app/shared/models';
import { StructureType } from 'addiction-components';
import { UsefulLinksState } from './useful-links.state';

export const TAG = 'usefulLinks';

export interface FiltersType {
	structureUUID?: string;
	communityUUID?: string;
	active?: boolean;
	updateDate?: string;
	communityEntityUUID?: string;
	[key: string]: unknown;
}

const initialState: UsefulLinksState = {
	filters: {
		featureValue: [],
		active: true,
		'structure.type': [StructureType.COCKPIT],
	},
	sort: undefined,
	pages: [0],
	data: { totalUsefulLinksCount: 0, usefulLinks: [] },
	structures: [],
	status: STATE_STATUS.UNKNOWN,
	gridSearch: null,
	deleteStatus: 'unknown',
	cachedPages: [],
};

export const reducer = createReducer(
	initialState,
	/**
	 * Parte standard di un reducer
	 */
	on(UsefulLinksActions.setSort, (state: UsefulLinksState, { sort }) => ({
		...state,
		sort,
	})),
	on(UsefulLinksActions.setPages, (state: UsefulLinksState, { pages }) => ({
		...state,
		pages,
	})),
	on(UsefulLinksActions.setUsefulLinksSuccess, (state: UsefulLinksState, { data }) => {
		return {
			...state,
			status: STATE_STATUS.READY,
			data: {
				usefulLinks: data.usefulLinks,
				totalUsefulLinksCount: data.totalUsefulLinksCount,
			},
			pages: data.pages,
		};
	}),
	on(UsefulLinksActions.setUsefulLinksError, (state: UsefulLinksState) => ({
		...state,
		status: STATE_STATUS.ERROR,
	})),
	on(UsefulLinksActions.setStatus, (state: UsefulLinksState, { status }) => {
		return {
			...state,
			status,
		};
	}),
	on(UsefulLinksActions.setStructures, (state: UsefulLinksState, { structures }) => ({
		...state,
		structures,
	})),
	on(UsefulLinksActions.setGridSearch, (state: UsefulLinksState, { search }) => {
		return {
			...state,
			gridSearch: search,
		};
	}),
	on(UsefulLinksActions.setDeleteUsefulLinks, (state: UsefulLinksState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.LOADING,
		};
	}),
	on(UsefulLinksActions.setDeleteUsefulLinksSuccess, (state: UsefulLinksState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.READY,
		};
	}),
	on(UsefulLinksActions.setDeleteUsefulLinksError, (state: UsefulLinksState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.ERROR,
		};
	}),
	on(UsefulLinksActions.setRecipients, (state: UsefulLinksState, { recipients }) => {
		return {
			...state,
			recipients: recipients,
		};
	}),
	/**
	 * PARTE SPECIFICA DEL REDUCER
	 */
	on(UsefulLinksActions.setFilters, (state: UsefulLinksState, { filters }) => ({
		...state,
		filters: {
			...filters,
			structureUUID: filters.structureUUID !== 'allStructures' ? filters.structureUUID : undefined,
			communityUUID: filters.communityEntityUUID,
			'structure.type': [StructureType.COCKPIT],
		},
	})),
	on(UsefulLinksActions.updateFilters, (state: UsefulLinksState, { updatedFilters }) => ({
		...state,
		filters: {
			...state.filters,
			...updatedFilters,
		},
	})),
	on(UsefulLinksActions.setCachedPages, (state: UsefulLinksState, { pages }) => ({
		...state,
		cachedPages: [...new Set([...state.cachedPages, ...pages])],
	})),
	on(UsefulLinksActions.resetCache, (state: UsefulLinksState) => ({
		...state,
		cachedPages: [],
		status: STATE_STATUS.READY,
		pages: [0],
		data: { totalUsefulLinksCount: 0, usefulLinks: [] },
	}))
);
