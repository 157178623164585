import { Injectable, computed, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DataleanBaseApiService, Parts, SimpleObject } from 'addiction-components';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FeedbackType } from '../models/feedback/feedback-type.enum';
import { Feedback, FeedbackWithDateString } from '../models/feedback/feedback.interface';
import { STATE_STATUS } from '../models/state-status.enum';

@Injectable({ providedIn: 'root' })
export class FeedbackService {
	//state
	private _feedback = signal<Array<Feedback> | null>(null);
	private _status = signal<STATE_STATUS>(STATE_STATUS.LOADING);
	private dataleanDataService = inject(DataleanBaseApiService);

	// selectors
	feedback = computed(() => this._feedback());
	status = computed(() => this._status());

	filteredFeedbackHasAccessed = computed(() => {
		const feedback = this.feedback();
		if (feedback) {
			const filteredFeedback = this.filterFeedback(feedback, FeedbackType.HAS_ACCESSED);
			return filteredFeedback;
		}
		return undefined;
	});

	filteredFeedbackHasLogged = computed(() => {
		const feedback = this.feedback();
		if (feedback) {
			const filteredFeedback = this.filterFeedback(feedback, FeedbackType.HAS_LOGGED);
			return filteredFeedback;
		}
		return undefined;
	});

	//action rxjs
	feedbackLoaded$ = this.getUpdateLastDates({
		userType: 'ApplicationUser',
		feedbackType: `${FeedbackType.HAS_ACCESSED},${FeedbackType.HAS_LOGGED}`,
	});

	constructor() {
		//  reducers rxjs
		this.feedbackLoaded$.pipe(takeUntilDestroyed()).subscribe({
			next: (feedback) => {
				this.setFeedback(feedback);
				this.setStatus(STATE_STATUS.READY);
			},
			error: () => this.setStatus(STATE_STATUS.ERROR),
		});
	}

	// actions/reducers signal
	private setStatus(status: STATE_STATUS) {
		this._status.set(status);
	}
	private setFeedback(feedback: Array<Feedback>) {
		this._feedback.set(feedback);
	}

	private filterFeedback(feedback: Feedback[], feedbackType: FeedbackType): Array<FeedbackWithDateString> {
		return feedback
			.filter((feedbackEntry) => feedbackEntry.feedbackType === feedbackType)
			.flatMap((feedbackEntry) => feedbackEntry.date.map((date) => ({ ...feedbackEntry, date })));
	}

	getUpdateLastDates(additionalParams: SimpleObject, entityUUID?: string): Observable<Array<Feedback>> {
		let feedbackUrl = environment.applicationUsersUrl + 'feedbacks';
		if (entityUUID) {
			feedbackUrl = environment.applicationUsersUrl + entityUUID + '/feedback';
		}
		return this.dataleanDataService.getMany<Feedback>(feedbackUrl, [Parts.EMPTY], additionalParams);
	}
}
