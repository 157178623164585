<div class="paginator">
	<div class="prev-page" (click)="prev()" [class.disabled]="!canGoBackward">
		<mat-icon>arrow_back_ios</mat-icon>
	</div>

	<div class="pages">
		<span *ngFor="let pageInfo of pages" class="page" [class.active]="pageInfo.page === currentPage" (click)="changePage(pageInfo.page)">
			<ng-container *ngIf="!pageInfo.fake; else fakePage">
				<span>{{ pageInfo.page + 1 }}</span>
			</ng-container>
			<ng-template #fakePage>
				<span>...</span>
			</ng-template>
		</span>
	</div>

	<div class="next-page" (click)="next()" [class.disabled]="!canGoForward">
		<mat-icon>arrow_forward_ios</mat-icon>
	</div>
</div>
