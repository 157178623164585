import { SubjectType } from './user.interface';

export enum EntityType {
	STRUCTURE = 'structure',
	SURVEY = 'survey',
	STRUCTURE_FIELD = 'structureField',
	FEATURE_VALUE = 'featureValue',
	FEATURE = 'feature',
	PRODUCT = 'product',
	PRODUCTVARIANTS = 'product_variants',
	WISHLIST = 'wishlist',
	COMMUNICATION = 'communication',
	COCKPIT = 'cockpit',
	FILTER = 'filter',
	BOOKABLE_RESOURCE = 'bookableResource',
	ASSET = 'asset',
	ALBUM = 'album',
	CONTENT = 'content',
}

export interface EntityViewPermission {
	uuid?: string;
	canView: boolean;
	entityType: EntityType;
	entityUUID: string;
	subjectType: SubjectType;
	subjectUUID: string;
}
