import { DataleanEntity } from '../datalean-entity';
import { FeatureValue } from '../feature/feature-value.model';
import { ObjectValuesType } from '../object-values-type';
import { Tag } from '../tag';
import { Thumbnail } from './thumbnail-config';

export interface Asset extends DataleanEntity {
	uuid: string;
	name: string;
	extension: string;
	originalName: string;
	assetType: AssetType;
	bytes?: number;
	childrenCount?: number;
	createDate?: string;
	format: string;
	parentUUID: string | null;
	path: string;
	schemaVersion?: string;
	updateDate?: string;
	url: string;
	children?: Asset[];
	previewUUID?: string;
	index?: number;
	/** Soggetto a PARTs */
	preview?: {
		url: string;
		uuid: string;
		format: string;
	};
	featureValueList?: FeatureValue[];
	communityUUID?: string;
	originalAssetUUID?: string;
	isPreview?: boolean;
	thumbnailsRefs?: Thumbnail[];
	order?: number;
	tags?: Tag[];
	originalHeight?: number; //solo per immagini
	originalWidth?: number; //solo per immagini
}

export const AssetType = {
	FOLDER: 'folder',
	IMAGE: 'image',
	VIDEO: 'video',
	PDF: 'pdf',
	RAW: 'raw',
	PREVIEW: 'preview',
	APPLICATION: 'application',
	DEFAULT: 'folder#not',
} as const;

export type AssetType = ObjectValuesType<typeof AssetType>;

export const AssetFormat = {
	PNG: 'png',
	JPEG: 'jpeg',
	GIF: 'gif',
} as const;
export type AssetFormat = ObjectValuesType<typeof AssetFormat>;
