import { createReducer, on } from "@ngrx/store";
import { RecipientState } from "./recipients.state";
import { setErrorStatusRecipients, setRecipients } from "./recipients.actions";
import { STATE_STATUS } from "../../models";

export const TAG_RECIPIENTS = 'recipients';

const initialState: RecipientState = {
  recipients: undefined,
  status: STATE_STATUS.UNKNOWN
};

export const reducer = createReducer(
	initialState,
  on(setRecipients, (state: RecipientState, { recipients }) => {
    return {
      ...state,
      recipients: recipients,
      status: STATE_STATUS.READY
    };
  }),
  on(setErrorStatusRecipients, (state: RecipientState) => {
    return {
      ...state,
      status: STATE_STATUS.ERROR,
    };
  }),
)
