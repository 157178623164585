import { AbstractControl, ValidatorFn } from "@angular/forms";

/** Valida la complessità della password */
export function passwordComplexity(): ValidatorFn {
    return (control: AbstractControl) => {
        const validationResult = validatePassword(control.value as string);

        if (validationResult.isValid())
            return null;

        const errors = validationResult.getErrors();
        // mappa il risultato ad un oggetto adatto alle reactive forms
        const error: { [key: string]:  (keyof PasswordValidatorResultSet)[] | boolean } = {
            complexity: errors
        };
        for (const failedCheck of errors) {
            error[failedCheck] = true;
        }
        return error;
    }
}

export const validatePassword = (password: string) : PasswordValidationResult => {
  password = password || '';

  const lengthCheck = password.length >= 8;
  const lowerCaseLetter = (/[a-z]/.test(password));
  const upperCaseLetter = (/[A-Z]/.test(password));
  const numberCheck = (/[0-9]/.test(password));
  const special = (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password));
  return new PasswordValidationResult({
    LENGTH: lengthCheck,
    LOWER: lowerCaseLetter,
    UPPER: upperCaseLetter,
    NUMBER: numberCheck,
    SPECIAL: special
  });
}

/** Risultato della validazione di una password */
export class PasswordValidationResult {
  public static readonly PossibleChecks:(keyof PasswordValidatorResultSet)[] = ['LENGTH','LOWER','UPPER','NUMBER','SPECIAL'];

  constructor(
    private checks:PasswordValidatorResultSet) {}

  public isInvalid(){
    return Object.entries(this.checks).some(([_key, value]) => value === false);
  }
  public isValid(){
    return !this.isInvalid();
  }

  public getErrors(): (keyof PasswordValidatorResultSet)[] {
    return Object.entries(this.checks).filter(([_key, value]) => value === false).map(([key]) => key as keyof PasswordValidatorResultSet);
  }

  public hasError(name:keyof PasswordValidatorResultSet){
    return Object.entries(this.checks).find(([key]) => key === name)?.map(([_key, value]) => value as boolean);
  }
}

/** Vengono impostati a true i check che passano la validazione */
export interface PasswordValidatorResultSet {
  LENGTH: boolean;
  LOWER: boolean;
  UPPER: boolean;
  NUMBER: boolean;
  SPECIAL: boolean;
}
