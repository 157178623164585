/** Rappresenta il collegamento tra un prodotto\comunicazione ed un asset, specificando eventuali parametri aggiuntivi */
export interface AssetReference {
	uuid: string;
	altText?: string;
}

/** Rappresenta il collegamento di un asset con un prodotto\comunicazione, scaricato con i dati dell'asset aggiuntivi */
export interface ResolvedAssetReference extends AssetReference {
	url: string;
	format: string;
	name?: string;
	preview?: {
		url: string;
		format?: string;
	};
}
