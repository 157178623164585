import { createReducer, on } from '@ngrx/store';
import { FeatureValue } from 'addiction-components';
import produce from 'immer';
import { FeaturesActions } from '../../../core/state/app.actions';
import { TreeManager } from '../../lib/tree-manager';
import { FeaturesState } from './features.state';

export const TAG = 'features';

export const initialState: FeaturesState = {
	// fake root - for simplicity
	root: { featureValues: [] },
	currentType: [],
	loading: false,
};

// helper
const treeManager = new TreeManager<FeatureValue>({
	keyProp: 'uuid',
	childrenProp: 'featureValues',
	parentProp: 'parentUUID',
	sortByKey: 'order',
});

export const reducer = createReducer(
	initialState,
	// GENERIC LOADING\ERROR ACTIONS
	on(
		FeaturesActions.createFeature,
		FeaturesActions.deleteFeature,
		FeaturesActions.updateFeature,
		FeaturesActions.reloadFeatures,
		FeaturesActions.cloneFeature,
		(state) => ({
			...state,
			loading: true,
			error: undefined,
		})
	),
	on(
		FeaturesActions.createFeatureFailure,
		FeaturesActions.deleteFeatureFailure,
		FeaturesActions.updateFeatureFailure,
		FeaturesActions.loadFeaturesFailure,
		FeaturesActions.cloneFeatureFailure,
		(state) => ({
			...state,
			loading: false,
			error: true,
		})
	),
	on(FeaturesActions.loadFeatures, (state, { featureType }) =>
		produce(state, (draft) => {
			if (featureType) draft.currentType = featureType;
		})
	),
	on(FeaturesActions.loadFeaturesSuccess, (state, { features }) => ({
		...state,
		loading: false,
		// builk a fake root node
		root: { featureValues: features },
		selectedUUID: undefined,
		editingUUID: undefined,
	})),
	on(FeaturesActions.createFeatureSuccess, (state) => ({
		...state,
		loading: false,
		selectedUUID: undefined,
		editingUUID: undefined,
		error: false,
	})),
	on(FeaturesActions.cloneFeatureSuccess, (state) => ({
		...state,
		loading: false,
		selectedUUID: undefined,
		editingUUID: undefined,
		error: false,
	})),
	on(FeaturesActions.deleteFeatureSuccess, (state, { uuid }) =>
		produce(state, (draft) => {
			draft.loading = false;
			draft.error = false;

			treeManager.removeNodesByID(draft.root, [uuid]);
		})
	),
	on(FeaturesActions.updateFeatureSuccess, (state, { feature }) =>
		produce(state, (draft) => {
			draft.loading = true;
			draft.error = false;
			draft.selectedUUID = feature.uuid;

			// la nuova feature non ha tutto l'albero delle informazioni, quindi vengon aggiorna solo i valori (no chilren, no parent)
			treeManager.replaceNodes(draft.root, [feature], true);
		})
	),
	on(FeaturesActions.selectFeature, (state, { uuid }) => ({
		...state,
		selectedUUID: uuid,
	})),
	on(FeaturesActions.editFeature, (state, { uuid }) => ({
		...state,
		editingUUID: uuid,
	}))
);
