import { createReducer, on } from '@ngrx/store';
import { CalendarActions } from 'src/app/core/state/app.actions';
import { STATE_STATUS } from 'src/app/shared/models';
import { CalendarState } from './calendar.state';
import { ReservationStatus } from 'src/app/shared/models/reservations/reservation.interface';

export const TAG = 'calendar';

export interface FiltersType {
	dateFrom: string;
	dateTo: string;
	productUUID: string;
	user: string;
	status: ReservationStatus;
}

const initialState: CalendarState = {
	filters: {},
	pages: [0],
	sort: { active: 'date.from', direction: 'asc' },
	data: { totalReservationCount: 0, reservations: [] },
	status: STATE_STATUS.UNKNOWN,
	gridSearch: null,
	deleteStatus: 'unknown',
	view: 'calendar',
	cachedPages: [],
};

export const counterReducer = createReducer(
	initialState,
	/**
	 * Parte standard di un reducer
	 */
	on(CalendarActions.setSort, (state: CalendarState, { sort }) => ({
		...state,
		sort,
	})),
	on(CalendarActions.setPages, (state: CalendarState, { pages }) => ({
		...state,
		pages,
	})),
	on(CalendarActions.setCalendarsSuccess, (state: CalendarState, { data }) => {
		return {
			...state,
			status: STATE_STATUS.READY,
			data: {
				reservations: data.reservations,
				totalReservationCount: data.totalReservationCount,
			},
			pages: [...state.cachedPages, ...data.pages],
		};
	}),
	on(CalendarActions.setCalendarsError, (state: CalendarState) => ({
		...state,
		status: STATE_STATUS.ERROR,
	})),
	on(CalendarActions.setStatus, (state: CalendarState, { status }) => {
		return {
			...state,
			status,
		};
	}),
	on(CalendarActions.setGridSearch, (state: CalendarState, { search }) => {
		return {
			...state,
			gridSearch: search,
		};
	}),
	on(CalendarActions.setDeleteCalendars, (state: CalendarState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.LOADING,
		};
	}),
	on(CalendarActions.setDeleteCalendarsSuccess, (state: CalendarState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.READY,
		};
	}),
	on(CalendarActions.setDeleteCalendarsError, (state: CalendarState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.ERROR,
		};
	}),
	/**
	 * PARTE SPECIFICA DEL REDUCER
	 */
	on(CalendarActions.setFilters, (state: CalendarState, { filters }) => ({
		...state,
		filters: {
			...filters,
		},
	})),
	on(CalendarActions.updateFilters, (state: CalendarState, { updatedFilters }) => ({
		...state,
		filters: {
			...state.filters,
			...updatedFilters,
		},
	})),
	on(CalendarActions.setCalendarView, (state: CalendarState, { view }) => ({
		...state,
		view,
	})),
	on(CalendarActions.setCachedPages, (state: CalendarState, { pages }) => ({
		...state,
		cachedPages: [...new Set([...state.cachedPages, ...pages])],
	})),
	on(CalendarActions.resetCache, (state: CalendarState) => ({
		...state,
		status: STATE_STATUS.READY,
		cachedPages: [],
		pages: [0],
		data: { totalReservationCount: 0, reservations: [] },
	}))
);
