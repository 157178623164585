import { HttpErrorResponse } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { Page } from 'addiction-components';
import { Community, STATE_STATUS } from 'src/app/shared/models';
import { CommunityError } from './community.state';

export const updatePage = createAction('[Communities] Update Page', props<{ updatedPage: Partial<Page> }>());
export const deleteCommunities = createAction('[Communities] Delete Communities', props<{ uuids: string[] }>());
export const disableCommunities = createAction('[Communities] Disable Communities', props<{ uuids: string[] }>());

export const setForceRefreshCommunities = createAction('[Communities] Set Force Refresh');
export const setCommunities = createAction('[Communities] Set Communities');
export const setCommunitiesSuccess = createAction(
	'[Communities] Set Communities Success',
	props<{ data: { totalCommunitiesCount: number; pages: number[]; communities: Community[][] } }>()
);
export const setCommunitiesError = createAction('[Communities] Set Communities Error', props<{ error: HttpErrorResponse }>());
export const setDeleteCommunities = createAction('[Communities] Set Delete Communities', props<{ community: Community }>());
export const setDeleteCommunitiesSuccess = createAction('[Communities] Set Delete Communities Success');
export const setDeleteCommunitiesError = createAction('[Communities] Set Delete Communities Error', props<{ error: HttpErrorResponse }>());
export const setStatus = createAction('[Communities] Set status', props<{ status: STATE_STATUS }>());
export const setPages = createAction('[Communities] Set pages', props<{ pages: number[] }>());
export const setSort = createAction('[Communities] Set sort', props<{ sort: Sort }>());
export const setGridSearch = createAction('[Communities] Set search', props<{ search: string | null }>());
export const setCachedPages = createAction('[Communities] Set cached pages', props<{ pages: number[] }>());
export const resetCache = createAction('[Communities] reset cache');
// Error
export const error = createAction('[Communities] Error', props<{ error: CommunityError }>());
