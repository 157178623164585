import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { UserActions } from '../../../core/state/app.actions';

@Injectable()
export class UserEffect {
	private actions$ = inject(Actions);
	private userService = inject(UserService);

	// update user data when requested
	fetchUserData$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserActions.fetchUserData),
			switchMap(({ uuid }) => this.userService.getByUUID(uuid)),
			map((userData) => UserActions.setUserData({ userData }))
		)
	);
	constructor() {}
}
