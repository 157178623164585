import { HttpErrorResponse } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { Page } from 'addiction-components';
import { STATE_STATUS } from 'src/app/shared/models';
import { FiltersType } from './calendar.reducers';
import { CalendarError } from './calendar.state';
import { Reservation } from 'src/app/shared/models/reservations/reservation.interface';

export const setFilters = createAction('[Calendars] Set Filters', props<{ filters: FiltersType }>());
export const updateFilters = createAction('[Calendars] Update Filters', props<{ updatedFilters?: Partial<FiltersType> }>());
export const updatePage = createAction('[Calendars] Update Page', props<{ updatedPage: Partial<Page> }>());
export const deleteCalendar = createAction('[Calendars] Delete Calendar', props<{ uuid: string }>());

export const setForceRefreshCalendars = createAction('[Calendars] Set Force Refresh');
export const setCalendars = createAction('[Calendars] Set Calendars');
export const setCalendarsSuccess = createAction(
	'[Calendars] Set Calendars Success',
	props<{ data: { totalReservationCount: number; pages: number[]; reservations: Reservation[][] } }>()
);
export const setCalendarsError = createAction('[Calendars] Set Calendars Error', props<{ error: HttpErrorResponse }>());
export const setDeleteCalendars = createAction('[Calendars] Set Delete Calendars', props<{ reservation: Reservation }>());
export const setDeleteCalendarsSuccess = createAction('[Calendars] Set Delete Calendars Success');
export const setDeleteCalendarsError = createAction('[Calendars] Set Delete Calendars Error', props<{ error: HttpErrorResponse }>());
export const setStatus = createAction('[Calendars] Set status', props<{ status: STATE_STATUS }>());
export const setPages = createAction('[Calendars] Set pages', props<{ pages: number[] }>());
export const setSort = createAction('[Calendars] Set sort', props<{ sort: Sort }>());
export const setGridSearch = createAction('[Calendars] Set search', props<{ search: string | null }>());
export const setCalendarView = createAction('[Calendars] Set view', props<{ view: 'calendar' | 'list' }>());
export const setCachedPages = createAction('[Calendars] Set cached pages', props<{ pages: number[] }>());
export const resetCache = createAction('[Calendars] reset cache');
// Error
export const error = createAction('[Calendars] Error', props<{ error: CalendarError }>());
