import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePickerComponent } from '../date-picker/date-picker.component';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent, MatAutocompleteModule } from '@angular/material/autocomplete';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FilterMenuSectionType, FilterMenuSection, FilterMenuState, FilterMenuField, FilterMenuFieldDateLabel } from './model/filter-menu-models';
import { AutoCompleteComponent } from '../autocomplete/autocomplete.component';
import { AutocompleteOptionGroup } from '../autocomplete/model/autocomplete-model';

interface FilterForm {
	[key: string]: FormControl;
}
@Component({
	selector: 'addiction-filter-menu',
	standalone: true,
	imports: [
		CommonModule,
		TranslateModule,
		DatePickerComponent,
		MatCheckboxModule,
		FormsModule,
		ReactiveFormsModule,
		MatChipsModule,
		MatAutocompleteModule,
		AutoCompleteComponent,
	],
	templateUrl: './filter-menu.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterMenuComponent implements OnInit, OnChanges {
	protected destroyRef = inject(DestroyRef);

	form = new FormGroup<FilterForm>({});

	formControlTagsToSubscribe: string[] = []; //contiene i nomi dei formControl che sono di tipo TAGS

	selectAutocomplete: { sectionId: string; options: AutocompleteOptionGroup }[] = [];

	menuType = FilterMenuSectionType;

	@Input() filterMenuSection: FilterMenuSection<unknown>[] = [];
	@Input() showFilterDelete? = false;

	@Output() filterChanged = new EventEmitter<FilterMenuState>();
	@Output() filterDelete = new EventEmitter<void>();

	ngOnChanges(changes: SimpleChanges) {
		const { filterMenuSection } = changes;

		if (filterMenuSection?.currentValue) {
			//aggiorno il form per inserirci le sezioni
			for (const section of filterMenuSection.currentValue) {
				this.form.addControl(section.id, new FormControl());
				if (section.sectionFilterType === this.menuType.tags) {
					this.formControlTagsToSubscribe.push(section.id); //mi segno che questo control è di tipo tags
					section.optionsTags = [...section.fields]; //inizialmente le opzioni sono tutti i tag presenti in fields
				} else if (section.sectionFilterType === this.menuType.rangeDate) {
					//per le sezioni di tipo rangeDate vengono creati due FormControl
					//uno per la data from => formCOntrolName = section.id
					//uno per la data to => formControlName = section.id+'TO'
					const sec: FilterMenuSection<Date> = section;
					this.form.addControl(sec.id + FilterMenuFieldDateLabel.TO, new FormControl()); //il FROM è nel formControl che ha l'id della sezione

					if (sec.fields?.length) {
						//ho date da ri-settare al componente
						this.form.patchValue({
							[sec.id]: sec.fields.find((el) => el.label === FilterMenuFieldDateLabel.FROM)?.value,
							[sec.id + FilterMenuFieldDateLabel.TO]: sec.fields.find((el) => el.label === FilterMenuFieldDateLabel.TO)?.value,
						});
					} else
						this.form.patchValue({
							//azzero la data se non è stata passata (caso in cui elimini i filtri)
							[sec.id]: null,
							[sec.id + FilterMenuFieldDateLabel.TO]: null,
						});
				}
			}

			this.form.updateValueAndValidity();
		}
	}

	ngOnInit() {
		this.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
			//ogni volta che cambia il form controllo se è cambiato un valore di una sezione Tag
			//e mi ricalcolo le opzioni mostrabili
			for (const formName of this.formControlTagsToSubscribe) {
				const valueChanged: string = value[formName]; //valore cambiato
				const section = this.filterMenuSection.find((el) => el.id == formName); //recupero la sezione

				if (valueChanged !== null && valueChanged !== undefined && valueChanged !== '') {
					//se il valore è cambiato ricalcolo le opzioni
					if (section) {
						//ricalcolo opzioni
						section.optionsTags = section.optionsTags?.filter((el) => {
							return el.label.toUpperCase().includes(valueChanged.toUpperCase());
						});
					}
				} else {
					if (section && section.optionsTags?.length !== section.fields.length)
						section.optionsTags = [...section.fields] as FilterMenuField<string>[]; //risetto il valore iniziale
				}
			}
		});
	}

	onDateRangeChange(value: string, section: FilterMenuSection<unknown>, isFrom: boolean) {
		//aggiorno l'array fields della sezione che deve contenere i soli oggetti delle date selezionate
		//es fields = [ {lable: 'FROM', isChecked: false, value: 'data'}]
		const nameControlFrom = section.id;
		const nameControlTo = section.id + FilterMenuFieldDateLabel.TO;

		if (isFrom) {
			if (!value) {
				//se non ho il valore elimino da fields l'eventuale vecchio valore di FROM
				this.form.patchValue({ [nameControlFrom]: undefined });
				this.removeDateFromSectionFields(section, FilterMenuFieldDateLabel.FROM);
			} else {
				this.form.patchValue({ [nameControlFrom]: new Date(value) });
				this.addDateToSectionFields(section, FilterMenuFieldDateLabel.FROM, new Date(value));

				if (this.form.value[nameControlTo] && this.form.value[nameControlTo].getTime() < (this.form.value[nameControlFrom]?.getTime() ?? 0)) {
					this.form.patchValue({ [nameControlTo]: new Date(value) });
					this.addDateToSectionFields(section, FilterMenuFieldDateLabel.TO, new Date(value));
				}
			}
		} else {
			if (!value) {
				this.form.patchValue({ [nameControlTo]: undefined });
				this.removeDateFromSectionFields(section, FilterMenuFieldDateLabel.TO);
			} else {
				this.form.patchValue({ [nameControlTo]: new Date(value) });
				this.addDateToSectionFields(section, FilterMenuFieldDateLabel.TO, new Date(value));
			}
		}

		this.emitOnChangeFilter();
	}

	private removeDateFromSectionFields(section: FilterMenuSection<unknown>, label: string) {
		section.fields = section.fields.filter((el) => el.label !== label);
	}

	private addDateToSectionFields(section: FilterMenuSection<unknown>, label: string, value: Date) {
		const index = section.fields.findIndex((el) => el.label == label); //controllo se esiste già (nel caso lo aggiorno)
		if (index > -1) section.fields[index].value = value;
		else section.fields.push({ label: label, checked: false, value: value });
	}

	emitOnChangeFilter() {
		const filter: FilterMenuState = {
			sections: this.filterMenuSection,
		};

		this.filterChanged.emit(filter);
	}

	addChip(event: MatChipInputEvent, section: FilterMenuSection<unknown>): void {
		const chipUUID = (event.value || '').trim();

		if (chipUUID) {
			const item = section.fields.find((el) => el.value == chipUUID);
			if (item) {
				item.checked = true;
				this.emitOnChangeFilter(); //emetto l'evento solo se ho effettivamente aggiunto
			}
		}
		// Clear the input value
		event.chipInput.clear();
		this.form.controls[section.id].setValue(null); //resetto il valore
	}

	removeChip(chipUUID: string, section: FilterMenuSection<unknown>): void {
		const index = section.fields?.findIndex((el) => el.value === chipUUID);
		if (index >= 0) {
			section.fields[index].checked = false;
		}

		this.emitOnChangeFilter();
	}

	selectedChip(event: MatAutocompleteSelectedEvent, section: FilterMenuSection<unknown>): void {
		const chipUUID = event.option.value;
		if (chipUUID) {
			const item = section.fields.find((el) => el.value == chipUUID);
			if (item) item.checked = true;
		}
		// Clear the input value
		event.option.value = '';
		this.form.controls[section.id].setValue(null); //resetto il valore

		this.emitOnChangeFilter();
	}

	valueChangedSelectAutocomplete(section: FilterMenuSection<unknown>, newValue: string) {
		this.form.get(section.id)?.setValue(newValue);

		for (const field of section.fields) {
			field.checked = field.label === newValue;
		}

		this.emitOnChangeFilter();
	}

	trackSection(_i: number, section: FilterMenuSection<unknown>) {
		return section.id;
	}

	trackFields(_i: number, section: FilterMenuField<unknown>) {
		return section.value;
	}

	asString(value: unknown): string {
		const result = '';
		if (value) return value + '';

		return result;
	}
}
