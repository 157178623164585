import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BaseModalContent } from 'addiction-components';
import { TreeBrowserConfig, TreeBrowserNode } from '../tree-browser/tree-browser.models';

export interface CustomFilterParam {
	key: string;
	value: unknown;
	sensitive: boolean;
}

@Component({
	selector: 'datalean-tree-selector-dialog',
	templateUrl: './tree-selector-dialog.component.html',
	styleUrls: ['./tree-selector-dialog.component.scss'],
})
export class TreeSelectorDialogComponent<T extends Record<string, unknown> = Record<string, unknown>>
	extends BaseModalContent<{ uuids: string[]; selectedUUID: string | undefined }>
	implements OnInit
{
	@Input() dataSelector: T[] = [];
	dataManager$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
	@Input() config?: TreeBrowserConfig;
	@Input() title: string | undefined;
	@Input() multiple: boolean | undefined;
	@Input() set selection(selection: string[]) {
		this.selectedUUIDs = selection;
	}
	selectedUUIDs: string[] = [];
	selectedNodeUUID?: string;

	constructor() {
		super();
	}

	ngOnInit(): void {
		this.dataManager$.next(this.dataSelector ?? []);
	}

	 close() {
		this.closeDialog({ uuids: this.selectedUUIDs, selectedUUID: this.selectedNodeUUID });
	}

	checkChanged(checkedNodes: TreeBrowserNode<T>[]) {
		this.selectedUUIDs = [...new Set([...this.selectedUUIDs, ...(checkedNodes.map((obj) => obj.id) as string[])])];
	}

	selectionChanged(selectedNode: T) {
		const key = this.config?.keys.key ?? 'uuid';
		const value = selectedNode[key];

		if (typeof value === 'string') {
			this.selectedNodeUUID = value;
		}
	}
}
