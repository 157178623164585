import { Injectable } from '@angular/core';
import { DataleanBaseApiService, Parts } from 'addiction-components';
import { Observable, combineLatest, map, tap } from 'rxjs';
import { EntityType, EntityViewPermission } from '../models/entityViewPermission.interface';
import { SubjectType } from '../models/user.interface';

@Injectable({ providedIn: 'root' })
export class ViewPermissionService {
	private mapViewPermission = new Map<string, EntityViewPermission[]>();

	constructor(private baseApi: DataleanBaseApiService) {}

	getEntityViewPermissionWithUUID(endpoint: string, entityUUID: string): Observable<EntityViewPermission[]> {
		return this.baseApi.getEntities(`${endpoint}${entityUUID}/permission`, undefined, [Parts.ALL]);
	}

	getEntitiesViewPermission(endpoint: string, entityUUIDs: string[]): Observable<Array<EntityViewPermission & { indeterminate?: boolean }>> {
		const obs = new Array<Observable<EntityViewPermission[]>>();
		for (const entityUUID of entityUUIDs) {
			obs.push(
				this.baseApi
					.getEntities<EntityViewPermission[]>(`${endpoint}${entityUUID}/permission`, undefined, [Parts.ALL])
					.pipe(tap((permissions) => this.mapViewPermission.set(entityUUID, permissions)))
			);
		}
		return combineLatest(obs).pipe(
			map((entityViewPermissions: EntityViewPermission[][]) =>
				entityViewPermissions
					.map((entityViewPermission, i, arr) =>
						entityViewPermission.map((permission) => {
							// console.log('permission', arr);
							const indeterminate = !arr.every((entityViewPermission) => {
								const found = entityViewPermission.find(
									(p) =>
										p.entityType === permission.entityType &&
										p.canView === permission.canView &&
										p.subjectType === permission.subjectType &&
										p.subjectUUID === permission.subjectUUID
								);
								// console.log('found', found);
								return found !== undefined;
							});

							// console.log('indeterminate', indeterminate);

							return { ...permission, indeterminate };
						})
					)
					.flat()
			)
		);
	}

	updateEntitiesViewPermission(
		endpoint: string,
		permissions: EntityViewPermission[],
		negativeReflection?: boolean
	): Observable<EntityViewPermission[]> {
		const obs = new Array<Observable<EntityViewPermission>>();
		for (const [entityUUID, per] of [...this.mapViewPermission.entries()]) {
			const filteredPermissions = per
				.filter((p) => !permissions.find((p2) => p2.subjectUUID === p.subjectUUID))
				.concat(permissions.map((p) => ({ ...p, entityUUID })));
			obs.push(
				this.baseApi.updateBulk(
					`${endpoint}${entityUUID}/permission`,
					filteredPermissions.filter((p) => p.canView !== negativeReflection)
				)
			);
		}

		return combineLatest(obs).pipe(map((entityViewPermissions) => entityViewPermissions.flat()));
	}

	createPermissionViewPermission(
		canView: boolean,
		entityType: EntityType,
		entityUUID: string,
		subjectType: SubjectType,
		subjectUUID: string
	): EntityViewPermission {
		return {
			canView,
			entityType,
			entityUUID,
			subjectType,
			subjectUUID,
		};
	}
}
