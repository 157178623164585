import { on } from '@ngrx/store';
import { Asset, Page } from 'addiction-components';
import { createContextReducer } from 'context-store';
import { produce } from 'immer';
import { MediaLibraryActions } from 'src/app/core/state/app.actions';
import { TreeManager } from 'src/app/shared/lib';
import { STATE_STATUS } from 'src/app/shared/models';
import { MediaLibErrorType, MediaLibraryState } from './media-library.state';

// helper per gestione operazioni su struttura ad albero
const treeManager = new TreeManager<Asset>({
	keyProp: 'uuid',
	childrenProp: 'children',
	parentProp: 'parentUUID',
	sortByKey: 'name',
});

export const TAG = 'mediaLibrary';
export const defaultPagination: Page = {
	pageNumber: 0,
	pageSize: 50,
	totalElements: 0,
};

export interface FiltersType {
	updateDate?: string;
	createDate?: string;
	[key: string]: unknown;
}

export const initialState: MediaLibraryState = {
	currentPath: '/',
	currentFeatureValuePath: [],
	viewMode: 'browse',
	folderRoot: { children: undefined, uuid: undefined },
	featureValuesRoot: { featureValues: undefined, uuid: undefined },
	filters: undefined,
	data: { totalAssetCount: 0, assets: [] },
	pagination: [],
	sort: { sortBy: 'name', sortDirection: 'asc' },
	loading: 0,
	uploading: false,
	thumbnailConfigs: [],
	conversions: [],
	queue: [],
	availableFilter: undefined,
	availableFilterStatus: STATE_STATUS.UNKNOWN,
	interruptUpload: false,
};

export const reducers = createContextReducer(
	initialState,
	/** General fetching of everything (in a given folder) */
	on(MediaLibraryActions.fetchAll, (state, { path, filters }) => ({
		...state,
		loading: state.loading + 1,
		currentPath: normalizePath(path ?? state.currentPath),
		filters: filters != '*' ? filters : state.filters,
	})),
	on(MediaLibraryActions.setSort, (state: MediaLibraryState, { sort }) => ({
		...state,
		sort,
		data: { totalAssetCount: -1, assets: [], pages: [] },
	})),
	on(MediaLibraryActions.changeFeatureValue, (state, { path }) => ({
		...state,
		currentFeatureValuePath: path ?? state.currentPath,
		viewMode: 'browse' as const,
		loading: state.loading + 1,
		pagination: [0], // on folder change, reset paging
		data: { totalAssetCount: -1, assets: [], pages: [] },
	})),
	/** Assets (images) reload request */
	// on(MediaLibraryActions.fetchAssets, (state, action) =>
	// 	produce(state, (draft) => {
	// 		draft.loading = true;
	// 		if ('filters' in action) draft.filters = action.filters;
	// 		if ('sort' in action) draft.sort = action.sort;
	// 	})
	// ),
	on(MediaLibraryActions.fetchAssets, (state, { filters }) => {
		return {
			...state,
			filters,
			loading: state.loading + 1,
		};
	}),
	/** Folders reload request */
	on(MediaLibraryActions.fetchFolders, (state) => ({
		...state,
		loading: state.loading + 1,
	})),
	on(MediaLibraryActions.fetchFeatureValues, (state) => ({
		...state,
		loading: state.loading + 1,
	})),

	/** Updating the folders */
	on(MediaLibraryActions.updateFolders, (state) => ({
		...state,
		loading: state.loading + 1,
	})),

	on(MediaLibraryActions.setStatus, (state, { assetStatusLoading }) => {
		return {
			...state,
			assetStatusLoading,
		};
	}),
	/** Assets (images) loaded succesfully */
	on(MediaLibraryActions.assetsLoaded, (state, { assets, pages, totalAssetCount }) => ({
		...state,
		loading: 0,
		pagination: pages,
		assetStatusLoading: STATE_STATUS.READY,
		error: undefined,
		data: { totalAssetCount, assets },
	})),
	/** Folders loaded succesfully */
	on(MediaLibraryActions.foldersLoaded, (state, { folders }) => ({
		...state,
		loading: state.loading > 0 ? state.loading - 1 : 0,
		folderRoot: { children: folders, uuid: undefined },
		error: undefined,
	})),
	on(MediaLibraryActions.featureValuesLoaded, (state, { featureValues }) => ({
		...state,
		loading: state.loading > 0 ? state.loading - 1 : 0,
		featureValuesRoot: { featureValues, uuid: undefined },
		error: undefined,
	})),
	/** Change to a new page */
	on(MediaLibraryActions.changePage, (state, { page }) => ({
		...state,
		pagination: { ...state.pagination, pageNumber: page },
		loading: state.loading + 1,
	})),
	/** Reset paging to default  */
	on(MediaLibraryActions.resetPage, (state) => ({
		...state,
		loading: state.loading + 1,
		pagination: [],
	})),
	/** Generic ERROR  */
	on(MediaLibraryActions.error, (state, { error }) => ({
		...state,
		assetStatusLoading: STATE_STATUS.ERROR,
		error,
	})),
	/** Move Folders */
	on(MediaLibraryActions.moveFolders, (state, { uuids, newParent }) =>
		produce(state, (draft) => {
			draft.loading = state.loading + 1;
			treeManager.moveNodesTo(draft.folderRoot, uuids, newParent);
			return draft;
		})
	),
	on(MediaLibraryActions.setPages, (state, { pages }) => ({ ...state, pagination: pages })),

	/** Move Images (no folders) */
	on(MediaLibraryActions.moveAssets, (state) => ({
		...state,
		loading: state.loading + 1,
	})),
	on(MediaLibraryActions.regenerateThumbnails, (state) => ({
		...state,
		loading: state.loading + 1,
	})),
	/** Create folder */
	on(MediaLibraryActions.createFolder, (state) => ({
		...state,
		loading: state.loading + 1,
	})),
	/* Create Asset */
	on(MediaLibraryActions.createAsset, (state, { queue }) => ({
		...state,
		queue: queue ?? state.queue,
	})),
	on(MediaLibraryActions.assetCreated, (state, { queue }) => ({
		...state,
		error: undefined,
		uploading: false,
		uploadProgress: undefined,
		queue: queue ?? state.queue,
	})),
	on(MediaLibraryActions.assetSaveError, (state, { errorType, data, queue }) => ({
		...state,
		uploading: false,
		loading: state.loading > 0 ? state.loading - 1 : 0,
		uploadProgress: undefined,
		error: errorType ? { errorType, data } : state.error,
		queue: queue ?? state.queue,
	})),
	/* Delete asset */
	on(MediaLibraryActions.deleteAsset, (state) => ({
		...state,
		loading: state.loading + 1,
	})),
	on(MediaLibraryActions.deleteAssets, (state) => ({
		...state,
		loading: state.loading + 1,
	})),
	on(MediaLibraryActions.assetDeleteError, (state, { error }) => ({
		...state,
		error: { errorType: MediaLibErrorType.GENERIC_HTTP, data: error },
	})),
	/* Update Asset */
	on(MediaLibraryActions.updateAsset, (state) => ({
		...state,
		loading: state.loading + 1,
	})),
	on(MediaLibraryActions.assetUpdated, (state, { asset }) =>
		produce(state, (draft) => {
			draft.error = undefined;
			draft.loading = state.loading > 0 ? state.loading - 1 : 0;
			draft.uploading = false;
			draft.uploadProgress = undefined;
			// make sure selectedAsset is up to date
			if (asset.uuid == draft.selectedAsset?.uuid) draft.selectedAsset = structuredClone({ ...asset });
		})
	),
	on(MediaLibraryActions.assetUploadProgress, (state, { progress, queue }) => ({
		...state,
		uploading: true,
		uploadProgress: progress,
		queue: queue ?? state.queue,
	})),
	//QUEUE
	on(MediaLibraryActions.clearQueue, (state, { clear }) => ({
		...state,
		uploading: false,
		interruptUpload: false,
		queue: clear ? [] : state.queue,
		data: { totalAssetCount: -1, assets: [], pages: [] },
	})),
	on(MediaLibraryActions.interruptUpload, (state, { interruptUpload, queue }) => ({
		...state,
		interruptUpload,
		queue: queue ?? state.queue,
	})),
	on(MediaLibraryActions.stopQueue, (state, { queue }) => ({
		...state,
		uploading: false,
		queue: queue ?? state.queue,
	})),
	on(MediaLibraryActions.assetUpdateQueue, (state, { queue }) => ({
		...state,
		queue,
	})),
	/* Selection */
	on(MediaLibraryActions.setSelectedAsset, (state, { asset }) => ({
		...state,
		selectedAsset: { ...asset, tags: asset.tags ?? [] },
	})),
	on(MediaLibraryActions.resetSelectedAsset, (state) => ({
		...state,
		selectedAsset: undefined,
	})),
	on(MediaLibraryActions.setViewMode, (state, { viewMode, filters }) => ({
		...state,
		viewMode,
		filters,
	})),
	on(MediaLibraryActions.setThumbnailConfigs, (state, { thumbnailConfigs }) => ({
		...state,
		thumbnailConfigs,
	})),
	on(MediaLibraryActions.setLoading, (state) => ({
		...state,
		loading: state.loading + 1,
	})),
	on(MediaLibraryActions.setConversions, (state, { conversions }) => ({
		...state,
		conversions,
	})),
	on(MediaLibraryActions.setAvailableFiltersSuccess, (state, availableFilter) => ({
		...state,
		availableFilter,
		availableFilterStatus: STATE_STATUS.READY,
	})),
	on(MediaLibraryActions.setAvailableFiltersError, (state, { availableFiltersError }) => ({
		...state,
		availableFiltersError,
		availableFilterStatus: STATE_STATUS.ERROR,
	})),
	on(MediaLibraryActions.fetchAvailableFilters, (state) => ({
		...state,
		availableFilterStatus: STATE_STATUS.LOADING,
	})),
	on(MediaLibraryActions.setOldFilters, (state, { oldFilters }) => ({
		...state,
		data: { totalAssetCount: -1, assets: [], pages: [] },
		oldFilters,
	})),
	on(MediaLibraryActions.assetDeleted, (state) => ({
		...state,
		data: { totalAssetCount: -1, assets: [], pages: [] },
	})),
	on(MediaLibraryActions.assetsDeleted, (state) => ({
		...state,
		data: { totalAssetCount: -1, assets: [], pages: [] },
	})),
	on(MediaLibraryActions.updateFeatureValueLists, (state) => ({
		...state,
		loading: state.loading + 1,
	}))
);

/** Normalizza un path della MediaLibrary in modo che inizi sempre da root (/) e finisca sempre con uno / */
export function normalizePath(path: string) {
	let result = (path ?? '').trim();

	if (!result.length) return '/';
	if (!result.startsWith('/')) result = '/' + result;
	if (!result.endsWith('/')) result = result + '/';
	return result;
}

/** Recupera ricorsivamente una cartella dato un percorso che parte dal root specificato */
export function findFolder(path: string[] | string, root: Partial<Asset>): Asset | undefined {
	const segments = Array.isArray(path) ? path : path.split('/').filter((p) => !!p);
	if (!segments.length) return root as Asset;

	const next = segments[0];

	const children = root.children?.find((child) => child.name === next);
	if (segments.length == 1)
		// end of the path
		return children ?? undefined;
	// keep traversing the tree

	if (!children) return undefined;
	return findFolder(segments.slice(1), children);
}
