import { Action, createReducer, on } from '@ngrx/store';
import * as ApplicationUsersActions from './application-users.actions';
import { STATE_STATUS } from 'src/app/shared/models/state-status.enum';
import { Sort } from '@angular/material/sort';
import { ApplicationUser } from 'src/app/shared/models/users-and-groups/application-user';
import { UserStructure } from 'addiction-components';

export const APPLICATION_USERS_TAG = 'applicationUsers';

export interface ApplicationUsersState {
  gridSearch: string | null;
  sort?: Sort;
  structureUUID: string | null
  //every user is an array of assets where the first index represents the page
  data: { totalUsersCount: number; users: ApplicationUser[][] };
  pages: number[];
  status: STATE_STATUS
  deleteStatus: STATE_STATUS,

  //TODO: separare quando verrà aggiunto la pagina delle strutture e mantenere solo la mappa
  structureStatus: STATE_STATUS,
  userStructures: UserStructure[]
  userCountPerStructureMap: {structureUUID: string, count: number}[],
  cachedPages: number[]
}

const initialState: ApplicationUsersState = {
  status: STATE_STATUS.UNKNOWN,
  deleteStatus: STATE_STATUS.UNKNOWN,
  structureStatus: STATE_STATUS.UNKNOWN,
  gridSearch: null,
  structureUUID: null,
  // sort: environment.sortOptions[0],
  pages: [],
  data: { totalUsersCount: 0, users: [] },
  userCountPerStructureMap: [],
  userStructures: [],
  cachedPages: []
};

export const reducers = createReducer(
  initialState,
  on(ApplicationUsersActions.setSort, (state: ApplicationUsersState, { sort }) => ({
    ...state,
    sort,
  })),
  on(ApplicationUsersActions.setPages, (state: ApplicationUsersState, { pages }) => ({
    ...state,
    pages,
  })),
  on(ApplicationUsersActions.setApplicationUsersSuccess, (state: ApplicationUsersState, { data }) => {
    return {
      ...state,
      status: STATE_STATUS.READY,
      data: {
				users: data.users,
        totalUsersCount: data.totalUsersCount,
      },
      pages: data.pages,
    }
  }),
  on(ApplicationUsersActions.setApplicationUsersError, (state: ApplicationUsersState) => ({
    ...state,
    status: STATE_STATUS.ERROR,
  })),
  on(ApplicationUsersActions.setStatus, (state: ApplicationUsersState, { status }) => {
    return {
      ...state,
      status,
    };
  }),
  on(ApplicationUsersActions.setGridSearch, (state: ApplicationUsersState, { search }) => {
    return {
      ...state,
      gridSearch: search,
    };
  }),
  on(ApplicationUsersActions.setDeleteApplicationUser, (state: ApplicationUsersState) => {
    return {
      ...state,
      deleteStatus: STATE_STATUS.LOADING,
    };
  }),
  on(ApplicationUsersActions.setDeleteApplicationUserSuccess, (state: ApplicationUsersState) => {
    return {
      ...state,
      deleteStatus: STATE_STATUS.READY,
    };
  }),
  on(ApplicationUsersActions.setDeleteApplicationUserError, (state: ApplicationUsersState) => {
    return {
      ...state,
      deleteStatus: STATE_STATUS.ERROR,
    };
  }),
  on(ApplicationUsersActions.setStructuresSuccess, (state: ApplicationUsersState, {userStructures}) => {
    return {
      ...state,
      userStructures,
      structureStatus: STATE_STATUS.READY
    };
  }),
  on(ApplicationUsersActions.setCountPerStructuresSuccess, (state: ApplicationUsersState, {userCountPerStructureMap}) => {
    return {
      ...state,
      userCountPerStructureMap,
    };
  }),
  on(ApplicationUsersActions.setStructureUUID, (state: ApplicationUsersState, {structureUUID}) => {
    return {
      ...state,
      structureUUID,
    };
  }),
  on(ApplicationUsersActions.setCachedPages, (state: ApplicationUsersState, { pages }) => ({
		...state,
		cachedPages: [...new Set([...state.cachedPages, ...pages])],
	})),
	on(ApplicationUsersActions.resetCache, (state: ApplicationUsersState) => ({
		...state,
		cachedPages: [],
    status: STATE_STATUS.READY,
		pages: [0],
		data: { totalUsersCount: 0, users: [] },
	}))
);

export const ApplicationUserReducer = (state: ApplicationUsersState | undefined, action: Action) => {
  return reducers(state, action);
};
