import { createReducer, on } from '@ngrx/store';
import { ChallengeActions } from 'src/app/core/state/app.actions';
import { STATE_STATUS } from 'src/app/shared/models';
import { ChallengeState } from './challenge.state';

export const TAG = 'challenges';

const initialState: ChallengeState = {
	sort: undefined,
	pages: [0],
	data: { totalChallengesCount: 0, challenges: [] },
	status: STATE_STATUS.UNKNOWN,
	gridSearch: null,
	deleteStatus: 'unknown',
	cachedPages: []
};

export const counterReducer = createReducer(
	initialState,
	/**
	 * Parte standard di un reducer
	 */
	on(ChallengeActions.setSort, (state: ChallengeState, { sort }) => ({
		...state,
		sort,
	})),
	on(ChallengeActions.setPages, (state: ChallengeState, { pages }) => ({
		...state,
		pages,
	})),
	on(ChallengeActions.setChallengesSuccess, (state: ChallengeState, { data }) => {
		return {
			...state,
			status: STATE_STATUS.READY,
			data: {
				challenges: data.challenges,
				totalChallengesCount: data.totalChallengesCount,
			},
			pages: [...state.cachedPages, ...data.pages],
		};
	}),
	on(ChallengeActions.setChallengesError, (state: ChallengeState) => ({
		...state,
		status: STATE_STATUS.ERROR,
	})),
	on(ChallengeActions.setStatus, (state: ChallengeState, { status }) => {
		return {
			...state,
			status,
		};
	}),
	on(ChallengeActions.setGridSearch, (state: ChallengeState, { search }) => {
		return {
			...state,
			gridSearch: search,
		};
	}),
	on(ChallengeActions.setDeleteChallenges, (state: ChallengeState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.LOADING,
		};
	}),
	on(ChallengeActions.setDeleteChallengesSuccess, (state: ChallengeState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.READY,
		};
	}),
	on(ChallengeActions.setDeleteChallengesError, (state: ChallengeState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.ERROR,
		};
	}),
	on(ChallengeActions.setCachedPages, (state: ChallengeState, { pages }) => ({
		...state,
		cachedPages: [...new Set([...state.cachedPages, ...pages])],
	})),
	on(ChallengeActions.resetCache, (state: ChallengeState) => ({
			...state,
      status: STATE_STATUS.READY,
			cachedPages: [],
			pages: [0],
			data: { totalChallengesCount: 0, challenges: [] },
	})),
);
