import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Collection, GridComponent, SearchComponent } from 'addiction-components';
import { CollectionsService } from './collections.service';
import { LottieModule } from 'ngx-lottie';
import { STATE_STATUS } from '../../../models/state-status.enum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'datalean-collections',
  standalone: true,
  imports: [CommonModule, GridComponent, TranslateModule, SearchComponent, LottieModule],
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CollectionsService]
})
export class CollectionsComponent {
  readonly STATE_STATUS = STATE_STATUS;
  service = inject(CollectionsService);
  pageSize = environment.pageSize;

  @Output() selectedCollections = new EventEmitter<Collection>();
  @Output() back = new EventEmitter<void>();
}
