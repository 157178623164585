import { HttpEvent, HttpEventType } from '@angular/common/http';
import { filter, map, Observable } from 'rxjs';
import { WithProgress } from '../models/with-progress';

/** Permette di tracciare il progresso di upload di una richiesta HTTP.
 * Da utilizzare in pipe ad una richiesta HTTP
 */
export const trackProgress = <TBody>() => {
	return (obs: Observable<HttpEvent<TBody>>) => {
		return obs.pipe(
			map((httpEvt: HttpEvent<TBody>) => {
				if (httpEvt.type === HttpEventType.UploadProgress && httpEvt.total)
					return { progress: Math.round(100 * httpEvt.loaded) / httpEvt.total, status: 'uploading' };

				if (httpEvt.type === HttpEventType.Response && httpEvt.body) return { progress: 100, status: 'complete', result: httpEvt.body };

				return null;
			}),
			filter((prog) => !!prog),
			map((prog) => prog as WithProgress<TBody>)
		);
	};
};
