<div class="modal-title">
	<div class="left">
		<div class="title">
			{{ title | translate }}
		</div>
	</div>
	<div class="right">
		<button
			*ngFor="let btn of buttons"
			type="button"
			class="modal-header-button {{ btn.class }}"
			(click)="onHeaderButtonClicked(btn)"
			[disabled]="btn.disabled">
			{{ btn.labelKey | translate }}
		</button>
		<button class="close-icon" *ngIf="allowCancel" (click)="cancel()"></button>
	</div>
</div>
