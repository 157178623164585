import { HttpErrorResponse } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { Product, STATE_STATUS } from 'src/app/shared/models';
import { ProductVersioningError } from './product-versioning.state';

export const setForceRefreshProductsVersioning = createAction('[Products Versioning] Set Force Refresh');
export const setProductsVersioning = createAction('[Products Versioning] Set Products');
export const setProductVersioningSuccess = createAction(
	'[Products Versioning] Set Products Success',
	props<{ data: { totalProductsCount: number; pages: number[]; products: Product[][] } }>()
);
export const setProductsError = createAction('[Products Versioning] Set Products Error', props<{ error: HttpErrorResponse }>());

export const setStatus = createAction('[Products Versioning] Set status', props<{ status: STATE_STATUS }>());
export const setPages = createAction('[Products Versioning] Set pages', props<{ pages: number[] }>());
export const setSort = createAction('[Products Versioning] Set sort', props<{ sort: Sort }>());
export const setGridSearch = createAction('[Products Versioning] Set search', props<{ search: string | null }>());
export const setCachedPages = createAction('[Products Versioning] Set cached pages', props<{ pages: number[] }>());
export const resetCache = createAction('[Products Versioning] reset cache');
export const setProductUUID = createAction('[Products Versioning] Set Product UUID', props<{ uuid: string  }>());
export const resetData =  createAction('[Products Versioning] Set Reset Data',
	props<{ data: { totalProductsCount: number; pages: number[]; products: Product[][] } }>()
);
// Error
export const error = createAction('[Products Versioning] Error', props<{ error: ProductVersioningError }>());
