import { Injectable, inject } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
	DataleanBaseApiService,
	DataleanPagedResult,
	PaginationInfo,
	Parts,
	SimpleObject,
	SortInfo,
	Structure,
	StructureField,
	StructureFieldType,
	StructureType,
} from 'addiction-components';
import { Observable, Subject, forkJoin } from 'rxjs';
import { HeaderLocaleService } from 'src/app/components';
import { environment } from 'src/environments/environment';
import { Resource } from 'src/app/shared/models/reservations/resource.interface';
import { ResourceActions } from 'src/app/core/state/app.actions';

@Injectable({ providedIn: 'root' })
export class ResourceService {
	private store = inject(Store);
	private translateService = inject(TranslateService);
	private headerSrv = inject(HeaderLocaleService);
	private baseApi = inject(DataleanBaseApiService);
	private destroyed$ = new Subject();
	/* private lastCommunitySelected$ = this.store.select(CommunitySelectSelectors.selectLastCommunitySelected).pipe(takeUntil(this.destroyed$)); */
	private resourceUrl = `${environment.reservationResourceUrl}`;

	readonly baseFormControls: StructureField[] = [
		{
			name: 'name',
			isRequired: true,
			uuid: '',
			label: this.translateService.instant('PRODUCTS.NAME'),
			type: StructureFieldType.TEXT,
			order: -1,
			viewInCard: '',
			localizable: true,
		},
		{
			name: 'order',
			isRequired: true,
			uuid: '',
			label: this.translateService.instant('PRODUCTS.ORDER'),
			type: StructureFieldType.TEXT,
			order: -1,
			viewInCard: '',
			localizable: true,
		},
		{
			name: 'related',
			isRequired: false,
			uuid: '',
			label: this.translateService.instant('PRODUCTS.RELATED'),
			type: StructureFieldType.RELATED_ENTITIES,
			order: -1,
			viewInCard: '',
		},
	];

	constructor() {}

	init(): void {
		this.getResourceStructures();
	}

	destroy(): void {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	getResourceStructures() {
		const structureAdditionalParams: {
			type?: StructureType;
		} = { type: StructureType.RESOURCE };

		this.baseApi
			.getMany<Structure>(environment.structureUrl, [Parts.STRUCTURE_FIELDS], { additionalParams: structureAdditionalParams })
			.subscribe((structures) => {
				this.store.dispatch(ResourceActions.setStructures({ structures }));
			});
	}

	getResources(options: {
		parts?: Parts[];
		locale?: string;
		pagination?: PaginationInfo;
		sort?: SortInfo;
		filters?: { uuids?: string[]; structureUUID?: string; query?: string };
	}) {
		const { filters, parts, locale, pagination, sort } = options;

		const params: SimpleObject = {
			locale: locale ?? this.headerSrv.getActiveLocale(),
			active: true,
		};

		// TODO: generalizzare questa logica in una funzione reutilizzabile
		if (filters?.structureUUID) params['structureUUID'] = filters.structureUUID;
		if (filters?.query) {
			params['q'] = filters.query;
			params['searchFields'] = ['name']; // parametrizzare i campi di ricerca??
		}

		if (filters?.uuids) params['uuid'] = filters.uuids.join(',');

		return this.baseApi.getManyPaged<Resource>(this.resourceUrl, parts ?? [Parts.ALL], {
			pagination,
			sort,
			additionalParams: params,
		});
	}

	getStructures(parts: Parts[]) {
		if (!parts) parts = [Parts.EMPTY];

		return this.baseApi.getManyPaged<Structure>(environment.structureUrl, parts);
	}

	countResources(filters: { structureUUID?: string }) {
		return this.baseApi.getOne<{ count: number }>(this.resourceUrl + 'count', '', [], {
			organizationUUID: environment.organizationUUID,
			...filters,
			active: true,
		});
	}

	fetchResources(
		pages: number[],
		sort?: Sort,
		locale?: string,
		gridSearch?: string | null,
		filters?: {structureUUID?: string }
	): Observable<DataleanPagedResult<Resource>[]> {
		const obs: Observable<DataleanPagedResult<Resource>>[] = [];

		const params: {
			sortBy?: string;
			q?: string;
			searchFields?: string;
			locale?: string;
			active: boolean;
			structureUUID?: string;
		} = {
			locale: locale ?? this.headerSrv.getActiveLocale(),
			active: true,
		};

		// TODO: generalizzare questa logica in una funzione reutilizzabile
		if (filters?.structureUUID) params['structureUUID'] = filters.structureUUID;

		//SORT
		if (sort) params.sortBy = `${sort.active}#${sort.direction}`;
		if (gridSearch) {
			params.q = gridSearch;
			params.searchFields = 'name,description';
		}

		for (const page of pages) {
			const pagination: PaginationInfo = new PaginationInfo(environment.pageSize, page);

			obs.push(
				this.baseApi.getManyPaged<Resource>(this.resourceUrl, [Parts.STRUCTURE, Parts.AUDIT, Parts.CREATED_BY, Parts.UPDATE_DATE], {
					pagination,
					additionalParams: params,
				})
			);
		}
		return forkJoin(obs);
	}

	deleteResource(uuid: string) {
		return this.baseApi.deleteOne(this.resourceUrl, uuid);
	}
}
