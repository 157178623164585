import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, filter, takeUntil } from 'rxjs';
import { UserSelector } from '../../../core/state/app.selectors';
import { DataleanUser } from '../../models';

@Injectable({ providedIn: 'root' })
export class UserDataService implements OnDestroy {
	authenticatedUser: DataleanUser | null = null;
	private isDestroyed = new Subject();

	constructor(private store: Store) {
		this.store
			.select(UserSelector.selectUserData)
			.pipe(
				filter((data) => !!data),
				takeUntil(this.isDestroyed)
			)
			.subscribe((user) => {
				this.authenticatedUser = user;
			});
	}

	getUserUUID(): string | undefined {
		if (this.authenticatedUser) return this.authenticatedUser.uuid;
		else return undefined;
	}

	ngOnDestroy() {
		this.isDestroyed.next(true);
		this.isDestroyed.complete();
	}
}
