<!-- Componente da non usare, usare solo addiction-confirmation-message -->

<div class="modal-input-container">
	<div class="body-container">
		<div class="text" *ngIf="message" [innerHTML]="message | translate"></div>
		<div class="text" *ngIf="code" [innerHTML]="'<pre>' + code + '</pre>'"></div>
		<div class="button-container">
			<button (click)="close()">{{ 'OK' | translate }}</button>
		</div>
	</div>
</div>
