import { HttpErrorResponse } from "@angular/common/http";
import { Sort } from "@angular/material/sort";
import { createAction, props } from "@ngrx/store"
import { UserStructure } from "addiction-components";
import { ApplicationUser } from "src/app/shared/models";
import { STATE_STATUS } from "src/app/shared/models/state-status.enum";


export const setForceRefreshApplicationUsers = createAction('[Application Users] Set Force Refresh')
export const setApplicationUsers = createAction('[Application Users] Set Application Users')
export const setApplicationUsersSuccess = createAction('[Application Users] Set Application Users Success', props<{ data: { totalUsersCount: number; pages: number[]; users: ApplicationUser[][] } }>() )
export const setApplicationUsersError = createAction('[Application Users] Set Application Users Error', props<{error: HttpErrorResponse}>());
export const setDeleteApplicationUser = createAction('[Application Users] Set Delete Application Users', props<{user: ApplicationUser}>())
export const setDeleteApplicationUserSuccess = createAction('[Application Users] Set Delete Application Users Success')
export const setDeleteApplicationUserError = createAction('[Application Users] Set Delete Application Users Error', props<{error: HttpErrorResponse}>());
export const setStatus = createAction('[Application Users] Set status', props<{ status: STATE_STATUS }>())
export const setPages = createAction('[Application Users] Set pages', props<{ pages: number[] }>());
export const setSort = createAction('[Application Users] Set sort', props<{ sort: Sort }>());
export const setGridSearch = createAction('[Application Users] Set search', props<{ search: string | null }>());
export const setStructureUUID = createAction('[Application Users] Set structureUUID', props<{ structureUUID: string | null }>());
export const setStructures = createAction('[Application Users] Set structures');
export const setStructuresSuccess = createAction('[Application Users] Set User Structures Success', props<{ userStructures: UserStructure[] }>() )
export const setStructuresError = createAction('[Application Users] Set User Structures Error', props<{error: HttpErrorResponse}>());
export const setCountPerStructures = createAction('[Application Users] Set Count Per Structures structures');
export const setCountPerStructuresSuccess = createAction('[Application Users] Set Count Per Structures Success', props<{ userCountPerStructureMap: {structureUUID: string, count: number}[] }>() )
export const setCountPerStructureError = createAction('[Application Users] Set Count Per Structures Error', props<{error: HttpErrorResponse}>());
export const setCachedPages = createAction('[Application Users] Set cached pages', props<{ pages: number[] }>());
export const resetCache = createAction('[Application Users] reset cache');
