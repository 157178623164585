import { CommonModule } from '@angular/common';
import { Component, effect } from '@angular/core';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { ChartOptions } from 'chart.js';
import { NgChartsModule } from 'ng2-charts';
import { FeedbackService } from '../../../shared/services/feedback.service';
import { ChartPointsData, AccessesChartPoint, ChartData, STATE_STATUS, FeedbackWithDateString } from '../../../shared/models';
import { addDays, addMonths, differenceInDays, format } from 'date-fns';

@Component({
	selector: 'datalean-accesses-chart',
	templateUrl: './accesses-chart.component.html',
	styleUrls: ['./accesses-chart.component.scss'],
	standalone: true,
	imports: [CommonModule, TranslateModule, NgChartsModule],
})
export class AccessesChartComponent {
	lineChartData: Array<ChartData> = [];
	lineChartLabels: string[] = [];
	lineChartOptions: ChartOptions<'line'> = {
		responsive: true,
		scales: {
			x: {
				ticks: {
					color: 'rgba(255, 255, 255, 0.5)',
					stepSize: 10,
				},
				grid: {
					color: 'rgba(255, 255, 255, 0)',
				},
			},
			y: {
				position: 'left',
				ticks: {
					color: 'rgba(255, 255, 255, 0.5)',
					stepSize: 10,
				},
				grid: {
					color: 'rgba(61, 93, 150, 0.5)',
				},
			},
		},
		plugins: {
			legend: {
				align: 'start',
				labels: {
					boxWidth: 10,
					boxHeight: 10,
					color: 'rgba(255, 255, 255, 0.75)',
					font: {
						family: "'Segma', sans-serif",
						size: 12,
						lineHeight: '100%',
					},
					padding: 40,
					textAlign: 'left',
					useBorderRadius: true,
					borderRadius: 2,
				},
			},
		},
		interaction: {
			mode: 'index',
			intersect: false,
			axis: 'xy',
		},
	};
	lineChartLegend = true;
	monthsNumbers = [3, 6, 9, 12];
	monthsNumber = 3;
	private pointsGroupedByWeeks: { accessNumber: number; loginNumber: number; dates: { start: Date; end: Date } }[] = [];
	private points: ChartPointsData[] = [];
	private daysInRangeNumber = 7;

	constructor(private feedbackService: FeedbackService, private translatePipe: TranslatePipe) {
		this.daysInRangeNumber = (this.monthsNumber / 3) * 7;
		this.lineChartLabels = this.getDatesInRange();
		//creo l'effect per stare in ascolto di quando cambia il mio status, così
		//da essere sicura che ci siano i dati dei feedback
		effect(() => {
			const status = this.feedbackService.status();
			if (status === STATE_STATUS.READY) {
				//recupero i filtri di tipo has_logged
				const filteredFeedbackHasLogged = this.feedbackService.filteredFeedbackHasLogged();
				//recupero i filtri di tipo has_accessed
				const filteredFeedbackHasAccessed = this.feedbackService.filteredFeedbackHasAccessed();
				if (filteredFeedbackHasLogged && filteredFeedbackHasAccessed) {
					this.getPoints(AccessesChartPoint.ACCESS_NUMER, filteredFeedbackHasLogged);
					this.getPoints(AccessesChartPoint.LOGIN_NUMBER, filteredFeedbackHasAccessed);
				}
			}
		});
	}

	//TODO: gestire integrazioni successivamente
	// onChangeIntegration(select: MatSelectChange) {
	// 	const lastIntegrationSelected = select.value;
	// 	this.store.dispatch(IntegrationSelectActions.setLastIntegrationSelected({ lastIntegrationSelected }));
	// }

	getPoints(type: AccessesChartPoint, userLogs: Array<FeedbackWithDateString>) {
		for (const appUser of userLogs) {
			// Converto la data dell'appUser in un oggetto
			const date = new Date(appUser.date);
			// Converto la data dell'appUser in un oggetto
			const dateMilliseconds = date.getTime();
			// Converto la data dell'appUser in un oggetto
			let pointIndex = this.points.findIndex((point) => point.date.getTime() === dateMilliseconds);

			// Se non viene trovato un elemento con la stessa data, lo aggiungo
			if (pointIndex === -1) {
				this.points.push({ accessNumber: 0, loginNumber: 0, date: date });
				pointIndex = this.points.length - 1;
			}

			const point = this.points[pointIndex];
			for (const group of this.pointsGroupedByWeeks) {
				// Calcolo la differenza tra la data e il limite inferiore del gruppo
				const differenceFromStartRange = differenceInDays(date, group.dates.start);
				// Calcolo la differenza tra il limite superiore del gruppo e la data

				const differenceFromEndRange = differenceInDays(addDays(group.dates.end, 1), date);

				// Se la data rientra nel range specificato, incremento il count per quel tipo
				if (differenceFromStartRange < this.daysInRangeNumber && differenceFromEndRange < this.daysInRangeNumber) {
					group[type]++;
				}
			}
			point[type]++;
		}

		this.lineChartData.push({
			data: [],
			label: this.translatePipe.transform(
				'PAGES.DASHBOARD.ROWS.CHART.' +
					type
						.split(/(?=[A-Z])/)
						.join('_')
						.toUpperCase()
			),
			backgroundColor: this.colorLabel(type),
			borderColor: this.colorLabel(type),
			pointBackgroundColor: this.colorLabel(type),
			pointBorderdColor: this.colorLabel(type),
			pointRadius: 2,
			pointStyle: 'line',
			lineTension: 0,
		});

		this.points.sort((a, b) => a.date.getTime() - b.date.getTime());

		this.lineChartData[0].data = this.getPointsInRange(AccessesChartPoint.ACCESS_NUMER);
		if (this.lineChartData.length > 1) this.lineChartData[1].data = this.getPointsInRange(AccessesChartPoint.LOGIN_NUMBER);
	}

	changeUnit(value: number) {
		//ricalcolo il range in base ai mesi
		this.monthsNumber = value;
		this.daysInRangeNumber = (this.monthsNumber / 3) * 7;
		this.lineChartLabels = this.getDatesInRange();

		for (const point of this.points) {
			for (const group of this.pointsGroupedByWeeks) {
				// Verifico se il punto è all'interno del range.
				if (
					differenceInDays(point.date, group.dates.start) < this.daysInRangeNumber &&
					differenceInDays(addDays(group.dates.end, 1), point.date) < this.daysInRangeNumber
				) {
					// Aggiorna i conteggi nel gruppo di punti della settimana.
					group.accessNumber += point.accessNumber;
					group.loginNumber += point.loginNumber;
				}
			}
		}

		// Aggiorna i dati del grafico
		this.lineChartData[0].data = this.getPointsInRange(AccessesChartPoint.ACCESS_NUMER);
		if (this.lineChartData[1]) this.lineChartData[1].data = this.getPointsInRange(AccessesChartPoint.LOGIN_NUMBER);
	}

	private getPointsInRange(line: AccessesChartPoint) {
		return this.pointsGroupedByWeeks.map((point) => ({
			x: `${format(point.dates.start, 'dd/MM/yyyy')} - ${format(point.dates.end, 'dd/MM/yyyy')}`,
			y: point[line],
		}));
	}

	private getDatesInRange(): string[] {
		const dates: { start: Date; end: Date }[] = [];
		let currentDate = new Date();
		// Calcolo la data di fine sottraendo il numero di mesi specificato da currentDate

		const endDate = addMonths(currentDate, -this.monthsNumber);

		this.pointsGroupedByWeeks = [];
		//suddivido i dati settimanalmente
		while (differenceInDays(currentDate, endDate) >= this.daysInRangeNumber) {
			const startDate = addDays(currentDate, -(this.daysInRangeNumber - 1));
			const range = { start: startDate, end: currentDate };
			this.pointsGroupedByWeeks.push({ accessNumber: 0, loginNumber: 0, dates: range });
			dates.push(range);
			currentDate = addDays(currentDate, -this.daysInRangeNumber);
		}

		if (differenceInDays(currentDate, endDate) > 0) {
			const range = { start: endDate, end: currentDate };
			this.pointsGroupedByWeeks.push({ accessNumber: 0, loginNumber: 0, dates: range });
			dates.push(range);
		}
		// Inverto l'ordine delle date e formatto
		this.pointsGroupedByWeeks.reverse();
		return dates.reverse().map((range) => {
			return `${format(range.start, 'dd/MM/yyyy')} - ${format(range.end, 'dd/MM/yyyy')}`;
		});
	}

	colorLabel(type: AccessesChartPoint): string {
		return type === AccessesChartPoint.ACCESS_NUMER ? 'rgba(223, 49, 255, 1)' : 'rgba(0, 75, 196, 1)';
	}
}
