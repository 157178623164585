import { Component, Input } from '@angular/core';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { BaseModalContent } from '../modal/base-modal-content';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'datalean-message',
	templateUrl: './message.component.html',
	styleUrls: ['./message.component.scss'],
	standalone: true,
	imports: [CommonModule, TranslateModule],
})
export class MessageComponent extends BaseModalContent<string> {
	@Input() message = '';
	@Input() status = '';
	@Input() code = '';
	@Input() disableSave = false;
	@Input() showCancelButton = true;

	constructor(protected pipe: TranslatePipe) {
		super();
	}

	 close() {
		this.closeDialog('ok');
	}

	cancel() {
		this.cancelDialog();
	}
}
