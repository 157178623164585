import { ObjectValuesType } from 'addiction-components';
import { IDataleanErrorManager } from '../datalean-error-interface';

export interface FilterType {
	userOrGroupUUID?: string;
	communityUUID?: string;
	active: boolean;
	updateDate?: string;
	communityEntityUUID?: string;
	[key: string]: unknown;
}

export interface UserAndGroupError {
	errorType: UserAndGroupErrorType;
	data?: IDataleanErrorManager | unknown;
}

export const UserAndGroupErrorType = {
	GENERIC_HTTP: 'generic_http',
	UPDATE_USER: 'update_user',
};

export type UserAndGroupErrorType = ObjectValuesType<typeof UserAndGroupErrorType>;
