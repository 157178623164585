import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
	ApiOptions,
	Asset,
	DataleanBaseApiService,
	DataleanPagedResult,
	FeatureValue,
	FeatureValueType,
	ISortInfo,
	Parts,
	SearchType,
	SortInfo,
	ThumbnailConfig,
	WithProgress,
	AssetType,
	SimpleObject,
} from 'addiction-components';
import { Observable, forkJoin, map } from 'rxjs';
import { MediaLibraryActions } from 'src/app/core/state/app.actions';
import { AssetSaveData, AssetUpdateBody, Conversion } from 'src/app/shared/models';
import { environment } from 'src/environments/environment';
import { MediaLibraryAvailableFilters, MediaLibraryFilters } from '../state/media-library.state';

@Injectable({ providedIn: 'root' })
export class MediaLibraryService {
	/** Parts standard necessari per tutte le richieste */
	private parts: Parts[] = [Parts.FULL_ASSET_INFO, Parts.UPDATE_DATE, Parts.TAGS];

	constructor(private baseApi: DataleanBaseApiService, private store: Store) {}

	public loadAssets(
		path: string[] | undefined,
		filters?: MediaLibraryFilters,
		pages: number[] = [],
		sort?: SortInfo | SortInfo[] | ISortInfo,
		locale?: string,
    communityUUID?: string
	) {
		const additionalParams: SimpleObject = {
			assetType: AssetType.FOLDER + SearchType.NOT, // filtro le cartelle se non c'è un filtro specificato sul tipo di file
			parentUUID: 'all', // 👈 necessario per eliminare il filtro che viene applicato di default sul parentUUID
		};
		if (path?.length) {
			if (!additionalParams['featureValueList']) additionalParams['featureValueList'] = '';
			additionalParams['featureValueList'] += `${path.join(',')}#and`;
		}
    if (communityUUID) additionalParams['communityUUID'] = communityUUID
		if (locale) {
			additionalParams['locale'] = locale;
		}

		if (filters && Object.keys(filters).length) {
			if (filters.q) {
				additionalParams['q'] = filters.q;
				additionalParams['searchFields'] = 'name';
			}
			if (filters.assetType?.length) {
				additionalParams['assetType'] = filters.assetType.join(',');
			}
			// } else if (filters.assetType === '') {
			// 	delete additionalParams['assetType'];
			// 	//se cerco un asset di un qualsiasi tipo faccio in modo che le cartella arrivino prima degli assets
			// 	const folderSort: SortInfo = { sortBy: 'assetType', sortDirection: 'folder' };
			// 	if (Array.isArray(sort)) {
			// 		sort = [folderSort].concat(sort);
			// 	} else {
			// 		if (sort) {
			// 			sort = [folderSort, sort];
			// 		} else {
			// 			sort = [folderSort];
			// 		}
			// 	}
			// }
			//range require format r|fromDate|toDate
			if (filters.updateFrom) {
				additionalParams['updateDate'] = 'r|' + filters.updateFrom;
			}
			if (filters.updateTo) {
				if (additionalParams['updateDate']) {
					additionalParams['updateDate'] += '|' + filters.updateTo;
				} else {
					additionalParams['updateDate'] = 'r||' + filters.updateTo;
				}
			}
			if (filters.createFrom) {
				additionalParams['createDate'] = 'r|' + filters.createFrom;
			}
			if (filters.createTo) {
				if (additionalParams['createDate']) {
					additionalParams['createDate'] += '|' + filters.createTo;
				} else {
					additionalParams['createDate'] = 'r||' + filters.createTo;
				}
			}
			if (filters.tags) {
				additionalParams['tags'] = filters.tags;
				if (filters.tags.split(',').length > 1) additionalParams['tags'] += '#and';
			}
			if (filters.featureValueList) {
				if (!additionalParams['featureValueList']) additionalParams['featureValueList'] = '';
				additionalParams['featureValueList'] += `${filters.featureValueList}#and`;
			}
			if (filters.format) {
				additionalParams['format'] = filters.format;
			}
		}
		const options: ApiOptions = {
			pagination: { pageNumber: 0, pageSize: 200 },
			additionalParams,
			sort,
		};

		if (sort) {
			if (!Array.isArray(sort)) {
				options.sort = [sort];
			} else {
				options.sort = sort;
			}
		}
		const obs: Observable<DataleanPagedResult<Asset>>[] = [];
		for (const page of pages) {
			const pagination = { pageNumber: page, pageSize: 200 };
			options.pagination = pagination;
			obs.push(this.baseApi.getManyPaged<Asset>(environment.mediaLibraryUrl, this.parts, options));
		}

		return forkJoin(obs);
	}

	public loadAsset(uuid: string) {
		return this.baseApi.getOne<Asset>(environment.mediaLibraryUrl, uuid, this.parts);
	}

	/** Recupera l'elenco delle cartelle
	 * @param treeStructure Indica se recuperare le cartelle come struttura ad albero o lista flat
	 */
	public loadFolders(treeStructure: boolean) {
		// TODO: DOVREBBE PRENDERE ANCHE LA COMMUNITY SELEZIONATA COME FILTRO
		const additionalParams: SimpleObject = {
			assetType: AssetType.FOLDER,
		};

		if (treeStructure) additionalParams['tree'] = true;

		return this.baseApi.getManyPaged<Asset>([environment.mediaLibraryUrl, 'folders'], [], {
			additionalParams,
			sort: {
				sortBy: 'index',
				sortDirection: 'asc',
			},
		});
	}

	public loadFeatureValues(treeStructure: boolean, locale?: string) {
		// TODO: DOVREBBE PRENDERE ANCHE LA COMMUNITY SELEZIONATA COME FILTRO
		const additionalParams: SimpleObject = {
			type: FeatureValueType.ASSET,
			parentUUID: '',
		};

		const parts: Parts[] = [];

		if (treeStructure) parts.push(Parts.FEATURE_VALUES);

		if (locale) additionalParams['locale'] = locale;

		return this.baseApi.getManyPaged<FeatureValue>([environment.featureValueUrl], parts, {
			additionalParams,
			sort: {
				sortBy: 'order',
				sortDirection: 'asc',
			},
		});
	}

	/** Sposta una serie di elementi in una nuova cartella */
	public moveItems(uuids: string[], newParentUUID: string | undefined) {
		const payload = {
			uuids,
			destinationUUID: newParentUUID ?? 'null',
		};

		return this.baseApi.createOne([environment.mediaLibraryUrl, 'moveTo'], payload).pipe(map(() => true));
	}

	/** Shifting the folders */
	public updateFolder(entityList: AssetSaveData[]) {
		return this.multipleUpdate({ entityList }).pipe(map(() => true));
	}

	public deleteFolder(folder: Asset) {
		return this.baseApi.deleteOne(environment.mediaLibraryUrl, folder.uuid).pipe(map(() => true));
	}

	public createFolder(folder: AssetSaveData) {
		// questo endpoint prevede l'utilizzo di un content/type multipart/form-data
		const fd = this.baseApi.toFormData(folder);
		return this.baseApi.createOne<FormData, Asset>(environment.mediaLibraryUrl, fd).pipe(map(() => true));
	}

	public deleteAsset(uuid: string): Observable<true> {
		return this.baseApi.deleteOne(environment.mediaLibraryUrl, uuid).pipe(map(() => true));
	}

	public createAsset(asset: AssetSaveData): Observable<WithProgress<Asset>> {
		return this.baseApi.createAsset(asset, this.parts);
	}

	public updateAsset(asset: AssetSaveData): Observable<WithProgress<Asset>> {
		return this.baseApi.updateAsset(asset, this.parts);
	}

	public cloneAsset(uuid: string) {
		return this.baseApi.createOne<Record<string, unknown>, Asset>([environment.mediaLibraryUrl, uuid, 'clone'], {}, [Parts.FULL_ASSET_INFO]);
	}

	public multipleUpdate(bulkPayload: { uuidList?: string[]; delete?: boolean; entityList?: AssetSaveData[] }) {
		return this.baseApi.updateBulk(environment.mediaLibraryUrl + 'multipleUpdate', bulkPayload).pipe(map(() => true));
	}
	public multipleUpdateV2(body: AssetUpdateBody) {
		return this.baseApi.updateBulk(environment.mediaLibraryUrl + 'multipleUpdate/v2', body).pipe(map(() => true));
	}

	getThumbnailConfigs() {
		this.baseApi.getMany<ThumbnailConfig>(environment.thumbnailConfigUrl, [Parts.EMPTY]).subscribe((thumbnailConfigs) => {
			this.store.dispatch(MediaLibraryActions.setThumbnailConfigs({ thumbnailConfigs }));
		});
	}

	createThumbnail(thumbnail: Omit<ThumbnailConfig, 'uuid'>): Observable<ThumbnailConfig> {
		return this.baseApi.createOne(environment.thumbnailConfigUrl, thumbnail);
	}

	regenerateThumbnails(asset: Asset) {
		const body = {
			asset: asset,
			operationType: 'update',
		};
		return this.baseApi.updateBulk<Asset, { asset: Asset; operationType: string }>(
			environment.mediaLibraryUrl + `${asset.uuid}/generateThumbnails`,
			body
		);
	}

	deleteThumbnail(thumbnailUUID: string): Observable<object> {
		return this.baseApi.deleteOne(environment.thumbnailConfigUrl, thumbnailUUID);
	}

	getConversions() {
		this.baseApi.getMany<Conversion>(environment.conversionUrl, [Parts.EMPTY]).subscribe((conversions) => {
			if (conversions.length <= 0) {
				this.store.dispatch(MediaLibraryActions.setConversions({ conversions: undefined }));
			} else {
				this.store.dispatch(MediaLibraryActions.setConversions({ conversions }));
			}
		});
	}

	createConversion(conversion: Omit<Conversion, 'uuid'>): Observable<Conversion> {
		return this.baseApi.createOne(environment.conversionUrl, conversion);
	}

	deleteConversion(conversionUUID: string): Observable<object> {
		return this.baseApi.deleteOne(environment.conversionUrl, conversionUUID);
	}

	fetchAvailableFilters(parentFeatureUUID?: string, filterState?: MediaLibraryFilters): Observable<MediaLibraryAvailableFilters> {
		//FILTRI
		let assetType, createDate, updateDate, format, tags, querySearch;
		if (filterState) {
			if (filterState.assetType && filterState.assetType.length) {
				//con array vuoto tengo il defaut
				assetType = filterState.assetType?.join(',');
			}

			if (filterState.tags) {
				tags = filterState.tags;
			}

			//calcolo DATE
			if (filterState.createFrom) {
				createDate = 'r|' + filterState.createFrom;
			}
			if (filterState.createTo) {
				if (createDate) createDate += '|' + filterState.createTo;
				else createDate = 'r||' + filterState.createTo;
			}
			if (filterState.updateFrom) {
				updateDate = 'r|' + filterState.updateFrom;
			}
			if (filterState.updateTo) {
				if (updateDate) updateDate += '|' + filterState.updateTo;
				else updateDate = 'r||' + filterState.updateTo;
			}

			//FORMATO
			if (filterState?.format) {
				format = filterState.format;
			}

			//SEARCH
			if (filterState.q) {
				querySearch = filterState.q;
			}
		}

		const param: {
			featureParentUUID?: string;
			assetType?: string;
			featureValueList?: string;
			tags?: string;
			format?: string;
			createDate?: string;
			updateDate?: string;
			q?: string; //query fatta dal utente
			searchFields?: string; //quando utente fa la query passarlo con name:q
		} = {};

		if (parentFeatureUUID) param.featureParentUUID = parentFeatureUUID;
		if (format) param.format = format;
		if (assetType) param.assetType = assetType;
		if (tags) param.tags = tags;
		if (querySearch) {
			param.q = querySearch;
			param.searchFields = 'name';
		}
		//DATE
		if (createDate) param.createDate = createDate;
		if (updateDate) param.updateDate = updateDate;

		return this.baseApi.getEntities<MediaLibraryAvailableFilters>(environment.mediaLibraryUrl + 'filters', param, []);
	}
}
