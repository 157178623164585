import { FormControl } from '@angular/forms';
import { HorizontalPositionType, MethodType, VerticalPositionType } from './media-library/thumbnail-config';

export interface FormModalThumbanil {
  thumbnailName: FormControl<string>;
	width: FormControl<number>;
	widthAuto: FormControl<boolean>;
	height: FormControl<number>;
	heightAuto: FormControl<boolean>;
	thumbnailPrintingMethod: FormControl<MethodType>;
  thumbnailVerticalPosition: FormControl<VerticalPositionType>;
	thumbnailHorizontalPosition: FormControl<HorizontalPositionType>;
}
