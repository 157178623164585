import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DATALEAN_LESSON_TAG, CoursesState } from './courses.reducer';

const selectCoursesState = createFeatureSelector<CoursesState>(DATALEAN_LESSON_TAG);

export const selectData = createSelector(selectCoursesState, (state: CoursesState) => state.data);
export const selectPages = createSelector(selectCoursesState, (state: CoursesState) => state.pages);
export const selectPagedData = createSelector(selectData, selectPages, (data, pages) => ({ ...data, pages }));
export const selectGridSearch = createSelector(selectCoursesState, (state: CoursesState) => state.gridSearch);
export const selectStatus = createSelector(selectCoursesState, (state: CoursesState) => state.status);
export const selectSort = createSelector(selectCoursesState, (state: CoursesState) => state.sort);
export const selectRecipients = createSelector(selectCoursesState, (state: CoursesState) => state.recipients);
export const selectCachedPages = createSelector(selectCoursesState, (state) => state.cachedPages);
