import { HttpErrorResponse } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { FeatureValue, Page, Structure } from 'addiction-components';
import { STATE_STATUS } from 'src/app/shared/models';
import { Product } from '../../../shared/models/product.interface';
import { FiltersType } from './product.reducers';
import { ProductError } from './product.state';

export const setFilters = createAction('[Products] Set Filters', props<{ filters: FiltersType }>());
// export const setSort = createAction('[Products] Set Sort', props<{ sort: SortInfo }>());
export const setStructures = createAction('[Products] Set Products Structures', props<{ structures: Array<Structure> }>());
export const setFeatures = createAction('[Products] Set Products Features', props<{ features: Array<FeatureValue> }>());
export const updateFilters = createAction('[Products] Update Filters', props<{ updatedFilters: FiltersType }>());
export const addFeatureValueToFilters = createAction('[Products] Add FeatureValue To Filters', props<{ featureValueUUID: string }>());
export const removeFeatureValueFromFilters = createAction('[Products] Remove FeatureValue From Filters', props<{ featureValueUUID: string }>());
export const updatePage = createAction('[Products] Update Page', props<{ updatedPage: Partial<Page> }>());
export const deleteProducts = createAction('[Products] Delete Products', props<{ uuids: string[] }>());
export const disableProducts = createAction('[Products] Disable Products', props<{ uuids: string[] }>());

export const setForceRefreshProducts = createAction('[Products] Set Force Refresh');
export const setProducts = createAction('[Products] Set Products');
export const setProductsSuccess = createAction(
	'[Products] Set Products Success',
	props<{ data: { totalProductsCount: number; pages: number[]; products: Product[][] } }>()
);
export const setProductsError = createAction('[Products] Set Products Error', props<{ error: HttpErrorResponse }>());
export const setDeleteProducts = createAction('[Products] Set Delete Products', props<{ product: Product }>());
export const setDeleteProductsSuccess = createAction('[Products] Set Delete Products Success');
export const setDeleteProductsError = createAction('[Products] Set Delete Products Error', props<{ error: HttpErrorResponse }>());
export const setDisableProductsError = createAction('[Products] Set Delete Contents Error', props<{ error: HttpErrorResponse }>());
export const setDisableProductsSuccess = createAction('[Products] Set Disable Contents Success');
export const setStatus = createAction('[Products] Set status', props<{ status: STATE_STATUS }>());
export const setPages = createAction('[Products] Set pages', props<{ pages: number[] }>());
export const setSort = createAction('[Products] Set sort', props<{ sort: Sort }>());
export const setGridSearch = createAction('[Products] Set search', props<{ search: string | null }>());
export const setCachedPages = createAction('[Products] Set cached pages', props<{ pages: number[] }>());
export const resetCache = createAction('[Products] reset cache');

// Error
export const error = createAction('[Products] Error', props<{ error: ProductError }>());

