import { inject } from "@angular/core";
import { combineLatest, first, map, Observable, switchMap } from "rxjs";
import { ContextStore } from "../../context-store.service";
import { NGRX_CONTEXT_SELECTOR_META } from "../../meta";

/**
 * 🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥
 * 🔥🔥 BENVENUTO ALL'INFERNO 🔥🔥
 * 🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥
 * 
 * Questo è l'abisso, da qui si può solo ascendere o perire.
 * Scrolla a tuo rischio e pericolo
 */

type Selector<TIn, TOut> = (ctx:string) => (state:TIn) => TOut;
type InputSelector<TPrev, TIn, TOut> = (Selector<TIn, TOut>) | ((prev:TPrev) => Selector<TIn, TOut>);
type ContextAction = { context?:string };

/**  Aggiunge alla pipeline corrente uno o più (fino a 4) selettori. Dopo questo operatore il valore sarà una tupla con al primo posto
 * il valore precedente e successivamente i risultati degli altri valori.
 * 
 * @example 
 * pipe(
 *  ...
 *  joinFromContext(selettore1, selettore2),
 *  map(([valorePrec, sel1, sel2]) => ... )
 *  ...
 * )
*/
export function joinFromContext<TState, S1, TPrev extends ContextAction>(store:ContextStore, s1:InputSelector<TPrev, TState, S1>): 
    ($:Observable<TPrev>) => Observable<[TPrev, S1, never, never, never]>;
export function joinFromContext<TState, S1, S2, TPrev extends ContextAction>(store:ContextStore, s1:InputSelector<TPrev, TState, S1>, s2:InputSelector<TPrev, TState, S2>): 
    ($:Observable<TPrev>) => Observable<[TPrev, S1, S2, never, never]>;
export function joinFromContext<TState, S1, S2, S3, TPrev extends ContextAction>(store:ContextStore, s1:InputSelector<TPrev, TState, S1>, s2:InputSelector<TPrev, TState, S2>, s3:InputSelector<TPrev, TState, S3>): 
    ($:Observable<TPrev>) => Observable<[TPrev, S1, S2, S3, never]>;
export function joinFromContext<TState, S1, S2, S3, S4, TPrev extends ContextAction>(store:ContextStore, s1:InputSelector<TPrev, TState, S1>, s2:InputSelector<TPrev, TState, S2>, s3:InputSelector<TPrev, TState, S3>, s4:InputSelector<TPrev, TState, S4>): 
    ($:Observable<TPrev>) => Observable<[TPrev, S1, S2, S3, S4]>;



export function joinFromContext<TState, S1, S2, S3, S4, TPrev extends ContextAction>(
    store:ContextStore, 
    s1:InputSelector<TPrev, TState, S1>, 
    s2?:InputSelector<TPrev, TState, S2>, 
    s3?:InputSelector<TPrev, TState, S3>,
    s4?:InputSelector<TPrev, TState, S4>
): ($:Observable<TPrev>) => Observable<[TPrev, S1, S2, S3, S4]>
{
    // dato che non c'è un modo sano di mente per fare overload in JS, devo dichiarare tutti i parametri come opzionali (tranne il primo)

    return (action$:Observable<TPrev>) => {

        return action$.pipe(
            switchMap((prev) => {
                // uso any per evitare di dover impazzire gli overload di invoke
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const selectors$ = [s1,s2,s3,s4].filter(s => !!s).map((s:any) => store.select(invoke(s, prev), prev.context));
                return combineLatest(selectors$).pipe(
                    first(), 
                    map((x) => {
                        // poi mi tocca controllare quale dei parametri è mancante e da li dedurre a quale overload sono (a mano...)
                        if (!s2)
                            return [prev, ...x] as [TPrev, S1, never, never, never]
                        else if (!s3)
                            return [prev, ...x] as [TPrev, S1, S2, never, never];
                        else if (!s4)
                            return [prev, ...x] as [TPrev, S1, S2, S3, never];
                        else 
                            return [prev, ...x] as [TPrev, S1, S2, S3, S4]

                        // il tipo 'never' viene usato per definire che quel tipo non c'è. Per via di come fuziona l'overload in JS,
                        // tutte le versioni della funzione devono restituire lo stesso tipo, quindi non si può omettere un tipo della tupla
                        // (ovvero non si può fare [TPrev, S1] e basta), ma devono essere sempre specificati tutti gli N tipi.
                    })
                )
            })
        )
    }
}

/** Questa funzione assolve uno scopo: gestire il polimorfismo del parametro che può essere:
 * _ un selettore con contesto
 * _ una funzione che restituisce un selettore con contest
 * 
 * Dato che non c'è un modo chiaro per distinguere il tipo dei parametri (sono entrambe due function), usa il trucchetto
 * di controllare se è definito un metadato sulla funzione. Questo metadato viene aggiunto solo dalla libreria
 */
function invoke<TPrev, TState, S1>(sel:InputSelector<TPrev, TState, S1>, prev:TPrev):Selector<TState, S1> {
    if (Object.getOwnPropertyNames(sel).includes(NGRX_CONTEXT_SELECTOR_META))
        return (sel as Selector<TState, S1>);
    else 
        return (sel as (value:TPrev) => Selector<TState, S1>)(prev);
}

