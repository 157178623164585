import { createReducer, on } from '@ngrx/store';
import { ConfigState } from './config.state';
import { ConfigActions } from '../../../core/state/app.actions';

export const TAG = 'config';

const initialState: ConfigState = {
  configurationMap: {}
};

export const counterReducer = createReducer(
  initialState,
  on(ConfigActions.fetchConfigSuccess, (state: ConfigState, {configurationMap}) => ({
    ...state,
    configurationMap,
  }))
);
