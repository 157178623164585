/* eslint-disable @typescript-eslint/no-explicit-any */

import { Action, ReducerTypes, ActionReducer, createReducer } from "@ngrx/store";
import { ContextState } from "../context-state.interface";



/** Crea un reducer basato sul context. Deve essere fornito lo stato di default che viene utilizzato per creare un nuovo contesto quando 
 * deve essere generato ed i vari reducer per alterare il singolo contesto al verificarsi dell'azione. 
 * La gestione dei contesti (creazione e merge) viene gestita automaticamente.
 */
export function createContextReducer<CtxState extends ContextState<InState>, InState, A extends Action = Action>(
	initialState: InState,
	...ons: ReducerTypes<InState, any>[]
): ActionReducer<CtxState, A> {
    const defaultState = { contexts: {} } as CtxState;
	const reducer = createReducer({} as InState, ...[...ons]);
	return function (state: CtxState = defaultState, action: A) {
		return ctxReducer<CtxState, InState, A>(initialState, reducer)(state, action);
	};
}

function ctxReducer<CtxState extends ContextState<InState>, InState, Next extends Action>(
    defaultState: InState,
	callback: (state: InState, next: Next) => InState,
) {
	return (state: CtxState, next: Next): CtxState => {
        // questo è un reducer custom che invoca il reducer sullo context giusto
        const { context } = next as Next & { context?:string };
       
        if (!context) {
            // not a context action, so ignore and the the default behaviour
            return callback(state as any, next) as any;
        }
   
        let newInnerState:InState | undefined = undefined;
        if (!(context in state.contexts)) {
            // new state, create it with default values
            newInnerState = callback({ ...defaultState }, next) as InState;
        } else {
            // existing state, update it
            newInnerState = callback(state.contexts[context], next) as InState;
        }
        
        // merge the new context with the existing one
        return {
            ...state,
            contexts: {
                ...state.contexts,
                [context]: newInnerState
            }
        }
	};
}