import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GROUPS_TAG, GroupsState } from './groups.reducer';

const selectGroupsState = createFeatureSelector<GroupsState>(GROUPS_TAG);

export const selectData = createSelector(selectGroupsState, (state: GroupsState) => state.data);
export const selectPages = createSelector(selectGroupsState, (state: GroupsState) => state.pages);
export const selectCachedPages = createSelector(selectGroupsState, (state: GroupsState) => state.cachedPages);
export const selectPagedData = createSelector(selectData, selectPages, (data, pages) => ({ ...data, pages }));
export const selectGridSearch = createSelector(selectGroupsState, (state: GroupsState) => state.gridSearch);
export const selectStatus = createSelector(selectGroupsState, (state: GroupsState) => state.status);
export const selectSort = createSelector(selectGroupsState, (state: GroupsState) => state.sort);
