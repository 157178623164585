import { ObjectValuesType } from 'addiction-components';

export interface AvailabilityOption {
	day: Days | 'ALL';
	type: AvailabilityOptionType;
	hourControl: AvailabilityOptionHourControl;
	workshifts?: TimeRange[];
}

export interface TimeRange {
	from: Date | string;
	to: Date | string;
}
export const Days = {
	SUNDAY: 'sunday',
	MONDAY: 'monday',
	TUESDAY: 'tuesday',
	WEDNESDAY: 'wednesday',
	THURSDAY: 'thursday',
	FRIDAY: 'friday',
	SATURDAY: 'saturday',
} as const;

export type Days = ObjectValuesType<typeof Days>;

export const AvailabilityOptionHourControl = {
	FULL_DAY: 'FULL_DAY',
	CUSTOM: 'CUSTOM',
} as const;
export type AvailabilityOptionHourControl = ObjectValuesType<typeof AvailabilityOptionHourControl>;

export const AvailabilityOptionType = {
	CAN_RESERVE: 'CAN_RESERVE',
	CAN_NOT_RESERVE: 'CAN_NOT_RESERVE',
} as const;
export type AvailabilityOptionType = ObjectValuesType<typeof AvailabilityOptionType>;
