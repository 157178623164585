import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, catchError, combineLatestWith, debounce, distinctUntilChanged, filter, first, map, of, switchMap, tap } from 'rxjs';
import { CommunitySelectActions, CoursesActions } from 'src/app/core/state/app.actions';
import { Course, STATE_STATUS } from 'src/app/shared/models';
import { CoursesService } from '../services/courses.service';
import {
	resetCache,
	setCoursesError,
	setCoursesSuccess,
	setDeleteCourses,
	setDeleteCoursesError,
	setDeleteCoursesSuccess,
	setForceRefreshCourses,
	setGridSearch,
	setPages,
	setSort,
	setStatus,
} from './courses.actions';
import { selectCachedPages, selectGridSearch, selectPagedData, selectPages, selectSort, selectStatus } from './courses.selectors';
import { CommunitySelectSelectors } from 'src/app/core/state/app.selectors';

@Injectable()
export class CoursesEffects {
	private actions$ = inject(Actions);
	private store = inject(Store);
	private translateService = inject(TranslateService);
	private courseService = inject(CoursesService);

	constructor() {}

	fetchCourses$ = createEffect(() =>
		this.actions$.pipe(
			//quando selezioni una categoria o fai una ricerca, viene triggherato il DamFilter, quindi ci registriamo ad
			//un suo eventuale successo per richiamare gli assets
			ofType(setPages, setForceRefreshCourses),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(CommunitySelectSelectors.selectLastCommunitySelectedForApiRest),
				this.store.select(selectCachedPages),
			]),
			debounce(() => this.store.select(selectStatus).pipe(filter((stat) => !stat || stat !== 'loading'))),
			//non voglio caricare se non ho le pagine pronte
			filter(([, pages]) => !!pages?.length),
			tap(() => this.store.dispatch(setStatus({ status: STATE_STATUS.LOADING }))),
			switchMap(([, pages, sort, gridSearch, communityUUID, cachedPages]) => {
				const actualPages = pages.filter((p) => !cachedPages.includes(p));
        if (!actualPages.length) return EMPTY;
				return this.courseService
					.fetchCourses(actualPages, sort, this.translateService.currentLang ?? this.translateService.defaultLang, gridSearch, communityUUID)
					.pipe(
						combineLatestWith(this.store.select(selectPagedData)),
						first(),
						map(([data, startingResult]) => {
							startingResult.pages = cachedPages;
							const result = data.reduce((acc, item) => {
								if (item.paginationInfo) {
									acc.pages.push(item.paginationInfo.numberOfPage);
									acc.totalCoursesCount = item.paginationInfo.totalNumberOfElements;
									if (item.result) {
										acc.courses[item.paginationInfo.numberOfPage] = item.result.map((course) => ({
											...course,
											lessons: course.lessonList?.length ?? 0,
										}));
									}
								}
								return acc;
							}, structuredClone(startingResult));

							return setCoursesSuccess({ data: result });
						}),
						catchError((error: HttpErrorResponse) => {
							console.error(error);
							return of(setCoursesError({ error }));
						})
					);
			})
		)
	);

	resetCache$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CoursesActions.setGridSearch, CoursesActions.setSort, CommunitySelectActions.setLastCommunitySelected),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(CommunitySelectSelectors.selectLastCommunitySelectedForApiRest),
				this.store.select(selectCachedPages),
			]),
			//non voglio caricare se i dati delle azioni sono gli stessi
			distinctUntilChanged(
				([, pagesPrev, sortPrev, gridSearchPrev], [, pagesCurr, sortCurr, gridSearchCurr, communityUUIDCurr, cachedPages]) => {
					return (
						pagesPrev.length === pagesCurr.length &&
						pagesPrev.every((f) => pagesCurr.includes(f)) &&
						sortCurr?.active === sortPrev?.active &&
						sortCurr?.direction === sortPrev?.direction &&
						gridSearchCurr === gridSearchPrev &&
						pagesPrev.filter((f) => !cachedPages.includes(f)).length === 0
					);
				}
			),
			map(() => CoursesActions.resetCache())
		)
	);

	deleteCourses$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setDeleteCourses),
			switchMap(({ uuids }) => {
				return this.courseService.delete(uuids).pipe(
					map(() => {
						return setDeleteCoursesSuccess();
					}),
					catchError((error: HttpErrorResponse) => {
						return of(setDeleteCoursesError({ error }));
					})
				);
			})
		)
	);

	courseSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setCoursesSuccess),
			map(({ data: { pages } }) => CoursesActions.setCachedPages({ pages }))
		)
	);

	afterDeleteCourses$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CoursesActions.setDeleteCoursesSuccess, CoursesActions.setDeleteCoursesError),
			map(() => CoursesActions.resetCache())
		)
	);

	forceRefreshTable$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CoursesActions.resetCache),
			map(() => CoursesActions.setForceRefreshCourses())
		)
	);
}
