import { createReducer, on } from '@ngrx/store';
import produce from 'immer';
import { PictogramStructureState } from './pictogram-structure.state';
import { PictogramStructureActions } from 'src/app/core/state/app.actions';

export const TAG = 'pictogramStructures';

export const initialState: PictogramStructureState = {
	structures: [],
	loading: false,
};


export const reducer = createReducer(
	initialState,
	// GENERIC LOADING\ERROR ACTIONS
	on(
		PictogramStructureActions.createPictogramStructure,
		PictogramStructureActions.deletePictogramStructure,
		PictogramStructureActions.updatePictogramStructure,
		PictogramStructureActions.reloadPictogramStructures,
		(state) => ({
			...state,
			loading: true,
			error: undefined,
		})
	),
	on(
		PictogramStructureActions.createPictogramStructureFailure,
		PictogramStructureActions.deletePictogramStructureFailure,
		PictogramStructureActions.updatePictogramStructureFailure,
		PictogramStructureActions.loadPictogramStructuresFailure,
		(state) => ({
			...state,
			loading: false,
			error: true,
		})
	),
	on(PictogramStructureActions.loadPictogramStructuresSuccess, (state, { pictogramStructures }) => ({
		...state,
		loading: false,
		structures: pictogramStructures,
		editingUUID: undefined,
	})),
	on(PictogramStructureActions.createPictogramStructureSuccess, (state, { pictogramStructure }) => ({
		...state,
		loading: false,
		selectedUUID: pictogramStructure.uuid,
		editingUUID: undefined,
		error: false,
	})),
	on(PictogramStructureActions.deletePictogramStructureSuccess, (state, { uuid }) =>
		produce(state, (draft) => {
			draft.loading = false;
			draft.error = false;
			draft.selectedUUID = uuid === draft.selectedUUID ? undefined : draft.selectedUUID
		})
	),
	on(PictogramStructureActions.updatePictogramStructureSuccess, (state, { pictogramStructure }) =>
		produce(state, (draft) => {
			draft.loading = true;
			draft.error = false;
			draft.selectedUUID = pictogramStructure.uuid;
		})
	),
	on(PictogramStructureActions.selectPictogramStructure, (state, { uuid }) => ({
		...state,
		selectedUUID: uuid,
	})),
	on(PictogramStructureActions.editPictogramStructure, (state, { uuid }) => ({
		...state,
		editingUUID: uuid,
	}))
);
