import { createReducer, on } from '@ngrx/store';
import { ApplicationState } from './application.state';
import { ApplicationActions } from '../app.actions';

export const TAG = 'application';

const initialState: ApplicationState = {
  activeLanguage: ''
};

export const counterReducer = createReducer(
  initialState,
  on(ApplicationActions.setActiveLanguage, (state: ApplicationState, { activeLanguage }) => ({
    ...state,
    activeLanguage
  }))
);
