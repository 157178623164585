import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, map, throwError } from 'rxjs';
import { CustomHeader, DataleanEntity, EnvironmentConfiguration, PaginationInfo, Parts, SearchInfo, SortInfo, UnknownObject, UrlBuilder } from '../models';
import { WithProgress } from '../models/with-progress';
import { trackProgress } from '../utils/track-progress';
import { DataleanApiService, Url } from './datalean-api.service';
import { HttpOptions } from '../models/http-options.interface';

@Injectable({ providedIn: 'root' })
export class DataleanBaseApiService extends DataleanApiService {
	constructor(@Inject('environment') environment: EnvironmentConfiguration) {
		super(environment);
	}

	getEntitiesWithPaginationData<T>(
		endpoint: string,
		additionalParams: Record<string, any> | undefined,
		parts: Parts[],
		searchInfo?: SearchInfo,
		paginationInfo?: PaginationInfo,
		sortInfo?: SortInfo
	): Observable<{ result: T[] | null; paginationInfo?: PaginationInfo }> {
		return this.http
			.get<T[]>(this.prepareUrl(endpoint, this.environment.organizationUUID, additionalParams, parts, searchInfo, paginationInfo, sortInfo), {
				observe: 'response',
			})
			.pipe(
				map((data) => {
					const result = data.body;
					let paginationInfoResult;
					if (!!paginationInfo && data instanceof HttpResponse && !!data?.headers) {
						paginationInfoResult = new PaginationInfo(
							parseInt(data.headers.get(CustomHeader.pageSize) ?? '25'),
							parseInt(data.headers.get(CustomHeader.pageIndex) ?? '1') - 1,
							parseInt(data.headers.get(CustomHeader.totalCount) ?? '0') ?? result?.length ?? 0
						);
					}
					return {
						result,
						paginationInfo: paginationInfoResult,
					};
				})
			);
	}

	getEntities<T>(
		endpoint: string,
		additionalParams: UnknownObject | undefined,
		parts: Parts[],
		searchInfo?: SearchInfo,
		paginationInfo?: PaginationInfo,
		sortInfo?: SortInfo,
		options?: HttpOptions
	): Observable<T> {
		return this.http.get<T>(
			this.prepareUrl(endpoint, this.environment.organizationUUID, additionalParams, parts, searchInfo, paginationInfo, sortInfo),
			options
		);
	}

	getEntity<T>(endpoint: string, entityUUID?: string, parts: Parts[] = [], params?: UnknownObject): Observable<T> {
		const lastCharSlash = endpoint.slice(-1) === '/';
		if (!lastCharSlash) endpoint += '/';

		let builder = new UrlBuilder(endpoint + entityUUID).withOrganizationUUID(this.environment.organizationUUID).withParts(parts);

		if (params) builder = builder.withAdditionalParameter(params);

		const requestUrl = builder.build();
		return this.http.get<T>(requestUrl);
	}

	createEntity<T = unknown>(
		endpoint: string,
		newEntity: unknown,
		parts: Parts[],
		additionalParameter = {},
		options?: Record<string, unknown>
	): Observable<T> {
		const requestUrl = new UrlBuilder(endpoint)
			.withOrganizationUUID(this.environment.organizationUUID)
			.withParts(parts)
			.withAdditionalParameter(additionalParameter)
			.build();
		return this.http.post<T>(requestUrl, newEntity, options);
	}

	updateEntity<T extends { uuid?: string }>(endpoint: string, updatedEntity: T, parts: Parts[], additionalParameter = {}): Observable<T> {
		if (updatedEntity?.['uuid']) {
			const lastCharSlash = endpoint.slice(-1) === '/';
			if (!lastCharSlash) endpoint += '/';
			const requestUrl = new UrlBuilder(endpoint + updatedEntity?.uuid)
				.withOrganizationUUID(this.environment.organizationUUID)
				.withParts(parts)
				.withAdditionalParameter(additionalParameter)
				.build();
			return this.http.put<T>(requestUrl, updatedEntity);
		} else {
			return throwError(() => new Error('update failed'));
		}
	}

	deleteEntity<T extends { uuid?: string }>(endpoint: string, entityToDelete: T, key?: keyof T): Observable<T> {
		if ((key && entityToDelete[key]) || entityToDelete?.['uuid']) {
			const lastCharSlash = endpoint.slice(-1) === '/';
			if (!lastCharSlash) endpoint += '/';
			const keyValue = key ? entityToDelete[key] : entityToDelete?.['uuid'];
			const requestUrl = new UrlBuilder(endpoint + keyValue).withOrganizationUUID(this.environment.organizationUUID).build();
			return this.http.delete<T>(requestUrl);
		} else {
			return throwError(() => new Error('update failed'));
		}
	}

	updateBulk<T, K = { uuidList?: string[]; delete?: boolean; active?: boolean }>(endpoint: string, entities: K): Observable<T> {
		return this.http.post<T>(this.url(endpoint), entities, { params: this.defaultQueryParams });
	}

	deleteBulk<T, K = { uuidList?: string[]; delete?: boolean; active?: boolean }>(endpoint: string, entities: K): Observable<T> {
		return this.http.delete<T>(this.url(endpoint), { params: { ...this.defaultQueryParams, ...entities } });
	}

	private prepareUrl(
		endpoint: string,
		organizationUUID: string,
		additionalParams: UnknownObject | undefined,
		parts: Parts[],
		searchInfo?: SearchInfo,
		paginationInfo?: PaginationInfo,
		sortInfo?: SortInfo
	): string {
		const requestUrlBuilder = new UrlBuilder(endpoint).withOrganizationUUID(organizationUUID).withParts(parts);

		if (additionalParams) {
			requestUrlBuilder.withAdditionalParameter(additionalParams);
		}

		if (paginationInfo) {
			requestUrlBuilder.withPaginationInfo(paginationInfo);
		}

		if (sortInfo && sortInfo.sortBy !== undefined) {
			requestUrlBuilder.withSortInfo(sortInfo);
		}

		if (searchInfo?.query && searchInfo?.searchFields) {
			requestUrlBuilder.withSearchFilter(searchInfo);
		}
		return requestUrlBuilder.build();
	}

	/**
	 * recupera lo zip da BE, che contiene tutte le immagini per l'asset con uuid === assetUUID
	 * @param assetUUID uuid dell'asset per cui si vuole recuperare lo zip con le immagini
	 * @returns Blob che rappresenta lo zip con le immagini
	 */
	downloadAsset(assetUUID: string) {
		const params = {
			...this.defaultQueryParams,
			...this.partsParams([Parts.FULL_ASSET_INFO]),
		};
		return this.http
			.get(this.url([this.environment.mediaLibraryUrl + 'downloadZipAssets', assetUUID]), {
				params,
				responseType: 'arraybuffer',
			})
			.pipe(
				map((data) => {
					const blobData = new Blob([data], {
						type: 'application/zip',
					});
					return blobData;
				})
			);
	}

	toFormData<T extends { file?: File }>(data: T): FormData {
		const fd = new FormData();

		const jsonData = { ...data } as UnknownObject;
		if (jsonData['previewUUID'] === undefined) {
			jsonData['previewUUID'] = null;
		}

		delete jsonData['_id'];
		delete jsonData['file']; // do not serialize a file in JSON format!!
		fd.append('assetData', JSON.stringify(jsonData));

		if (data.file) fd.append('file', data.file);

		return fd;
	}

	public createAsset<T extends { file?: File | undefined }, K>(asset: T, parts: Parts[]): Observable<WithProgress<K>> {
		const fd = this.toFormData<T>(asset);
		const params = {
			...this.defaultQueryParams,
			...this.partsParams(parts),
		};
		return this.http.post<K>(this.environment.mediaLibraryUrl, fd, { params: params, observe: 'events', reportProgress: true }).pipe(trackProgress());
	}

	public updateAsset<T extends { file?: File | undefined; uuid?: string }, K>(asset: T, parts: Parts[]): Observable<WithProgress<K>> {
		if (!asset.uuid) throw 'Cannot update asset without a valid UUID';
		const fd = this.toFormData(asset);
		const params = {
			...this.defaultQueryParams,
			parts: parts.join(','),
		};
		return this.http
			.put<K>(this.url([this.environment.mediaLibraryUrl, asset.uuid]), fd, { params, observe: 'events', reportProgress: true })
			.pipe(trackProgress());
	}

	updateLessonsFeatures<T extends DataleanEntity>(endpoint: Url, object: Record<string, unknown>): Observable<T[]> {
		return this.http.post<T[]>(this.url(endpoint), object, { params: this.defaultQueryParams });
	}
}
