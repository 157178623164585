import { createAction, props } from '@ngrx/store';
import { PictogramStructure } from 'src/app/shared/models/pictogram/pictogram.interface';

/* READ */
export const loadPictogramStructures = createAction('[PictogramStructures] Load PictogramStructures');
export const reloadPictogramStructures = createAction('[PictogramStructures] Reload PictogramStructures');
export const loadPictogramStructuresSuccess = createAction('[PictogramStructures] Load PictogramStructures Success', props<{ pictogramStructures: PictogramStructure[] }>());
export const loadPictogramStructuresFailure = createAction('[PictogramStructures] Load PictogramStructures Failure', props<{ error: unknown }>());

/* CREATE */
export const createPictogramStructure = createAction('[PictogramStructures] Create PictogramStructure', props<{ pictogramStructure: PictogramStructure }>());
export const createPictogramStructureSuccess = createAction('[PictogramStructures] Create PictogramStructure Success', props<{ pictogramStructure: PictogramStructure }>());
export const createPictogramStructureFailure = createAction('[PictogramStructures] Create PictogramStructure Failure', props<{ error: unknown }>());

/* UPDATE */
export const updatePictogramStructure = createAction('[PictogramStructures] Update PictogramStructure', props<{ pictogramStructure: PictogramStructure; uuid: string }>());
export const updatePictogramStructureSuccess = createAction('[PictogramStructures] Update PictogramStructure Success', props<{ pictogramStructure: PictogramStructure }>());
export const updatePictogramStructureFailure = createAction('[PictogramStructures] Update PictogramStructure Failure', props<{ error: unknown }>());

/* DELETE */
export const deletePictogramStructure = createAction('[PictogramStructures] Delete PictogramStructure', props<{ uuid: string }>());
export const deletePictogramStructureSuccess = createAction('[PictogramStructures] Delete PictogramStructure Success', props<{ uuid: string }>());
export const deletePictogramStructureFailure = createAction('[PictogramStructures] Delete PictogramStructure Failure', props<{ error: unknown }>());

/* SELECTION */
export const selectPictogramStructure = createAction('[PictogramStructures] Select PictogramStructure', props<{ uuid?: string }>());
export const editPictogramStructure = createAction('[PictogramStructures] Edit PictogramStructure', props<{ uuid: string }>());

export const changePictogramStructureSelected = createAction('[PictogramStructures] Change PictogramStructure Selected', props<{ uuid: string }>());
export const createNewPictogramStructure = createAction('[PictogramStructures] Create New PictogramStructure');
