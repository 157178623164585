import { ObjectValuesType } from 'addiction-components';

export const FeedbackType = {
	HAS_OPENED: 'has_opened',
	HAS_CONFIRMED: 'has_confirmed',
	LIKES: 'likes',
	HAS_LOGGED: 'has_logged',
	HAS_ACCESSED: 'has_accessed',
} as const;
export type FeedbackType = ObjectValuesType<typeof FeedbackType>;
