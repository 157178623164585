
/**
 *
 * @param obj1 oggetto 1 per la comparazione
 * @param obj2 oggetto 2 per la comparazione
 * @returns true se i due oggetti sono diversi, altrimenti false
 */
export function ObjDiff(obj1: Record<string, unknown>, obj2: Record<string, unknown>): boolean {
	if (Object.keys(obj1 || {}).length !== Object.keys(obj2 || {}).length) {
		return true;
	}

	for (const key in obj1) {
		if (!['string', 'number', 'undefined', 'null', 'boolean'].includes(typeof obj1[key])) {
			if (Array.isArray(obj1[key])) {
				const uArray1 = obj1[key] as Array<unknown>;
				const uArray2 = obj2[key] as Array<unknown>;
				if (uArray1.length !== uArray2.length) {
					return true;
				}
				const equal = [...uArray1].reduce((val: boolean, item, index: number) => !val || item === uArray2[index], true);
				if (!equal) return true;
			} else {
				const diff = ObjDiff(obj1[key] as Record<string, unknown>, obj2[key] as Record<string, unknown>);
				if (diff) return true;
			}
		} else if (obj1[key] !== obj2?.[key]) {
			return true;
		}
	}

	return false;
}

export function deepEqual(a: any, b: any) {
  if (a === b) return true;
  if (a == null || typeof a != "object" ||
      b == null || typeof b != "object") return false;
  const keysA = Object.keys(a), keysB = Object.keys(b);
  if (keysA.length != keysB.length) return false;
  for (const key of keysA) {
      if (!keysB.includes(key) || !deepEqual(a[key], b[key])) return false;
  }
  return true;
}
