import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { BaseModalContent } from '../modal/base-modal-content';

@Component({
	selector: 'addiction-confirmation-message',
	standalone: true,
	imports: [CommonModule, TranslateModule],
	templateUrl: './confirmation-message.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationMessageComponent extends BaseModalContent {
	constructor(
		@Inject(DIALOG_DATA)
		public data: {
			title?: string;
			bodyMessage?: string;
			htmlMessage?: string;
			buttons: {
				label: string;
				handler: () => void;
			}[];
		}
	) {
		super();
	}
}
