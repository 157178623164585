import { HttpErrorResponse } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { Page, Structure } from 'addiction-components';
import { STATE_STATUS } from 'src/app/shared/models';
import { FiltersType } from './resource.reducers';
import { ResourceError } from './resource.state';
import { Resource } from 'src/app/shared/models/reservations/resource.interface';

export const setFilters = createAction('[Resources] Set Filters', props<{ filters: FiltersType }>());
export const updateFilters = createAction('[Resources] Update Filters', props<{ updatedFilters: FiltersType }>());
// export const setSort = createAction('[Resources] Set Sort', props<{ sort: SortInfo }>());
export const setStructures = createAction('[Resources] Set Resources Structures', props<{ structures: Array<Structure> }>());
export const updatePage = createAction('[Resources] Update Page', props<{ updatedPage: Partial<Page> }>());
export const deleteResource = createAction('[Resources] Delete Resource', props<{ uuid: string }>());

export const setForceRefreshResources = createAction('[Resources] Set Force Refresh');
export const setResources = createAction('[Resources] Set Resources');
export const setResourcesSuccess = createAction(
	'[Resources] Set Resources Success',
	props<{ data: { totalResourcesCount: number; pages: number[]; resources: Resource[][] } }>()
);
export const setResourcesError = createAction('[Resources] Set Resources Error', props<{ error: HttpErrorResponse }>());
export const setDeleteResources = createAction('[Resources] Set Delete Resources', props<{ resource: Resource }>());
export const setDeleteResourcesSuccess = createAction('[Resources] Set Delete Resources Success');
export const setDeleteResourcesError = createAction('[Resources] Set Delete Resources Error', props<{ error: HttpErrorResponse }>());
export const setStatus = createAction('[Resources] Set status', props<{ status: STATE_STATUS }>());
export const setPages = createAction('[Resources] Set pages', props<{ pages: number[] }>());
export const setSort = createAction('[Resources] Set sort', props<{ sort: Sort }>());
export const setGridSearch = createAction('[Resources] Set search', props<{ search: string | null }>());
export const setCachedPages = createAction('[Resources] Set cached pages', props<{ pages: number[] }>());
export const resetCache = createAction('[Resources] reset cache');
// Error
export const error = createAction('[Resources] Error', props<{ error: ResourceError }>());
