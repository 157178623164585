import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RegistrationService } from '../../services/registration.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BehaviorSubject, catchError, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { mustMatch } from '../../utils/validators/must-match';
import { PasswordValidationResult, PasswordValidatorResultSet, passwordComplexity } from '../../utils/validators/password-complexity';
import { LottieComponent } from 'ngx-lottie';
import { PrivacyConfig, StructureField, UserStructure, UserStructureField } from '../../models';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ToLocalizedValuePipe } from '../../pipes';
import { CheckboxPrivacyComponent } from '../checkbox-privacy/checkbox-privacy.component';

interface RegistrationForm {
	name: FormControl<string>;
	surname: FormControl<string>;
	username: FormControl<string>;
	email: FormControl<string>;
	psw: FormControl<string>;
	confirmPsw: FormControl<string>;
	[key: string]: FormControl;
}

interface SignupData {
	authenticationMethods: AuthMethods[];
	email: string;
	firstName: string;
	lastName: string;
	username: string;
	structureUUID: string;
	cataleanUser: boolean;

	[key: string]: unknown;
}

interface AuthMethods {
	password: string;
	type: 'USERNAME_PASSWORD';
	username: string;
}

@Component({
	selector: 'addiction-simple-registration',
	templateUrl: './simple-registration.component.html',
	standalone: true,
	imports: [
	  CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    ToLocalizedValuePipe,
    MatCheckboxModule,
    DialogModule,
    LottieComponent,
    CheckboxPrivacyComponent
  ],
})
export class SimpleRegistrationComponent implements OnChanges {
	form = new FormGroup<RegistrationForm>(
		{
			name: new FormControl('', {
				validators: [Validators.required, Validators.minLength(1), Validators.maxLength(64)],
				nonNullable: true,
			}),
			surname: new FormControl('', {
				validators: [Validators.required, Validators.minLength(1), Validators.maxLength(64)],
				nonNullable: true,
			}),
			username: new FormControl('', {
				validators: [Validators.required, Validators.minLength(2), Validators.maxLength(64)],
				nonNullable: true,
			}),
			email: new FormControl('', {
				validators: [Validators.required, Validators.minLength(1), Validators.maxLength(64)],
				nonNullable: true,
			}),
			psw: new FormControl('', {
				validators: [Validators.required, Validators.minLength(8), Validators.maxLength(64), passwordComplexity()],
				nonNullable: true,
			}),
			confirmPsw: new FormControl('', {
				validators: [Validators.required, Validators.minLength(8), Validators.maxLength(64)],
				nonNullable: true,
			}),
		},
		{ validators: mustMatch('psw', 'confirmPsw') }
	);

	privacyFields: UserStructureField[] = [];
	showRegistrationConfirm: BehaviorSubject<boolean> = new BehaviorSubject(false);

	passwordChecks = PasswordValidationResult.PossibleChecks;

	//riceve la struttura dal padre (per motivi di visualizzazione UI, evitando il cdRef)
	@Input() structure: UserStructure | undefined | null;
	@Input() showCloseButton: boolean = true; //comanda se mostrare il bottone di chiusura registrazione

	@Output() errorRegistration = new EventEmitter<HttpErrorResponse>();
	@Output() privacyOpen = new EventEmitter<UserStructureField>();
	@Output() navigate = new EventEmitter();
	@Output() changeShowSpinner = new EventEmitter<boolean>(false); //comanda se far vedere o meno lo spinner di caricamento
	@Output() closeButtonClicked = new EventEmitter();

	constructor(private registrationService: RegistrationService, public dialog: Dialog) {}

	get showMismatchError() {
		return this.form.hasError('mustMatch') && this.form.controls.psw?.touched && this.form.controls.confirmPsw?.touched;
	}

	get showFieldsError() {
		return (
			(this.form.controls.name?.invalid && this.form.controls.name?.touched) ||
			(this.form.controls.surname?.invalid && this.form.controls.surname?.touched) ||
			(this.form.controls.username?.invalid && this.form.controls.username?.touched) ||
			(this.form.controls.email?.invalid && this.form.controls.email?.touched)
		);
	}

	onConfirm() {
		//check validità del form
		if (!this.isValidForm()) {
			this.form.markAllAsTouched();
			return;
		}

		this.changeShowSpinner.emit(true);
		//se ok invio i dati
		const requestBody = this.prepareData();
		this.registrationService
			.register(requestBody)
			.pipe(
				catchError((error: HttpErrorResponse) => {
					console.log(error);
					this.errorRegistration.emit(error);
					return of(null);
				})
			)
			.subscribe((data) => {
				this.changeShowSpinner.emit(false);

				if (data) {
					this.showRegistrationConfirm.next(true);
				}
			});
	}

	hasPwPolicyError(error: keyof PasswordValidatorResultSet) {
		const control = this.form.controls.psw;
		return control.touched && control.hasError(error);
	}

	isValidForm(): boolean {
		if (!this.form.valid) return false;

		const { name, surname, username, psw, confirmPsw: confirmPsw, email } = this.form.value;
		if (this.isNullOrEmpty(name) || this.isNullOrEmpty(surname) || this.isNullOrEmpty(username) || this.isNullOrEmpty(email)) return false;

		if (psw !== confirmPsw) return false;

		return true;
	}

	private isNullOrEmpty(str?: string) {
		return str == undefined || str == null || str?.trim() == '';
	}

  parseJSON(structureFieldExtra: string): PrivacyConfig | undefined {
		try {
      const config = JSON.parse(structureFieldExtra) as PrivacyConfig;
			return config
		} catch (e) {
      return undefined;
    }
	}

	private prepareData(): SignupData {
		const { name, surname, username, psw, /* confirmPsw: confirmPsw, */ email, confirmPsw, ...privacyFields } = this.form.value;
		const structures = Object.entries(privacyFields);
		const registrationData: SignupData = {
			authenticationMethods: [],
			email: email?.trim() ?? '',
			firstName: name?.trim() ?? '',
			lastName: surname?.trim() ?? '',
			username: username?.trim() ?? '',
			structureUUID: this.structure?.uuid ?? '',
			cataleanUser: true,
			//i campi privaci sono field del primo livello
		};

		registrationData.authenticationMethods.push({
			password: psw ?? '',
			type: 'USERNAME_PASSWORD',
			username: username?.trim() ?? '',
		});

		//PRIVACY
		for (const [structureLabel, structureValue] of structures) {
			registrationData[structureLabel] = structureValue as boolean;
		}

		return registrationData;
	}

	navigateEmit() {
		this.navigate.emit();
	}

	ngOnChanges(changes: SimpleChanges) {
		const { structure } = changes;
		if (structure?.currentValue) {
			for (const structureField of structure.currentValue.structureFields as StructureField[]) {
				if (structureField.type === 'privacy' || (structureField.type === 'boolean' && structureField.name?.includes('privacy'))) {

					const validators = [];
					if (structureField.isRequired) {
						validators.push(Validators.requiredTrue);
					}
					this.form.addControl(structureField.name, new FormControl(undefined, validators));
					this.privacyFields = [...this.privacyFields, structureField];
				}
			}
			this.form.updateValueAndValidity();
		}
	}

	onCloseButtonClicked() {
		this.closeButtonClicked.emit();
	}
}
