import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToLocalizedValuePipe } from 'addiction-components';
import { EMPTY, catchError, filter, of, switchMap } from 'rxjs';
import { combineLatestWith, debounce, distinctUntilChanged, first, map, tap } from 'rxjs/operators';
import { CommunitySelectActions, ProductActions } from 'src/app/core/state/app.actions';
import { STATE_STATUS } from 'src/app/shared/models';
import { selectLastCommunitySelectedForApiRest } from 'src/app/shared/state/community-select/community-select.selectors';
import { ConfigurationService } from '../../../shared/services/configuration.service';
import { ProductService } from '../services/product.service';
import { setProductsSuccess } from './product.actions';
import {
	selectCachedPages,
	selectGridSearch,
	selectPagedData,
	selectPages,
	selectProductsFilters,
	selectSort,
	selectStatus,
} from './product.selectors';

@Injectable()
export class ProductEffect {
	private store = inject(Store);
	private actions$ = inject(Actions);
	private productService = inject(ProductService);
	private translateService = inject(TranslateService);
	private localizablePipe = inject(ToLocalizedValuePipe);
	private configurationService = inject(ConfigurationService);

	fetchProducts$ = createEffect(() =>
		this.actions$.pipe(
			//quando selezioni una categoria o fai una ricerca, viene triggherato il DamFilter, quindi ci registriamo ad
			//un suo eventuale successo per richiamare gli assets
			ofType(ProductActions.setPages, ProductActions.setForceRefreshProducts),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(selectProductsFilters),
				this.store.select(selectLastCommunitySelectedForApiRest),
				this.store.select(selectCachedPages),
			]),
			debounce(() => this.store.select(selectStatus).pipe(filter((stat) => !stat || stat !== 'loading'))),
			//non voglio caricare se non ho le pagine pronte
			filter(([, pages]) => !!pages?.length),
			tap(() => {
				this.store.dispatch(ProductActions.setStatus({ status: STATE_STATUS.LOADING }));
			}),
			switchMap(([, pages, sort, gridSearch, filters, communityUUID, cachedPages]) => {
				const actualPages = pages.filter((p) => !cachedPages.includes(p));
				if (!actualPages.length) return EMPTY;
 				const filterWithCommunity = structuredClone(filters);
				if (communityUUID) filterWithCommunity.communityUUID = communityUUID;
				return this.productService
					.fetchProducts(
						actualPages,
						sort,
						this.translateService.currentLang,
						gridSearch,
						filterWithCommunity,
						this.configurationService.getConfigValue('hasVersioning')
					)
					.pipe(
						combineLatestWith(this.store.select(selectPagedData)),
						first(),
						map(([data, startingResult]) => {
							// console.log(startingResult);
							startingResult.pages = cachedPages;
							const result = data.reduce((acc, item) => {
								if (item.paginationInfo) {
									acc.pages.push(item.paginationInfo.pageNumber);
									acc.totalProductsCount = item.paginationInfo.totalElements ?? 0;
									if (item.result) {
										acc.products[item.paginationInfo.pageNumber] = item.result.map((product) => ({
											...product,
											features: product.featureValueList.map((f) => (typeof f === 'string' ? '' : this.localizablePipe.transform(f.label || f.name))),
										}));
									}
								}
								return acc;
							}, structuredClone(startingResult));

							// console.log(result);

							return ProductActions.setProductsSuccess({ data: result });
						}),
						catchError((error: HttpErrorResponse) => {
							console.error(error);
							return of(ProductActions.setProductsError({ error }));
						})
					);
			})
		)
	);

	resetCache$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				ProductActions.setGridSearch,
				ProductActions.setSort,
				ProductActions.updateFilters,
				ProductActions.addFeatureValueToFilters,
				ProductActions.removeFeatureValueFromFilters,
				CommunitySelectActions.setLastCommunitySelected
			),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(selectProductsFilters),
				this.store.select(selectLastCommunitySelectedForApiRest),
				this.store.select(selectCachedPages),
			]),
			// //non voglio caricare se i dati delle azioni sono gli stessi
			distinctUntilChanged(
				(
					[, pagesPrev, sortPrev, gridSearchPrev, filterPrev],
					[, pagesCurr, sortCurr, gridSearchCurr, filterCurr, communityUUIDCurr, cachedPages]
				) => {
					// console.log(action);
					return (
						pagesPrev.length === pagesCurr.length &&
						pagesPrev.every((f) => pagesCurr.includes(f)) &&
						sortCurr?.active === sortPrev?.active &&
						sortCurr?.direction === sortPrev?.direction &&
						filterPrev === filterCurr &&
						gridSearchCurr === gridSearchPrev &&
						pagesPrev.filter((f) => !cachedPages.includes(f)).length === 0
					);
				}
			),
			map(() => ProductActions.resetCache())
		)
	);

	deleteProducts$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProductActions.deleteProducts),
			// invoke API
			switchMap(({ uuids }) => {
				return this.productService
					.multipleUpdate({
						uuidList: uuids,
						delete: true,
					})
					.pipe(
						map(() => {
							return ProductActions.setDeleteProductsSuccess();
						}),
						catchError((error: HttpErrorResponse) => of(ProductActions.error({ error: { errorType: 'generic_http', data: error } })))
					);
			})
		)
	);

	disableProducts$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProductActions.disableProducts),
			// invoke API
			switchMap(({ uuids }) =>
				this.productService
					.multipleUpdate({
						uuidList: uuids,
						active: false,
					})
					.pipe(
						map(() => {
							return ProductActions.setDisableProductsSuccess();
						}),
						catchError((error: HttpErrorResponse) => of(ProductActions.error({ error: { errorType: 'generic_http', data: error } }))),
						tap(() => this.productService.getStructureAndFeatures())
					)
			)
		)
	);

	productSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setProductsSuccess),
			map(({ data: { pages } }) => ProductActions.setCachedPages({ pages }))
		)
	);

	deleteAndDisableProduct$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				ProductActions.setDeleteProductsSuccess,
				ProductActions.setDeleteProductsError,
				ProductActions.setDisableProductsSuccess,
				ProductActions.setDisableProductsError
			),
			map(() => ProductActions.resetCache())
		)
	);

	forceRefreshTable$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProductActions.resetCache),
			map(() => ProductActions.setForceRefreshProducts())
		)
	);

	constructor() {}
}
