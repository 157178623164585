import { Asset, DataleanEntity } from 'addiction-components';

export interface PictogramAttribute {
	position: PictogramPosition;
	text: string;
}

export enum PictogramPosition {
	TOP = 'TOP',
	BOTTOM = 'BOTTOM',
	BOTTOM_ADJACENT = 'BOTTOM ADJACENT',
	CENTER_SIDE = 'CENTER SIDE',
	TOP_SIDE = 'TOP SIDE',
}

export interface BasePictogramEntity extends DataleanEntity {
	simbol: Partial<Asset>;
	name1: string;
	name2: string;
	description: string;
	code: string;
	attributes: PictogramAttribute[];
	name?: string;
}

// al momento coincide con l'entità base
// ma voglio un' entità separata per avere la possibilità di estendere la struttura in maniera indipendente
export interface PictogramStructure extends BasePictogramEntity {
	pictogramsCount?: number;
}

export interface Pictogram extends BasePictogramEntity {
	isActive: boolean;
	structureUUID: string;
	structure?: PictogramStructure;
}
