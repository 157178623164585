<div *ngIf="assetUploading$ | async as assetList">
	<div class="queue-container" *ngIf="assetList.length > 0" [class.open]="showList">
		<div class="single-row-header" (click)="showList = !showList">
			<span class="title">{{ 'GRID.UPLOAD_PROGRESS' | translate }}</span>
			<div class="arrow" [class.open]="showList"></div>
		</div>

		<div class="list-container">
			<div
				class="single-row"
				*ngFor="let uploadingAsset of assetList; trackBy: assetByName"
				[class.completed]="uploadingAsset.progress === 100 && uploadingAsset.status === 'finished'"
				[class.progressing]="uploadingAsset.status === 'waiting' || uploadingAsset.status === 'uploading'"
				[class.error]="uploadingAsset.status === 'interrupted' || uploadingAsset.status === 'error'">
				<ng-container *ngIf="uploadingAsset.assetInfo.file as file">
					<div class="single-column icon">
						<span [class]="uploadingAsset.className"></span>
					</div>
					<div class="single-column name">
						<div class="title">{{ file.name }}</div>
						<div class="progress-bar">
							<span class="progress-value" [style]="'width: ' + uploadingAsset.progress + '%'"></span>
						</div>
					</div>
					<div class="single-column action">
						<button class="progress-action completed" *ngIf="uploadingAsset.progress === 100 && uploadingAsset.status === 'finished'"></button>
						<button
							class="progress-action progressing"
							*ngIf="uploadingAsset.status === 'waiting' || uploadingAsset.status === 'uploading'"
							(click)="switchOffQueue(uploadingAsset, assetList)"></button>
						<button class="progress-action interrupted" *ngIf="uploadingAsset.status === 'interrupted'"></button>
						<button class="progress-action error" *ngIf="uploadingAsset.status === 'error'"></button>
					</div>
				</ng-container>
			</div>
		</div>
		<span class="buttons-container">
			<button class="interrupt" *ngIf="!finishedUpload" (click)="stopQueue(assetList)">
				<span>{{ 'GRID.INTERRUPT_UPLOAD' | translate }}</span>
			</button>
			<button class="confirm" *ngIf="finishedUpload" (click)="clearQueue()">
				<span>{{ 'GRID.CLEAR_CLOSE' | translate }}</span>
			</button>
		</span>
	</div>
</div>
