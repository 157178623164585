import { createReducer, on } from '@ngrx/store';
import produce from 'immer';
import { PictogramState } from './pictogram.state';
import { PictogramActions } from 'src/app/core/state/app.actions';

export const TAG = 'pictograms';

export const initialState: PictogramState = {
	pictograms: [],
	loading: false,
	structures: []
};


export const reducer = createReducer(
	initialState,
	// GENERIC LOADING\ERROR ACTIONS
	on(
		PictogramActions.createPictogram,
		PictogramActions.deletePictogram,
		PictogramActions.updatePictogram,
		PictogramActions.reloadPictograms,
		(state) => ({
			...state,
			loading: true,
			error: undefined,
		})
	),
	on(
		PictogramActions.createPictogramFailure,
		PictogramActions.deletePictogramFailure,
		PictogramActions.updatePictogramFailure,
		PictogramActions.loadPictogramsFailure,
		PictogramActions.loadPictogramStructuresFailure,
		(state) => ({
			...state,
			loading: false,
			error: true,
		})
	),
	on(PictogramActions.loadPictogramsSuccess, (state, { pictograms }) => ({
		...state,
		loading: false,
		pictograms: pictograms,
		editingUUID: undefined,
	})),

	on(PictogramActions.loadPictogramStructuresSuccess, (state, { structures }) => ({
		...state,
		structures,
	})),
	on(PictogramActions.createPictogramSuccess, (state, { pictogram }) => ({
		...state,
		loading: false,
		selectedPictogram: {...state.selectedPictogram, pictogram: pictogram},
		editingUUID: undefined,
		error: false,
	})),
	on(PictogramActions.deletePictogramSuccess, (state, { uuid }) =>
		produce(state, (draft) => {
			draft.loading = false;
			draft.error = false;
			draft.selectedPictogram = {pictogram: uuid === draft.selectedPictogram?.pictogram?.uuid ? undefined : draft.selectedPictogram?.pictogram, structure: undefined}
		})
	),
	on(PictogramActions.updatePictogramSuccess, (state, { pictogram }) =>
		produce(state, (draft) => {
			draft.loading = true;
			draft.error = false;
			draft.selectedPictogram = {...draft.selectedPictogram, pictogram};
		})
	),
	on(PictogramActions.selectPictogram, (state, { pictogram, structure }) => ({
		...state,
		selectedPictogram: {pictogram, structure},
	})),
	on(PictogramActions.editPictogram, (state, { uuid }) => ({
		...state,
		editingUUID: uuid,
	}))
);
