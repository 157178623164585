<ng-container *ngrxLet="(tree$ | async)! as root">
	<ng-container *ngrxLet="(currentNode$ | async)! as node">
		<div class="top-bar" *ngIf="allowSearch || addable">
			<addiction-search *ngIf="allowSearch" (search)="userSearch$.next($event ?? '')"></addiction-search>

			<div class="buttons">
				<button *ngFor="let btn of customButtons" [class]="btn.cssClass" (click)="customBtnClick(btn)">
					{{ btn.content }}
				</button>
				<button class="add" *ngIf="addable" (click)="add(node.id, node)"></button>
			</div>
		</div>

		<div class="tree-browser">
			<div class="current-node">
				<button *ngIf="node.parent" class="back" [disabled]="buttonDisable" (click)="goTo(node.parent, true)"></button>
				<span class="current-node-title" (click)="select(node)" drop-target [class.selected]="node.id === selectedUUID" [attr.data-id]="node.id">{{
					node.title
				}}</span>
			</div>
			<div class="children" cdkDropList (cdkDropListDropped)="onDrop($event, node)" [cdkDropListSortPredicate]="sortPredicate">
				<div
					class="child"
					*ngFor="let child of node.children"
					[class.selected]="child.id === selectedUUID"
					cdkDrag
					cdkDragPreviewContainer="parent"
					cdkDragBoundary=".children"
					[cdkDragDisabled]="!sortable || !editing || (userSearch$ | async)"
					[cdkDragData]="child"
					[attr.data-id]="child.id"
					drop-target>
					<div class="drag-placeholder" *cdkDragPlaceholder></div>
					<div class="single-child-container" cdkDragHandle>
						<input type="checkbox" *ngIf="checkable" [checked]="child.checked" (change)="toggleChecked(child)" />
						<button class="title" [disabled]="buttonDisable" (click)="select(child)">
							<span class="folder"></span>
							<span class="label">
								{{ child.title | toLocalizedValuePipe }}
							</span>
							<!-- <span *ngIf="renameble && editing">
								<input
                  type="text"
                  class="node-rename"
                  [class.error]="child.error"
                  [value]="child.title"
                  [dataleanForbidChars]="forbidChars"
                  (change)="nodeRenamed(child, $event.target!, node.children)" />
							</span> -->
						</button>
					</div>

					<div class="controls">
						<ng-container *ngIf="editing">
							<button class="menu" [matMenuTriggerFor]="editMenu" [ngClass]="{ 'more-space': !child.children.length }"></button>
							<mat-menu #editMenu>
								<button mat-menu-item class="clone" *ngIf="clonable" (click)="clone(child)">
									{{ 'OPTIONS_BUTTONS.CLONE' | translate }}
								</button>
								<button mat-menu-item class="remove" *ngIf="removable" (click)="remove(child)">
									{{ 'OPTIONS_BUTTONS.DELETE' | translate }}
								</button>
								<button mat-menu-item class="permissions" *ngIf="editablePermissions" (click)="openPermissions(child)">
									{{ 'OPTIONS_BUTTONS.PERMISSIONS' | translate }}
								</button>
							</mat-menu>
						</ng-container>
						<ng-container #navigation *ngIf="!(search$ | async)">
							<button class="navigate" [disabled]="buttonDisable" (click)="goTo(child, false)" *ngIf="child.children.length"></button>
						</ng-container>
					</div>
				</div>

				<div class="placeholder" *ngIf="!node.children || node.children.length === 0">
					<!--          {{'MEDIA_LIBRARY.NO_ITEMS' | translate}}-->
				</div>
			</div>
		</div>

		<div class="checked-nodes" *ngIf="checkable">
			<addiction-chip *ngFor="let node of checkedNodes$ | async" [label]="node.title" (remove)="toggleChecked(node, false)"></addiction-chip>
		</div>
	</ng-container>
</ng-container>
