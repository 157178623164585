import { createReducer, on } from '@ngrx/store';
import { ProductVersioningActions } from 'src/app/core/state/app.actions';
import { STATE_STATUS } from 'src/app/shared/models';
import { ProductVersioningState } from './product-versioning.state';

export const TAG = 'productVersioning';

const initialState: ProductVersioningState = {
	sort: undefined,
	pages: [0],
	data: { totalProductsCount: 0, products: [] },
	status: STATE_STATUS.UNKNOWN,
 	cachedPages: [],
	productUUID: '',
};

export const reducer = createReducer(
	initialState,
	/**
	 * Parte standard di un reducer
	 */
	on(ProductVersioningActions.setSort, (state: ProductVersioningState, { sort }) => ({
		...state,
		sort,
	})),
	on(ProductVersioningActions.setPages, (state: ProductVersioningState, { pages }) => ({
		...state,
		pages,
	})),
	on(ProductVersioningActions.setProductVersioningSuccess, (state: ProductVersioningState, { data }) => {
		return {
			...state,
			status: STATE_STATUS.READY,
			data: {
				products: data.products,
				totalProductsCount: data.totalProductsCount,
			},
			pages: [...state.cachedPages, ...data.pages],
		};
	}),
	on(ProductVersioningActions.setProductsError, (state: ProductVersioningState) => ({
		...state,
		status: STATE_STATUS.ERROR,
	})),
	on(ProductVersioningActions.setStatus, (state: ProductVersioningState, { status }) => {
		return {
			...state,
			status,
		};
	}),
	on(ProductVersioningActions.setGridSearch, (state: ProductVersioningState, { search }) => {
		return {
			...state,
			gridSearch: search,
		};
	}),
	on(ProductVersioningActions.setProductUUID, (state: ProductVersioningState, { uuid }) => {
		return {
			...state,
			productUUID: uuid,
      status: STATE_STATUS.READY
		};
	}),
	on(ProductVersioningActions.setCachedPages, (state: ProductVersioningState, { pages }) => ({
		...state,
		cachedPages: [...new Set([...state.cachedPages, ...pages])],
	})),
	on(ProductVersioningActions.resetCache, (state: ProductVersioningState) => ({
		...state,
		status: STATE_STATUS.READY,
		cachedPages: [],
		pages: [0],
		data: { totalProductsCount: 0, products: [] },
	})),
  on(ProductVersioningActions.resetData, (state: ProductVersioningState) => ({
		...state,
		status: STATE_STATUS.READY,
		cachedPages: [],
		pages: [0],
		data: { totalProductsCount: 0, products: [] },
	})),

);
