import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MessageHandler, Structure } from 'addiction-components';
import { catchError, map, of, switchMap } from 'rxjs';
import { PictogramService } from '../services/pictogram.service';
import { PictogramActions } from 'src/app/core/state/app.actions';
import { PictogramStructuresService } from '../../pictogram-structures/services/pictogra-structure.service';

@Injectable()
export class PictogramEffect {
	private actions$ = inject(Actions);
	private pictogramSrv = inject(PictogramService);
	private pictogramStructureSrv = inject(PictogramStructuresService);
	private store = inject(Store);
	private messageHandler = inject(MessageHandler);
	private translateService = inject(TranslateService);

	loadPictogram$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				PictogramActions.loadPictograms,
				PictogramActions.reloadPictograms,
				PictogramActions.createPictogramSuccess,
				PictogramActions.updatePictogramSuccess,
				PictogramActions.deletePictogramSuccess
			),
			switchMap(() =>
				this.pictogramSrv.getPictograms().pipe(
					map((pictograms) => PictogramActions.loadPictogramsSuccess({ pictograms })),
					catchError((error) => of(PictogramActions.loadPictogramsFailure({ error })))
				)
			)
		)
	);
	// UPDATE ONE
	updatePictogram$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PictogramActions.updatePictogram),
			switchMap(({ pictogram }) =>
				this.pictogramSrv.updatePictogram(pictogram).pipe(
					map((pictogram) => {
						this.messageHandler.createToast('ALERT.SUCCESS_SAVE_MESSAGE');
						return PictogramActions.updatePictogramSuccess({ pictogram });
					}),
					catchError((error) => {
						this.messageHandler.createErrorToast('ALERT.ERROR_SAVE_MESSAGE');
						return of(PictogramActions.updatePictogramFailure({ error }));
					})
				)
			)
		)
	);

	// CREATE ONE
	createPictogram$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PictogramActions.createPictogram),
			switchMap(({ pictogram }) =>
				this.pictogramSrv.createPictogram(pictogram).pipe(
					map((pictogram) => {
						this.messageHandler.createToast('ALERT.SUCCESS_SAVE_MESSAGE');
						return PictogramActions.createPictogramSuccess({ pictogram });
					}),
					catchError((error) => {
						this.messageHandler.createErrorToast('ALERT.ERROR_SAVE_MESSAGE');
						return of(PictogramActions.createPictogramFailure({ error }));
					})
				)
			)
		)
	);


	// DELETE ONE
	deletePictogram$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PictogramActions.deletePictogram),
			switchMap(({ uuid }) =>
				this.pictogramSrv.deletePictogram(uuid).pipe(
					map(() => PictogramActions.deletePictogramSuccess({ uuid })),
					catchError((error) => of(PictogramActions.deletePictogramFailure({ error })))
				)
			)
		)
	);

	selectedPictogram$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PictogramActions.changePictogramSelected),
			map(({ pictogram }) => PictogramActions.selectPictogram({ pictogram }))
		)
	);

	selectedEditablePictogram$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PictogramActions.changePictogramSelected),
			map(({ pictogram }) => PictogramActions.editPictogram({ uuid: pictogram.uuid }))
		)
	);

	editNewPictogram$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PictogramActions.createNewPictogram),
			map(() => PictogramActions.editPictogram({ uuid: 'new' }))
		)
	);

	loadPictogramStructure$ = createEffect(() =>
	this.actions$.pipe(
		ofType(
			PictogramActions.loadPictogramStructuress,
		),
		switchMap(() =>
			this.pictogramStructureSrv.getPictogramStructures().pipe(
				map((structures) => PictogramActions.loadPictogramStructuresSuccess({ structures })),
				catchError((error) => of(PictogramActions.loadPictogramsFailure({ error })))
			)
		)
	)
);

}
