import { HttpErrorResponse } from "@angular/common/http";
import { Sort } from "@angular/material/sort";
import { createAction, props } from "@ngrx/store"
import { DataleanUser } from "src/app/shared/models";
import { STATE_STATUS } from "src/app/shared/models/state-status.enum";


export const setForceRefreshDataleanUsers = createAction('[Datalean Users] Set Force Refresh')
export const setDataleanUsers = createAction('[Datalean Users] Set Datalean Users')
export const setDataleanUsersSuccess = createAction('[Datalean Users] Set Datalean Users Success', props<{ data: { totalUsersCount: number; pages: number[]; users: DataleanUser[][] } }>() )
export const setDataleanUsersError = createAction('[Datalean Users] Set Datalean Users Error', props<{error: HttpErrorResponse}>());
export const setDeleteDataleanUser = createAction('[Datalean Users] Set Delete Datalean Users', props<{user: DataleanUser}>())
export const setDeleteDataleanUserSuccess = createAction('[Datalean Users] Set Delete Datalean Users Success')
export const setDeleteDataleanUserError = createAction('[Datalean Users] Set Delete Datalean Users Error', props<{error: HttpErrorResponse}>());
export const setStatus = createAction('[Datalean Users] Set status', props<{ status: STATE_STATUS }>())
export const setPages = createAction('[Datalean Users] Set pages', props<{ pages: number[] }>());
export const setSort = createAction('[Datalean Users] Set sort', props<{ sort: Sort }>());
export const setGridSearch = createAction('[Datalean Users] Set search', props<{ search: string | null }>());
export const setCachedPages = createAction('[Datalean Users] Set cached pages', props<{ pages: number[] }>());
export const resetCache = createAction('[Datalean Users] reset cache');
