/* eslint-disable @ngrx/avoid-dispatching-multiple-actions-sequentially */
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, zip, Subject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ConfigSelector } from 'src/app/core/state/app.selectors';
import { first, tap } from 'rxjs/operators';

export interface LocaleConfig {
	selectedLocales?: string[];
	activeLocale?: string;
}

//Questo servizio è eliminabile spostando il tutto su state, i motivi per cui è stato creato non sono più validi
@Injectable({ providedIn: 'root' })
export class HeaderLocaleService {
	private activeLocale: string = '';
	private store = inject(Store);

	allLocales$ = this.store.select(ConfigSelector.selectAvailableLanguages);
	selectedLocales$ = new BehaviorSubject<string[]>([]);
	private _activeLocale$ = new BehaviorSubject<string>('');
	removedLocale$ = new Subject<string>();

	constructor() {}

	get activeLocale$(): Observable<string> {
		return this._activeLocale$.asObservable();
	}

	private setActiveLocale(locale: string) {
		this.activeLocale = locale;
		this._activeLocale$.next(locale);
	}

	getActiveLocale() {
		return this.activeLocale;
	}

	resetService() {
		this._activeLocale$.next(''), this.selectedLocales$.next([]);
	}

	addConfig(config: LocaleConfig) {
		if (config.selectedLocales) {
			this.selectedLocales$.next(config.selectedLocales);
		}
		if (config.activeLocale) {
			this.changeActiveLocale(config.activeLocale);
		}
	}

	changeActiveLocale(newActiveLocale: string) {
		//parto dal allLocales per tenere l'ordine delle lingue
		zip(this.selectedLocales$, this.allLocales$)
			.pipe(first())
			.subscribe(([selectedLocales, allLocales]) => {
				if (!selectedLocales.includes(newActiveLocale)) {
					this.selectedLocales$.next(allLocales.filter((locale) => selectedLocales.includes(locale) || locale === newActiveLocale));
				}
				this.setActiveLocale(newActiveLocale);
			});
	}

	removeLocale(localeToRemove: string) {
		this.selectedLocales$.pipe(first()).subscribe((selectedLocales) => {
			this.selectedLocales$.next(selectedLocales.filter((currentLocale) => currentLocale !== localeToRemove));
			this.removedLocale$.next(localeToRemove);
		});
	}
}
