<form [formGroup]="changePasswordForm">
	<div class="form-field password">
		<label for="new-password">{{ 'ADDICTION_PASSWORD_FORM.NEW_PASSWORD' | translate }}</label>
		<input
			id="new-password"
			type="password"
			autocomplete="new-password"
			class="form-control profile-field new-password"
			name="new-password"
			formControlName="newPassword" />
		<div [hidden]=" !changePasswordForm.controls['newPassword'].errors?.['required']" class="invalid-feedback">
			{{ 'ADDICTION_PASSWORD_FORM.REQUIRED' | translate }}
		</div>
		<ul class="invalid-feedback" *ngIf="changePasswordForm.controls['newPassword'].invalid && !unifyMessages">
			<li *ngFor="let error of changePasswordForm.controls['newPassword'].errors | keyvalue">
				<span [ngClass]="'error-message ' + passwordErrorMessage[error.key]">
					{{ 'ADDICTION_PASSWORD_FORM.VALIDATION_PASSWORD.' + error.key | uppercase | translate }}
				</span>
			</li>
		</ul>
	</div>
	<div class="form-field field-container">
		<label for="confirm-new-password">{{ 'ADDICTION_PASSWORD_FORM.VERIFY_NEW_PASSWORD' | translate }}</label>
		<input
			id="confirm-new-password"
			type="password"
			class="form-control profile-field confirm-new-password"
			name="confirm-new-password"
			autocomplete="new-password"
			formControlName="confirmNewPassword" />
		<ul class="invalid-feedback" *ngIf="(changePasswordForm.controls['confirmNewPassword'].invalid || changePasswordForm.invalid) && !unifyMessages">
			<li *ngFor="let error of changePasswordForm.controls['confirmNewPassword'].errors | keyvalue">
				{{ 'ADDICTION_PASSWORD_FORM.password_' + error.key | uppercase | translate }}
			</li>
			<li *ngFor="let error of changePasswordForm.errors | keyvalue">
				{{ 'ADDICTION_PASSWORD_FORM.password_' + error.key | uppercase | translate }}
			</li>
		</ul>
	</div>
</form>
<ul
	class="invalid-feedback"
	*ngIf="
		(changePasswordForm.controls['newPassword'].invalid || changePasswordForm.controls['confirmNewPassword'].invalid || changePasswordForm.invalid) &&
		unifyMessages
	">
	<li *ngFor="let error of changePasswordForm.controls['newPassword'].errors | keyvalue">
		<span [ngClass]="'error-message ' + passwordErrorMessage[error.key]">
			{{ 'ADDICTION_PASSWORD_FORM.VALIDATION_PASSWORD.' + error.key | uppercase | translate }}</span
		>
	</li>
	<li *ngFor="let error of changePasswordForm.controls['confirmNewPassword'].errors | keyvalue">
		{{ 'ADDICTION_PASSWORD_FORM.VALIDATION_PASSWORD.password_' + error.key | uppercase | translate }}
	</li>
	<li *ngFor="let error of changePasswordForm.errors | keyvalue">
		{{ 'ADDICTION_PASSWORD_FORM.VALIDATION_PASSWORD.password_' + error.key | uppercase | translate }}
	</li>
</ul>
