import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductVersioningReducer } from 'src/app/core/state/app.reducers';
import { ProductVersioningState } from './product-versioning.state';

const selectProductsVersionigState = createFeatureSelector<ProductVersioningState>(ProductVersioningReducer.TAG);

export const selectData = createSelector(selectProductsVersionigState, (state) => state.data);
export const selectPages = createSelector(selectProductsVersionigState, (state) => state.pages);
export const selectPagedData = createSelector(selectData, selectPages, (data, pages) => ({ ...data, pages }));
export const selectStatus = createSelector(selectProductsVersionigState, (state) => state.status);
export const selectSort = createSelector(selectProductsVersionigState, (state) => state.sort);
export const selectCachedPages = createSelector(selectProductsVersionigState, (state) => state.cachedPages);
export const selectProductUUID = createSelector(selectProductsVersionigState, (state) => state.productUUID);
