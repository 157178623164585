import { Inject, Injectable, InjectionToken, Optional } from "@angular/core";
import { Action, Store } from "@ngrx/store";
import { FunctionIsNotAllowed } from "@ngrx/store/src/models";
import { Observable } from "rxjs";
import { initContextAction } from "./helpers/context-actions";

export const ContextStoreToken = new InjectionToken<string>('DataLean.StoreContext')

@Injectable()
export class ContextStore<T = object> {
    private currentContext:string = '*';

    constructor(
        @Inject(ContextStoreToken) @Optional() context:string,
        private store:Store<T>
    ) {
        this.currentContext = context || '*';
        this.store.dispatch(initContextAction({ context: this.currentContext }))
    }

    /** Seleziona dal contesto attuale, deve essere utilizzato un selettore con parametro 'context' */
    select<K>(mapFn: (ctx:string) => (state: T) => K, ctx:string = this.currentContext): Observable<K> {
        return this.store.select(mapFn(ctx));
    }

    /** Seleziona dallo store globale dell'applicazione, bypassando il context */
    selectGlobal<K>(mapFn: (state: T) => K): Observable<K> {
        return this.store.select(mapFn);
    }

    /** Esegue un'azione sul context corrente */
    dispatch<V extends Action & { context?:string } = Action>(action: V & FunctionIsNotAllowed<V, 'Functions are not allowed to be dispatched. Did you forget to call the action creator function?'>): void {
        action.context = this.currentContext;
        return this.store.dispatch<V>(action);
    }

    /** Esegue un'azione sul context globale */
    dispatchGlobal<V extends Action & { context?:string } = Action>(action: V & FunctionIsNotAllowed<V, 'Functions are not allowed to be dispatched. Did you forget to call the action creator function?'>): void { 
        return this.store.dispatch<V>(action);
    }


    deleteContext(){
        //...
    }
}
