import * as HeaderReducer from '../../core/components/header/state/header.reducer';
import * as CommunicationReducers from '../../pages/communications/state/communication.reducers';
import * as ContentsReducers from '../../pages/contents/state/content.reducers';
import * as ChallengeReducers from '../../pages/contest/challenges/challenge-list/state/challenge.reducers';
import * as ContestReducers from '../../pages/contest/contests/contest-list/state/contest.reducers';
import * as ApplicationUsersReducers from '../../pages/idm/app-users/state/application-users.reducer';
import * as DataleanUsersReducers from '../../pages/idm/datalean-users/state/datalean-users.reducer';
import * as GroupsReducers from '../../pages/idm/groups/state/groups.reducer';
import * as SmartGroupsReducers from '../../pages/idm/smart-groups/state/smart-groups.reducer';
import * as CoursesReducers from '../../pages/lms/courses/state/courses.reducer';
import * as LessonsReducers from '../../pages/lms/lessons/state/lessons.reducer';
import * as QuizReducers from '../../pages/lms/quiz/state/quiz.reducer';
import * as MediaLibraryReducers from '../../pages/media-library/state/media-library.reducers';
import * as PictogramStructureReducers from '../../pages/pictogram/pictogram-structures/state/pictogram-structure.reducers';
import * as PictogramReducers from '../../pages/pictogram/pictograms/state/pictogram.reducers';
import * as ProductVariantsReducers from '../../pages/products-variants/state/product-variants.reducers';
import * as ProductReducers from '../../pages/products/state/product.reducers';
import * as ProductVersioningReducer from '../../pages/products/state/product-versioning/product-versioning.reducers';
import * as CalendarReducers from '../../pages/reservations/calendar/state/calendar.reducers';
import * as BookableProductReducers from '../../pages/reservations/products/state/bookable-product.reducers';
import * as ResourceReducers from '../../pages/reservations/resources/state/resource.reducers';
import * as CommunityReducers from '../../pages/settings/communities/state/community.reducers';
import * as SurveyCommunicationsReducers from '../../pages/surveys/state/survey-communication.reducer';
import * as SurveysReducers from '../../pages/surveys/state/surveys.reducer';
import * as UsefulLinksReducers from '../../pages/useful-links/state/useful-links.reducers';
import * as CommunitySelectReducers from '../../shared/state/community-select/community-select.reducers';
import * as ConfigReducers from '../../shared/state/config/config.reducers';
import * as FeaturesReducers from '../../shared/state/features/features.reducers';
import * as NavigationReducer from '../../shared/state/navigation/navigation.reducer';
import * as RecipientsReducers from '../../shared/state/recipients/recipients.reducer';
import * as StructuresReducers from '../../shared/state/structures/structures.reducers';
import * as TagsReducers from '../../shared/state/tags/tags.reducers';
import * as UserReducers from '../../shared/state/user/user.reducers';
import * as ApplicationReducers from './application/application.reducers';

export {
	ApplicationReducers,
	ApplicationUsersReducers,
	BookableProductReducers,
	CalendarReducers,
	ChallengeReducers,
	CommunicationReducers,
	CommunityReducers,
	CommunitySelectReducers,
	ConfigReducers,
	ContentsReducers,
	ContestReducers,
	CoursesReducers,
	DataleanUsersReducers,
	FeaturesReducers,
	GroupsReducers,
	HeaderReducer,
	LessonsReducers,
	MediaLibraryReducers,
	NavigationReducer,
	PictogramReducers,
	PictogramStructureReducers,
	ProductReducers,
	ProductVariantsReducers,
  ProductVersioningReducer,
	QuizReducers,
	RecipientsReducers,
	ResourceReducers,
	SmartGroupsReducers,
	StructuresReducers,
	SurveyCommunicationsReducers,
	SurveysReducers,
	TagsReducers,
	UsefulLinksReducers,
	UserReducers,
};
