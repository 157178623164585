import { WidgetTypes } from "./widget/widget-types";

export interface DashboardWidgetInfo {
	title?: string;
	rows?: Array<DashboardWidgetRowInfo>;
	img?: boolean;
	path?: string;
	type: WidgetTypes;
	icon?: string;
	requiredPermissions: Array<string>;
}

export interface DashboardWidgetRowInfo {
	label: string;
	value: number;
}
