import { Observable } from 'rxjs';
import { DashboardWidgetInfo, DashboardWidgetRowInfo } from '../dashboard-model';
import { WidgetTypes } from './widget-types';

export interface WidgetDataConfig {
	chart: WidgetTypes;
	chartCase: string;
	dashboardWidget: DashboardWidgetInfo;
	pathWidget?: string;
	callback?: () => Observable<Array<DashboardWidgetRowInfo> | undefined>;
}
