import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TagsState } from './tags.state';

const selectTag = createFeatureSelector<TagsState>('tags');

export const selectLoading = createSelector(selectTag, (state) => state.loading);
export const selectError = createSelector(selectTag, (state) => state.error);

export const selectEditingTag = createSelector(selectTag, (state) => state.tagList.find((x) => x.uuid == state.editingUUID));
export const selectList = createSelector(selectTag, (state) => state.tagList);
export const selectIsEditing = createSelector(selectTag, ({ editingUUID }) => !!editingUUID);
