import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { catchError, debounce, distinctUntilChanged, filter, map, of, switchMap, tap } from 'rxjs';
import { Communication, STATE_STATUS, Survey } from 'src/app/shared/models';
import { SurveysService } from '../services/surveys.service';
import { Parts } from 'addiction-components';
import {
	setCurrentSurveyUUID,
	setForceRefreshSurveyCommunications,
	setGridSearch,
	setPages,
	setSort,
	setStatus,
	setSurveyCommunicationsError,
	setSurveyCommunicationsSuccess,
} from './survey-communication.actions';
import { selectCurrentSurveyUUID, selectGridSearch, selectPages, selectSort, selectStatus } from './survey-communication.selectors';
import { setDeleteSurveysError } from './surveys.actions';

@Injectable()
export class SurveyCommunicationsEffects {
	private actions$ = inject(Actions);
	private store = inject(Store);
	private surveysService = inject(SurveysService);
	private translateService = inject(TranslateService);

	constructor() {}

	fetchSurveys$ = createEffect(() =>
		this.actions$.pipe(
			//quando selezioni una categoria o fai una ricerca, viene triggherato il DamFilter, quindi ci registriamo ad
			//un suo eventuale successo per richiamare gli assets
			ofType(setPages, setSort, setGridSearch, setForceRefreshSurveyCommunications, setCurrentSurveyUUID),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(selectCurrentSurveyUUID),
			]),
			debounce(() => this.store.select(selectStatus).pipe(filter((stat) => !stat || stat !== 'loading'))),
			//non voglio caricare se non ho le pagine pronte
			filter(([, pages, , , surveyUUID]) => !!pages?.length && !!surveyUUID),
			//non voglio caricare se i dati delle azioni sono gli stessi
			distinctUntilChanged(
				([, pagesPrev, sortPrev, gridSearchPrev, surveyUUIDPrev], [action, pagesCurr, sortCurr, gridSearchCurr, surveyUUIDCurr]) => {
					return (
						action.type !== '[Datalean SurveyCommunications] Set Force Refresh' &&
						pagesPrev.length === pagesCurr.length &&
						pagesPrev.every((f) => pagesCurr.includes(f)) &&
						sortCurr?.active === sortPrev?.active &&
						sortCurr?.direction === sortPrev?.direction &&
						gridSearchCurr === gridSearchPrev &&
						surveyUUIDCurr === surveyUUIDPrev
					);
				}
			),
			tap(() => this.store.dispatch(setStatus({ status: STATE_STATUS.LOADING }))),
			switchMap(([, pages, sort, gridSearch, surveyUUID]) => {
				return this.surveysService.fetchSurveyCommunications(surveyUUID!, pages, sort, this.translateService.currentLang, gridSearch).pipe(
					map((data) => {
						const startingResult: { pages: number[]; communications: Communication[][]; totalCommunicationsCount: number; hasResponse: boolean } = {
							pages: [],
							communications: [],
							totalCommunicationsCount: 0,
							hasResponse: false,
						};
						const result = data.reduce((acc, item) => {
							if (item.paginationInfo) {
								acc.pages.push(item.paginationInfo.pageNumber);
								acc.totalCommunicationsCount = item.paginationInfo.totalElements ?? 0;
								if (item.result) {
									acc.communications[item.paginationInfo.pageNumber] = item.result;
								}
							}
							return acc;
						}, startingResult);

						return setSurveyCommunicationsSuccess({ data: result });
					}),
					switchMap((p) => {
						if (surveyUUID && p.data.communications.length) {
							return this.surveysService.getSurvey(surveyUUID, [Parts.ANSWERS]).pipe(
								map((surveyData) => {
									const survey = Object.assign(new Survey(''), surveyData);
									p.data.hasResponse = !!survey.response?.length;
									return p;
								})
							);
						}
						return of(p);
					}),
					catchError((error: HttpErrorResponse) => {
						console.error(error);
						return of(setSurveyCommunicationsError({ error }));
					})
				);
			})
		)
	);

	forceRefreshTable$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setDeleteSurveysError),
			map(() => setForceRefreshSurveyCommunications())
		)
	);
}
