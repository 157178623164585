import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AddictionComponentsModule } from 'addiction-components';
import { BaseModalContent } from 'addiction-components';

@Component({
	selector: 'datalean-input-dialog',
	templateUrl: './input-dialog.component.html',
	styleUrls: ['./input-dialog.component.scss'],
	standalone: true,
	imports: [CommonModule, AddictionComponentsModule, TranslateModule],
})
export class InputDialogComponent extends BaseModalContent<string> implements OnInit {
	nameToSave: string = '';

	constructor() {
		super();
	}

	ngOnInit(): void {
		this.nameToSave = 'MEDIA_LIBRARY.NEW_FOLDER';
	}

	 close() {
		this.closeDialog(this.nameToSave);
	}

	saveName(value: string | number) {
		this.nameToSave = `${value}`;
	}
}
