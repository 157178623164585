import { HttpErrorResponse } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { Page } from 'addiction-components';
import { ProductError } from 'src/app/pages/products/state/product.state';
import { Lesson } from 'src/app/shared/models';
import { STATE_STATUS } from 'src/app/shared/models/state-status.enum';

export const setForceRefreshLessons = createAction('[Datalean Lessons] Set Force Refresh');
export const setLessons = createAction('[Datalean Lessons] Set Datalean Lessons');
export const setLessonsSuccess = createAction(
	'[Datalean Lessons] Set Datalean Lessons Success',
	props<{ data: { totalLessonsCount: number; pages: number[]; lessons: Lesson[][] } }>()
);
export const setLessonsError = createAction('[Datalean Lessons] Set Datalean Lessons Error', props<{ error: HttpErrorResponse }>());
export const updatePage = createAction('[Datalean Lessons] Update Page', props<{ updatedPage: Partial<Page> }>());
export const setDeleteLessons = createAction('[Datalean Lessons] Set Delete Datalean Lessons', props<{ uuids: string[] }>());
export const setDeleteLessonsSuccess = createAction('[Datalean Lessons] Set Delete Datalean Lessons Success');
export const setDeleteLessonsError = createAction('[Datalean Lessons] Set Delete Datalean Lessons Error', props<{ error: HttpErrorResponse }>());
export const setStatus = createAction('[Datalean Lessons] Set status', props<{ status: STATE_STATUS }>());
export const setPages = createAction('[Datalean Lessons] Set pages', props<{ pages: number[] }>());
export const setSort = createAction('[Datalean Lessons] Set sort', props<{ sort: Sort }>());
export const setGridSearch = createAction('[Datalean Lessons] Set search', props<{ search: string | null }>());
export const setCachedPages = createAction('[Datalean Lessons] Set cached pages', props<{ pages: number[] }>());
export const resetCache = createAction('[Datalean Lessons] reset cache');

// Error
export const error = createAction('[Datalean Lessons] Error', props<{ error: ProductError }>());
