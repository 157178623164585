import { DestroyRef, EventEmitter } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';

/** Converte un observable generico in un event emitter che copia il comportamento dell'observable.
 *
 * ℹ️ E' obbligatorio utilizzare il decoratore `UntiDestroy()` sul componente utilizzatore!
 */
export function createEmitter<T>(destroyRef: DestroyRef, obs: Observable<T>): EventEmitter<T> {
	const emitter = new EventEmitter<T>();
	obs.pipe(takeUntilDestroyed(destroyRef)).subscribe({
		next: (val) => emitter.emit(val),
	});
	return emitter;
}
