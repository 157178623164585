import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[addictionForbidChars]',
  standalone: true
})
export class ForbidCharsDirective {
  @Input()
  addictionForbidChars: string[] = [];

  @HostListener('keypress', ['$event'])
  keyPress(event:KeyboardEvent){
    if (this.addictionForbidChars.includes(event.key)){
      // not allowed char typed!
      event.preventDefault();
    }
  }

  constructor(
    private el:ElementRef<HTMLInputElement>
  ) { }

}
