import { Action, createReducer, on } from '@ngrx/store';
import * as DataleanUsersActions from './datalean-users.actions';
import { DataleanUser } from 'src/app/shared/models';
import { STATE_STATUS } from 'src/app/shared/models/state-status.enum';
import { Sort } from '@angular/material/sort';

export const DATALEAN_USERS_TAG = 'dataleanUsers';

// export interface DataleanUserFilters {

// }

export interface DataleanUsersState {
	// filters: DataleanUserFilters;
	gridSearch: string | null;
	sort?: Sort;
	data: { totalUsersCount: number; users: DataleanUser[][] };
	pages: number[];
	status: STATE_STATUS;
	deleteStatus: STATE_STATUS;
	cachedPages: number[];
}

const initialState: DataleanUsersState = {
	status: STATE_STATUS.UNKNOWN,
	deleteStatus: STATE_STATUS.UNKNOWN,
	gridSearch: null,
	// sort: environment.sortOptions[0],
	pages: [],
	data: { totalUsersCount: 0, users: [] },
	cachedPages: [],
};

export const reducers = createReducer(
	initialState,
	on(DataleanUsersActions.setSort, (state: DataleanUsersState, { sort }) => ({
		...state,
		sort,
	})),
	on(DataleanUsersActions.setPages, (state: DataleanUsersState, { pages }) => ({
		...state,
		pages,
	})),
	on(DataleanUsersActions.setDataleanUsersSuccess, (state: DataleanUsersState, { data }) => {
		return {
			...state,
			status: STATE_STATUS.READY,
			data: {
				users:   data.users,
				totalUsersCount: data.totalUsersCount,
			},
			pages: data.pages,
		};
	}),
	on(DataleanUsersActions.setDataleanUsersError, (state: DataleanUsersState) => ({
		...state,
		status: STATE_STATUS.ERROR,
	})),
	on(DataleanUsersActions.setStatus, (state: DataleanUsersState, { status }) => {
		return {
			...state,
			status,
		};
	}),
	on(DataleanUsersActions.setGridSearch, (state: DataleanUsersState, { search }) => {
		return {
			...state,
			gridSearch: search,
		};
	}),
	on(DataleanUsersActions.setDeleteDataleanUser, (state: DataleanUsersState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.LOADING,
		};
	}),
	on(DataleanUsersActions.setDeleteDataleanUserSuccess, (state: DataleanUsersState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.READY,
		};
	}),
	on(DataleanUsersActions.setDeleteDataleanUserError, (state: DataleanUsersState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.ERROR,
		};
	}),
  on(DataleanUsersActions.setCachedPages, (state: DataleanUsersState, { pages }) => ({
		...state,
		cachedPages: [...new Set([...state.cachedPages, ...pages])],
	})),
	on(DataleanUsersActions.resetCache, (state: DataleanUsersState) => ({
		...state,
    status: STATE_STATUS.READY,
		cachedPages: [],
		pages: [0],
		data: { totalUsersCount: 0, users: [] },
	}))
);

export const DataleanUserReducer = (state: DataleanUsersState | undefined, action: Action) => {
	return reducers(state, action);
};
