import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DataleanBaseApiService, Parts } from 'addiction-components';
import { Observable, map, switchMap } from 'rxjs';
import { MessageHandler } from 'addiction-components';
import { Community, DataleanUser, Permission } from 'src/app/shared/models';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { FiltersType } from 'src/app/shared/state/community-select/community-select.state';
import { environment } from 'src/environments/environment';
import { CommunitySelectActions } from '../../state/app.actions';
import { CommunitySelectSelectors, UserSelector } from '../../state/app.selectors';
import { HeaderLocaleService } from './service/header-locale.service';
import {
	getShowCommunitySelect,
	getShowLocales,
	selectBackButtonUrl,
	selectRemoveButton,
	selectShowBackButton,
	selectShowPlusButton,
	selectShowWaringOnBack,
} from './state/header.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';


@Component({
	selector: 'datalean-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
	private store = inject(Store);
	private auth = inject(AuthService);
	private dataleanApiService = inject(DataleanBaseApiService);
	private router = inject(Router);
	private messageHandler = inject(MessageHandler);
	protected hs = inject(HeaderLocaleService);

	headerLogo = 'assets/images/logo/datalean-logo.png';

	showLocales$ = this.store.select(getShowLocales);
	showCommunitySelect$ = this.store.select(getShowCommunitySelect);
	showBackButton$ = this.store.select(selectShowBackButton);
	showBackButtonWarning$ = this.store.select(selectShowWaringOnBack);
	showPlusButton$ = this.store.select(selectShowPlusButton);
	showRemoveButton$ = this.store.select(selectRemoveButton);

	canViewAllCommunities?: boolean;
	isCommunityAdmin?: boolean;

	showNoCommunityChoice$ = this.store.select(CommunitySelectSelectors.selectShowNoCommunityChoice);

	lastCommunitySelected$: Observable<string | undefined> = this.store.select(CommunitySelectSelectors.selectLastCommunitySelected);

	userData$: Observable<DataleanUser | null> = this.store.select(UserSelector.selectUserData);

	communitySelectFilters$: Observable<FiltersType | undefined> = this.store.select(CommunitySelectSelectors.selectFilters);

	communityData$: Observable<Community[]> = this.userData$.pipe(
		switchMap((userData) => {
			if (userData && userData.roles) {
				const permissions = userData.roles.map((r) => r.permissions).flat();
				this.canViewAllCommunities = permissions.find((permission) => permission.name === Permission.VIEW_ALL_COMMUNITIES) !== undefined;
				this.isCommunityAdmin =
					!this.canViewAllCommunities && permissions.find((permission) => permission.name === Permission.ADMINISTER_COMMUNITY) !== undefined;
			} else {
				this.canViewAllCommunities = false;
				this.isCommunityAdmin = false;
			}

			if (userData && this.isCommunityAdmin) {
				// user is community admin
				const filters = {
					communityListAdminUUID: userData.uuid,
				} as FiltersType;
				this.store.dispatch(CommunitySelectActions.setFilters({ filters }));
			}

			return this.communitySelectFilters$.pipe(
				switchMap((filter) =>
          //fetch community
					this.dataleanApiService.getManyPaged<Community>(environment.communityUrl, [Parts.VALUES], {
						additionalParams: { ...filter },
					})
				),
				map((data) => {
					const dataList: Community[] = data.result;
					if (this.canViewAllCommunities) {
						this.store.dispatch(CommunitySelectActions.setShowNoCommunityChoice({ showNoCommunityChoice: true }));
					} else if (this.isCommunityAdmin && dataList[0]) {
						this.store.dispatch(CommunitySelectActions.setLastCommunitySelected({ lastCommunitySelected: dataList[0].uuid }));
					}
					this.store.dispatch(CommunitySelectActions.setData({ data: dataList }));
					return dataList;
				})
			);
		})
		// shareReplay({ refCount: true, bufferSize: 1 })
	);

	showUserMenuButton = true;
	backUrl?: string[];

	constructor() {
		//nell'header devo attivare la visualizzazione delle community
		//TODO: per ora è nascosto lato sccs. Quando verrà implementato, mostrare solo nel caso in cui ci sia una community.
		//this.store.dispatch(HeaderActions.setShowConfig({ communitySelect: true, localeSelect: false }));

		this.store
			.select(getShowLocales)
			.pipe(takeUntilDestroyed())
			.subscribe((res) => {
				// console.log('getShowLocales ' + res);
			});

		this.store
			.select(getShowCommunitySelect)
			.pipe(takeUntilDestroyed())
			.subscribe((res) => {
				// console.log('getShowCommunitySelect ' + res);
			});

		this.store
			.select(selectBackButtonUrl)
			.pipe(takeUntilDestroyed())
			.subscribe((res) => {
				this.backUrl = res;
			});
	}

	logout() {
		this.auth.logout();
	}

	goToUserProfile() {
		this.router.navigate(['/profile']);
	}

	communitySelectChange(option: MatSelectChange) {
		this.store.dispatch(
			CommunitySelectActions.setLastCommunitySelected({
				lastCommunitySelected: option.value,
			})
		);
	}

	changeLocale(locale: string) {
		this.hs.changeActiveLocale(locale);
	}

	removeLocale(locale: string) {
		this.hs.removeLocale(locale);
	}

	goBack(showWarning?: boolean) {
		if (showWarning) {
			this.messageHandler.createConfirm({
				title: 'WARNING',
				htmlMessage: 'ALERT.WARNING_DISCARD_MESSAGE',
				buttons: [
					{
						label: 'YES',
						handler: () => {
							this.router.navigate(this.backUrl ?? ['/']);
						},
					},
					{
						label: 'NO',
						handler: () => {},
					},
				],
			});
		} else {
			this.router.navigate(this.backUrl ?? ['/']);
		}
	}
}
