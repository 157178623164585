import { CommonModule } from '@angular/common';
import { Component, forwardRef, inject } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ChipComponent, ModalService } from 'addiction-components';
import { Observable, filter, map, switchMap } from 'rxjs';
import { QuizService } from 'src/app/pages/lms/quiz/services/quiz.service';
import { environment } from 'src/environments/environment';
import { Quiz } from '../../models';
import { GenericSelectorDialogComponent } from '../generic-selector-dialog/generic-selector-dialog.component';
import { BaseChipsSelectorComponent, ChipValue } from './base-chips-selector.component';

// ℹ️ Segue la documentazione: https://addiction.atlassian.net/wiki/spaces/DOCTEC/pages/2269315073/ChipsSelector

@Component({
	selector: 'datalean-quiz-chips-selector',
	templateUrl: './chips-selector.component.html',
	styleUrls: ['./chips-selector.component.scss'],
	standalone: true,
	imports: [CommonModule, ChipComponent, ReactiveFormsModule],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: forwardRef(() => QuizChipsSelectorComponent) },
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: forwardRef(() => QuizChipsSelectorComponent),
		},
	],
})
export class QuizChipsSelectorComponent extends BaseChipsSelectorComponent {
	private modalSrv = inject(ModalService);
	private quizSrv = inject(QuizService);

	localizationService = inject(TranslateService);

	constructor() {
		super();
	}

	override writeValue(val: ChipValue[]): void {
		if (!Array.isArray(val)) {
			val = [];
		}
		super.writeValue(val);
	}

	override openDialog(currentValues: ChipValue[] = []): Observable<{ selected: ChipValue[]; replace?: boolean | undefined }> {
		// console.log('currentValues', currentValues);
		const selected = (currentValues || []).map(({ uuid }) => uuid);

		return this.modalSrv
			.openDialog(
				GenericSelectorDialogComponent,
				{ title: '' },
				{
					negativeReflection: false,
					selectedUUIDs: selected,
					endpoint: environment.surveyUrl,
					additionalParams: {
						type: 'quiz',
						locale: this.localizationService.currentLang ?? this.localizationService.defaultLang,
					},
				}
			)
			.result$.pipe(
				// controllo che la modal sia stata confermata
				filter((result) => result?.reason === 'COMPLETE' && !!result.data),
				switchMap(({ data }) => this.quizSrv.getQuizes({ parts: [], filters: { uuids: data?.selected } })),
				map(({ result }) => ({ selected: result.map((p) => this.mapQuizToChipValue(p)), replace: true }))
			);
	}

	private mapQuizToChipValue(quiz: Quiz): ChipValue {
		return {
			uuid: quiz.uuid,
			name: quiz.name,
		};
	}
}
