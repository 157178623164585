import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, forwardRef, inject } from '@angular/core';
import {
	ControlValueAccessor,
	FormControl,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ReactiveFormsModule,
	UntypedFormArray,
	UntypedFormGroup,
	Validator,
	Validators,
} from '@angular/forms';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { LocalizableField, StructureField, StructureFieldType } from 'addiction-components';
import { AvailabilityOption } from 'src/app/shared/models/reservations/availability.interface';
import { v4 } from 'uuid';
import { ContainerComponent } from '../../container/container.component';
import { AvailabilityOptionHourControl, AvailabilityOptionType, Days } from './../../../models/reservations/availability-option';

@Component({
	selector: 'datalean-availability-option',
	templateUrl: './availability-option.component.html',
	styleUrls: ['./availability-option.component.scss'],
	standalone: true,
	imports: [TranslateModule, NgIf, NgFor, ReactiveFormsModule, forwardRef(() => ContainerComponent)],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: forwardRef(() => AvailabilityOptionComponent) },
		{ provide: NG_VALIDATORS, multi: true, useExisting: forwardRef(() => AvailabilityOptionComponent) },
	],
})
export class AvailabilityOptionComponent implements ControlValueAccessor, OnInit, Validator {
	private translatePipe = inject(TranslatePipe);

	parentGroup = new UntypedFormGroup({
		day: new FormControl('ALL', {
			validators: Validators.compose([Validators.required]),
		}),
		type: new FormControl<AvailabilityOptionType>(AvailabilityOptionType.CAN_RESERVE, {
			validators: Validators.compose([Validators.required]),
			nonNullable: true,
		}),
		hourControl: new FormControl<AvailabilityOptionHourControl | null>(null, {
			validators: Validators.compose([Validators.required]),
			nonNullable: true,
		}),
	});
	showForm = true;

	@Input() showDeleteButton = false;
	@Output() deleteItem = new EventEmitter();
	hoursControl = AvailabilityOptionHourControl;

	optionFieldList: StructureField[] = [
		{
			name: 'day',
			label: new LocalizableField([
				{ language: 'it-IT', value: 'Giorno' },
				{ language: 'en-US', value: 'Day' },
			]),
			uuid: v4(),
			type: StructureFieldType.SELECT,
			isRequired: true,
			desktopRatio: 25,
			parsedExtra: [
				{ label: this.translatePipe.transform('AVAILABILITY-RULE.' + 'ALL'), value: 'ALL' },
				...Object.values(Days).map((day) => ({ label: this.translatePipe.transform('AVAILABILITY-RULE.' + day.toUpperCase()), value: day })),
			],
			predefinedValue: new LocalizableField([{ language: 'it-IT', value: 'ALL' }]),
		},
		{
			name: 'type',
			label: new LocalizableField([
				{ language: 'it-IT', value: 'Come' },
				{ language: 'en-US', value: 'As' },
			]),
			uuid: v4(),
			type: StructureFieldType.SELECT,
			isRequired: true,
			desktopRatio: 25,
			parsedExtra: Object.values(AvailabilityOptionType).map((type) => ({
				label: this.translatePipe.transform('AVAILABILITY-RULE.' + type.toUpperCase()),
				value: type,
			})),
			predefinedValue: new LocalizableField([{ language: 'it-IT', value: AvailabilityOptionType.CAN_RESERVE }]),
		},
		{
			name: 'hourControl',
			label: new LocalizableField([
				{ language: 'it-IT', value: 'Controllo orario' },
				{ language: 'en-US', value: 'Hour control' },
			]),
			uuid: v4(),
			type: StructureFieldType.SELECT,
			isRequired: true,
			desktopRatio: 25,
			parsedExtra: Object.values(AvailabilityOptionHourControl).map((type) => ({
				label: this.translatePipe.transform('AVAILABILITY-RULE.' + type.toUpperCase()),
				value: type,
			})),
			predefinedValue: new LocalizableField([{ language: 'it-IT', value: AvailabilityOptionHourControl.FULL_DAY }]),
		},
		{
			name: 'workshifts',
			label: new LocalizableField([
				{ language: 'it-IT', value: 'Orari' },
				{ language: 'en-US', value: 'Shifts' },
			]),
			uuid: v4(),
			type: StructureFieldType.CONTAINER,
			repeatable: true,
			isRequired: false,
			desktopRatio: 25,
			parsedExtra: { parentControl: true },
			fields: [
				{
					name: 'from',
					label: new LocalizableField([
						{ language: 'it-IT', value: 'Da' },
						{ language: 'en-US', value: 'From' },
					]),
					uuid: v4(),
					type: StructureFieldType.DATE,
					isRequired: true,
					desktopRatio: 50,
					parsedExtra: { showDate: false, showTime: true },
				},
				{
					name: 'to',
					label: new LocalizableField([
						{ language: 'it-IT', value: 'A' },
						{ language: 'en-US', value: 'To' },
					]),
					uuid: v4(),
					type: StructureFieldType.DATE,
					isRequired: true,
					desktopRatio: 50,
					parsedExtra: { showDate: false, showTime: true },
				},
			],
		},
	];

	constructor() {}

	ngOnInit(): void {
		this.parentGroup.get('hourControl')?.valueChanges.subscribe({
			next: (value: AvailabilityOptionHourControl) => this.setWorkshiftControl(value),
		});
	}

	public onTouched: () => void = () => {};

	onValidatorChange = () => {};

	registerOnValidatorChange(fn: () => void): void {
		this.parentGroup.updateValueAndValidity({ onlySelf: true, emitEvent: false });
		// console.log('registerOnValidatorChange', this.parentGroup.valid);
		this.validate();
		fn();
	}

	validate(): { [key: string]: unknown } | null {
		// console.log('registerOnValidatorChange', this.parentGroup.valid);
		return this.parentGroup.valid ? null : { invalidForm: { valid: false, message: 'Form fields are invalid' } };
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	writeValue(obj: Partial<AvailabilityOption>): void {
		this.parentGroup.addControl(
			'workshifts',
			new UntypedFormArray(obj?.workshifts?.map((shift) => new FormControl(shift)) ?? [], {
				validators: [Validators.required],
			})
		);

		this.parentGroup.patchValue({
			day: obj?.day ?? 'ALL',
			type: obj?.type ?? AvailabilityOptionType.CAN_RESERVE,
			hourControl: obj?.hourControl ?? AvailabilityOptionHourControl.FULL_DAY,
			workshifts: obj?.workshifts?.map((shift) => ({ from: new Date(shift.from), to: new Date(shift.to) })) ?? [],
		});

		this.parentGroup.updateValueAndValidity();
	}

	registerOnChange(fn: (val: unknown | undefined) => void): void {
		this.parentGroup.valueChanges.subscribe(fn);
	}

	private setWorkshiftControl(hourControl: AvailabilityOptionHourControl) {
		// console.log(hourControl);
		if (hourControl === AvailabilityOptionHourControl.FULL_DAY) {
			(this.parentGroup.controls['workshifts'] as UntypedFormArray).clear();
			this.parentGroup.removeControl('workshifts', { emitEvent: false });
		} else {
			// this.parentGroup.get('workshifts')?.enable();
			if (!this.parentGroup.get('workshifts')) {
				this.parentGroup.addControl('workshifts', new UntypedFormArray([], { validators: [Validators.required, Validators.min(1)] }));
			}
		}
		this.parentGroup.updateValueAndValidity();
	}
}
