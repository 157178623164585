import { MediaLibraryFilters } from 'src/app/pages/media-library/state/media-library.state';
import { FilterParams } from '../models/filterParams';

//ritorna l'oggetto filterParams, necessario per chiamare la multipleUpdate del BE
export function createFilterObject(filters?: MediaLibraryFilters, featureUUID?: string): FilterParams {
	const params: FilterParams = {};

	// CATEGORIE
	if (featureUUID) {
		params.featureValueList = featureUUID;
		if (filters?.featureValueList) params.featureValueList += ',' + filters.featureValueList;
	}

	if (!filters) return params;

	if (filters.assetType && filters.assetType.length) {
		//con array vuoto tengo il defaut
		params.assetType = filters.assetType?.join(',');
	}

	//TAGS
	if (filters.tags) {
		params.tags = filters.tags;
	}

	//calcolo DATE
	let createDate, updateDate;
	if (filters.createFrom) {
		createDate = 'r|' + filters.createFrom;
	}
	if (filters.createTo) {
		if (createDate) createDate += '|' + filters.createTo;
		else createDate = 'r||' + filters.createTo;
	}
	if (createDate) params.createDate = createDate;

	if (filters.updateFrom) {
		updateDate = 'r|' + filters.updateFrom;
	}
	if (filters.updateTo) {
		if (updateDate) updateDate += '|' + filters.updateTo;
		else updateDate = 'r||' + filters.updateTo;
	}
	if (updateDate) params.updateDate = updateDate;

	//SEARCH
	if (filters.q) {
		params.q = filters.q;
		params.searchFields = 'name';
	}

	return params;
}
