import { EntityType } from '../entityViewPermission.interface';
import { UserType } from '../user-type.enum';
import { FeedbackType } from './feedback-type.enum';

export interface Feedback {
	date: string[];
	entityType: EntityType;
	entityUUID: string;
	extraValues: Array<ExtraFeedback>;
	feedbackType: FeedbackType;
	userType: UserType;
	userUUID: string;
}

export type FeedbackWithDateString = Omit<Feedback, 'date'> & { date: string };

export interface ExtraFeedback {
	key: string;
	value: string;
}
