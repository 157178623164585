<div class="modal-input-container">
	<div class="body-container">
		<div class="text" *ngIf="data.bodyMessage as message">
			{{ message | translate }}
		</div>
		<div class="text" *ngIf="data.htmlMessage as message" [innerHTML]="message | translate"></div>
		<div class="button-container" *ngIf="data.buttons?.length">
			<button *ngFor="let item of data.buttons" (click)="item.handler()">
				<span>{{ item.label | translate }}</span>
			</button>
		</div>
	</div>
</div>
