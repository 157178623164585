import { Injectable } from '@angular/core';
import { DataleanBaseApiService, FeatureValue, FeatureValueType, Parts, SimpleObject } from 'addiction-components';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HeaderLocaleService } from 'src/app/components';

@Injectable({ providedIn: 'root' })
export class FeaturesService {
	private featureUrl = environment.featureValueUrl;

	constructor(private baseApi: DataleanBaseApiService, private headerSrv: HeaderLocaleService) {}

	getFeatures(type: FeatureValueType[], locale?: string, uuids?: string[]): Observable<FeatureValue[]> {
		const additionalParams: SimpleObject = {};

		additionalParams['sortBy'] = 'order#asc';

		if (type) additionalParams['type'] = type;
		if (uuids && uuids.length) additionalParams['uuids'] = uuids.join(',');
		if (locale) {
			additionalParams['locale'] = locale;
		}

		additionalParams['parentUUID'] = '';

		return this.baseApi.getMany<FeatureValue>(this.featureUrl, [Parts.FEATURE_VALUES, Parts.PREVIEW_ASSET_INFO], {
			additionalParams,
		});
	}

	getFeature(uuid: string, locale: string = 'all') {
		return this.baseApi.getOne<FeatureValue>(this.featureUrl, uuid, [Parts.FEATURE_VALUES, Parts.PREVIEW_ASSET_INFO, Parts.FILTER_OPTIONS], {
			locale,
		});
	}

	updateFeature(feature: FeatureValue): Observable<FeatureValue> {
		const { uuid } = feature;
		return this.baseApi.updateOne<FeatureValue>(this.featureUrl, uuid, feature, [Parts.EMPTY]);
	}

	createFeature(feature: FeatureValue): Observable<FeatureValue> {
		return this.baseApi.createOne<FeatureValue>(this.featureUrl, feature, [Parts.EMPTY]);
	}

	cloneFeature(feature: FeatureValue): Observable<FeatureValue> {
		return this.baseApi.createOne<FeatureValue>(this.featureUrl + 'clone', feature, [Parts.FEATURE_VALUES]);
	}

	deleteFeature(uuid: string): Observable<true> {
		return this.baseApi.deleteOne(this.featureUrl, uuid).pipe(map(() => true));
	}

	changeFeatureOrder(featuresToUpdate: FeatureValue[]) {
		return this.baseApi.updateMany<FeatureValue>(this.featureUrl + 'multipleUpdate/', featuresToUpdate);
	}
}
