<addiction-search (search)="filterByName($event)"></addiction-search>

<datalean-table
	[configuration]="baseTableConfig"
	[rows]="tableRows$ | async"
	[page]="(pageSettings$ | async)!"
	(pageChange)="pageChanged($event)"
	(selectedChange)="selectionChanged($event)"
	[selected]="(selectedRows$ | async)!"
	(sortChange)="sort($event)"></datalean-table>

<div class="buttons-container">
	<button (click)="close()">
    <span>{{ 'ADD' | translate }}</span>
  </button>
</div>
