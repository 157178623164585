import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ContentsReducers } from 'src/app/core/state/app.reducers';
import { ContentState } from 'src/app/pages/contents/state/content.state';


const selectContentsState = createFeatureSelector<ContentState>(ContentsReducers.TAG);

export const selectContentsFilters = createSelector(selectContentsState, (state) => state?.filters);
export const selectContentsFeatures = createSelector(selectContentsState, (state) => state?.features);
export const selectContentsStructures = createSelector(selectContentsState, (state) => state?.structures);

export const selectData = createSelector(selectContentsState, (state) => state.data);
export const selectPages = createSelector(selectContentsState, (state) => state.pages);
export const selectPagedData = createSelector(selectData, selectPages, (data, pages) => ({ ...data, pages }));
export const selectGridSearch = createSelector(selectContentsState, (state) => state.gridSearch);
export const selectSort = createSelector(selectContentsState, (state) => state.sort);
export const selectStatus = createSelector(selectContentsState, (state) => state.status);
export const selectCachedPages = createSelector(selectContentsState, (state) => state.cachedPages);
