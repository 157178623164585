import { Asset } from 'addiction-components';
import { BaseUser } from './base-user.interface';

export interface Community {
  uuid: string,
  name: string,
  category: string,
  logo?: Asset,
  listAdmin: BaseUser[],
  active: boolean,
  members: BaseUser[],
  code: string
}
