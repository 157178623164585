import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { AssetPreviewData } from '../models/media-library/asset-preview-data';

@Directive({
	selector: '[dataleanAssetImage]',
})
/** Direttiva che gestisce la presentazione in un TAG <img> un Asset (media library).
 */
export class AssetImageDirective implements OnChanges {
	@Input('dataleanAssetImage') imageRef?: AssetPreviewData;

	constructor(private el: ElementRef) {}

	ngOnChanges(): void {
		this.updateImageSource();
	}

	updateImageSource() {
		this.el.nativeElement.src = this.getPreviewUrl();
	}

	getPreviewUrl(): string | undefined {
		if (!this.imageRef) return;

		let url = this.imageRef.preview?.url;
		if (!url && this.isAssetImage(this.imageRef.format)) url = this.imageRef.url; // per le immagini, la preview può essere l'asset stesso

		if (!url) return this.getPlaceholderImage(this.imageRef.format);

		return url;
	}

	// 👇 TODO: valutare se sostare in un service dedicato queste funzioni, per ora non è necessario 👇
	private isAssetImage(format?: string) {
		const imageFormats = ['png', 'jpg', 'jpeg', 'svg', 'svg+xml', 'gif', 'ico'];
		return format && imageFormats.includes(format);
	}

	//preview dei formati richiesti.
	private getPlaceholderImage(format?: string) {
		let iconName = 'placeholder-image.svg';
		if (format) {
			switch (format.toLowerCase()) {
				case 'psd':
					iconName = 'placeholder-image.svg';
					break;
				case 'ai':
					iconName = 'placeholder-vector.svg';
					break;
				case 'dwg':
					iconName = 'placeholder-3d.svg';
					break;
				case 'indd':
					iconName = 'placeholder-publishing.svg';
					break;
				case 'doc':
				case 'docx':
				case 'txt':
				case 'odt':
					iconName = 'placeholder-text.svg';
					break;
				case 'ppt':
				case 'pptx':
					iconName = 'placeholder-presentation.svg';
					break;
				case 'xls':
				case 'xlsx':
					iconName = 'placeholder-sheet.svg';
					break;
				case 'xml':
				case 'json':
					iconName = 'placeholder-code.svg';
					break;
				case 'ogg':
				case 'mov':
				case 'avi':
				case 'mp4':
				case 'mpg':
				case 'wmv':
				case 'flv':
					iconName = 'placeholder-video.svg';
					break;
				case 'zip':
				case 'rar':
				case 'tgz':
					iconName = 'placeholder-archive.svg';
					break;
				case 'mp3':
				case 'wav':
				case 'm4a':
					iconName = 'placeholder-audio.svg';
					break;
				default:
					iconName = 'placeholder-image.svg';
					break;
			}
		}

		return 'assets/images/placeholder/' + iconName;
	}
}
