import { BaseLocalizedValues, DataleanEntity, UnknownObject } from "addiction-components";
import { Challenge } from "./challenge.interface";
import { ResolvedAssetReference } from "../asset-reference.interface";
import { Recipient } from "../recipient";
interface DataleanContest extends DataleanEntity {
    localizedValues?: ContestLocalizedValues[];
    challengesUUID: string[];
    challenges?: Challenge[];
    recipientsUUID: string[];
    recipients?: Recipient[];
    image?:  Partial<ResolvedAssetReference>;
}
export type Contest = DataleanContest & Partial<{[key in keyof ContestLocalizedValues]: ContestLocalizedValues[key]}>  &  {[key: string]: unknown | UnknownObject};

export interface ContestLocalizedValues extends BaseLocalizedValues {
    name?: string;
    description?: string;
    regulation?: string;
    reward?: string;
}


export interface Ranking {
    placements: RankingPlacement[];
}

export interface RankingPlacement {
    position: number;
    firstName: string;
    lastName: string;
    email: string;
    score: number;
    username: string;
    userUUID: string;
}