<form [formGroup]="formGroup" class="date-picker-container">
	<mat-form-field color="accent" appearance="fill" *ngIf="showDate">
		<label *ngIf="dateLabel">{{ dateLabel }}</label>
		<input
			[readonly]="readonly"
			[min]="minDate"
			[max]="maxDate"
			formControlName="date"
			matInput
			[matDatepicker]="picker2"
			(dateChange)="updateDateTime($event, 'date')" />
		<mat-datepicker-toggle [disabled]="disabled || readonly" matSuffix [for]="picker2"></mat-datepicker-toggle>
		<mat-datepicker [ariaReadOnly]="readonly" #picker2 color="primary"></mat-datepicker>
	</mat-form-field>
	<mat-form-field appearance="fill" *ngIf="showTime">
		<label *ngIf="timeLabel">{{ timeLabel }}</label>
		<mat-select #timeInput [ariaReadOnly]="readonly" (selectionChange)="updateDateTime($event, 'time')" formControlName="time">
			<mat-option *ngFor="let timeValue of timeOptions" [value]="timeValue">{{ timeValue }}</mat-option>
		</mat-select>
	</mat-form-field>
</form>
