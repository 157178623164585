import { Injectable } from '@angular/core';
import { DataleanBaseApiService, Parts } from 'addiction-components';
import { environment } from 'src/environments/environment';
import { DataleanUser } from '../models/base-user.interface';

@Injectable({ providedIn: 'root' })
export class UserService {
	constructor(private baseApi: DataleanBaseApiService) {}

	getByUUID(uuid: string) {
		return this.baseApi.getOne<DataleanUser>([environment.usersUrl], uuid, [Parts.ROLES]);
	}

	updateUserFields(userUuid: string, body: Record<string, unknown>) {
		return this.baseApi.patchOne<DataleanUser>(environment.usersUrl, userUuid, body);
	}
}
