import { ObjectValuesType } from '../object-values-type';

/** Inserire gli structureType che non si devono visualizzare nella creazione di una struttura nell'ExtraStructureFieldType */
export const StructureFieldType = {
	CONTAINER: 'container',
	ATTACHMENT_3: 'attachment_3.0',
	TEXT: 'text',
	URLTITLE: 'urlTitle',
	TEXTAREA: 'textarea',
	FILE_3: 'file_3.0',
	BOOLEAN: 'boolean',
	TOGGLE: 'toggle',
	HTML: 'html',
	PHONE_3: 'phone_3.0',
	EMAIL_3: 'email_3.0',
	SELECT: 'select',
	RADIO: 'radio',
	QRCODE: 'qrcode',
	IMAGE_MAP_CONTAINER_3: 'imageMapContainer_3.0',
	FEATURE_VALUES_CONTAINER: 'featureValuesContainer',
	RELATED_ENTITIES: 'relatedEntities',
	DATE: 'date',
	NUMBER: 'number',
} as const;

export type StructureFieldType = ObjectValuesType<typeof StructureFieldType>;

export const ExtraStructureFieldType = {
	...StructureFieldType,
	SURVEY_DOMAIN_OF_ANSWERS: 'surveyDomainOfAnswers',
	LESSON_ATTACHMENT: 'lessonAttachment',
	SINGLE_RELATED_ENTITY: 'singleRelatedEntity',
	HIDDEN: 'hidden',
	ROLES: 'roles',
	PICTOGRAM: 'pictogram',
	AVAILABILITY_OPTION: 'availabilityOption',
	AVAILABILITY_RULE: 'availabilityRule',
	IMAGE_MAP_CONTAINER: 'imageMapContainer',
	FILE: 'file',
	ATTACHMENT: 'attachment',
	PHONE: 'phone',
	EMAIL: 'email',
	PRIVACY: 'privacy',
} as const;
export type ExtraStructureFieldType = ObjectValuesType<typeof ExtraStructureFieldType>;

export const ContainerType = {
	CONTAINER: 'container',
	ATTACHMENT: 'attachment',
	IMAGE_MAP_CONTAINER: 'imageMapContainer',
	IMAGE_MAP_CONTAINER_3: 'imageMapContainer_3.0',
} as const;

export type ContainerType = ObjectValuesType<typeof ContainerType>;
