<form class="form-group-container" [formGroup]="formGroup" *ngIf="formGroup">
	<div class="title-fields-container">
		<h2 *ngIf="label">{{ label }}</h2>
	</div>
	<div class="field-container ratio-50">
		<addiction-input [readonly]="readonly" label="{{ 'EMAIL.ADDRESS' | translate }}" formControlName="email"></addiction-input>
	</div>
	<div class="field-container ratio-50">
		<addiction-input [readonly]="readonly" label="{{ 'EMAIL.BUTTON_TEXT' | translate }}" formControlName="emailButtonText"></addiction-input>
	</div>
	<div class="field-container ratio-50">
		<addiction-input [readonly]="readonly" label="{{ 'EMAIL.SUBJECT' | translate }}" formControlName="emailSubject"></addiction-input>
	</div>
	<div class="field-container ratio-100">
		<label>{{ 'EMAIL.TEXT' | translate }}</label>
		<addiction-html-editor [readonly]="readonly" [control]="formGroup.controls.emailText"></addiction-html-editor>
	</div>
</form>
