import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UsefulLinksReducers } from 'src/app/core/state/app.reducers';
import { UsefulLinksState } from './useful-links.state';

const selectUsefulLinksState = createFeatureSelector<UsefulLinksState>(UsefulLinksReducers.TAG);

export const selectUsefulLinksFilters = createSelector(selectUsefulLinksState, (state) => state?.filters);
export const selectUsefulLinksStructures = createSelector(selectUsefulLinksState, (state) => state?.structures);

export const selectData = createSelector(selectUsefulLinksState, (state) => state.data);
export const selectPages = createSelector(selectUsefulLinksState, (state) => state.pages);
export const selectPagedData = createSelector(selectData, selectPages, (data, pages) => ({ ...data, pages }));
export const selectGridSearch = createSelector(selectUsefulLinksState, (state) => state.gridSearch);
export const selectStatus = createSelector(selectUsefulLinksState, (state) => state.status);
export const selectSort = createSelector(selectUsefulLinksState, (state) => state.sort);
export const selectRecipients = createSelector(selectUsefulLinksState, (state: UsefulLinksState) => state.recipients);
export const selectCachedPages = createSelector(selectUsefulLinksState, (state) => state.cachedPages);
