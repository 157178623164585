import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PictogramState } from './pictogram.state';


const pictogram = createFeatureSelector<PictogramState>('pictograms');

export const selectPictograms = createSelector(pictogram, (state) => state.pictograms);
export const selectError = createSelector(pictogram, (state) => state.error);
export const selectSelectedPictogram = createSelector(pictogram, (state) => state.selectedPictogram);
export const selectPictogramStructures = createSelector(pictogram, (state) => state.structures);
