<div class="modal-input-container">
  <div class="header-container">
    <div class="title" *ngIf="data.title as title">
      {{ title | translate }}
    </div>
    <div class="close-container">
      <button class="close" (click)="dialogRef.close()"></button>
    </div>
  </div>

  <div class="body-container">
    <div class="text" *ngIf="data.bodyMessage as message">
      {{ message | translate }}
    </div>
    <form #inputForm="ngForm" (ngSubmit)="onSubmit()">
      <div class="input-container">
        <addiction-input
          name="inputControl"
          [label]='data.inputLabel | translate'
          [(ngModel)]="inputValue"
          [placeholder]="data.placeholder ? (data.placeholder | translate) : ''"
        ></addiction-input>

<!--        <input id="inputControl" name="inputControl" required [minlength]="1"  />-->
      </div>
      <div class="buttons-container">
        <button class="confirm" [disabled]="inputForm.invalid" type="submit">
          <span>{{ data.confirmLabel | translate }}</span>
        </button>
      </div>
    </form>
  </div>
</div>
