import { FilterSort } from 'addiction-components';
import { Toolbar } from 'ngx-editor';

export interface BaseEnvironment {
	production: boolean;
	authenticationUrl: string;
	usersUrl: string;
	lmsUrl: string;
	surveyUrl: string;
	surveyResponseUrl: string;
	groupsUrl: string;
	smartGroupsUrl: string;
	contestUrl: string;
	tableUsersUrl: string;
	applicationUsersUrl: string;
	productUrl: string;
	contentUrl: string;
	communicationUrl: string;
	communityUrl: string;
	mediaLibraryUrl: string;
	availabilityRuleUrl: string;
	operationPermissionUrl: string;
	structureUrl: string;
	structureFieldUrl: string;
	featureValueUrl: string;
	organizationUrl: string;
	configurationUrl: string;
	rolesUrl: string;
	permissionUrl: string;
	userStructureUrl: string;
	wishlistUrl: string;
	templateUrl: string;
	integrationUrl: string;
	filterUrl: string;
	thumbnailConfigUrl: string;
	conversionUrl: string;
	privacyUrl: string;
	conditionTermUrl: string;
	cataleanUrl: string;
	orgLogoUrl: string;
	version: string;
	htmlEditorMenu: Toolbar;
	tagUrl: string;
	collectionsUrl: string;
	sortOptions: FilterSort[];
	pageSize: number;
	customProductExportUrl?: string;
	customProductImportUrl?: string;
	reservationUrl: string;
	reservationProductUrl: string;
	reservationResourceUrl: string;
	pictogramUrl?: string;
	productVariantsUrl?: string;
	challengeUrl: string;
	contestActionUrl: string;
}

export interface Environment extends BaseEnvironment {
	organizationPrefix: string;
	organizationUUID: string;
	activeLanguage: string;
}
