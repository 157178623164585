export class PaginationInfo {
	constructor(private _numberOfElementsOnPage: number, private _numberOfPage: number = 0, private _totalNumberOfElements: number = 0) {}

	static empty() {
		return new PaginationInfo(0, 0, 0);
	}

	restart() {
		this._numberOfPage = 0;
		this._totalNumberOfElements = 0;
	}

	get nextPage(): number {
		return this._numberOfPage + 1;
	}

	get numberOfPage(): number {
		return this._numberOfPage;
	}

	get numberOfElementsOnPage(): number {
		return this._numberOfElementsOnPage;
	}

	get totalNumberOfElements(): number {
		return this._totalNumberOfElements;
	}

	set totalNumberOfElements(value: number) {
		this._totalNumberOfElements = value;
	}

	set numberOfPage(value: number) {
		this._numberOfPage = value;
	}
}
