import { AbstractControl, ValidationErrors, ValidatorFn, UntypedFormGroup } from '@angular/forms';

export const checkPasswords = (newPassword: AbstractControl) => {
  return (control: AbstractControl): ValidationErrors | null => {
    const pass = newPassword.value;
    const confirmPass = control.value;
    return pass === confirmPass ? null : { notSame: true };
  };
};

export const lowercase = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const valid = !control.value?.length || /(?=.*[a-z])/.test(control.value);
    return !valid ? { lowercase: false } : null;
  };
};

export const uppercase = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const valid = !control.value?.length || /(?=.*[A-Z])/.test(control.value);
    return !valid ? { uppercase: false } : null;
  };
};

export const special = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const valid = !control.value?.length || /(?=.*\W)/.test(control.value);
    return !valid ? { special: false } : null;
  };
};

export const number = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const valid = !control.value?.length || /(?=.*\d)/.test(control.value);
    return !valid ? { number: false } : null;
  };
};
