import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ActiveModalService } from '../../services/active-modal.service';

/** Classe base per i componenti modali, dichiara il tipo restituito dalla modal (se restituisce qualcosa) */
export class BaseModalContent<TResult = null> {
	[key: string]: unknown;

	private dialogId?: string;
	protected activeModal = inject(ActiveModalService);

	constructor() {}
	/** Restitusce true se il componente corrente è stato renderizzato in una finestra di dialogo.
	 * Utile quando un componente può essere utilizzato sia in pagina che in una modale
	 */
	protected get isInsideDialog() {
		return !!this.dialogId;
	}

	setDialogId(id: string) {
		this.dialogId = id;
	}

	closeDialog(res: TResult) {
		if (this.dialogId) this.activeModal.closeModal(this.dialogId, 'COMPLETE', res);
	}

	cancelDialog() {
		if (this.dialogId) this.activeModal.closeModal(this.dialogId, 'CANCEL', null);
	}

	getHeaderButtons(): Observable<HeaderButton[]> | HeaderButton[] {
		return [];
	}
}

export interface HeaderButton {
	/** Chiave (localization) della stringa da usare come label */
	labelKey: string;
	disabled: boolean;
	class?: string;
	onclick?: () => unknown;
}
