<datalean-tree-browser
	*ngIf="list"
	[nodes]="list"
	[checkedKeys]="selectedUUIDs"
	[config]="config"
	[checkable]="true"
	(checked)="onChecked($event)"
	[allowSearch]="true"></datalean-tree-browser>

<div class="buttons-container">
	<button class="confirm" (click)="confirm()">
		<span>{{ 'CONFIRM' | translate }}</span>
	</button>
</div>
