import { ActionCreatorProps, createAction, NotAllowedCheck, props } from "@ngrx/store";

/** Crea un'azione context-aware (ovvero che si applica un contesto specifico) */
export function createContextAction<T extends string, P extends object>(
    type: T,
    customProps?: ActionCreatorProps<P>,
  ) {
    const ctxProps = props<{ context:string }>();
    const union = { ...ctxProps, ...(customProps || {}) } as ActionCreatorProps<P & {context?:string}> & NotAllowedCheck<P & { context?:string }>;

    return createAction<T, P & { context?:string }>(type, union);
  }


// eslint-disable-next-line @ngrx/good-action-hygiene
export const initContextAction = createAction('@init-context', props<{ context: string }>());