import { Component, Input } from '@angular/core';
import { ActiveModalService } from '../../../services';
import { HeaderButton } from '../base-modal-content';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: 'datalean-modal-header',
	templateUrl: './modal-header.component.html',
	styleUrls: ['./modal-header.component.scss'],
	standalone: true,
	imports: [CommonModule, TranslateModule],
})
export class ModalHeaderComponent {
	@Input()
	title: string = '';

	@Input()
	allowCancel: boolean = true;

	@Input()
	dialogId!: string;

	@Input()
	buttons: HeaderButton[] = [];

	constructor(private activeModal: ActiveModalService) {}
	cancel() {
		this.activeModal.closeModal(this.dialogId, 'CANCEL');
	}

	onHeaderButtonClicked(btn: HeaderButton) {
		if (btn.onclick) btn.onclick();
	}
}
