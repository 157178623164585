import { createReducer, on } from '@ngrx/store';
import { TagsState } from './tags.state';
import { TagsAction } from '../../../core/state/app.actions';

export const initialState: TagsState = {
	loading: false,
  tagList: []
};

export const reducer = createReducer(
	initialState,
	on(TagsAction.createTag, TagsAction.updateTag, TagsAction.loadTags, (state) => ({
		...state,
		loading: true,
		error: undefined,
	})),
  on(TagsAction.loadTags, (state) => ({
		...state,
		loading: true,
		error: undefined,
	})),
	on(TagsAction.createTagFailure, TagsAction.updateTagFailure, TagsAction.loadTagsFailure, (state, { error }) => ({
		...state,
		loading: false,
		error,
	})),

	on(TagsAction.loadTagsSuccess, (state, {tags}) => ({
		...state,
		loading: false,
    tagList: tags
	})),
	on(TagsAction.editTag, (state, { uuid }) => ({
		...state,
		editingUUID: uuid,
	}))
);
