import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DATALEAN_SURVEYS_TAG, SurveysState } from './surveys.reducer';

const selectSurveysState = createFeatureSelector<SurveysState>(DATALEAN_SURVEYS_TAG);

export const selectData = createSelector(selectSurveysState, (state: SurveysState) => state.data);
export const selectPages = createSelector(selectSurveysState, (state: SurveysState) => state.pages);
export const selectPagedData = createSelector(selectData, selectPages, (data, pages) => ({ ...data, pages }));
export const selectGridSearch = createSelector(selectSurveysState, (state: SurveysState) => state.gridSearch);
export const selectStatus = createSelector(selectSurveysState, (state: SurveysState) => state.status);
export const selectSort = createSelector(selectSurveysState, (state: SurveysState) => state.sort);
export const selectCachedPages = createSelector(selectSurveysState, (state) => state.cachedPages);
