<div class="controls-bar">
	<!-- DIMENSIONE -->
	<div *ngIf="assetdisplayMode === 1" class="size-container">
		<!-- AGGIUNGERE QUI LO SLIDER PER LA DIMENSIONE GRIGLIA QUANDO PRONTO -->
		<label>{{ 'MEDIA_LIBRARY.RESIZE_LABEL' | translate }}</label>
		<mat-slider [min]="minGridSize" [max]="maxGridSize" step="1">
			<input matSliderThumb [(ngModel)]="gridSize" (ngModelChange)="setGridViewOptions()" />
		</mat-slider>
	</div>

	<!-- VIEW GRID/LIST -->
	<div class="view-mode-container">
		<button class="view-button grid-view" [class]="assetdisplayMode === 1 ? 'active' : ''" (click)="setGridViewOptions(1)"></button>
		<button class="view-button list-view" [class]="assetdisplayMode === 2 ? 'active' : ''" (click)="setGridViewOptions(2)"></button>
	</div>

	<!-- ORDER -->
	<div class="order-container">
		<div class="select-container" *ngIf="sortOptions.length">
			<label>{{ 'MEDIA_LIBRARY.ORDER_BY.TITLE' | translate }}</label>
			<addiction-select [value]="sortValue?.value" [options]="sortOptions" (valueChange)="onChangeSort($event)"> </addiction-select>
		</div>
	</div>

	<!-- COLLEZIONI -->
	<div class="collections-container" *ngIf="hasDam$ | async">
		<button class="collections-button" (click)="goToCollections()">
			<span class="label">{{ 'HEADER.COLLECTIONS' | translate }}</span>
		</button>
	</div>

	<!-- NUOVO -->
	<div class="create-new-container">
		<button class="add-new" type="button" (click)="openUploadDialog()"></button>
	</div>
</div>
