import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounceTime, Subject } from 'rxjs';
import { InputComponent } from '../input/input.component';
import { SharedModule } from '../../modules/shared.module';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'addiction-search',
	templateUrl: './search.component.html',
	standalone: true,
	imports: [InputComponent, SharedModule],
})
export class SearchComponent {
	@Output() search = new EventEmitter<string | null>();

	@Input() placeholder = 'SEARCH.PLACEHOLDER';
	@Input() instantSearch = false;
	@Input() set value(val: string | null | undefined) {
		this._value = val || '';
		this.emit();
	}

	get value() {
		return this._value;
	}

	@Input() resettable: boolean = false;

	_value: string | null = null;
	valueSubj = new Subject<string | null>();
	changes$ = this.valueSubj.asObservable().pipe(debounceTime(100));

	constructor() {
		this.changes$.pipe(takeUntilDestroyed()).subscribe((val) => {
			this.search.emit(val);
		});
	}

	emit(key?: Event) {
		if (this.instantSearch) {
			this.valueSubj.next(this._value?.trim() ?? null);
		} else {
			if ((key instanceof KeyboardEvent && (key?.code === 'Enter' || 'NumpadEnter')) || key instanceof PointerEvent) {
				this.valueSubj.next(this._value?.trim() ?? null);
			}
		}
	}

	reset() {
		this._value = null;
		this.search.emit(null);
	}
}
