import { BulkActionEnum } from './bulk-action.enum';
import { FilterParams } from './filterParams';

export interface AssetUpdateBody {
	targetName?: string;
	targetUUIDs?: string[];
	entityUUIDs?: string[];
	entityUUIDsMode: 'exclusive' | 'inclusive';
	// entityFilter?: { [p: string]: string };
	entityFilter?: FilterParams;
	bulkAction: BulkActionEnum;
}
