<ng-container *ngIf="headerTemplate" [ngTemplateOutlet]="headerTemplate"></ng-container>
<ng-container *ngIf="tableHeaderTemplate && _itemsRows.length" [ngTemplateOutlet]="tableHeaderTemplate"></ng-container>
<cdk-virtual-scroll-viewport
	[appendOnly]="false"
	[itemSize]="itemSize"
	class="list-viewport"
	(scrolledIndexChange)="onScroll()"
	[class.hidden]="!_itemsRows.length"
	[ngClass]="{
		'with-header': headerTemplate,
		'with-header-template': tableHeaderTemplate && _itemsRows.length
	}">
	<div class="list-row" *cdkVirtualFor="let item of _itemsRows" (click)="rowClick.emit(item)">
		<ng-container *ngIf="rowTemplate" [ngTemplateOutlet]="rowTemplate" [ngTemplateOutletContext]="{item}"></ng-container>
	</div>
</cdk-virtual-scroll-viewport>
<ng-container *ngIf="noItemsTemplate && !_itemsRows.length" [ngTemplateOutlet]="noItemsTemplate"> </ng-container>
